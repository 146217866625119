import { UpdateStamp } from '../../UpdateStamp';
import { Suspend, GeoTag, Revalidation, AutoLapse, CreateNew,ClosedBy, LMSIntegration, EmailNotification, SMSNotification, AutoClose, PermitControlRole } from './PermitControlRole';
import { HierarchyViewModel } from '../../HierarchyViewModel';
import { EmailToSiteConfiguration } from './EmailToSiteConfiguration';
import { Symbology } from '../../Map/SymbologyViewModel';

export class PermitControl extends UpdateStamp {
    PermitControlId: number
    PermitTypeVersionId: number
    Suspend: boolean
    GeoTag: boolean
    Revalidation: boolean
    AutoLapse: boolean
    AutoClose:boolean
    CreateNew: boolean
    ClosedBy: boolean
    IssuedDateTimeEditable:boolean
  
    LmsIntegration: boolean
    EmailNotification: boolean
    SmsNotification: boolean
    rolesForSuspend: Suspend[]
    rolesForGeoTag: GeoTag[]
    rolesForRevalidation: Revalidation[]
    revalidationMaxOccurance: number
    rolesForCreateNew: CreateNew[]
    rolesForClosedBy : ClosedBy[]
    rolesForLMSIntegration: LMSIntegration[]
    rolesForEmailNotification: EmailNotification[]
    rolesForSMSNotification: SMSNotification[]
    AutoLapseHours?: AutoLapse | null;
    AutoCloseHours?:AutoClose |null;
    MaxOccurrance?: Revalidation | null
    SelectedTimeOptionId: number
    EmailToSiteConfigurations: EmailToSiteConfiguration[] | null
    SymbologyList: Symbology[] | null
    PermitControlRole:PermitControlRole[]|null
    PermitExpireDateTimeText :string
    PermitIssueDateTimeText :string
    PermitNoText: string
    SiteText :string
    AreaText : string
    PermitRequestorText:string

}