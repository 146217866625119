import { Attachment } from './Attachment';

export class PermitCreatedByUserViewModel {
    UserId: number;
    LoginId:string;
    FullName:string;
    EmailAddress:string;
    MobileNumber:string;
    SiteName:string;
    Attachment: Attachment | null;
}