import { PermitTypeQuestion } from './PermitBuilder/PermitDivision/PermitTypeQuestion';
import { TableTypeResponse } from './TableTypeResponse';
import { UpdateStamp } from './UpdateStamp';
import { ResponseAttachment } from './ResponseAttachment';
import {SubQuesResponse} from  './SubQuesResponse';
 
export class Response extends UpdateStamp
{
    ResponseId: number
    PermitTypeQuestionId:number
    ResponseText: string
    SelectedAnswerOptionId: number 
    PermitTypeQuestionRef: string|null 
    PermitTypeAnswerOptionRef : string|null  
    Question: PermitTypeQuestion
    TableTypeResponse: TableTypeResponse[]
    Approved: boolean
    Edited: boolean
    Rejected: boolean
    ResponseAttachment: ResponseAttachment[]
    SubQuesResponse: SubQuesResponse[]
}