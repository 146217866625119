import { Response } from './Response';
import { UpdateStamp } from './UpdateStamp';
import { PermitState } from './PermitState';
import { PermitActivityLog } from './PermitActivityLog';
import { PermitWorkflowViewModel } from './PermitWorkflowViewModel';
import { GISMapViewModel } from './Map/GISMapViewModel';
import { PermitTypeVersion } from './PermitBuilder/PermitTypeVersion';
import { UserForSectionViewModel } from './UserForSectionViewModel';
import { RoleGroupApproval } from './UserForRoleGroupViewModel';
import { PermitIdentifier } from '../ViewModel/PermitIdentifier';
import { PermitAssetAndWorkOrder } from '../ViewModel/PermitAssetAndWorkOrder';
import { ChangeLog } from './Dashboard/ChangeLog';

export class Permit extends UpdateStamp {
    PermitId: number
    PermitNumber: string
    PermitIssuedDateTime?: any
    PermitExpiryDateTime?: Date
    RevalidationHours: number
    PermitStateId: number
    SiteId: number;
    SiteName: string;
    SRUID: number ;
    SRUName: string;
    CreatedByName:string;
    WorkOrderNo: string;
    PermitTypeId: number
    PermitTypeVersionId: number
    CsePermitRegisterId?: number
    CsePermitRegistryProcedureId?: number
    PermitState: PermitState
    Responses: Response[]
    PermitActivityLogs: PermitActivityLog[]
    PermitRevisionId?: number;
    CommentsCount?: number;
    PrintWithoutAttachment?: boolean;
    PermitWorkflowViewModel: PermitWorkflowViewModel;
    GISMapViewModel: GISMapViewModel;
    PermitTypeVersion: PermitTypeVersion;
    RoleGroupApproval:RoleGroupApproval[];
    DynamicFilter: string;
    PermitIdentifier: PermitIdentifier[];
    PermitAssetAndWorkOrder: PermitAssetAndWorkOrder[];
    ClosedByName: string;

    IdentifierVal1: string;
    IdentifierVal2: string;
    IdentifierVal3: string;
    IdentifierVal4: string;
    IdentifierVal5: string;
    IdentifierVal6: string;
    IdentifierVal7: string;
    LanguageId?: any;
    permitIssuedDateTimeStr: string;
    permitExpiryDateTimeStr: string;
    ChangeLog?: ChangeLog[];
}
