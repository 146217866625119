import { Attributes } from "./Attributes";
import { HierarchyTranslation } from "./HierarchyTranslation";
import { SubBU } from './SubBU';
import { SubSite } from './SubSite';
import { SRU } from './sru';
import { Region } from './Region';
import { Site } from './Site';
import { Department } from './Department';
import { Crew } from './Crew';


export class BU {
    BUId: number;
    BUName: string;
    Active: boolean;
    CreatedBy: string;
    CreatedDate: Date;
    ModifiedBy: string;
    ModifiedDate: Date;
    Attributes?: Attributes[] | null;
    IsEditable: boolean;
    HierarchyTranslations?: HierarchyTranslation[] | null;
    Regions?: Region[] | null;
    SubBUs?: SubBU[] | null;
    Sites?: Site[] | null;
    SubSites?: SubSite[] | null;
    SRUs?: SRU[] | null;
    Departments?:Department[]|null;
    Crews?:Crew[]|null;
}
