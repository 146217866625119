import { Component, OnInit, DoCheck } from '@angular/core';
import { MessageService, SelectItem, ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../Services/notification.service';
import { HierarchyTree } from '../ViewModel/HierarchyTree';
import { PermitType, PermitTypeViewModel } from '../ViewModel/PermitBuilder/PermitType';
import { CategoryService } from '../Services/category.service';
import { Category } from '../ViewModel/Category';
import { PermitTypeService } from '../Services/permit-type.service';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { CommonService } from '../Services/common.service';
import { PermitActionService } from '../Services/permit-action.service';
import { PermitActionViewModel } from '../ViewModel/PermitActionViewModel';
import { UserService } from '../Services/user.service';
import { User } from '../ViewModel/User';

@Component({
  selector: 'wps-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  providers: [MessageService, TranslateService, NotificationService]
})
export class LayoutComponent implements OnInit, DoCheck {

  containerHeight = window.innerHeight - 52;
  _currentLoggedInUser: User
  notificationCount: string = "0";
  loggedUserId: string = "";
  loadingCompleted: boolean = false;
  suspendIconFlag = true;
  SuspendIconPath = "../../assets/Images/suspend.png";
  UnsuspendIconPath = "../../assets/Images/unsuspend.png";

  // Suspend All permits
  showSuspendAllPopUp: boolean
  selectedSuspendOption: string = ''
  _objArrCategories: Category[] = []
  //userHierarchy: HierarchyTree
  _previousHierarchy: HierarchyTree
  _objArrPermitType: PermitType[] = [];
  _objArrPermitTypeNames: SelectItem[] = [];
  selectedPermitTypesForSuspend: any[] = [];

  _ddlCategory: number = 0
  _ddlPermitType: number = 0
  attrHeader: string
  permitsSuspended: boolean = false
  permitsUnSuspended: boolean = true

  siteTobeSuspended: boolean = false;
  permitTypeTobeSuspended: boolean = false;
  filteredCategories: any[] = [];
  selectedCategory: Category
  _topMargin:number = 0;

  constructor(private _notificationService: NotificationService, private _messageService: MessageService, private translate: TranslateService,
    private _categoryService: CategoryService, private _permitTypeService: PermitTypeService,
    private _commonService: CommonService, private _permitActionService: PermitActionService,
    private _confirmationService: ConfirmationService, private _userService: UserService) { }

  get userHierarchy(): HierarchyTree {
    return this._commonService.getUserHierarchy();

  }
  set userHierarchy(value: HierarchyTree) {
    this._commonService.setUserHierarchy(value);
  }

  ngOnInit() {

    let containerHeight = window.innerHeight - 117;
    let heightOutsideCardContainer = containerHeight - 480;
    this._topMargin = heightOutsideCardContainer / 3;

    //TODO: Bind it from service
    //this.notificationCount = "3";
    this.loggedUserId = localStorage.getItem("_loginId");
    if (localStorage.getItem("_page_load") && localStorage.getItem("_page_load") == "completed") {
      this.loadingCompleted = true;
    }
    // if (localStorage.getItem("_loginId")) {
    //   this.GetNotificationCount(this.loggedUserId);
    // }
  }



  ngDoCheck(): void {
    let userHier = new HierarchyTree();
    if (this.userHierarchy) {
      userHier = Object.assign({}, this.userHierarchy);
      if (this._previousHierarchy) {
        if (this._previousHierarchy.BuId != userHier.BuId || this._previousHierarchy.RegionId != userHier.RegionId
          || this._previousHierarchy.SubBuId != userHier.SubBuId || this._previousHierarchy.SiteId != userHier.SiteId
          || this._previousHierarchy.SubSiteId != userHier.SubSiteId || this._previousHierarchy.SruId != userHier.SruId
          || this._previousHierarchy.DepartmentId != userHier.DepartmentId || this._previousHierarchy.CrewId != userHier.CrewId) {
          // this.GetAllCategory();
          // this.GetSuspendedPermitsList(userHier.SiteId, true);
        }
      }
      this._previousHierarchy = userHier
    }
  }

  GetNotificationCount(loggedUserId: string) {
    this._notificationService.GetNotificationCount(loggedUserId).subscribe(
      (data: string) => {
        this.notificationCount = data;
      },
      () => {
        this._messageService.clear();
        //this._messageService.add({key: 'error', sticky: true,severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail:  this.translate.instant('UserNotification.ErrorMessage')});
      }
    )
  }

  featureNotAvailableMessage(imgRef) {
    this._messageService.clear('error');
    this._messageService.add({ key: 'info', sticky: true, severity: 'info', summary: this.translate.instant('infoMsgHeader'), detail: this.translate.instant('FeatureNotAvailable') });
    return false;
  }

  onOK() {
    this._messageService.clear('error');
    this._messageService.clear('info');
  }
}