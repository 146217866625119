import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { Message } from 'primeng/api';
import { BU } from '../ViewModel/BU';
import { Region } from '../ViewModel/Region';
import { SubBU } from '../ViewModel/SubBU';
import { Site } from '../ViewModel/Site';
import { SubSite } from '../ViewModel/SubSite';
import { SRU } from '../ViewModel/sru';
import { Department } from '../ViewModel/Department';
import { Crew } from '../ViewModel/Crew';
import { HierarchyTree } from '../ViewModel/HierarchyTree';
import { Contractor } from '../ViewModel/Contractor';
import { Category } from '../ViewModel/Category';
import { PermitTypeDivision } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeDivision';
import { Languages } from '../ViewModel/Languages';
import { User } from '../ViewModel/User';
import { AttributesType } from '../ViewModel/AttributesType';
import { Role } from '../ViewModel/Role';
import { RoleGroupApproval } from '../ViewModel/UserForRoleGroupViewModel';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class HelperService {

  ErrorMsgAdmin: Message[] = [];
  _objArrBU: BU[] = [];
  _objArrRegions: Region[] = [];
  _objArrSubBU: SubBU[] = [];
  _objArrSite: Site[] = [];
  _objArrSubSite: SubSite[] = [];
  _objArrSRU: SRU[] = [];
  _objArrDept: Department[] = [];
  _objArrCrew: Crew[] = [];
  _objArrContractors: Contractor[] = [];
  _objArrCategory: Category[] = [];
  _userCurrentHierarchy: HierarchyTree;
  savedDivisions: PermitTypeDivision[] = [];
  _changedValuePermitBuilder: boolean = false
  _changedValuePermitBuilderTree: boolean = false
  _changedValuePermitBuilderCategory: boolean = false
  _changedValuePermitBuilderPermit: boolean = false
  _disableAllElements: boolean = true;
  _disableAllCategoryElements: boolean = true;
  _loggedInUser: User = new User();
  _treeData: BU[] = [];
  _isHerarchyChanged: boolean = true;
  _languages: Languages[] = [];
  _attributetype: AttributesType[] = [];
  _buChildren: BU[] = [];
  _roleForApproval: RoleGroupApproval[] = [];
  _categories: Category[] = [];
  _roles: Role[] = [];
  _contractors: Contractor[] = [];
  _notificationCount: number = 0;

  public ddlRegion = 0;
  public ddlBU = 0;
  public ddlSite = 0;
  public ddlSubBU = 0;
  public ddlSubSite = 0;
  public ddlSru = 0;
  public ddlDept = 0;
  public ddlCrew = 0;

  @Output() 
  notificationCountEvent = new EventEmitter<string>();

  public EscapeSpecialCharacters(input: string): string {
    let inputText = input;
    let specialCharArray = [
      { 'Character': '!', 'Code': 'U0021' },
      { 'Character': '#', 'Code': 'U0023' },
      { 'Character': '$', 'Code': 'U0024' },
      { 'Character': '%', 'Code': 'U0025' },
      { 'Character': '&', 'Code': 'U0026' },
      { 'Character': '(', 'Code': 'U0028' },
      { 'Character': ')', 'Code': 'U0029' },
      { 'Character': '*', 'Code': 'U002A' },
      { 'Character': '+', 'Code': 'U002B' },
      { 'Character': '-', 'Code': 'U002D' },
      { 'Character': '/', 'Code': 'U002F' },
      { 'Character': '?', 'Code': 'U003F' },
      { 'Character': '@', 'Code': 'U0040' },
      { 'Character': '\\', 'Code': 'U005C' },
      { 'Character': '=', 'Code': 'U003D' },
      { 'Character': '_', 'Code': 'U005F' },
      { 'Character': ':', 'Code': 'U003A' },
      { 'Character': '{', 'Code': 'U007B' },
      { 'Character': '}', 'Code': 'U007D' },
      { 'Character': '[', 'Code': 'U005B' },
      { 'Character': ']', 'Code': 'U005D' },
      { 'Character': ',', 'Code': 'U002C' },
      { 'Character': '"', 'Code': 'U0022' },
      { 'Character': "'", 'Code': 'U0027' }
    ]
    let charArray = Array.from(inputText);
    let stringArray = [];
    for (let objChar of charArray) {
      let obj = specialCharArray.find(x => x.Character == objChar);
      stringArray.push(obj == undefined ? objChar : obj.Code);
    }

    inputText = stringArray.join('');

    return inputText;
  }

  notificationCountEventEmit(notificationCount: string){
    this.notificationCountEvent.emit(notificationCount);
  }

  removeDuplicateFromArray(data){
    return data.filter((value, index) => data.indexOf(value) === index);
  }

  constructor() { }

}
