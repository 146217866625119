import { Component, OnInit, OnChanges, ViewChild } from '@angular/core';
import { Permit } from 'src/app/ViewModel/Permit';
import { Response } from 'src/app/ViewModel/Response';
import { PermitTypeQuestion } from 'src/app/ViewModel/PermitBuilder/PermitDivision/PermitTypeQuestion';
import { TableTypeResponse } from 'src/app/ViewModel/TableTypeResponse';
import { PermitTypeDivision } from 'src/app/ViewModel/PermitBuilder/PermitDivision/PermitTypeDivision';
import { PermitTypeSection } from 'src/app/ViewModel/PermitBuilder/PermitDivision/PermitTypeSection';
import { PermitTypeAnswerOption } from 'src/app/ViewModel/PermitBuilder/PermitDivision/PermitTypeAnswerOption';
import { PermitTypeVersion } from 'src/app/ViewModel/PermitBuilder/PermitTypeVersion';
import { PermitService } from 'src/app/Services/permit.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, ConfirmationService, SelectItem, TreeNode } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { PermitTypeService } from 'src/app/Services/permit-type.service';
import { HierarchySearchViewModel } from 'src/app/ViewModel/HierarchySearchViewModel';
import { PermitTypeViewModel } from 'src/app/ViewModel/PermitBuilder/PermitType';
import { PermitState } from 'src/app/ViewModel/PermitState';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { CommonService } from '../Services/common.service';
import html2canvas from 'html2canvas';
// import * as jspdf from 'jspdf';
import { PermitTypeVersionService } from '../Services/pemit-type-version.service';
import { HierarchyTree } from '../ViewModel/HierarchyTree';
import { PermitTypeTableFieldType } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeTableFieldType';
import { User } from '../ViewModel/User';
import { PermitStates as Status, PermitStates } from 'src/app/ViewModel/helpers/PermitStates'
import { PermitActivityLog } from '../ViewModel/PermitActivityLog';
import { PermitTypeTableAnswerOption } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeTableAnswerOption';
import { DynamicDataSourceService } from '../Services/dynamicdatasource.service';
import { DynamicDataSourceOption } from '../ViewModel/DynamicDataSourceOption';
import { MediaService } from '../Services/media.service';
import { PermitControlService } from '../Services/permit-control.service';
import { PermitControl } from '../ViewModel/PermitBuilder/PermitControl/PermitControl';
import { Attachment } from '../ViewModel/Attachment';
import { Location, DatePipe } from '@angular/common';
import { UserService } from '../Services/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Role } from '../ViewModel/Role';
import { Category } from '../ViewModel/Category';
import { RoleService } from '../Services/role.service';
import { CategoryService } from '../Services/category.service';
import { PermitCreatedByUserViewModel } from '../ViewModel/PermitCreatedByUserViewModel';
import { Comment } from '../ViewModel/comment';
import { ChangelogService } from '../Services/changelog.service';
import { PermitTypeLevelApproval } from '../ViewModel/PermitBuilder/PermitTypeLevelApproval';
import { TimeOption } from '../ViewModel/helpers/TimeOption';
import { RevalidateService } from '../Services/revalidate.service';
import { PermitRevision } from '../ViewModel/PermitRevision';
import { ResponseAttachment } from '../ViewModel/ResponseAttachment';
import { PermitWorkFlow } from '../ViewModel/PermitWorkFlow';
import { PermitWorkflowViewModel } from '../ViewModel/PermitWorkflowViewModel';
import { GISMapViewModel } from '../ViewModel/Map/GISMapViewModel';
import { GeoTagService } from '../Services/geotag.service';
import { Dialog } from 'primeng/dialog';
import { environment } from 'src/environments/environment';
import { RoleTrainingStateViewModel } from '../ViewModel/RoleTrainingStateViewModel';
import { IndexedDBService } from '../Services/indexedDB.service';
import { UserForRoleGroupViewModel, UserShortInfo, RoleGroupApproval, RoleGroupUserDetails } from '../ViewModel/UserForRoleGroupViewModel';
import { UserForSectionViewModel } from '../ViewModel/UserForSectionViewModel';
import { Group, exportPDF } from '@progress/kendo-drawing';
import { HelperService } from '../Services/helper.service';
// import * as JSZip from 'jszip';
// import * as FileSaver from 'file-saver';
// import JSZipUtils from "jszip-utils";
// import { saveAs } from '@progress/kendo-drawing/pdf';
// import { RadioButtonModule } from 'primeng/radiobutton';
import { saveAs } from '@progress/kendo-file-saver';
import { PrintPdfSelectedComponent } from '../print-pdf-selected/print-pdf-selected.component';
declare var jspdf: any;

@Component({
  selector: 'app-work-permit',
  templateUrl: './work-permit.component.html',
  styleUrls: ['./work-permit.component.css'],
  providers: [DatePipe, IndexedDBService]
})
export class WorkPermitComponent implements OnInit, OnChanges {

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.userHierarchy = changes.selectedHierarchy.currentValue;
  }

  constructor(private _location: Location, private _commonService: CommonService, private _spinner: SpinnerVisibilityService,
    private _permitService: PermitService, private translate: TranslateService, private _permitControlService: PermitControlService,
    private _messageService: MessageService, private _confirmationService: ConfirmationService, private route: ActivatedRoute, private _router: Router,
    private _permitTypeService: PermitTypeService, private _permitTypeVersionService: PermitTypeVersionService,
    private _dynamicDataSourceService: DynamicDataSourceService,
    private _mediaService: MediaService, private _userService: UserService,
    private _helperService: HelperService,
    private sanitizer: DomSanitizer, private _roleService: RoleService,
    private _datePipe: DatePipe, private _revalidateService: RevalidateService, private _geotagService: GeoTagService,
    private _categoryService: CategoryService, private _indexDbService: IndexedDBService
  ) { }

  public showdownloadmodal = false;
  public showError = false;
  public zipcontentURL: any = [];
  public selectedvalue = '';
  clsBorderColor: string = "#dbdbdb";
  permitContainerHeight: number;
  displayPreview: boolean = false;
  displayuserforrole: boolean = false
  permits: PermitTypeVersion[] = [];
  permit: PermitTypeVersion;
  au: any;
  _addEditUserRoleObj = new UserShortInfo();
  assetDetails: any[] = [];
  lstboxAssetDetails = [];
  tempAssetDetails: any[] = [];
  entryLeaders;
  selectedUserShortInfo: string[] = [];
  rows: any[];
  _sectionId: any[] = [];
  usershortinfoforroles: any[] = [];
  _PermitVersion: PermitTypeVersion;
  _PermitVersionForUser: PermitTypeVersion;
  _PermitVersionList: PermitTypeVersion[] = [];
  _permitTypeName: string
  _divisions: PermitTypeDivision[] = []
  _sections: PermitTypeSection[] = []
  _questions: PermitTypeQuestion[] = []
  _answerOptions: PermitTypeAnswerOption[] = []
  _permitObj: Permit = new Permit();
  isLMSIntegrationEnabled : boolean = false;
  _question: PermitTypeQuestion;
  _userbutton: boolean = false;
  ddlUserForRole: number = 0
  _tableTypeResponse: TableTypeResponse[] = []
  _permitTypeAbbr: string = ''
  _screenGrab: any;
  _showImage: boolean = false;
  _permitTypeId: number
  _permitId: number
  _UserForRole: UserForRoleGroupViewModel[] = [];
  _permitTypeSiteAbbr: string = ''
  _permitNumber: string = ''
  _permitType: PermitTypeViewModel;
  _permitTypeBUName: string;
  _permitTypeRegionName: string;
  _permitTypeSubBUName: string;
  _permitTypeSiteName: string;
  _permitTypeSubSiteName: string;
  _permitTypeSRUName: string;
  _permitTypeDeptName: string;
  _permitTypeCrewName: string;
  _permitBackColor: string
  _permitForeColor: string
  _permitState: PermitState
  _userButton: boolean = false;
  _userForBind: UserForSectionViewModel[] = []
  _userForApprover: UserForSectionViewModel[] = []
  totalHeight: number = 0;
  sub: any
  counter: number = 0;
  checkRevalidation: boolean = false;
  _currentLoggedInUser: User;
  _currentLoggedInUserRoles: any[] = [];
  _roles: Number[] = [];
  defaultValues: boolean = true; // to disable the first four textboxes in the form
  isPDFFlag: boolean = false;
  isPDFFlagRow: boolean = false;
  isEnabled: boolean = false;
  selectedDropdownText: string;
  selectedDropdownText1: string;
  selectedDropdownText2: string;
  permitTypeImage: any;
  revalidationHours: number = 12
  revalidationPeriod: string = ''
  displayStatusPopUp: boolean = false;
  permitApprovalStatus: any;
  assetListFlag: boolean = false;
  assetDescFlag: boolean = false;
  entryLeaderFlag: boolean = false;
  selectedAsset;
  selectedAssetID;
  selectedAssetDesc;
  selectedEntryLeader;
  allRoles: Role[] = [];
  _category: Category[] = [];
  _categoryIds: number[] = [];
  _objArrPermitTypeIds: number[] = []
  allRolesFiltered: Role[] = [];
  permitCreatedByUser: PermitCreatedByUserViewModel;
  userProfilePicture: any;
  showStatus: boolean = true;
  previewFlag: string = "0";

  _allDynamicDatasourceOptions: any[] = [];
  _permitActivityLog: PermitActivityLog[] = [];
  _activeQuestions: number[] = [];
  _sequenceList: any[];
  _activeSequence: any[];

  _canCopyPermit: boolean;
  _copyPermit: boolean = false;
  _displayPermitSelectPopUp: boolean = false;
  _selectedPermitId: number
  popUpHeader: string
  _permits: Permit[] = [];
  filteredPermits: string[] = [];
  _permitNumbers: string[] = [];
  _selectedPermit: Permit;
  _clonedPermitObj: Permit;
  _resetFlag: boolean = false;
  _showApproverDetail: boolean = false;
  _approverUserDetail: any;
  isEntryLeader: boolean = false;
  _EntryLeaderName: string;
  takeOverMessage: string;
  takeOverHeader: string;
  isPermitActive: boolean = false;//false
  hasEntryLeaderQuestion: boolean = false;//false
  isConfinedSpace: boolean = false;
  entryLeaderName: string = "";
  _permitTypeLevelApprovalList: PermitTypeLevelApproval[] = [];
  _activePermitLevelSequence: number;
  displayMap: boolean;
  geoTag: boolean = false;
  _PermitVersionPdf: PermitTypeVersion;
  _printableSections: string[] = [];
  _pritableSectionTreeNode: TreeNode[] = [];
  _selectedSectionToPrint: TreeNode[] = [];
  _sectionToPrint: string[] = [];
  _permitPdf: Permit;
  permitCreatedByUserPdf: PermitCreatedByUserViewModel;
  private _ObjArrResponseAttachment: ResponseAttachment[] = []
  responseAttachedFiles: any;
  responseAttachments: any[] = [];
  responseAttachmentFiles: any[] = [];
  uploadedFiles: any[] = [];
  commentBoxIsVisible: boolean = false;

  _displayCommentPopUp: boolean = false; // This is for entering comments while approving the permit
  _commentFlag: number;
  _approverComments: string = null;
  _commentsCount: number = 0;
  _roleForUser: RoleGroupApproval[] = [];
  _canBeRevalidated: boolean = false
  _maxOccursExhausted: boolean = false
  revisionCounter: number = 0;
  _permitControl: PermitControl = new PermitControl();
  count = 0;

  finalAttachmentList: any[] = []
  // _excelContentType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  // _pdfContentType: string = 'application/pdf'
  // _docxContentType: string = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  // _docContentType
  // _pptContentType: string = ''
  // _imageContentType: string = ''
  _docxContentType: string = ''
  _docContentType: string = ''
  _xlsxContentType: string = ''
  _xlsContentType: string = ''
  _pdfContentType: string = ''
  _pptxContentType: string = ''
  _pptContentType: string = ''
  screengrab: any;

  isMailRequired: boolean = false;
  _excelThumbnailIconPath: string = '../../assets/Images/excel-icon.png'
  _pdfThumbnailIconPath: string = '../../assets/Images/pdf-icon.png'
  _imageThumbnailIconPath: string = '../../assets/Images/image-icon.png'
  _wordThumbnailIconPath: string = '../../assets/Images/word-icon.png'
  _pptThumbnailIconPath: string = '../../assets/Images/ppt-icon.png'
  _allowedFileTypes: string = ''
  _maxAttachmentFileSize: number

  _permitWorkflowViewModel: PermitWorkflowViewModel;

  // event capturing
  _buttonSaveClicked: boolean = false;
  _buttonSubmitClicked: boolean = false;
  _buttonApproveClicked: boolean = false;
  _buttonRejectClicked: boolean = false;
  _buttonUserApproverClicked: boolean = false;

  _divisionIndex: number = 0;
  _sectionIndex: number = 0;
  _questionIndex: number = 0;
  _mailDistributionRequired: boolean = false;
  @ViewChild('pdfExport') pdfExport;
  @ViewChild('pdfSelectedExport') pdfSelectedExport;
  @ViewChild('childPrint') childPDFComponent: PrintPdfSelectedComponent;
  _allSectionNames: string[] = [];
  lastSectionName: string = '';

  // LMS training check
  _trainingNotPresentForRole: RoleTrainingStateViewModel[] = [];
  _roleTrainingStateList: RoleTrainingStateViewModel[] = [];
  _displayLMSErrorForUser: boolean = false;
  _isRevalidate: boolean = false;
  previousEntryleader: string;
  entryLeaderRole:number[]=[];

  _db: IDBDatabase;

  _roleGroupApprover: RoleGroupApproval[] = [];
  _submitButtonClicked: boolean = false;
  isRevalidateBeforeLapse : boolean = false;
  isBtnEntryLeaderVisible: boolean = false;

  get userHierarchy(): HierarchyTree {
    return this._commonService.getUserHierarchy();
  }

  set userHierarchy(value: HierarchyTree) {
    this._commonService.setUserHierarchy(value);
  }

  GoBack() {
    this._location.back();
    // let redirectURL = '/createpermit';
    // this._router.navigate([redirectURL]);
  }

  ngOnInit() {
    // this.permitContainerHeight = window.innerHeight - 273;
    this._EntryLeaderName = localStorage.getItem("_userName");
    this.permitContainerHeight = window.innerHeight - 179;
    this._allowedFileTypes = environment.ALLOWEDFILETYPES;
    this._maxAttachmentFileSize = environment.MAXIMUMATTACHMENTFILESIZE;
    this._docxContentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    this._docContentType = 'application/msword';
    this._xlsxContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    this._xlsContentType = 'application/vnd.ms-excel';
    this._pdfContentType = 'application/pdf';
    this._pptxContentType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    this._pptContentType = 'application/vnd.ms-powerpoint';
    this.getPermitTypeIdFromURL();


    this.InitializeDb();

  }

  getPermitTypeIdFromURL() {
    this.sub = this.route.params.subscribe(params => {
      // console.log('params', params);
      this._permitTypeId = +params['permitTypeId'];
      this._permitId = +params['permitId'];
      if (this._permitId == 0) {
        sessionStorage.removeItem("mapJson");
        sessionStorage.removeItem("screengrab");
      }
      this.GetUserDetails();
      //  this.getPermitControlsForPermitVersion();
    });
  }


  // User details is needed to get the roles of the Logged-In user
  GetUserDetails() {
    let userLoginId: string
    userLoginId = localStorage.getItem("_loginId");

    this._userService.getUserWithRoleBasedOnPermit(userLoginId, this._permitId).subscribe(
      (data: User) => {
        if (data != null) {
          // intentionally kept blank as we needed the else part only
          if (this._permitObj && this._permitObj.PermitId > 0) {
          }
          else {
            this.permitCreatedByUser = new PermitCreatedByUserViewModel();
            this.permitCreatedByUser.FullName = data.LastName.toUpperCase() + ', ' + data.FirstName.toUpperCase();
          }
          this._currentLoggedInUser = data;
          if (this._currentLoggedInUserRoles) {
            this._currentLoggedInUserRoles = this._currentLoggedInUser.UserRoles.filter(x => x.Active == true);
          }
          // console.log("this._currentLoggedInUserRoles ",this._currentLoggedInUserRoles)
          // debugger;
          this._currentLoggedInUserRoles.forEach(x=>{
            this._roles.push(x.Role.RoleId);
            //debugger;
            if(x.Role.RoleKey == "ENTRYLEADER" && this.userHierarchy && this.userHierarchy.SiteId != null)
            {
              if(x.Region.RegionId == this.userHierarchy.RegionId && x.Site.SiteId == this.userHierarchy.SiteId) {
                this.entryLeaderRole.push(x.Role.RoleId);
                this.isEntryLeader = true;
              }
              else if(x.Region.RegionId == this.userHierarchy.RegionId && (x.Site.SiteId == null || x.Site.SiteId == 0)) {
                this.entryLeaderRole.push(x.Role.RoleId);
                this.isEntryLeader = true;
              }
            }
          });
          //console.log( this.entryLeaderRole)
          // console.log('RoleID - ',this._roles);
        }
        this.getAllRoles();
        this.getCSECategory();
        // this.getUserwithclosedbyRoles();
      })

  }

  getAllRoles() {
    this._roleService.getAllRoles().subscribe((data: Role[]) => {
      if (data != undefined) {
        this.allRoles = data;
        this.allRolesFiltered = this.allRoles.filter(x => x.Active == true);
        //this.getPermitTypeIdFromURL();
        this.getPermitVersionDetails(this._permitTypeId);
        this.getLocalCalender();
      }
    })
  }
  getCSECategory() {
    //  this._categoryService.getAllCategory().subscribe(
    // debugger;
    this._categoryService.searchCategory('CONFINED SPACE', null, null).subscribe(
      (data: Category[]) => {
        this._category = [];
        this._category = data;
        this._category.forEach(x => {
          this._categoryIds.push(x.Category.CategoryId);
        });
        if (this._categoryIds.length > 0) {
          this.getCSEPermitTypeIDs();
        }
      }
    );
    // console.log('CategoryID', this._categoryIds);
  }
  getCSEPermitTypeIDs() {
    // debugger;
    let objHierarchySearchViewModel = new HierarchySearchViewModel();
    objHierarchySearchViewModel.CategoryIds = this._categoryIds;
    objHierarchySearchViewModel.BuId = this.userHierarchy.BuId;
    objHierarchySearchViewModel.RegionId = this.userHierarchy.RegionId;
    objHierarchySearchViewModel.SubBuId = this.userHierarchy.SubBuId;
    objHierarchySearchViewModel.SiteId = this.userHierarchy.SiteId;
    objHierarchySearchViewModel.SubSiteId = this.userHierarchy.SubSiteId;
    objHierarchySearchViewModel.SruId = this.userHierarchy.SruId;
    objHierarchySearchViewModel.DepartmentId = this.userHierarchy.DepartmentId;
    objHierarchySearchViewModel.CrewId = this.userHierarchy.CrewId;

    this._permitTypeService.getPublishedPermitTypes(objHierarchySearchViewModel).subscribe(
      (data: PermitTypeViewModel[]) => {
        //  console.log('PermitTypeResponse', data);
        this._objArrPermitTypeIds = [];
        data.forEach(x => {
          this._objArrPermitTypeIds.push(x.PermitType.PermitTypeId);
        });
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.searchErrorPermit') });
      }
    );
  }

  OLD_getPermitVersionDetails(permitTypeId) {
    //this._permitTypeVersionService.getPermitTypeVersion(permitTypeId).subscribe((permitTypeVersion: PermitTypeVersion[]) => {
    this._permitTypeVersionService.getPermitTypeVersionWithActiveQuestions(permitTypeId, true).subscribe((permitTypeVersion: PermitTypeVersion[]) => {
      if (permitTypeVersion.length > 0) {
        this._PermitVersionList = permitTypeVersion;
        this._PermitVersion = new PermitTypeVersion();
        this._PermitVersion = permitTypeVersion.find(p => p.PublishStatus == true);
        // this._PermitVersion.Divisions.forEach(objDivision=>{
        //   objDivision.Sections.forEach(objSection=>{
        //     objSection.Questions.forEach(objQusetion=>{
        //       objQusetion.QuestionText=objQusetion.QuestionText.replace(/<[^>]*>/g, '')
        //     })
        //   })


        // })

        this._permitTypeLevelApprovalList = [];
        this._permitTypeLevelApprovalList = this._PermitVersion.PermitType.PermitTypeLevelApprovals.filter(x => x.Active == true);
        this.setDefaultValues();

        this.getPermiTypeDetails(this._PermitVersion.PermitTypeId);



        //this.MapTableResponseValues();
        if (this._permitId > 0) {
          this.finalAttachmentList = [];
          this.responseAttachedFiles = [];
          this.responseAttachmentFiles = []
          //this.getPermit(this._permitId, false);
        }
        else {
          this.AddRowsToTables();
        }
        this.rows = [];
        this.rows.push({ Prop: "Prop" });
        //this._currentLoggedInUserRoles = this.GetCurrentLoggedInUserRoles();
      }
      else {
        this._messageService.clear('error');
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.NoVersionAvailable') });
      }

    });
  }

  getOptionValue(event) {
    event.target.parentElement.lastElementChild.innerHTML = event.target.options[event.target.options.selectedIndex].text;
  }

  getLocalCalender() {
    this.au = {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',

      clear: 'Clear'
    }
  }

  Counter(value: number) {
    this.counter = this.counter + value;
    return true;
  }

  SelectAssetID(divisionIndex, sectionIndex, questionIndex) {

    let siteId = this._permitObj.SiteId ? this._permitObj.SiteId : this.userHierarchy.SiteId;
    this.assetDetails = [];
    this._permitService.getAssetDetails(siteId).subscribe(res => {
      this.assetDetails = res;
      this.assetListFlag = true;
    });
    this._divisionIndex = divisionIndex;
    this._sectionIndex = sectionIndex;
    this._questionIndex = questionIndex;
  }


  selectAsset(value, description) {

    this._PermitVersion.Divisions[this._divisionIndex].Sections[this._sectionIndex].Questions[this._questionIndex].FieldValue = value;
    this.assetListFlag = false;
    this._PermitVersion.Divisions[this._divisionIndex].Sections[this._sectionIndex].Questions[this._questionIndex].FieldDesc = description;
    // document.getElementById("selectAssetDesc").removeAttribute("disabled");
  }

  selectDesc(value, divisionIndex, sectionIndex, questionIndex) {


    this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].FieldDesc = value;

    this.assetDescFlag = false;
    document.getElementById("links").removeAttribute("disabled");
  }


  SelectEntryLeader(divisionIndex, sectionIndex, questionIndex) {

    // this._permitService.getEntryLeader().subscribe(res => {
    //   this.entryLeaders = res
    //   this.entryLeaderFlag = true;
    // });
    this._userService.getEntryLeader(this.userHierarchy.RegionId, this.userHierarchy.SiteId).subscribe(res => {
      this.entryLeaders = res
      this.entryLeaderFlag = true;
    });
    this._divisionIndex = divisionIndex;
    this._sectionIndex = sectionIndex;
    this._questionIndex = questionIndex;
  }



  selectLeader(value: string) {

    this.previousEntryleader = this._PermitVersion.Divisions[this._divisionIndex].Sections[this._sectionIndex].Questions[this._questionIndex].AnswerText;
    this._PermitVersion.Divisions[this._divisionIndex].Sections[this._sectionIndex].Questions[this._questionIndex].AnswerText = value.toUpperCase();
    this.TakeOver(false);
    this.entryLeaderFlag = false;
  }


  // getPermitTypeIdFromURL() {
  //   this.sub = this.route.params.subscribe(params => {
  //     this._permitTypeId = +params['permitTypeId'];
  //     this._permitId = +params['permitId'];
  //   });
  // }

  ExportToPdfEdit() {
    this._spinner.show();
    // for (var i = 0; i < document.getElementsByClassName("divDropDownRow").length; i++) {

    //   document.getElementsByClassName("divDropDownRow")[i].children[1].innerHTML = ((document.getElementsByClassName("divDropDownRow")[i].children[0]) as HTMLSelectElement).options[((document.getElementsByClassName("divDropDownRow")[i].children[0]) as HTMLSelectElement).options.selectedIndex].text

    // }
    this.isEnabled = true;
    this.isPDFFlag = true;
    this.isPDFFlagRow = true;
    this.previewFlag = "1";

    setTimeout(() => {

      this.ExportToPdf()
    }, 0);

  }
  // async ExportToPdf() {

  //   if (document.getElementById('pnlPreviewTemp')) {
  //     document.getElementById('divPreviewTemp').style.height = '100%';
  //   }
  //   if (document.getElementById('printPermit')) {
  //     document.getElementById('printPermit').style.marginTop = '12%';
  //   }

  //   //To add page break in PDF generation
  //   // Delaying execution so that the html gets parsed properly
  //   await this.DelayExecution();
  //   var windowWidth = window.innerWidth;
  //   var pdfWidth = 210;
  //   var pdfHeight = 297;
  //   var windowHeight = windowWidth * pdfHeight / pdfWidth;
  //   windowHeight = Math.floor(windowHeight);


  //   // let offsetHeight = (windowHeight < 2000) ? 0 : 100;
  //   // offsetHeight = 0;


  //   // var sections = document.getElementsByClassName("tblPermitTemplate");
  //   // var header = document.getElementById("divPreviewHeader");

  //   // let totalHeight: number = header.offsetHeight + 20;

  //   // for (let i = 0; i < sections.length; i++) {
  //   //   let table = sections[i] as HTMLTableElement;
  //   //   let sectionHeight = table.offsetHeight;
  //   //   totalHeight = totalHeight + sectionHeight;

  //   //   totalHeight = totalHeight + 5;

  //   //   if (totalHeight > windowHeight) {
  //   //     totalHeight = totalHeight - sectionHeight;

  //   //     for (let j = 0; j < table.rows.length; j++) {
  //   //       var row = table.rows[j] as HTMLTableRowElement;

  //   //       let rowHeight = row.offsetHeight + 0;
  //   //       totalHeight = totalHeight + rowHeight;
  //   //       if (totalHeight > windowHeight) {
  //   //         totalHeight = totalHeight - rowHeight;

  //   //         let flag = this.CheckForInnerTable(row);
  //   //         if (flag == true) {
  //   //           let innerTable = row.firstElementChild.firstElementChild.firstElementChild.firstElementChild as HTMLTableElement;
  //   //           totalHeight = totalHeight + 5;
  //   //           for (let k = 0; k < innerTable.rows.length; k++) {
  //   //             var innerRow = innerTable.rows[k] as HTMLTableRowElement;

  //   //             let innerRowHeight = innerRow.offsetHeight + 2;
  //   //             totalHeight = totalHeight + innerRowHeight;

  //   //             if (totalHeight > windowHeight) {
  //   //               let extraHeight = innerRowHeight - (totalHeight - windowHeight);
  //   //               offsetHeight = (extraHeight > 30) ? 0 : 100;
  //   //               let x = 'extraHeight: ' + extraHeight + ' | totalHeight: ' + totalHeight + ' | windowHeight: ' + windowHeight;
  //   //               let str = '<div class="page-break" style="height:' + (extraHeight + offsetHeight) + 'px;"></div>';
  //   //               let row = innerTable.insertRow(k);
  //   //               let cell = row.insertCell(0);
  //   //               cell.innerHTML = str;
  //   //               totalHeight = offsetHeight == 0 ? 25 : 75;
  //   //             }
  //   //           }
  //   //           totalHeight = totalHeight + 10;
  //   //         }
  //   //         else {
  //   //           totalHeight = totalHeight + rowHeight;
  //   //           if (totalHeight > windowHeight) {
  //   //             let extraHeight = rowHeight - (totalHeight - windowHeight);
  //   //             offsetHeight = (extraHeight > 30) ? 0 : 100;
  //   //             let x = 'extraHeight: ' + extraHeight + ' | totalHeight: ' + totalHeight + ' | windowHeight: ' + windowHeight;
  //   //             let str = '<div class="page-break" style="height:' + (extraHeight + offsetHeight) + 'px;"></div>';
  //   //             let row = table.insertRow(j);
  //   //             let cell = row.insertCell(0);
  //   //             cell.innerHTML = str;
  //   //             totalHeight = offsetHeight == 0 ? 25 : 75;
  //   //           }
  //   //         }
  //   //       }
  //   //     }
  //   //   }
  //   //   totalHeight = totalHeight + 5;
  //   // }

  //   var data = document.getElementById('pnlPreviewTemp');

  //   html2canvas(data).then(canvas => {
  //     var imgWidth = 208;
  //     var pageHeight = 295;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     var heightLeft = imgHeight;
  //     const contentDataURL = canvas.toDataURL('image/png')
  //     let pdf = new jspdf('p', 'mm', 'a4');
  //     var position = 0;
  //     heightLeft -= pageHeight;
  //     //pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
  //     pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight, 'alias', 'FAST')

  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight + 1;//8 
  //       pdf.addPage();
  //       pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight, 'alias', 'FAST')
  //       heightLeft -= pageHeight;
  //     }
  //     pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight, 'alias', 'FAST')
  //     pdf.save(this._permitTypeName + '_' + new Date().toDateString() + '.pdf')
  //     this._spinner.hide();
  //     this.displayPreview = false;

  //     if (document.getElementById('printPermit')) {
  //       document.getElementById('printPermit').style.marginTop = '';
  //     }
  //     var pageBreakDiv = document.getElementsByClassName("page-break");

  //     for (let i = 0; i < pageBreakDiv.length; i++) {
  //       let divObj = pageBreakDiv[i] as HTMLElement;
  //       divObj.parentNode.removeChild(divObj);
  //     }
  //   });

  // }

  async ExportToPdf() {

    if (document.getElementById('printPermit')) {
      document.getElementById('printPermit').style.marginTop = '12%';
    }
    // await this.DelayExecution();
    // let windowHeight = Math.floor(document.getElementById('pnlPreviewTemp').offsetWidth * (297 / 210));
    // windowHeight = windowHeight - 50;
    // var previewDiv = document.getElementById("divPreviewTemp") as HTMLElement;
    // var sections = previewDiv.getElementsByClassName("tblPermitTemplate");

    // let totalHeight = document.getElementById("divPreviewHeader").offsetHeight + 10;
    // //
    // let heightStamp;
    // heightStamp = '<span style="color: yellow;">W: ' + windowHeight + ', S:' + totalHeight + ', T: ' + totalHeight + ', L: ' + (windowHeight - totalHeight) + '</span>';
    // //
    // for (let i = 0; i < sections.length; i++) {
    //   let section = sections[i] as HTMLTableElement;
    //   let sectionHeight = section.offsetHeight + 5;
    //   if ((totalHeight + sectionHeight) < windowHeight) {
    //     totalHeight = totalHeight + sectionHeight;
    //     //
    //     heightStamp = '<span style="color: yellow;">W: ' + windowHeight + ', S:' + sectionHeight + ', T: ' + totalHeight + ', L: ' + (windowHeight - totalHeight) + '</span></th>';
    //     // section.rows[0].innerHTML = section.rows[0].innerHTML.replace('</th>', heightStamp);
    //     //
    //   }
    //   else {
    //     let questions = section.rows;
    //     for (let j = 0; j < questions.length; j++) {
    //       let question = questions[j] as HTMLTableRowElement;
    //       let questionHeight = question.offsetHeight;
    //       if ((totalHeight + questionHeight) < windowHeight) {
    //         totalHeight = totalHeight + questionHeight;
    //         //
    //         if (j == 0) {
    //           heightStamp = '<span style="color: yellow;">W: ' + windowHeight + ', S:' + questionHeight + ', T: ' + totalHeight + ', L: ' + (windowHeight - totalHeight) + '</span></th>';
    //           // section.rows[j].innerHTML = section.rows[j].innerHTML.replace('</th>', heightStamp);
    //         }
    //         else {
    //           let spanList = question.getElementsByTagName('span');
    //           heightStamp = '<span style="color: red;">W: ' + windowHeight + ', S:' + questionHeight + ', T: ' + totalHeight + ', L: ' + (windowHeight - totalHeight) + '</span>';
    //           // spanList[0].innerHTML = spanList[0].innerHTML + heightStamp;
    //         }

    //       }
    //       else {
    //         let innerTable = question.getElementsByClassName('clsTable').length > 0 ? question.getElementsByClassName('clsTable')[0] as HTMLTableElement : false;
    //         let innerTableHeader = question.getElementsByClassName('clsTableHeader').length > 0 ? question.getElementsByClassName('clsTableHeader')[0] as HTMLTableElement : false;

    //         if (innerTable != false && innerTableHeader != false) {
    //           totalHeight = totalHeight + innerTableHeader.offsetHeight + 35;
    //           let innerRows = innerTable.rows;

    //           for (let k = 0; k < innerRows.length; k++) {
    //             let row = innerRows[k] as HTMLTableRowElement;
    //             let rowHeight = row.offsetHeight + 1;
    //             if ((totalHeight + rowHeight) < windowHeight) {
    //               totalHeight = totalHeight + rowHeight;
    //             }
    //             else {
    //               let extraHeight = windowHeight - totalHeight + 70;
    //               let str: any = '<div class="page-break" style="margin: 0px -2500px; background-color: white; height: ' + extraHeight + 'px;"></div>';
    //               let row = innerTable.insertRow(k);
    //               let cell = row.insertCell(0);
    //               cell.innerHTML = str;
    //               totalHeight = 0;
    //             }
    //           }
    //         }
    //         else {
    //           let extraHeight = windowHeight - totalHeight + 70;
    //           let str: any = '<div class="page-break" style="background-color: white; height: ' + extraHeight + 'px; color: white;"></div>';
    //           let row = section.insertRow(j);
    //           let cell = row.insertCell(0);
    //           cell.innerHTML = str;
    //           totalHeight = 0;
    //         }
    //       }
    //     }
    //   }
    // }

    var data = document.getElementById('pnlPreviewTemp');
    html2canvas(data).then(canvas => {
      var imgWidth = 210;
      var pageHeight = 297;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');

      var position = 0;
      heightLeft -= pageHeight;
      pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight, 'alias', 'FAST')

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight + 1;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight, 'alias', 'FAST')
        heightLeft -= pageHeight;
      }
      pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight, 'alias', 'FAST')

      pdf.save(this._permitTypeName + '_' + new Date().toDateString() + '.pdf')
      // convert PDF object to base64 string
      var obj = btoa(pdf.output())
      this._spinner.hide();

      var pageBreakDiv = document.getElementsByClassName("page-break");
      for (let i = 0; i < pageBreakDiv.length; i++) {
        let divObj = pageBreakDiv[i] as HTMLElement;
        divObj.parentNode.removeChild(divObj);
      }
    });
    if (document.getElementById('printPermit')) {
      document.getElementById('printPermit').style.marginTop = '';
    }
  }


  CheckForInnerTable(element: HTMLElement) {
    if (element.childElementCount > 0) {
      if (element.classList.contains('clsTable')) {
        return true;
      }
      else {
        return this.CheckForInnerTable(element.firstElementChild as HTMLElement);
      }
    }
    else {
      return false;
    }
  }

  openMap() {
    this.displayMap = true;
    //this.setCoordinates(this._permitId);
  }

  AddUserRole(selectedUserShortInfo: string[]) {

    let roleGroupApprover = this._permitObj.RoleGroupApproval;

    roleGroupApprover.forEach((rg, index) => {
      let updatedUserDetails = this._roleForUser[index].UserDetails;
      if (rg.UserDetails.length > 0) {
        rg.UserDetails.forEach(ud => {
          if (updatedUserDetails.filter(x => x.LoginId == ud.LoginId).length > 0) {
            ud.Active = true;
          }
          else {
            ud.Active = false;
          }
          if (ud.RoleGroupUserDetailsId > 0) {
            ud.ModifiedDate = new Date();
            ud.ModifiedBy = localStorage.getItem("_loginId");
          }
          else {
            ud.CreatedDate = new Date();
            ud.CreatedBy = localStorage.getItem("_loginId");
          }
        });
      }
      else {
        let userRoleGroupDetails: RoleGroupUserDetails[] = [];
        rg.UserShortInfo.forEach(us => {
          let userDetail: any = {};
          if (updatedUserDetails.filter(x => x.LoginId == us.LoginId).length > 0) {
            userDetail.Active = true;
            userDetail.CreatedDate = new Date();
            userDetail.CreatedBy = localStorage.getItem("_loginId");
            userDetail.LoginId = us.LoginId;
            userRoleGroupDetails.push(userDetail);
          }
          else {
            userDetail.LoginId = us.LoginId;
            userDetail.Active = false;
            userDetail.CreatedDate = new Date();
            userDetail.CreatedBy = localStorage.getItem("_loginId");
            userRoleGroupDetails.push(userDetail);
          }
        });
        rg.UserDetails = userRoleGroupDetails;
      }
    });

    this._roleGroupApprover = roleGroupApprover;
    this._permitObj.RoleGroupApproval = roleGroupApprover;
    this._roleForUser.forEach(ru => {
      ru.Active = true;
      ru.CreatedDate = new Date();
      ru.ModifiedBy = '';
      ru.CreatedBy = localStorage.getItem("_loginId");

    })
    this._helperService._roleForApproval = this._roleForUser

    // Call save permit methods based on Save/Submit button click
    if (this._submitButtonClicked) {
      this._submitButtonClicked = false;
      this.SubmitPermitResponse();
    }
    else {
      this.SaveResponse();
    }
    this.displayuserforrole = false;
  }

  setCoordinates(permitId) {

    this._geotagService.getGeoTag(permitId).subscribe((data: GISMapViewModel) => {
      if (data) {
        if (data.features.length > 0) {
          data.type = "FeatureCollection";
          data.name = "mapJson_1";
          this._indexDbService.editRecordAsync("mapJson", data).subscribe((data) => {
          });
          this._mediaService.getFileProperties(data.AzureFileId).subscribe(
            (res: HttpResponse<any>) => {
              if (res.body.uri != null && res.body.uri != '') {
                this._commonService.getMediaBlob(this.EscapeSpecialCharacters(res.body.uri)).subscribe(
                  (imageData: any) => {
                    let base64Image = "data:image/png;base64," + imageData.toString();

                    let screenGrab: any = {};
                    screenGrab.name = "screengrab"
                    screenGrab.screengrab = base64Image;
                    if (base64Image != null || base64Image != undefined) {
                      this._showImage = true;
                      this._screenGrab = base64Image;
                    }
                    this._indexDbService.editRecordAsync("mapJson", screenGrab).subscribe((data) => {

                    });
                    let site: any = {};
                    site.name = "site";
                    site.sitename = data.SiteName;
                    this._indexDbService.editRecordAsync("mapJson", site).subscribe((data) => {
                      // console.log("Site Name", data);

                    });
                    //sessionStorage.setItem('screengrab', base64Image);
                  });
              }
            });
          //   let objCoordinate = [];
          //   data.features.forEach(feature => {
          //     objCoordinate = [];
          //     feature.coordinates.forEach(c => {
          //       let coordinate: string[] = [];
          //       coordinate.push(c.coordinate[0]);
          //       coordinate.push(c.coordinate[1]);
          //       objCoordinate.push(coordinate);
          //     });
          //     feature.coordinates = [];
          //     feature.coordinates = objCoordinate;
          //   });
          //   sessionStorage.setItem('mapJson', JSON.stringify(data));
          // }
        }
        else {
          // sessionStorage.removeItem('mapJson');
          sessionStorage.setItem('mapJson', null)
          sessionStorage.removeItem('screengrab');
        }
        let featEditable: any = {};
        featEditable.name = "Editable";
        if (data.features.length > 0) {
          featEditable.Editable = data.Editable;
        }
        else {
          featEditable.Editable = true;
        }
        this._indexDbService.editRecordAsync("mapJson", featEditable).subscribe((data) => {
        });
      }
    });
  }

  getCoordinates(): void {
    this.displayMap = false;
    //let screenGrab: any;
    this._indexDbService.getRecord("mapJson", "screengrab").subscribe((data) => {
      if (data.screengrab) {
        this._showImage = true;
        this._screenGrab = data.screengrab;
      }
    });
  }

  saveCoordinates() {
    // if (sessionStorage.getItem('mapJson')) {
    //   let mapViewModel: GISMapViewModel;
    //   const mapValueFromSession = sessionStorage.getItem('mapJson');
    //   mapViewModel = JSON.parse(mapValueFromSession);
    //   mapViewModel.PermitId = this._permitId;
    //   if (mapViewModel.features[0].coordinates.length > 0) {
    //     this._geotagService.addGeoTag(mapViewModel).subscribe((res) => {
    //       if (res.status == 200) {
    //         this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.LocationSavedSuccessfull') });
    //         this.displayMap = false;
    //       }
    //       else {
    //         this._messageService.clear('error');
    //         this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.LocationError') });
    //       }
    //     });
    //   }
    //   else {
    //     this._messageService.clear('error');
    //     this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.LocationNotFound') });
    //   }

    // }
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private async DelayExecution() {
    // Sleep thread for 5 seconds
    await this.delay(5000);
  }

  hideRow(currenRow, flag) {
    if (flag == "hidden") {
      (currenRow as HTMLTableRowElement).hidden = true;
    }
  }

  GetUsersForRoleGroup() {


  }

  BindEmptyTable(divisionIndex, sectionIndex, questionIndex, ques) {
    let idx = (ques.TableData == undefined) ? 0 : ques.TableData.length
    //Other login
    let question: PermitTypeQuestion = this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex];
    if (question.TableData == undefined) {
      question.TableData = [];

      question.TableData[idx] = [];
      for (let headerIndex in question.TableFieldType) {
        if (question.TableFieldType[headerIndex].AnswerTypeId == 1) {
          question.TableData[idx][question.TableFieldType[headerIndex].ColumnName] = { "SelectedAnswerOptionId": 0 };
        }
        else {
          question.TableData[idx][question.TableFieldType[headerIndex].ColumnName] = {};
        }
      }
    }
    else {
      question.TableData[idx] = [];
      for (let headerIndex in question.TableFieldType) {
        if (question.TableFieldType[headerIndex].AnswerTypeId == 1) {
          question.TableData[idx][question.TableFieldType[headerIndex].ColumnName] = { "SelectedAnswerOptionId": 0 };
        }
        else {
          question.TableData[idx][question.TableFieldType[headerIndex].ColumnName] = {};
        }
      }
    }
  }

  AddRowClick(divisionIndex, sectionIndex, questionIndex, ques) {
    this.BindEmptyTable(divisionIndex, sectionIndex, questionIndex, ques);
  }

  // DeleteRow(divisionIndex, sectionIndex, questionIndex, pindex, currenRow) {
  //   (currenRow as HTMLTableRowElement).hidden = true;
  //   let ques = this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex];
  //   ques.TableData["deleted" + pindex] = "hidden"
  //   if (this._permitObj.PermitId > 0) {
  //     let responses = this._permitObj.Responses.filter(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId);
  //     for (let resp of responses) {
  //       let deletedResponses = resp.TableTypeResponse.filter(x => x.RowIndex == pindex)
  //       for (let currentResp of deletedResponses) {
  //         currentResp.Active = false;
  //       }
  //     }
  //   }
  // }
  DeleteRow(question: PermitTypeQuestion, pIndex) {

    //let responses = this._permitObj.Responses.filter(r => r.PermitTypeQuestionId == question.PermitTypeQuestionId);
    let deletedData = question.TableData;
    if (deletedData.length > 0) {
      for (let i = 0; i < deletedData.length; i++) {
        if (i == pIndex) {
          deletedData.splice(i, 1);

        }
      }
    }
    // question.TableData.splice(,1)

    // for (let resp of responses) {
    //   let tableResponses = resp.TableTypeResponse;
    //   if(tableResponses.length > 0) {
    //     for(let i = 0; i < tableResponses.length; i++){ 
    //       if (tableResponses[i].RowIndex == pIndex) {
    //         tableResponses.splice(i, 1); 
    //         tableResponses[i].Active=false;
    //         i--;
    //       }
    //    }
    //   }
    //    = tableResponses;
    // }

  }

  AddRowsToTables() {
    for (let divisionIndex in this._PermitVersion.Divisions) {
      for (let sectionIndex in this._PermitVersion.Divisions[divisionIndex].Sections) {
        for (let questionIndex in this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions) {
          if (this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerTypeId == 9) {
            let rowCount = this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].RowCount;
            for (var index = 0; index < rowCount; index++) {
              this.BindEmptyTable(divisionIndex, sectionIndex, questionIndex, this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex]);
            }
          }
        }
      }
    }
  }

  showDialogMaximized(dialog: Dialog) {
    setTimeout(() => {
      dialog.maximize();
    }, 0);
  }

  // Fetch all the dynamic data source values associated with the selected permit type and store in a local variable.
  // This local variable will be used to populate the dropdown going forward.
  GetAllDynamicDataSourceValues() {
    this._PermitVersion.Divisions.forEach(div => {
      div.Sections.forEach(sec => {
        sec.Questions.forEach(ques => {
          if (ques.AnswerTypeId == 9) {
            ques.TableFieldType.forEach(tableItem => {
              if (tableItem.AnswerTypeId == 1) {
                if (tableItem.AnswerOptions[0].DynamicDataSource) {
                  this._dynamicDataSourceService.getDataSourceOptions(tableItem.AnswerOptions[0].DynamicDataSourceId)
                    .subscribe((data: DynamicDataSourceOption[]) => {
                      let obj = {
                        permitTypeQuestionId: ques.PermitTypeQuestionId,
                        dynamicDataSourceOptions: data
                      }
                      this._allDynamicDatasourceOptions.push(obj);
                    });
                }
              }
            });
          }
          if (ques.AnswerOptions.length > 0 && ques.AnswerOptions[0].DynamicDataSource == true) {
            this._dynamicDataSourceService.getDataSourceOptions(ques.AnswerOptions[0].DynamicDataSourceId)
              .subscribe((data: DynamicDataSourceOption[]) => {
                let obj = {
                  permitTypeQuestionId: ques.PermitTypeQuestionId,
                  dynamicDataSourceOptions: data
                }
                this._allDynamicDatasourceOptions.push(obj);
              });
          }

        })
      })
    })
  }

  getAnswerOption(question: PermitTypeQuestion) {
    if (this._allDynamicDatasourceOptions != undefined) {
      let obj = this._allDynamicDatasourceOptions.find(x => x.permitTypeQuestionId == question.PermitTypeQuestionId)
      if (obj != undefined) {
        var sortedDynamicDataSourceOptions = obj.dynamicDataSourceOptions.sort((n1, n2) =>
          n1.OptionText > n2.OptionText ? 1 : -1);

        return sortedDynamicDataSourceOptions;
      }
    }
    else {
      return [];
    }
  }

  getAnswerOptionForTableType(field: PermitTypeTableFieldType) {
    let obj: any[];
    obj = [];
    field.AnswerOptions.forEach(ans => {
      obj.push(ans)
    });
    return obj;
  }

  chkOnChange() {
  }

  MapTableResponseValues() {
    for (let section of this._PermitVersion.Divisions[0].Sections) {
      for (let question of section.Questions) {

        if (question.AnswerTypeId == 9) {

          for (let tResponse of question.TableTypeResponses) {
            for (var i = 0; i < question.TableData.length; i++) {
              for (let header of question.TableFieldType) {

                if (header.AnswerTypeId == 1 || header.AnswerTypeId == 2 || header.AnswerTypeId == 4) {
                  (question.TableData[i][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = tResponse.SelectedAnswerOptionId;
                }
                else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                  (question.TableData[i][header.ColumnName] as TableTypeResponse).ResponseText = tResponse.ResponseText;
                }

              }
            }
          }

        }
      }
    }
  }

  getPermiTypeDetails(permitTypeId) {
    let hierarchySearch = new HierarchySearchViewModel();
    hierarchySearch.PermitTypeId = permitTypeId;
    this._permitTypeService.getPermitTypes(hierarchySearch).subscribe(permitType => {
      this._permitType = permitType.find(p => p.PermitType.PermitTypeId == permitTypeId);
      this._permitTypeAbbr = this._permitType.PermitType.Abbreviation
      this._permitTypeName = this._permitType.PermitType.PermitTypeName
      this._permitTypeId = this._permitType.PermitType.PermitTypeId;
      this._permitBackColor = this._permitType.PermitType.BackColor;
      this._permitForeColor = this._permitType.PermitType.ForeColor;

      this._canCopyPermit = this._permitType.PermitType.CopyPermitType;

      if (this._canCopyPermit && this._resetFlag == false && this._permitId == 0) {
        // this._permitService.getPermitsByPermitType(this._permitTypeId).subscribe((permits: Permit[]) => {
        //   this._permits = permits;
        //   if (this._permits.length > 0) {
        //     this._permits.forEach(p => {
        //       this._permitNumbers.push(p.PermitNumber);
        //     });
        //     this._displayPermitSelectPopUp = true;
        //     this._selectedPermitId = 0 // to be fetched once user selects the permit number from the pop up
        //     this.popUpHeader = this.translate.instant('Permit.ChoosePermit');
        //   }
        //   this._resetFlag = false;
        // });
        this._displayPermitSelectPopUp = true;
      }

      // To get revalidation hours for the permit version
      this.getPermitControlsForPermitVersion();
      this.GetAllDynamicDataSourceValues();
      if (this._permitType.Attachment != null && this._permitType.Attachment.AzureFileId) {
        this.getPermitTypeImage(this._permitType.Attachment.AzureFileId);
      }
    })
  }

  getPermitTypeImage(azurefileid: number) {
    if (azurefileid != null && azurefileid != 0) {
      this._mediaService.getFileProperties(azurefileid).subscribe(
        (res: HttpResponse<any>) => {
          if (res.body.uri != null && res.body.uri != '') {
            this._commonService.getMediaBlob(this.EscapeSpecialCharacters(res.body.uri)).subscribe(
              (data: any) => {

                let base64Image = "data:image/png;base64," + data.toString();
                let thumbnailImage = this.sanitizer.bypassSecurityTrustUrl(base64Image);
                this.permitTypeImage = thumbnailImage;
              });

          }
        });
    }
  }

  EscapeSpecialCharacters(input: string): string {
    let inputText = input;
    let specialCharArray = [
      { 'Character': '!', 'Code': 'U0021' },
      { 'Character': '#', 'Code': 'U0023' },
      { 'Character': '$', 'Code': 'U0024' },
      { 'Character': '%', 'Code': 'U0025' },
      { 'Character': '&', 'Code': 'U0026' },
      { 'Character': '(', 'Code': 'U0028' },
      { 'Character': ')', 'Code': 'U0029' },
      { 'Character': '*', 'Code': 'U002A' },
      { 'Character': '+', 'Code': 'U002B' },
      { 'Character': '-', 'Code': 'U002D' },
      { 'Character': '/', 'Code': 'U002F' },
      { 'Character': '?', 'Code': 'U003F' },
      { 'Character': '@', 'Code': 'U0040' },
      { 'Character': '\\', 'Code': 'U005C' },
      { 'Character': '=', 'Code': 'U003D' },
      { 'Character': '_', 'Code': 'U005F' }
    ]

    let charArray = Array.from(inputText);
    let stringArray = [];
    for (let objChar of charArray) {
      let obj = specialCharArray.find(x => x.Character == objChar);
      stringArray.push(obj == undefined ? objChar : obj.Code);
    }

    inputText = stringArray.join('');

    return inputText;
  }

  AssignValues() {
    // this._permitTypeSiteName = this.userHierarchy.SiteName
    // this._permitTypeSiteAbbr = this._permitTypeSiteName.toUpperCase().substring(0, 3);
    //this._permitTypeSiteAbbr = this.userHierarchy.SiteAbbreviation;
    if (this._permitObj.PermitId == undefined || this._permitObj.PermitId == 0) {
      this._permitObj = new Permit();
      // site abbreviation to be fetched from API
      this._permitObj.PermitNumber = '-' + this._permitTypeAbbr + '-' + new Date().getFullYear().toString().substring(2, 4) + '-';
      // this._permitObj.PermitNumber = this._permitTypeSiteAbbr + '-' + this._permitTypeAbbr + '-' + new Date().getFullYear().toString().substring(2, 4) + '-';
      this._permitObj.PermitId = 0;
      this._permitObj.Responses = [];
      this._permitObj.PermitIssuedDateTime = null;
      this._permitObj.RevalidationHours = this.revalidationHours;
      this._permitObj.PermitExpiryDateTime = null;
      this._permitObj.PermitTypeVersionId = this._PermitVersion.PermitTypeVersionId;
      this._permitObj.PermitTypeId = this._PermitVersion.PermitTypeId;
      this._permitObj.SiteId = this.userHierarchy.SiteId;
      this._permitObj.CreatedBy = localStorage.getItem("_loginId");
      this._permitObj.CreatedDate = new Date();
      this._permitObj.SiteName = this.userHierarchy.SiteName;

    }
    else {
      this._permitObj.PermitId = this._permitObj.PermitId;
      this._permitObj.PermitNumber = this._permitObj.PermitNumber;
    }
    this._permitObj.Active = true;
    this._permitObj.ModifiedBy = localStorage.getItem("_loginId");
    this._permitObj.ModifiedDate = new Date();

    let responseArray: Response[] = [];
    this._PermitVersion.Divisions.forEach((div: PermitTypeDivision) => {
      div.Sections.forEach((sec: PermitTypeSection) => {
        sec.Questions.forEach((ques: PermitTypeQuestion) => {
          let checkBoxAnswerOptionsArray = ques.AnswerOptions.filter(ao => ao.PermitTypeQuestionId == ques.PermitTypeQuestionId);
          let response = new Response();
          if (this._permitObj.PermitId > 0) {
            response = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId)
          }
          this._tableTypeResponse = [];
          // For handling checkboxes
          if (ques.AnswerTypeId == 2) {
            let checkBoxAnswers: number[]
            checkBoxAnswers = ques.SelectedAnswerCheckBox
            if (this._permitObj.PermitId > 0) {
              if (ques.SelectedAnswerCheckBox) {
                let checkBoxRespArray: Response[] = [];
                checkBoxRespArray = this._permitObj.Responses.filter(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId);
                checkBoxRespArray.forEach(r => {
                  r.SelectedAnswerOptionId = null;
                  r.ModifiedBy = localStorage.getItem("_loginId");
                  r.ModifiedDate = new Date();
                  r.Active = true;
                  r.CreatedDate = new Date();
                  r.CreatedBy = localStorage.getItem("_loginId");
                  r.PermitTypeQuestionId = ques.PermitTypeQuestionId
                });
                checkBoxAnswers.forEach((ans: number) => {
                  if (ans != null) {
                    let indexOfSelectedAnswerOption = checkBoxAnswerOptionsArray.findIndex(x => x.PermitTypeAnswerOptionId == ans)
                    checkBoxRespArray[indexOfSelectedAnswerOption].SelectedAnswerOptionId = ans
                  }
                });

                checkBoxRespArray.forEach(x => {
                  responseArray.push(x);
                })
              }
            }
            else {
              for (let i = 0; i < checkBoxAnswerOptionsArray.length; i++) {
                let checkBoxResponseForNew = new Response()
                checkBoxResponseForNew.ResponseId = 0;
                checkBoxResponseForNew.Active = true;
                checkBoxResponseForNew.CreatedBy = localStorage.getItem("_loginId");
                checkBoxResponseForNew.CreatedDate = new Date();
                checkBoxResponseForNew.ModifiedBy = localStorage.getItem("_loginId");
                checkBoxResponseForNew.ModifiedDate = new Date();
                checkBoxResponseForNew.PermitTypeQuestionId = ques.PermitTypeQuestionId
                checkBoxResponseForNew.SelectedAnswerOptionId = null;
                if (checkBoxAnswers) {
                  for (let j = 0; j < checkBoxAnswers.length; j++) {
                    if (checkBoxAnswerOptionsArray[i].PermitTypeAnswerOptionId == checkBoxAnswers[j]) {
                      checkBoxResponseForNew.SelectedAnswerOptionId = checkBoxAnswers[j]
                    }
                  }
                }
                responseArray.push(checkBoxResponseForNew);
              }
            }
          }
          // For handling Table Responses
          else if (ques.AnswerTypeId == 9) {
            if (this._permitObj.PermitId > 0) {
              //Update Start
              let response: any;

              for (let res of this._permitObj.Responses) {
                if (res.PermitTypeQuestionId == ques.PermitTypeQuestionId) {
                  response = res;
                  break;

                }
              }
              let deletedIndexes: number[];
              deletedIndexes = [];
              for (let rowIndex in ques.TableData) {
                if (rowIndex.toString().startsWith('deleted')) {
                  if (ques.TableData[rowIndex.toString()] == "hidden") {
                    deletedIndexes.push(parseInt(rowIndex.toString().replace("deleted", "")))
                  }
                }
              }


              for (let rowIndex in ques.TableData) {
                if (rowIndex.toString().startsWith('delete') == false) {
                  let status = this.checkRowForDeletion(rowIndex, ques.TableFieldType, ques.TableData, deletedIndexes);
                  if (status != "SKIP") {

                    for (let header of ques.TableFieldType) {
                      let tblTypeResponse = response.TableTypeResponse.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == +rowIndex);//new TableTypeResponse();

                      if (tblTypeResponse) {
                        let tblCheckboxResponses = response.TableTypeResponse.filter(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == parseInt(rowIndex));//new TableTypeResponse();
                        for (let respObj of tblCheckboxResponses) {
                          respObj.Active = status == "ACTIVE" ? true : false;
                          if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                            if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                              tblTypeResponse.ResponseText = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText;
                              this._tableTypeResponse.push(tblTypeResponse);
                            }
                            else {
                              tblTypeResponse.SelectedAnswerOptionId = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId;
                              this._tableTypeResponse.push(tblTypeResponse);
                            }

                          }
                          else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                            respObj.ResponseText = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText;
                            this._tableTypeResponse.push(respObj);

                          }

                        }
                        if (header.AnswerTypeId == 2) {
                          let tableFields = ques.TableFieldType.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId && x.PermitTypeTableFieldTypeId == header.PermitTypeTableFieldTypeId);
                          let PermitTypeTableAnsOptions: PermitTypeTableAnswerOption[] = []
                          tableFields.AnswerOptions.forEach(x => {
                            PermitTypeTableAnsOptions.push(x);
                          })

                          let tblTypeResponseForCheckBox = response.TableTypeResponse.filter(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == parseInt(rowIndex));

                          let selectedCheckBoxes = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable;

                          for (let chkbox of tblTypeResponseForCheckBox) {
                            chkbox.SelectedAnswerOptionId = null;
                          }
                          for (let j = 0; j < selectedCheckBoxes.length; j++) {
                            for (let i = 0; i < PermitTypeTableAnsOptions.length; i++) {

                              if (PermitTypeTableAnsOptions[i].PermitTypeTableAnswerOptionId == selectedCheckBoxes[j]) {
                                tblTypeResponseForCheckBox[i].SelectedAnswerOptionId = selectedCheckBoxes[j]
                                break;
                              }
                            }
                          }
                          tblTypeResponseForCheckBox.forEach(resp => {
                            resp.Active = status == "ACTIVE" ? true : false;
                            this._tableTypeResponse.push(resp);
                          });
                        }

                      }
                      else {
                        let tblTypeResponse = new TableTypeResponse();
                        tblTypeResponse.TableTypeResponseId = 0
                        tblTypeResponse.Active = true;
                        tblTypeResponse.CreatedBy = localStorage.getItem("_loginId");
                        tblTypeResponse.CreatedDate = new Date()
                        tblTypeResponse.ModifiedBy = localStorage.getItem("_loginId");
                        tblTypeResponse.ModifiedDate = new Date()
                        tblTypeResponse.TableFieldTypeId = header.PermitTypeTableFieldTypeId;
                        tblTypeResponse.RowIndex = +rowIndex
                        tblTypeResponse.ResponseId = response.ResponseId;
                        if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                          if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                            tblTypeResponse.ResponseText = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText;
                            this._tableTypeResponse.push(tblTypeResponse);
                          }
                          else {
                            tblTypeResponse.SelectedAnswerOptionId = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId;
                            this._tableTypeResponse.push(tblTypeResponse);
                          }

                        }
                        else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                          tblTypeResponse.ResponseText = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText;
                          this._tableTypeResponse.push(tblTypeResponse);

                        }
                        else if (header.AnswerTypeId == 2) {
                          let tableFields = ques.TableFieldType.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId && x.AnswerTypeId == header.AnswerTypeId);
                          let PermitTypeTableAnsOptions: PermitTypeTableAnswerOption[] = []
                          tableFields.AnswerOptions.forEach(x => {
                            PermitTypeTableAnsOptions.push(x);
                          })

                          for (let i = 0; i < PermitTypeTableAnsOptions.length; i++) {
                            let tblTypeResponseForCheckBox = new TableTypeResponse();
                            tblTypeResponseForCheckBox.TableTypeResponseId = 0
                            tblTypeResponseForCheckBox.ResponseId = response.ResponseId;
                            tblTypeResponseForCheckBox.Active = true;
                            tblTypeResponseForCheckBox.CreatedBy = localStorage.getItem("_loginId");
                            tblTypeResponseForCheckBox.CreatedDate = new Date()
                            tblTypeResponseForCheckBox.ModifiedBy = localStorage.getItem("_loginId");
                            tblTypeResponseForCheckBox.ModifiedDate = new Date()
                            tblTypeResponseForCheckBox.TableFieldTypeId = header.PermitTypeTableFieldTypeId;
                            tblTypeResponseForCheckBox.RowIndex = +rowIndex
                            tblTypeResponseForCheckBox.SelectedAnswerOptionId = null
                            let selectedCheckBoxes = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable;
                            if (selectedCheckBoxes) {
                              for (let j = 0; j < selectedCheckBoxes.length; j++) {
                                if (PermitTypeTableAnsOptions[i].PermitTypeTableAnswerOptionId == selectedCheckBoxes[j]) {
                                  tblTypeResponseForCheckBox.SelectedAnswerOptionId = selectedCheckBoxes[j]
                                }
                              }
                            }
                            this._tableTypeResponse.push(tblTypeResponseForCheckBox);
                          }
                        }
                      }
                    }
                  }
                }
              }
              response.ModifiedBy = localStorage.getItem('_loginId');
              response.ModifiedDate = new Date();
              response.TableTypeResponse = this._tableTypeResponse;
              responseArray.push(response);
              //Update End
            }
            else {
              let response = new Response();
              response.ResponseId = 0
              response.Active = true;
              response.CreatedBy = localStorage.getItem("_loginId");
              response.CreatedDate = new Date()
              // response.ModifiedBy = localStorage.getItem("_loginId");
              // response.ModifiedDate = new Date()
              response.PermitTypeQuestionId = ques.PermitTypeQuestionId;

              let deletedIndexes: number[];
              deletedIndexes = [];
              for (let rowIndex in ques.TableData) {
                if (rowIndex.toString().startsWith('deleted')) {
                  if (ques.TableData[rowIndex.toString()] == "hidden") {
                    deletedIndexes.push(parseInt(rowIndex.toString().replace("deleted", "")))
                  }
                }
              }
              let currentRowIndex = 0;
              for (let rowIndex in ques.TableData) {
                if (rowIndex.toString().startsWith('delete') == false) {

                  let status = this.checkRowForDeletion(rowIndex, ques.TableFieldType, ques.TableData, deletedIndexes);
                  if (status != "SKIP") {
                    for (let header of ques.TableFieldType) {
                      let tblTypeResponse = new TableTypeResponse();
                      tblTypeResponse.TableTypeResponseId = 0
                      tblTypeResponse.ResponseId = 0
                      tblTypeResponse.Active = status == "ACTIVE" ? true : false;
                      tblTypeResponse.CreatedBy = localStorage.getItem("_loginId");
                      tblTypeResponse.CreatedDate = new Date()
                      tblTypeResponse.ModifiedBy = localStorage.getItem("_loginId");
                      tblTypeResponse.ModifiedDate = new Date()
                      tblTypeResponse.TableFieldTypeId = header.PermitTypeTableFieldTypeId;
                      tblTypeResponse.RowIndex = currentRowIndex


                      if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                        if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                          tblTypeResponse.ResponseText = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText;
                          this._tableTypeResponse.push(tblTypeResponse);
                        }
                        else {
                          tblTypeResponse.SelectedAnswerOptionId = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId;
                          this._tableTypeResponse.push(tblTypeResponse);
                        }

                      }
                      else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                        tblTypeResponse.ResponseText = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText;
                        this._tableTypeResponse.push(tblTypeResponse);

                      }
                      else if (header.AnswerTypeId == 2) {
                        let tableFields = ques.TableFieldType.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId && x.AnswerTypeId == header.AnswerTypeId);
                        let PermitTypeTableAnsOptions: PermitTypeTableAnswerOption[] = []
                        tableFields.AnswerOptions.forEach(x => {
                          PermitTypeTableAnsOptions.push(x);
                        })

                        for (let i = 0; i < PermitTypeTableAnsOptions.length; i++) {
                          let tblTypeResponseForCheckBox = new TableTypeResponse();
                          tblTypeResponseForCheckBox.TableTypeResponseId = 0
                          tblTypeResponseForCheckBox.ResponseId = 0
                          tblTypeResponseForCheckBox.Active = status == "ACTIVE" ? true : false;
                          tblTypeResponseForCheckBox.CreatedBy = localStorage.getItem("_loginId");
                          tblTypeResponseForCheckBox.CreatedDate = new Date()
                          tblTypeResponseForCheckBox.ModifiedBy = localStorage.getItem("_loginId");
                          tblTypeResponseForCheckBox.ModifiedDate = new Date()
                          tblTypeResponseForCheckBox.TableFieldTypeId = header.PermitTypeTableFieldTypeId;
                          tblTypeResponseForCheckBox.RowIndex = currentRowIndex;
                          tblTypeResponseForCheckBox.SelectedAnswerOptionId = null
                          let selectedCheckBoxes = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable;
                          if (selectedCheckBoxes) {
                            for (let j = 0; j < selectedCheckBoxes.length; j++) {
                              if (PermitTypeTableAnsOptions[i].PermitTypeTableAnswerOptionId == selectedCheckBoxes[j]) {
                                tblTypeResponseForCheckBox.SelectedAnswerOptionId = selectedCheckBoxes[j]
                              }
                            }
                          }
                          this._tableTypeResponse.push(tblTypeResponseForCheckBox);
                        }
                      }
                    }
                    currentRowIndex++;
                  }
                }
              }
              response.TableTypeResponse = this._tableTypeResponse;
              responseArray.push(response);
            }
          }
          // For handling drop downs types
          else if (ques.AnswerTypeId == 1) {
            if (this._permitObj.PermitId > 0) {
              let responseId = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseId;
              response.ResponseId = responseId;
              response.ModifiedBy = localStorage.getItem('_loginId');
              response.ModifiedDate = new Date();
            }
            else {
              response.ResponseId = 0;
              response.Active = true;
              response.CreatedDate = new Date();
              response.CreatedBy = localStorage.getItem('_loginId');
            }
            response.PermitTypeQuestionId = ques.PermitTypeQuestionId
            if (ques.AnswerOptions.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId).DynamicDataSource == true) {
              response.ResponseText = ques.AnswerText;
            }
            else {
              response.SelectedAnswerOptionId = +ques.SelectedAnswerOptionID
              response.ResponseText = ques.AnswerOptions.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId
                && x.PermitTypeAnswerOptionId == ques.SelectedAnswerOptionID) ? ques.AnswerOptions.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId
                  && x.PermitTypeAnswerOptionId == ques.SelectedAnswerOptionID).OptionText : '';
            }
            responseArray.push(response)

          }
          // For handling other types
          else {
            if (this._permitObj.PermitId > 0) {
              let responseId = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseId;
              response.ResponseId = responseId;
              response.ModifiedBy = localStorage.getItem('_loginId');
              response.ModifiedDate = new Date();
            }
            else {
              response.ResponseId = 0;
              response.Active = true;
              response.CreatedDate = new Date();
              response.CreatedBy = localStorage.getItem('_loginId');
            }
            response.PermitTypeQuestionId = ques.PermitTypeQuestionId
            if (ques.AnswerTypeId == 11) {
              if (ques.FieldValue != undefined && ques.FieldValue != null) {
                response.ResponseText = ques.FieldDesc != null ? ques.FieldValue + "@@@" + ques.FieldDesc : ques.FieldValue;
              }

            }
            else {
              response.ResponseText = ques.AnswerText;
            }

            response.SelectedAnswerOptionId = ques.SelectedAnswerOptionID // This will be fetched from realtime user response

            responseArray.push(response)
          }
        })
      })
    });

    this._permitObj.Responses = responseArray;
    this.AssignAttachments();

  }

  AssignAttachments() {
    this.finalAttachmentList = [];
    this.finalAttachmentList = this.responseAttachmentFiles;
    if (this._permitObj.PermitId > 0) {
      // this._ObjArrResponseAttachment = [];
      if (this.finalAttachmentList.length > 0) {
        this._permitObj.Responses.forEach(response => {
          let attachments = this.finalAttachmentList.filter(x => x.questionId == response.PermitTypeQuestionId);
          if (attachments) {
            attachments.forEach(attachment => {
              if (response.PermitTypeQuestionId == attachment.questionId) {
                let respAttachment = response.ResponseAttachment.find(x => x.ResponseId == response.ResponseId && x.AzureFileId == attachment.AzureFileId);
                if (respAttachment) {
                  // responseAttachment.ResponseAttachmentId = respAttachment.ResponseAttachmentId
                  respAttachment.AzureFileId = attachment.AzureFileId
                  respAttachment.ModifiedBy = localStorage.getItem('_loginId');
                  respAttachment.ModifiedDate = new Date();
                  respAttachment.Active = attachment.Active;
                }
                else {
                  let responseAttachment = new ResponseAttachment();
                  responseAttachment.ResponseAttachmentId = 0
                  responseAttachment.AzureFileId = attachment.AzureFileId
                  responseAttachment.ResponseId = response.ResponseId;
                  responseAttachment.Active = attachment.Active;
                  responseAttachment.CreatedBy = localStorage.getItem('_loginId');
                  responseAttachment.CreatedDate = new Date();

                  // this._ObjArrResponseAttachment.push(responseAttachment);
                  response.ResponseAttachment.push(responseAttachment)
                }


              }
            })

          }
        });
      }
      else {
        this._permitObj.Responses.forEach(resp => {
          resp.ResponseAttachment = []
        })
      }
    }
    else {
      if (this.finalAttachmentList.length > 0) {
        this._ObjArrResponseAttachment = [];

        this.finalAttachmentList.forEach(attachment => {

          let responseAttachment = new ResponseAttachment();
          responseAttachment.ResponseAttachmentId = 0;
          responseAttachment.AzureFileId = attachment.AzureFileId
          responseAttachment.ResponseId = attachment.questionId;
          responseAttachment.Active = attachment.Active;
          responseAttachment.CreatedBy = localStorage.getItem('_loginId');
          responseAttachment.CreatedDate = new Date();
          responseAttachment.ModifiedBy = null;
          responseAttachment.ModifiedDate = null;
          this._ObjArrResponseAttachment.push(responseAttachment);

          let response = this._permitObj.Responses.find(resp => resp.PermitTypeQuestionId == attachment.questionId);
          response.ResponseAttachment = this._ObjArrResponseAttachment.filter(x => x.ResponseId == response.PermitTypeQuestionId);

          // this._permitObj.Responses.find(resp => resp.PermitTypeQuestionId == attachment.questionId).ResponseAttachment = this._ObjArrResponseAttachment.filter(x=>x.ResponseId == )
          // this._permitObj.Responses.forEach(resp=>{
          //   if(resp.PermitTypeQuestionId == attachment.questionId){
          //     resp
          //   }
          // })
        });


        // this._ObjArrResponseAttachment.forEach(attachment => {
        //   this._permitObj.Responses.find(resp => resp.PermitTypeQuestionId == attachment.ResponseId).ResponseAttachment = []
        //   this._permitObj.Responses.find(resp => resp.PermitTypeQuestionId == attachment.ResponseId).ResponseAttachment.push(attachment)
        // })
      }
    }

  }

  OLD_SaveValues(permitStateId) {
    if (this._permitObj.PermitId > 0) {
      let languageId = localStorage.getItem("_languageID");
      this._permitObj.LanguageId = languageId;
      this._permitService.updatePermit(this._permitObj).subscribe((data: HttpResponse<any>) => {
        if (data.status === 200) {
          if (!(this._approverComments == null || this._approverComments.trim() == '')) {
            this.SaveApproverComments();
          }
          //this.getPermit(this._permitObj.PermitId, false)
          if (permitStateId == Status.DRAFT) {
            this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.PermitSavedAsDraft') });
          }
          else if (permitStateId == Status.ACTIVE) {
            this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.CreatePermitSuccess') });
          }
          else {
            this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.UpdatePermitSuccess') });
          }
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.UpdatePermitError') });
        }
      });
    }
    else {
      this._permitService.addPermit(this._permitObj).subscribe((data: HttpResponse<any>) => {
        if (data.status === 200) {
          //this.getPermit(data.body.PermitId, false);
          let redirectURL = '/permit/' + this._permitTypeId + '/' + data.body.PermitId;
          if (permitStateId == Status.DRAFT) {
            this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.PermitSavedAsDraft') });
          }
          else {
            this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.CreatePermitSuccess') });
          }
          this._router.navigate([redirectURL]);

        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.CreatePermitError') });
        }
      });
    }
  }

  nullifyPermitDetails() {
    this._permitObj.PermitId = 0;
    this._permitObj.PermitIssuedDateTime = null;
    this._permitObj.PermitExpiryDateTime = null;
    this._permitObj.RevalidationHours = 0;
    this._permitObj.PermitNumber = null;
    this._permitObj.PermitActivityLogs = null;
    this._permitObj.PermitState = null;
    this._permitObj.PermitStateId = null;
    this._permitObj.CreatedBy = null;
    this._permitObj.CreatedDate = null;
    this._permitObj.ModifiedBy = null;
    this._permitObj.ModifiedDate = null;
  }

  OLD_getPermit(permitId: number, permitCloneFlag: boolean) {
    if (permitCloneFlag) {
      this._permitService.getPermit(permitId).subscribe((permit: Permit) => {
        if (permit) {
          this._permitObj = permit
          this.PopulateResponsesForPermit(permitCloneFlag);
          this.nullifyPermitDetails();
        }
      });
    }
    else {
      if (permitId > 0) {
        this._permitService.getPermit(permitId).subscribe((permit: Permit) => {
          if (permit != undefined) {
            this._permitObj = permit;
            this._commentsCount = this._permitObj.CommentsCount;
            if (this._currentLoggedInUserRoles != undefined) {
              //debugger;
              if (this._permitObj.PermitStateId == Status.ACTIVE) {
                this.isPermitActive = true;
              }
              this._PermitVersion = this.fetchVersionBasedOnPermit();
              this._permitActivityLog = [];
              this._activeQuestions = [];
              this._permitTypeLevelApprovalList = [];
              this._permitTypeLevelApprovalList = this._PermitVersion.PermitType.PermitTypeLevelApprovals.filter(x => x.Active == true);
              this.GetActiveQuestions();
              this.GetSequenceWiseSections();
              this.GetActiveSequence();
              this.GetPermitLevelSequence();
              this.GetDataForPermitStatus();
              this.getPermitControlsForPermitVersion();
              this.PopulateResponsesForPermit(false);
              this.PopulateAttachmentForPermit();
              this.GetPermitCreatedByUserDetails(this._permitObj.PermitId);
              this.GetMaxOccurance(permitId);

            }
          }
          else {
            this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.PermitNotFoundError') });
            let redirectURL = '/permit/' + this._permitTypeId + '/0';
            this._router.navigate([redirectURL]);
          }
        });
      }
    }

  }

  async PopulateAttachmentForPermit() {
    this.responseAttachedFiles = [];
    let flag = 0;
    let quesId;
    this._permitObj.Responses.forEach(response => {
      if (response.ResponseAttachment.length > 0) {
        response.ResponseAttachment.forEach(async attachment => {
          if (response.Question.AnswerTypeId == 2) {
            if (quesId != response.PermitTypeQuestionId) {
              flag = 0;
            }
            if (flag == 0) {
              this.SetAttachedFilesArray(attachment.AzureFileId, response.PermitTypeQuestionId, attachment.Active);
              await this.DelayExecution();
              quesId = response.PermitTypeQuestionId;
              flag++;
            }
          }
          else {
            this.SetAttachedFilesArray(attachment.AzureFileId, response.PermitTypeQuestionId, attachment.Active);
            await this.DelayExecution();
          }
        })
      }
    })
  }

  // Method to get the checkbox responses for a permit
  bindCheckBoxResponses(ques: PermitTypeQuestion): number[] {
    let responses = this._permitObj.Responses.filter(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId);
    let arrayForCheckBox: number[] = [];
    responses.forEach(response => {
      arrayForCheckBox.push(response.SelectedAnswerOptionId);
    });
    return arrayForCheckBox;
  }

  // Method to get responses for a permit
  bindResponseText(ques: PermitTypeQuestion) {
    let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseText;
    if (ques.AnswerTypeId == 10 || ques.AnswerTypeId == 7) {
      ansText = this.formatAMPM(ansText);
    }
    return ansText;
  }

  bindSelectedAnswerOptionId(ques: PermitTypeQuestion): number {
    let ansID = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).SelectedAnswerOptionId;
    return ansID;
  }

  PopulateResponsesForPermit(permitCloneFlag: boolean) {
    if (permitCloneFlag) {
      this._PermitVersion.Divisions.forEach((div: PermitTypeDivision) => {
        div.Sections.forEach((sec: PermitTypeSection) => {
          sec.Questions.forEach((ques: PermitTypeQuestion) => {
            if (ques.CopyQuestionValue) {
              if (ques.AnswerTypeId == 2) {
                ques.SelectedAnswerCheckBox = this.bindCheckBoxResponses(ques);
              }
              else if (ques.AnswerTypeId == 9) {
                ques.TableData = [];
                let tableTypeResponses: TableTypeResponse[] = [];
                let deletedTableTypeResponses: TableTypeResponse[] = [];

                this._permitObj.Responses.forEach(x => {
                  if (x.PermitTypeQuestionId == ques.PermitTypeQuestionId) {
                    tableTypeResponses = x.TableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                    deletedTableTypeResponses = x.TableTypeResponse.filter(x => x.Active == false);

                    if (tableTypeResponses.length > 0) {
                      ques.TableData = [];
                      const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                      let distinctRowIndexes = new Set(indexArray);
                      let counter = 0;

                      const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                      let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                      distinctRowIndexes.forEach(idx => {

                        let rowCheck = 0;

                        deletedDistinctRowIndexes.forEach(function (value) {

                          if (idx == value) {
                            rowCheck++;

                          }
                        });
                        // if (rowCheck == 0) {
                        //   ques.TableData["deleted" + counter] = "visible";
                        // }
                        // else {
                        //   ques.TableData["deleted" + counter] = "hidden";
                        // }

                        ques.TableData[counter] = [];

                        for (let header of ques.TableFieldType) {
                          {
                            ques.TableData[counter][header.ColumnName] = {};
                          }
                        }

                        for (let header of ques.TableFieldType) {

                          if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                            if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                              (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).ResponseText;
                            }
                            else {
                              (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId;
                              // (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).tabl;
                              (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = (header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId)) ? header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText : '';
                            }
                          }
                          else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                            (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).ResponseText;
                          }
                          else if (header.AnswerTypeId == 2) {
                            let responses: number[] = []
                            tableTypeResponses.filter(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).forEach(x => {
                              if (x.SelectedAnswerOptionId != null) {
                                responses.push(x.SelectedAnswerOptionId);
                              }
                            });
                            (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                          }
                        }
                        counter++;
                      });
                    }
                  }
                });
              }
              else if (ques.AnswerTypeId == 11) {
                let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseText;
                if (ansText != undefined && ansText != null && ansText.trim() != "") {
                  let data = ansText.split("@@@");
                  ques.FieldValue = data[0];
                  ques.FieldDesc = data[1];
                }
              }
              else if (ques.AnswerTypeId == 13) {
                let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseText;
                if (ansText != undefined && ansText != null && ansText.trim() != "") {
                  let data = ansText.split("@@@");
                  ques.Approver = data[0];
                  ques.TimeStamp = data[1];
                  ques.ContactNumber = data[2];
                }
              }
              else {
                ques.SelectedAnswerOptionID = this.bindSelectedAnswerOptionId(ques);
                ques.AnswerText = this.bindResponseText(ques);
              }
            }
          })
        })
      });
    }
    else {
      this._PermitVersion.Divisions.forEach((div: PermitTypeDivision) => {
        div.Sections.forEach((sec: PermitTypeSection) => {
          sec.Questions.forEach((ques: PermitTypeQuestion) => {
            if (ques.AnswerTypeId == 2) {
              ques.SelectedAnswerCheckBox = this.bindCheckBoxResponses(ques);
            }
            else if (ques.AnswerTypeId == 9) {
              ques.TableData = [];
              let tableTypeResponses: TableTypeResponse[] = [];
              let deletedTableTypeResponses: TableTypeResponse[] = [];

              this._permitObj.Responses.forEach(x => {
                if (x.PermitTypeQuestionId == ques.PermitTypeQuestionId) {
                  tableTypeResponses = x.TableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                  deletedTableTypeResponses = x.TableTypeResponse.filter(x => x.Active == false);

                  if (tableTypeResponses.length > 0) {
                    ques.TableData = [];
                    const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                    let distinctRowIndexes = new Set(indexArray);
                    let counter = 0;

                    const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                    let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                    distinctRowIndexes.forEach(idx => {
                      let rowCheck = 0;
                      deletedDistinctRowIndexes.forEach(function (value) {
                        if (idx == value) {
                          rowCheck++;
                        }
                      });
                      // if (rowCheck == 0) {
                      //   ques.TableData["deleted" + counter] = "visible";
                      // }
                      // else {
                      //   ques.TableData["deleted" + counter] = "hidden";
                      // }

                      ques.TableData[counter] = [];

                      for (let header of ques.TableFieldType) {
                        {
                          ques.TableData[counter][header.ColumnName] = {};
                        }
                      }

                      for (let header of ques.TableFieldType) {
                        if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                          //(ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId;
                          if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                            (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).ResponseText;
                          }
                          else {
                            (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId;

                            let tableTypeResponseObj = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx);
                            if (tableTypeResponseObj) {
                              let headerObj = header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponseObj.SelectedAnswerOptionId);
                              if (headerObj) {
                                (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = headerObj.OptionText;
                              }
                            }

                            // (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText;
                          }
                        }
                        else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                          (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).ResponseText;
                        }
                        else if (header.AnswerTypeId == 2) {
                          let responses: number[] = []
                          tableTypeResponses.filter(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).forEach(x => {
                            if (x.SelectedAnswerOptionId != null) {
                              responses.push(x.SelectedAnswerOptionId);
                            }
                          });
                          (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                        }
                      }
                      counter++;
                    });
                  }
                }
              });
            }
            else if (ques.AnswerTypeId == 11) {
              let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseText;
              if (ansText != undefined && ansText != null && ansText.trim() != "") {
                let data = ansText.split("@@@");
                ques.FieldValue = data[0];
                ques.FieldDesc = data[1];
              }
            }
            else if (ques.AnswerTypeId == 13) {
              let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseText;
              if (ansText != undefined && ansText != null && ansText.trim() != "") {
                let data = ansText.split("@@@");
                ques.Approver = data[0];
                ques.TimeStamp = data[1];
                ques.ContactNumber = data[2];
              }
            }
            else {
              ques.SelectedAnswerOptionID = this.bindSelectedAnswerOptionId(ques);
              ques.AnswerText = this.bindResponseText(ques);
            }
          })
        })

      });
    }
  }

  fetchVersionBasedOnPermit(): PermitTypeVersion {
    // let versionToBeAccepted = new PermitTypeVersion()
    // this._PermitVersionList.forEach(version => {
    //   version.Divisions.forEach(div => {
    //     div.Sections.forEach(sec => {
    //       sec.Questions.forEach(ques => {
    //         if (ques.PermitTypeQuestionId == this._permitObj.Responses[0].PermitTypeQuestionId) {
    //           versionToBeAccepted = version;
    //           return versionToBeAccepted;
    //         }
    //       });
    //     });
    //   });
    // });
    return this._PermitVersionList.find(x => x.PermitTypeVersionId == this._permitObj.PermitTypeVersionId);
  }

  getPermitControlsForPermitVersion() {
    this._permitControlService.getPermitTypeControl(this._PermitVersion.PermitTypeVersionId).subscribe((permitControl: PermitControl) => {
      this._permitControl = permitControl;
      if (permitControl) {
        if (permitControl.AutoLapse) {
          this.checkRevalidation = true;
        }
        // this.revalidationHours = permitControl.AutoLapseHours != null ? +permitControl.AutoLapseHours.AutoLapseText : 0;
        if (permitControl.AutoLapseHours) {

          this.revalidationHours = (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Years) ? (+permitControl.AutoLapseHours.AutoLapseText * 24 * 365)
            : (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Months) ? (+permitControl.AutoLapseHours.AutoLapseText * 24 * 30)
              : (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Weeks) ? (+permitControl.AutoLapseHours.AutoLapseText * 24 * 7)
                : (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Days) ? (+permitControl.AutoLapseHours.AutoLapseText * 24)
                  : +permitControl.AutoLapseHours.AutoLapseText

          this.revalidationPeriod = permitControl.AutoLapseHours.AutoLapseText.concat(
            (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Years) ? ' Year(s)'
              : (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Months) ? ' Month(s)'
                : (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Weeks) ? ' Week(s)'
                  : (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Days) ? ' Day(s)'
                    : ' Hour(s)'

          );

        }

        if (permitControl.AutoCloseHours) {

          this.revalidationHours = (permitControl.AutoCloseHours.TimeOptionId == TimeOption.Years) ? (+permitControl.AutoCloseHours.AutoCloseHour * 24 * 365)
            : (permitControl.AutoCloseHours.TimeOptionId == TimeOption.Months) ? (+permitControl.AutoCloseHours.AutoCloseHour * 24 * 30)
              : (permitControl.AutoCloseHours.TimeOptionId == TimeOption.Weeks) ? (+permitControl.AutoCloseHours.AutoCloseHour * 24 * 7)
                : (permitControl.AutoCloseHours.TimeOptionId == TimeOption.Days) ? (+permitControl.AutoCloseHours.AutoCloseHour * 24)
                  : (permitControl.AutoCloseHours.TimeOptionId == TimeOption.Hours) ? (+permitControl.AutoCloseHours.AutoCloseHour * 1)
                    : +permitControl.AutoCloseHours



        }
        if (permitControl.Revalidation) {
          if (this._permitObj.PermitStateId == PermitStates.SUSPENDED || this._permitObj.PermitStateId == PermitStates.LAPSED) {
            this._canBeRevalidated = true

            if (permitControl.rolesForRevalidation == null || permitControl.rolesForRevalidation == undefined || permitControl.rolesForRevalidation.length == 0) {
              this._canBeRevalidated = true
            }
            else if (permitControl.rolesForRevalidation && permitControl.rolesForRevalidation.length > 0 && this._currentLoggedInUserRoles) {
              for (let i = 0; i < permitControl.rolesForRevalidation.length; i++) {
                if (permitControl.rolesForRevalidation[i].RoleId != null || permitControl.rolesForRevalidation[i].RoleId > 0) {
                  if (this._currentLoggedInUserRoles.includes(permitControl.rolesForRevalidation[i])) {
                    this._canBeRevalidated = true;
                    break;
                  }
                }
                else {
                  this._canBeRevalidated = true;
                  break;
                }
              }
            }
          }
          else if (this._permitObj.PermitStateId == PermitStates.ACTIVE && permitControl.AutoLapse) {

            if (permitControl.rolesForRevalidation && permitControl.rolesForRevalidation.length > 0 && this._currentLoggedInUserRoles) {

              this.allRoles.forEach(x => {
                if (x.RoleKey == "ENTRYLEADER") {
                  for (let i = 0; i < permitControl.rolesForRevalidation.length; i++) {
                    // if (permitControl.rolesForRevalidation[i].RoleId == x.RoleId ) {
                    //if (this._currentLoggedInUserRoles.includes(permitControl.rolesForRevalidation[i])) {
                    if (permitControl.rolesForRevalidation[i].RoleId != null || permitControl.rolesForRevalidation[i].RoleId > 0) {
                      if (this.entryLeaderRole.includes(x.RoleId)) {
                        this._canBeRevalidated = true;
                        this.isRevalidateBeforeLapse = true;
                        break;
                      }
                    }
                  }
                }
              });
            }
          }
        }
        if (permitControl.GeoTag) {
          if (this._permitObj.PermitStateId == Status.ACTIVE || this._permitObj.PermitStateId == Status.DRAFT ||
            this._permitObj.PermitStateId == undefined || this._permitObj.PermitStateId == null ||
            this._permitObj.PermitStateId == Status.AWAITINGEDITORRESPONSE || this._permitObj.PermitStateId == Status.PENDINGAPPROVAL) {
            this.geoTag = permitControl.GeoTag;
            if (permitControl.SymbologyList && permitControl.SymbologyList.length > 0) {
              sessionStorage.setItem('lineSymbology', JSON.stringify(permitControl.SymbologyList[0]));


              let lineSymbology = permitControl.SymbologyList[0];
              lineSymbology.name = "lineSymbology";
              let areaSymbology = permitControl.SymbologyList[1];
              this._indexDbService.addRecordAsync("mapJson", lineSymbology).subscribe(data => {
              });
              this._indexDbService.addRecordAsync("mapJson", areaSymbology).subscribe(data => {
              });
              if (this._permitTypeName) {
                let permitType: any = {};
                permitType.name = "permitType";
                permitType.permitType = this._permitTypeName;
                this._indexDbService.addRecordAsync("mapJson", permitType).subscribe(data => {
                });
              }
              let site: any = {};
              site.name = "site";
              if (this._permitObj.SiteName) {
                site.sitename = this._permitObj.SiteName;
              }
              else {
                site.sitename = this.userHierarchy.SiteName;
              }
              this._indexDbService.editRecordAsync("mapJson", site).subscribe((data) => {
                // console.log("Site Name", data);

              });
              if (this._permitId == 0) {
                let featEditable: any = {};
                featEditable.name = "Editable";
                featEditable.Editable = true;
                this._indexDbService.editRecordAsync("mapJson", featEditable).subscribe((data) => {
                });
              }
              let arcGISKey = {
                key: this._commonService.getArcGISKey(),
                name: 'arcGISKey'
              }
              this._indexDbService.addRecordAsync("mapJson", arcGISKey).subscribe();

              sessionStorage.setItem('areaSymbology', JSON.stringify(permitControl.SymbologyList[1]));
            }
          }
        }
        else {
          // this._canBeRevalidated = false
        }
        if (this._canBeRevalidated) {
          if (this.revisionCounter < permitControl.MaxOccurrance.MaxOccurence) {
            this._maxOccursExhausted = false
          }
          else {
            this._maxOccursExhausted = true
          }
        }
      }
    });
  }

  // Fetches all the active questions for this permit that needs to be edited based on the Logged-In user (Editor)
  GetActiveQuestions() {

    // Fethes active questiones based on Editor from the FIRST DIVISION as the permit status is PENDINGAPPROVAL
    if (this._permitObj.PermitStateId == Status.PENDINGAPPROVAL) {

      this._PermitVersion.Divisions[0].Sections.forEach(sec => {
        sec.Questions.forEach(ques => {

          if (this._PermitVersion.Divisions[0].Editor != null) {
            let userRole = this._currentLoggedInUserRoles.find(x => x.RoleId == this._PermitVersion.Divisions[0].Editor);
            if (userRole != undefined) {
              this._activeQuestions.push(ques.PermitTypeQuestionId);
            }
          }
          else {
            if (sec.Editor != null) {
              let userRole = this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Editor);
              if (userRole != undefined) {
                this._activeQuestions.push(ques.PermitTypeQuestionId);
              }
            }
            else if (sec.Editor == null) {
              this._activeQuestions.push(ques.PermitTypeQuestionId);
            }
          }
        })
      })

      // Removes the questions from from the list that have already been approved or edited
      // If a response is approved, editing it is not required
      this._permitObj.Responses.forEach(res => {
        if (res.Approved == true || res.Edited == true) {
          this._activeQuestions = this._activeQuestions.filter(x => x != res.PermitTypeQuestionId)
        }
      })

    }

    // Fethes active questiones based on Editor from the SECOND & THIRD DIVISION as the permit status is ACTIVE
    if (this._permitObj.PermitStateId == Status.ACTIVE) {
      for (let i = 1; i < this._PermitVersion.Divisions.length; i++) {
        let div = this._PermitVersion.Divisions[i];
        div.Sections.forEach(sec => {
          sec.Questions.forEach(ques => {

            if (div.Editor != null) {
              let userRole = this._currentLoggedInUserRoles.find(x => x.RoleId == div.Editor);
              if (userRole != undefined) {
                this._activeQuestions.push(ques.PermitTypeQuestionId);
              }
            }
            else {
              if (sec.Editor != null) {
                let userRole = this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Editor);
                if (userRole != undefined) {
                  this._activeQuestions.push(ques.PermitTypeQuestionId);
                }
              }
              else if (sec.Editor == null) {
                this._activeQuestions.push(ques.PermitTypeQuestionId);
              }
            }

          })
        })
      }

      // Removes the questions from from the list that have already been approved or edited
      // If a response is approved, editing it is not required
      this._permitObj.Responses.forEach(res => {
        if (res.Approved == true || res.Edited == true) {
          this._activeQuestions = this._activeQuestions.filter(x => x != res.PermitTypeQuestionId)
        }
      })
    }

    // Removes duplicate questions from from the list
    this.RemoveDuplicateActiveQuestions();
  }

  GetSequenceWiseSections() {
    this._sequenceList = [];
    let versionObj: PermitTypeVersion = JSON.parse(JSON.stringify(this._PermitVersion));
    versionObj.Divisions.forEach(div => {
      let obj = {
        Division: div,
        SectionSequence: this.GetSequenceWiseSectionsBasedOnDivision(div)
      }
      this._sequenceList.push(obj);
    })
  }

  GetSequenceWiseSectionsBasedOnDivision(division: PermitTypeDivision) {

    let secList = division.Sections;

    if (secList.length == 0) {
      return [];
    }

    secList = secList.sort((x1, x2) => x1.SequenceNumber - x2.SequenceNumber);

    let seqList: any[] = [];
    let sectionIdLst: any[] = [];

    sectionIdLst.push(secList[0]);
    let obj = {
      SeqNumber: secList[0].SequenceNumber,
      Sections: sectionIdLst
    }
    seqList.push(obj);

    for (let i = 1; i < secList.length; i++) {
      if (seqList[seqList.length - 1].SeqNumber == secList[i].SequenceNumber) {
        sectionIdLst = [];
        sectionIdLst = seqList[seqList.length - 1].Sections;
        sectionIdLst.push(secList[i]);
        seqList[seqList.length - 1].Sections = sectionIdLst;
      }
      else {
        sectionIdLst = [];
        sectionIdLst.push(secList[i]);
        let obj = {
          SeqNumber: secList[i].SequenceNumber,
          Sections: sectionIdLst
        }
        seqList.push(obj);
      }
    }

    return seqList;
  }

  RemoveDuplicateActiveQuestions() {
    let tempList = Object.assign(this._activeQuestions);
    this._activeQuestions = [];
    this._activeQuestions.push(tempList[0]);
    tempList.forEach(val => {
      if (!this._activeQuestions.find(x => x == val || x == undefined)) {
        this._activeQuestions.push(val);
      }
    })
  }

  OLD_SaveResponse() {
    // if (this.ErrorCount() == 0) {
    if (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null) {
      this.getPermiTypeDetails(this._PermitVersion.PermitTypeId)
      this.AssignValues();

      if (this._permitObj != null && this._permitObj != undefined && this._permitObj.PermitStateId >= Status.PENDINGAPPROVAL) {
        this.BindEditorState(0);
        this._permitObj.PermitActivityLogs = this.RemoveDuplicateLogs();
      }
      this._permitObj.PermitStateId = (this._permitObj == null || this._permitObj.PermitStateId == undefined || this._permitObj.PermitStateId == Status.DRAFT) ? Status.DRAFT : this._permitObj.PermitStateId;

      if (this._permitObj.PermitStateId == Status.DRAFT) {
        this.SaveValues();
      }
      else {
        this.SaveValues();
      }
    }
    else {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
    }
    // }
  }

  isResponseValid() {
    errors: Number;
    if (this.ErrorCount() == 0) {
      var Hierarchy = (document.getElementById("selectedHierarchy") as HTMLSpanElement).innerText;
      if (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null && Hierarchy.search("SITE")) {
        this.getPermiTypeDetails(this._PermitVersion.PermitTypeId)
        this.AssignValues();
        this._permitObj.PermitStateId = 2;
        this.SaveValues();
      }
      else {
        //this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
      }
    }
  }

  OLD_ErrorCount() {
    let errorCount: number = 0;
    let permitDivision = new PermitTypeDivision()
    if (this._permitObj.PermitStateId == 3) {
      permitDivision = this._PermitVersion.Divisions[1]
    }
    else {
      permitDivision = this._PermitVersion.Divisions[0]
    }
    for (let section of permitDivision.Sections) {
      for (let question of section.Questions) { // Non table question
        if (question.AnswerTypeId != 9) {
          if (question.Mandatory) {
            if (question.AnswerTypeId == 1) {

              if (question.AnswerOptions[0].DynamicDataSource) {
                // if (question.AnswerText == undefined || question.AnswerText.trim() == "") {
                if (question.AnswerText == undefined || question.AnswerText == '0') {
                  this._messageService.clear('error');
                  this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                  errorCount++;
                  return errorCount;
                }
              }
              else {
                if (question.SelectedAnswerOptionID == undefined || question.SelectedAnswerOptionID == 0) {
                  this._messageService.clear('error');
                  this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                  errorCount++;
                  return errorCount;
                }
              }

            }
            if (question.AnswerTypeId == 2) {
              if (question.SelectedAnswerCheckBox == undefined || question.SelectedAnswerCheckBox.length == 0) {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                errorCount++;
                return errorCount;
              }
            }
            if (question.AnswerTypeId == 3) {
              // if (question.AnswerText == undefined || question.AnswerText.trim() == "") {
              if (question.AnswerText == undefined) {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                errorCount++;
                return errorCount;
              }
            }
            if (question.AnswerTypeId == 4) {
              if (question.SelectedAnswerOptionID == undefined || question.SelectedAnswerOptionID == 0) {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                errorCount++;
                return errorCount;
              }
            }
            if (question.AnswerTypeId == 6) {
              // if (question.AnswerText == undefined || question.AnswerText.trim() == "") {
              if (question.AnswerText == undefined) {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                errorCount++;
                return errorCount;
              }
            }

            if (question.AnswerTypeId == 11) {
              // if (question.AnswerText == undefined || question.AnswerText.trim() == "") {
              if (question.FieldDesc == undefined || question.FieldDesc.trim() == "") {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                errorCount++;
                return errorCount;
              }
              if (question.FieldValue == undefined || question.FieldValue.trim() == "") {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                errorCount++;
                return errorCount;
              }

            }


            if (question.AnswerTypeId == 12) {
              // if (question.AnswerText == undefined || question.AnswerText.trim() == "") {
              if (question.AnswerText == undefined) {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                errorCount++;
                return errorCount;
              }
            }

            if (question.AnswerTypeId == 7 || question.AnswerTypeId == 10) {
              // if (question.AnswerText == undefined || question.AnswerText.trim() == "") {
              if (question.AnswerText == undefined) {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                errorCount++;
                return errorCount;
              }
            }

            if (question.AnswerTypeId == 10) {
              // if (question.AnswerText == undefined || question.AnswerText.trim() == "") {
              if (question.AnswerText == undefined) {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                errorCount++;
                return errorCount;
              }
            }
          }
        }
        else {
          let isTableMandatory: boolean = false;
          for (let header of question.TableFieldType) {
            if (header.Mandatory) {
              isTableMandatory = true;
            }
          }
          if (isTableMandatory && this.GetRowCount(document.getElementById("TableId" + question.PermitTypeQuestionId)) < 2) {
            this._messageService.clear('error');
            this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTableBlank') });
            errorCount++;
            return errorCount;
          }

          //For table 
          let deletedIndexes: number[];
          deletedIndexes = [];
          for (let rowIndex in question.TableData) {
            if (rowIndex.toString().startsWith('deleted')) {
              if (question.TableData[rowIndex.toString()] == "hidden") {
                deletedIndexes.push(parseInt(rowIndex.toString().replace("deleted", "")))
              }
            }
          }
          for (let rowIndex in question.TableData) {
            if (rowIndex.toString().startsWith('delete') == false) {
              if (!this.IsRowDeleted(rowIndex, deletedIndexes)) {
                for (let header of question.TableFieldType) {
                  if (header.Mandatory) {

                    if (header.AnswerTypeId == 1) {

                      if (header.AnswerOptions[0].DynamicDataSource) {
                        if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == "") {
                          this._messageService.clear('error');
                          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                          errorCount++;
                          return errorCount;
                        }
                      }
                      else {
                        if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                          this._messageService.clear('error');
                          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                          errorCount++;
                          return errorCount;
                        }
                      }

                      if (header.AnswerOptions[0].DynamicDataSource) {
                        if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == "") {
                          this._messageService.clear('error');
                          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                          errorCount++;
                          return errorCount;
                        }
                      }
                      else {
                        if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                          this._messageService.clear('error');
                          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                          errorCount++;
                          return errorCount;
                        }
                      }

                    }
                    if (header.AnswerTypeId == 2) {
                      if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable.length == 0) {
                        this._messageService.clear('error');
                        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                        errorCount++;
                        return errorCount;
                      }
                    }
                    if (header.AnswerTypeId == 3) {
                      if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                        this._messageService.clear('error');
                        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                        errorCount++;
                        return errorCount;
                      }
                    }
                    if (header.AnswerTypeId == 4) {
                      if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                        this._messageService.clear('error');
                        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                        errorCount++;
                        return errorCount;
                      }
                    }
                    if (header.AnswerTypeId == 6) {
                      if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                        this._messageService.clear('error');
                        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                        errorCount++;
                        return errorCount;
                      }
                    }

                    if (header.AnswerTypeId == 7) {// TODO:
                      if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                        this._messageService.clear('error');
                        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                        errorCount++;
                        return errorCount;
                      }
                    }

                    if (header.AnswerTypeId == 10) {// TODO:
                      if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                        this._messageService.clear('error');
                        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                        errorCount++;
                        return errorCount;
                      }
                    }
                  }
                }
              }
            }
          }

        }
      }
    }
    return errorCount;
  }

  OLD_SubmitPermitResponse() {
    this._confirmationService.confirm({
      message: this.translate.instant('Permit.ConfirmSumbitMsg'),
      header: this.translate.instant('Permit.ConfirmSubmitHeader'),
      key: 'confirmSubmit',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.SubmitResponse();
      },
      reject: () => {
      }
    });
  }

  OLD_SubmitResponse() {

    if (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null) {
      if (this.ErrorCount() == 0) {
        this.AssignValues();
        if (this._permitObj != null && this._permitObj != undefined && this._permitObj.PermitStateId >= Status.PENDINGAPPROVAL) {
          this.BindEditorState(1);
          this._permitObj.PermitActivityLogs = this.RemoveDuplicateLogs();
          this.BindEditorResponse(true);
        }
        this._permitObj.PermitStateId = (this._permitObj == null || this._permitObj.PermitStateId == undefined || this._permitObj.PermitStateId == Status.DRAFT) ? this.CheckIfApproverPresentAtPermitTypeLevelAndCreatePermitSectionLevel() : this._permitObj.PermitStateId;
        this._permitObj.PermitState = null;
        if (this._permitObj.PermitStateId >= Status.ACTIVE) {
          this.SetPermitTime()
        }
        this.SaveValues();
      }
    }
    else {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
    }

  }

  // Check if any appover is defined for the FIRST DIVISION.
  // If no approver is present, mark the permit as ACTIVE upon submit
  CheckIfApproverPresentAtPermitTypeLevelAndCreatePermitSectionLevel() {
    let count = 0;

    this._PermitVersion.Divisions[0].Sections.forEach(sec => {
      if (sec.Approver != null) {
        count++;
      }
    })

    if (this._permitTypeLevelApprovalList.length > 0 || count > 0) {
      return Status.PENDINGAPPROVAL;
    }

    else {
      return Status.ACTIVE;
    }
  }

  onOK() {
    this._messageService.clear('error');
    this._messageService.clear('success');
  }

  // Logic to show / hide the SAVE & SUBMIT button based on
  // 1. Logged-In user
  // 2. Permit State
  // 3. Active Questions
  OLD_showSaveSubmitButton() {
    let count = 0;
    if (this._permitObj) {
      // When the permit is created for the first time, the button should be visible for the user to SAVE/SUBMIT the permit
      if (this._permitObj.PermitStateId == undefined) {
        count++;
      }
      // When the permit is opened if draft mode, the buttons are visible only to the creator of the permit
      else if (this._permitObj.PermitStateId == Status.DRAFT && this._currentLoggedInUser) {
        if (this._currentLoggedInUser.LoginId == this._permitObj.CreatedBy) {
          count++;
        }
      }
      // When the permit has been submitted and is pending for approval, 
      // the buttons are visible only if the Logged-In user is the Editor of active-question sections/division.
      // Only the FIRST DIVISION is considered as the permit state is PENDINGAPPROVAL
      else if (this._permitObj.PermitStateId == Status.PENDINGAPPROVAL && this._currentLoggedInUserRoles) {
        if (this._PermitVersion.Divisions[0].Editor != null) {
          if (this._currentLoggedInUserRoles.find(x => x.RoleId == this._PermitVersion.Divisions[0].Editor)) {
            this._PermitVersion.Divisions[0].Sections.forEach(sec => {
              sec.Questions.forEach(ques => {
                if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                  count++;
                }
              })
            })
          }
        }
        else {
          this._PermitVersion.Divisions[0].Sections.forEach(sec => {
            if (sec.Editor != null) {
              if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Editor)) {
                sec.Questions.forEach(ques => {
                  if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                    count++;
                  }
                })
              }
            }
            else {
              sec.Questions.forEach(ques => {
                if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                  count++;
                }
              })
            }
          })
        }
      }
      // When the permit is ACTIVE, 
      // the buttons are visible only if the Logged-In user is the Editor of active-question sections/division.
      // The SECOND & THIRD DIVISION is considered as the permit state is ACTIVE
      else if (this._permitObj.PermitStateId == Status.ACTIVE && this._currentLoggedInUserRoles) {
        if (this._currentLoggedInUserRoles.find(x => x.RoleId == this._PermitVersion.Divisions[1].Editor)) {
          this._PermitVersion.Divisions[1].Sections.forEach(sec => {
            sec.Questions.forEach(ques => {
              if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                count++;
              }
            })
          })
        }
        else if (this._currentLoggedInUserRoles.find(x => x.RoleId == this._PermitVersion.Divisions[2].Editor)) {
          this._PermitVersion.Divisions[2].Sections.forEach(sec => {
            sec.Questions.forEach(ques => {
              if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                count++;
              }
            })
          })
        }
        else {
          for (let i = 1; i < this._PermitVersion.Divisions.length; i++) {
            let div = this._PermitVersion.Divisions[i];
            div.Sections.forEach(sec => {
              if (sec.Editor != null) {
                if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Editor)) {
                  sec.Questions.forEach(ques => {
                    if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                      count++;
                    }
                  })
                }
              }
              else if (sec.Editor == null) {
                sec.Questions.forEach(ques => {
                  if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                    count++;
                  }
                })
              }
            })
          }
        }
      }
    }
    if (count > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  GetActiveSequence() {

    this._activeSequence = [];

    if (this._permitObj.PermitStateId == Status.PENDINGAPPROVAL) {
      this.GetActiveSequenceList(this._sequenceList[0]);
    }

    else if (this._permitObj.PermitStateId == Status.ACTIVE) {
      for (let i = 1; i < this._sequenceList.length; i++) {
        this.GetActiveSequenceList(this._sequenceList[i]);
      }
    }
  }

  GetActiveSequenceList(sequenceObj) {
    let sectionSeq = sequenceObj.SectionSequence;

    for (let i = 0; i < sectionSeq.length; i++) {
      if (sectionSeq[i].SeqNumber == null) {
        continue;
      }

      let sectionApproved: number = 0;
      for (let j = 0; j < sectionSeq[i].Sections.length; j++) {
        let sec = sectionSeq[i].Sections[j];
        for (let k = 0; k < sec.Questions.length; k++) {
          let ques = sec.Questions[k];

          let response = this._permitObj.Responses.filter(res => res.PermitTypeQuestionId == ques.PermitTypeQuestionId && res.Approved == true)
          if (response && response.length > 0) {
            sectionApproved++;
            break;
          }
        }
      }

      if (sectionApproved < sectionSeq[i].Sections.length) {
        let obj = {
          DivisionId: sequenceObj.Division.PermitTypeDivisionId,
          SectionSequence: sectionSeq[i].SeqNumber
        }
        this._activeSequence.push(obj);
        break;
      }
    }
  }

  GetPermitLevelSequence() {

    if (!this.IsFirstDivisionApproved()) {
      return;
    }

    let objList: PermitTypeLevelApproval[] = this._permitTypeLevelApprovalList;
    let list = [];
    list.push(null);
    for (let i = 0; i < objList.length; i++) {
      if (!list.find(x => x == objList[i].ApprovalSequence)) {
        list.push(objList[i].ApprovalSequence);
      }
    }
    list = list.sort((x1, x2) => x1 - x2).filter(x => x != null);

    let flag = 0;
    for (let j = 0; j < list.length; j++) {
      let approvalList = objList.filter(x => x.ApprovalSequence == list[j]);

      for (let k = 0; k < approvalList.length; k++) {
        let permitLevelObj = this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == approvalList[k].PermitTypeLevelApprovalId && x.PermitStateId == Status.PERMITLEVELAPPROVED);
        if (permitLevelObj == undefined) {
          flag++;
          break;
        }
      }

      if (flag != 0) {
        this._activePermitLevelSequence = list[j];
        break;
      }
    }
  }

  OLD_showApproveRejectButton() {
    let count = 0;
    if (this._permitObj.PermitId > 0 && this._currentLoggedInUserRoles && this._activeSequence) {
      if (this._permitObj.PermitStateId == Status.PENDINGAPPROVAL) {
        if (!this.IsFirstDivisionApproved()) {
          this._PermitVersion.Divisions[0].Sections.forEach(sec => {
            let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
            if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
              if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
                let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
                if (logSectionObj == undefined) {
                  count++;
                }
              }
            }
          })
        }
        else {
          let permitLevelApprovalList = this._permitTypeLevelApprovalList.filter(x => x.ApprovalSequence == null || x.ApprovalSequence == this._activePermitLevelSequence);
          permitLevelApprovalList.forEach(item => {
            if (this._currentLoggedInUserRoles.find(x => x.RoleId == item.Approver)) {
              let logPermitLevelObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == item.PermitTypeLevelApprovalId && x.PermitStateId == Status.PERMITLEVELAPPROVED);
              if (logPermitLevelObj == undefined) {
                count++;
              }
            }
          })
        }
      }
      else if (this._permitObj.PermitStateId == Status.ACTIVE) {
        // for (let i = 1; i < this._PermitVersion.Divisions.length; i++) {
        this._PermitVersion.Divisions[1].Sections.forEach(sec => {
          let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
          if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
            if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
              let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
              if (logSectionObj == undefined) {
                count++;
              }
            }
          }
        })
        // }
      }
    }
    if (count > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  OLD_showInputElementsForEditor(divisonObj, sectionObj, questionObj) {
    if (this._permitObj && this._currentLoggedInUser) {

      // check if division / section has already been approved before edit
      if (this._permitObj.PermitActivityLogs && this._permitObj.PermitActivityLogs.length > 0 && this._permitObj.PermitActivityLogs != null) {
        if (this._permitObj.PermitActivityLogs.find(x => (x.PermitStateId == Status.DIVISIONAPPROVED || x.PermitStateId == Status.DIVISIONEDITED) && x.ResponseTagNumber == divisonObj.PermitTypeDivisionId)) {
          return true;
        }
        if (this._permitObj.PermitActivityLogs.find(x => (x.PermitStateId == Status.SECTIONAPPROVED || x.PermitStateId == Status.SECTIONEDITED) && x.ResponseTagNumber == sectionObj.PermitTypeSectionId)) {
          return true;
        }
      }

      // first time load or draft
      if (this._permitObj.PermitStateId == Status.DRAFT || this._permitObj.PermitStateId == null || this._permitObj.PermitStateId == undefined) {
        if (this._PermitVersion.Divisions[0].PermitTypeDivisionId != divisonObj.PermitTypeDivisionId) {
          return true;
        }
        else if (this._permitObj.CreatedBy == null || this._permitObj.CreatedBy == undefined || this._permitObj.CreatedBy == "") {
          return false;
        }
        else {
          return this._permitObj.CreatedBy == this._currentLoggedInUser.LoginId ? false : true
        }
      }
      // submitted by the creator or approved 
      if (this._permitObj.PermitStateId == Status.PENDINGAPPROVAL || this._permitObj.PermitStateId == Status.ACTIVE) {
        if (this._PermitVersion.Divisions[0].PermitTypeDivisionId != divisonObj.PermitTypeDivisionId && this._permitObj.PermitStateId == Status.PENDINGAPPROVAL) {
          return true;
        }
        if (this._PermitVersion.Divisions[0].PermitTypeDivisionId == divisonObj.PermitTypeDivisionId && this._permitObj.PermitStateId == Status.ACTIVE) {
          return true;
        }
        if (divisonObj.Editor == null && sectionObj.Editor == null) {
          return false;
        }
        if (divisonObj.Editor != null && this._currentLoggedInUserRoles) {
          let userRole = this._currentLoggedInUserRoles.find(x => x.RoleId == divisonObj.Editor);
          if (userRole != undefined) {
            if (userRole.length == 0) {
              return true;
            }
            else {
              if (this._permitObj.Responses.find(res => res.PermitTypeQuestionId == questionObj.PermitTypeQuestionId).Edited != true) {
                return false;
              }
            }
          }
          return true;
        }
        if (sectionObj.Editor != null && this._currentLoggedInUserRoles) {
          let userRole = this._currentLoggedInUserRoles.find(x => x.RoleId == sectionObj.Editor);
          if (userRole != undefined) {
            if (userRole.length == 0) {
              return true;
            }
            else {
              if (this._permitObj.Responses.find(res => res.PermitTypeQuestionId == questionObj.PermitTypeQuestionId).Edited != true) {
                return false;
              }
            }
          }
          return true;
        }
      }
      // suspended or lapsed or rejected or cancelled
      else {
        return true
      }
    }
    return false;
  }

  BindApproverResponse(value: boolean) {
    // IF the permit id is ACTIVE, all the responses for section/division for wich the Logged-In user is marked as approver will be approved.
    // For this, the SECOND & THIRD DIVISIONS are considered.
    // Also, a check is made if the section/division has not already been approved.
    if (this._permitObj != null && this._permitObj != undefined && this._permitObj.PermitStateId == Status.ACTIVE) {
      // for (let i = 1; i < this._PermitVersion.Divisions.length; i++) {
      this._permitObj.Responses.forEach(res => {
        this._PermitVersion.Divisions[1].Sections.forEach(sec => {
          let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
          if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
            if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
              if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId)) {
                let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
                if (logSectionObj != undefined) {
                  res.Approved = value;
                }
              }
            }
          }
        })
      })
      // }
    }
    // IF the permit id is PENDINGFORAPPROVAL, all the responses for section under FIRST DIVISION for wich the Logged-In user is marked as approver will be approved.
    // For this, only the FIRST DIVISIONS is considered.
    // Also, a check is made if the section/division has not already been approved.
    else {
      if (!this.IsFirstDivisionApproved()) {
        this._permitObj.Responses.forEach(res => {
          this._PermitVersion.Divisions[0].Sections.forEach(sec => {
            let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
            if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
              if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
                if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId)) {
                  let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
                  if (logSectionObj != undefined) {
                    res.Approved = value;
                  }
                }
              }
            }
          })
        })
      }
    }
  }

  BindEditorResponse(value: boolean) {

    // IF the permit is ACTIVE, all the responses for section/division for wich the Logged-In user is marked as Editor will be edited.
    // For this, the SECOND & THIRD DIVISIONS are considered.
    // Also, a check is made if the section/division has not already been edited based on the acive-questions list.
    if (this._permitObj != null && this._permitObj != undefined && this._permitObj.PermitStateId == Status.ACTIVE) {
      for (let i = 1; i < this._PermitVersion.Divisions.length; i++) {
        let div = this._PermitVersion.Divisions[i];
        this._permitObj.Responses.forEach(res => {
          if (this._currentLoggedInUserRoles.find(x => x.RoleId == div.Editor)) {
            div.Sections.forEach(sec => {
              if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId) && this._activeQuestions.find(aq => aq == res.PermitTypeQuestionId)) {
                res.Edited = value
              }
            })
          }
          else {
            div.Sections.forEach(sec => {
              if (sec.Editor != null) {
                if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Editor)) {
                  if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId) && this._activeQuestions.find(aq => aq == res.PermitTypeQuestionId)) {
                    res.Edited = value
                  }
                }
              }
              else {
                if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId) && this._activeQuestions.find(aq => aq == res.PermitTypeQuestionId)) {
                  res.Edited = value
                }
              }
            })
          }
        })
      }
    }
    // IF the permit is PENDINGFORAPPROVAL, all the responses for section under FIRST DIVISION for wich the Logged-In user is marked as Editor will be edited.
    // For this, only the FIRST DIVISION is considered.
    // Also, a check is made if the section/division has not already been edited based on the acive-questions list.
    else {
      this._permitObj.Responses.forEach(res => {
        if (this._currentLoggedInUserRoles.find(x => x.RoleId == this._PermitVersion.Divisions[0].Editor)) {
          this._PermitVersion.Divisions[0].Sections.forEach(sec => {
            if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId) && this._activeQuestions.find(aq => aq == res.PermitTypeQuestionId)) {
              res.Edited = value
            }
          })
        }
        else {
          this._PermitVersion.Divisions[0].Sections.forEach(sec => {
            if (sec.Editor != null) {
              if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Editor)) {
                if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId) && this._activeQuestions.find(aq => aq == res.PermitTypeQuestionId)) {
                  res.Edited = value
                }
              }
            }
            else {
              if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId) && this._activeQuestions.find(aq => aq == res.PermitTypeQuestionId)) {
                res.Edited = value
              }
            }
          })
        }
      })
    }
  }

  IsPermitTypeLevelApprovalCompleted(logList: any) {
    if (this.IsFirstDivisionApproved()) {
      let flag = 0;
      this._permitTypeLevelApprovalList.forEach(item => {
        let log = logList.find(x => x.ResponseTagNumber == item.PermitTypeLevelApprovalId && x.PermitStateId == Status.PERMITLEVELAPPROVED)
        if (log == undefined) {
          flag++;
        }
      })
      if (flag == 0) {
        return true;
      }
    }
    return false;
  }

  // Check if all the responses in the sections under FIRST DIVISION is approved.
  // If yes, the Permit State is marked as ACTIVE. 
  IsFirstDivisionApproved(): boolean {
    let count = 0;
    this._PermitVersion.Divisions[0].Sections.forEach(sec => {
      sec.Questions.forEach(ques => {
        if (sec.Approver != null && this._permitObj.Responses.find(res => res.PermitTypeQuestionId == ques.PermitTypeQuestionId && res.Approved != true)) {
          count++;
        }
      })
    })
    if (count > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  OLD_ApprovePermit() {
    this._displayCommentPopUp = false;

    this.BindApproverState(0);
    let distinctActivityLogs = this.RemoveDuplicateLogs();

    let logList: any[] = [];
    this._permitObj.PermitActivityLogs.forEach(x => logList.push(x));
    distinctActivityLogs.forEach(x => logList.push(x));

    this._permitObj.PermitActivityLogs = distinctActivityLogs;
    this.BindApproverResponse(true);
    this._permitObj.PermitStateId = this.IsPermitTypeLevelApprovalCompleted(logList) ? Status.ACTIVE : Status.PENDINGAPPROVAL;
    this._permitObj.PermitState = null;
    if (this._permitObj.PermitStateId >= Status.ACTIVE) {
      this.SetPermitTime();
    }
    this.SaveValues();

  }

  SetPermitTime() {
    let IssuedDateTime = new Date()
    let ExpiryDateTime = new Date()

    this._permitObj.PermitIssuedDateTime = IssuedDateTime
    if (this.revalidationHours > 0) {
      ExpiryDateTime.setMilliseconds(ExpiryDateTime.getMilliseconds() + (this.revalidationHours * 3600 * 1000))
      this._permitObj.PermitExpiryDateTime = ExpiryDateTime
    }
  }

  OLD_RejectPermit() {
    this._displayCommentPopUp = false;
    this.BindApproverState(1);
    this._permitObj.PermitActivityLogs = this.RemoveDuplicateLogs();
    this.BindApproverResponse(false);
    this._permitObj.PermitStateId = Status.REJECTED;
    this._permitObj.PermitState = null;
    this.SaveValues();
  }

  RemoveDuplicateLogs(): PermitActivityLog[] {
    let tempList = Object.assign(this._permitActivityLog);
    this._permitActivityLog = [];
    if (tempList.length > 0) {
      this._permitActivityLog.push(tempList[0]);
    }
    tempList.forEach(list => {
      if (!this._permitActivityLog.find(x => x.ResponseTagNumber == list.ResponseTagNumber && x.PermitStateId == list.PermitStateId)) {
        this._permitActivityLog.push(list);
      }
    })
    return this._permitActivityLog;
  }

  setFixSectionQuestions() {
    let objQ1 = new PermitTypeQuestion();
    let objQ2 = new PermitTypeQuestion();
    let objQ3 = new PermitTypeQuestion();
    let objQ4 = new PermitTypeQuestion();
    for (let prop in this._PermitVersion.Divisions[0].Sections[0].Questions[0]) {
      if (this._PermitVersion.Divisions[0].Sections[0].Questions[0].hasOwnProperty(prop)) {
        objQ1[prop] = this._PermitVersion.Divisions[0].Sections[0].Questions[0][prop];
        objQ2[prop] = this._PermitVersion.Divisions[0].Sections[0].Questions[0][prop];
        objQ3[prop] = this._PermitVersion.Divisions[0].Sections[0].Questions[0][prop];
        objQ4[prop] = this._PermitVersion.Divisions[0].Sections[0].Questions[0][prop];
      }
    }
    objQ1.QuestionText = "Permit No:";
    objQ1.AnswerTypeId = 3;
    objQ2.QuestionText = "Permit Issued Date/Time:";
    objQ2.AnswerTypeId = 3;
    objQ3.QuestionText = "Permit Expiry Date/Time:";
    objQ3.AnswerTypeId = 3;
    objQ4.QuestionText = "Revalidation Period:";
    objQ4.AnswerTypeId = 3;
    this._PermitVersion.Divisions[0].Sections[0].Questions.unshift(objQ4);
    this._PermitVersion.Divisions[0].Sections[0].Questions.unshift(objQ3);
    this._PermitVersion.Divisions[0].Sections[0].Questions.unshift(objQ2);
    this._PermitVersion.Divisions[0].Sections[0].Questions.unshift(objQ1);
  }

  setDefaultValues() {
    if (this._PermitVersion) {
      for (let division of this._PermitVersion.Divisions) {
        for (let section of division.Sections) {
          for (let question of section.Questions) {
            if (question.AnswerTypeId == 12) {
              // debugger;
              this.hasEntryLeaderQuestion = true;
              this.entryLeaderName = question.AnswerText;
              if (this.isEntryLeader && !this.isPermitActive) {
                question.AnswerText = this.permitCreatedByUser.FullName;
                // if (this._PermitVersion) {
                //   for (let division of this._PermitVersion.Divisions) {
                //     for (let section of division.Sections) {
                //       for (let question of section.Questions) {
                //         if (question.AnswerTypeId == 12 && this.isEntryLeader) {

                //           question.AnswerText = this.permitCreatedByUser.FullName;
                //         }

                //       }
                //     }
                //   }
                // }
              }
            }
            if (question.AnswerTypeId == 1 && question.AnswerOptions[0].DynamicDataSource == true) {
              question.AnswerText = "0";
            }
            // To be updated to show select in dds
            // else if(question.AnswerTypeId == 9){
            //   question.TableFieldType.forEach(field=>{
            //     if(field.AnswerTypeId == 1 && field.AnswerOptions[0].DynamicDataSource == true){
            //      question.TableData[(field.RowCount!=null)? field.RowCount : 0][field.ColumnName].ResponseText = "0";
            //     }
            //   })
            // }
            question.SelectedAnswerOptionID = 0;
          }
        }
      }
    }
  }

  selectStartDate(divisionIndex, sectionIndex, questionIndex, pIndex, date, colName) {

    let today: Date;
    today = new Date(date);
    let approvedDate = this.GetFormattedDateTime(today);

    if (this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerTypeId == 9) {
      let columnName: string = colName;
      // let columnName: string = '';
      // this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].TableFieldType.forEach(x => {
      //   if (x.AnswerTypeId == 7) {
      //     columnName = x.ColumnName
      //   }
      // })
      if (columnName != '') {

        this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].TableData[pIndex][columnName].ResponseText = approvedDate;
        // return true;
      }
    }
    else {
      //selectedDate = (today.getDate() < 10 ? "0" + today.getDate() : today.getDate()) + '/' + (today.getMonth() < 9 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1) + '/' + today.getFullYear();

      this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerText = approvedDate;
    }

  }

  selectStartDateTime(divisionIndex, sectionIndex, questionIndex, pIndex, date, colName) {

    let today: Date;
    today = new Date(date);
    // let selectedDate: string;
    //selectedDate = (today.getDate() < 10 ? "0" + today.getDate() : today.getDate()) + '/' + (today.getMonth() < 9 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1) + '/' + today.getFullYear();
    let approvedDate = this.formatAMPM(today);

    if (this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerTypeId == 9) {
      let columnName: string = colName;
      // let columnName: string = '';
      // this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].TableFieldType.forEach(x => {
      //   if (x.AnswerTypeId == 7) {
      //     columnName = x.ColumnName
      //   }
      // })
      if (columnName != '') {

        this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].TableData[pIndex][columnName].ResponseText = approvedDate;
        // return true;
      }
    }
    else {
      //selectedDate = (today.getDate() < 10 ? "0" + today.getDate() : today.getDate()) + '/' + (today.getMonth() < 9 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1) + '/' + today.getFullYear();

      this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerText = approvedDate;
    }

  }

  // To show who had approved the section along with date stamp
  getSectionApprovalMessage(divisionObj, sectionObj) {
    if (this._permitObj.PermitId > 0) {
      let logDivisionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == divisionObj.PermitTypeDivisionId && x.PermitStateId == Status.DIVISIONAPPROVED);
      if (logDivisionObj) {
        let approver = logDivisionObj.PerformedBy;
        let approvedDate = this.GetFormattedDateTime(logDivisionObj.PerformedDate);
        return ' (This division has already been approved by ' + approver + ' on ' + approvedDate + ')';
      }
      let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sectionObj.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
      if (logSectionObj) {
        let approver = logSectionObj.PerformedBy;
        let approvedDate = this.GetFormattedDateTime(logSectionObj.PerformedDate);
        return ' (This section has already been approved by ' + approver + ' on ' + approvedDate + ')';
      }
      return '';
    }
  }

  GetFormattedDateTime(obj): string {
    let dateObj: Date = new Date(obj);

    let formattedDate: string = '';
    let MMM: string;

    let dd = dateObj.getDate();
    let M = dateObj.getMonth();
    let yyyy = dateObj.getFullYear();


    switch (M) {
      case 0: MMM = 'Jan';
        break;
      case 1: MMM = 'Feb';
        break;
      case 2: MMM = 'Mar';
        break;
      case 3: MMM = 'Apr';
        break;
      case 4: MMM = 'May';
        break;
      case 5: MMM = 'Jun';
        break;
      case 6: MMM = 'Jul';
        break;
      case 7: MMM = 'Aug';
        break;
      case 8: MMM = 'Sep';
        break;
      case 9: MMM = 'Oct';
        break;
      case 10: MMM = 'Nov';
        break;
      case 11: MMM = 'Dec';
        break;
    }
    // formattedDate = dd + '-' + MMM + '-' + yyyy + ' ' + hh + ':' + mm + ':' + ss;
    formattedDate = dd + '-' + MMM + '-' + yyyy;
    return formattedDate;
  }

  formatAMPM(obj): string {
    if (obj == null || obj == undefined) {
      return null;
    }

    let date: Date = new Date(obj);

    let MMM: string;
    var d = date.getDate();

    var m = date.getMonth();
    var y = date.getFullYear();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    switch (m) {
      case 0: MMM = 'Jan';
        break;
      case 1: MMM = 'Feb';
        break;
      case 2: MMM = 'Mar';
        break;
      case 3: MMM = 'Apr';
        break;
      case 4: MMM = 'May';
        break;
      case 5: MMM = 'Jun';
        break;
      case 6: MMM = 'Jul';
        break;
      case 7: MMM = 'Aug';
        break;
      case 8: MMM = 'Sep';
        break;
      case 9: MMM = 'Oct';
        break;
      case 10: MMM = 'Nov';
        break;
      case 11: MMM = 'Dec';
        break;
    }
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    //minutes = minutes < 10 ? '0'+minutes : minutes;
    var strDateTime = d + '-' + MMM + '-' + y + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    if (hours == 12 && minutes == 0 && seconds == 0) {
      strDateTime = d + '-' + MMM + '-' + y;
    }
    return strDateTime;
  }

  // To make entry in the PermitActivityLog table
  // Checks if Logged-In user is Editor and 
  // the if the question is present in the active-question list for the particular section/division
  BindEditorState(value: number) {
    this._PermitVersion.Divisions.forEach(div => {
      if (this._currentLoggedInUserRoles.find(x => x.RoleId == div.Editor)) {
        div.Sections.forEach(sec => {
          sec.Questions.forEach(ques => {
            if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
              let obj: PermitActivityLog = {
                PermitActivityLogId: 0,
                PermitId: this._permitObj.PermitId,
                ResponseTagNumber: div.PermitTypeDivisionId,
                PermitStateId: (value == 0) ? Status.DIVISIONSAVED : Status.DIVISIONEDITED,
                PerformedBy: this._currentLoggedInUser.LoginId,
                PerformedByName: null,
                PerformedDate: new Date(),
                Permit: null,
                PermitState: null,
                PermitRevisionId: this._permitObj.PermitRevisionId
              }
              this._permitActivityLog.push(obj);
            }
          })
        })
      }
      else {
        div.Sections.forEach(sec => {
          if (sec.Editor != null) {
            if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Editor)) {
              sec.Questions.forEach(ques => {
                if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                  let obj: PermitActivityLog = {
                    PermitActivityLogId: 0,
                    PermitId: this._permitObj.PermitId,
                    ResponseTagNumber: sec.PermitTypeSectionId,
                    PermitStateId: (value == 0) ? Status.SECTIONSAVED : Status.SECTIONEDITED,
                    PerformedBy: this._currentLoggedInUser.LoginId,
                    PerformedByName: null,
                    PerformedDate: new Date(),
                    Permit: null,
                    PermitState: null,
                    PermitRevisionId: this._permitObj.PermitRevisionId
                  }
                  this._permitActivityLog.push(obj);
                }
              })
            }
          }
          else {
            sec.Questions.forEach(ques => {
              if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                let obj: PermitActivityLog = {
                  PermitActivityLogId: 0,
                  PermitId: this._permitObj.PermitId,
                  ResponseTagNumber: sec.PermitTypeSectionId,
                  PermitStateId: (value == 0) ? Status.SECTIONSAVED : Status.SECTIONEDITED,
                  PerformedBy: this._currentLoggedInUser.LoginId,
                  PerformedByName: null,
                  PerformedDate: new Date(),
                  Permit: null,
                  PermitState: null,
                  PermitRevisionId: this._permitObj.PermitRevisionId
                }
                this._permitActivityLog.push(obj);
              }
            })
          }

        })
      }
    })
  }

  // To make entry in the PermitActivityLog table
  // Checks if Logged-In user is Editor and 
  // the if the question is present in the active-question list for the particular section/division
  BindApproverState(value: number) {
    // SECOND & THIRD DIVISIONS are considered since, the permit state is ACTIVE
    if (this._permitObj != null && this._permitObj != undefined && this._permitObj.PermitStateId == Status.ACTIVE) {
      // for (let i = 1; i < this._PermitVersion.Divisions.length; i++) {

      let div = this._PermitVersion.Divisions[1];

      div.Sections.forEach(sec => {
        let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
        if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
          if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
            let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
            if (logSectionObj == undefined) {
              let obj: PermitActivityLog = {
                PermitActivityLogId: 0,
                PermitId: this._permitObj.PermitId,
                ResponseTagNumber: sec.PermitTypeSectionId,
                PermitStateId: (value == 0) ? Status.SECTIONAPPROVED : Status.SECTIONREJECTED,
                PerformedBy: this._currentLoggedInUser.LoginId,
                PerformedByName: null,
                PerformedDate: new Date(),
                Permit: null,
                PermitState: null,
                PermitRevisionId: this._permitObj.PermitRevisionId
              }
              this._permitActivityLog.push(obj);
            }
          }
        }
      })
      // }
    }

    // only FIRST DIVISION is considered since, the permit state is ACTIVE
    else {
      if (!this.IsFirstDivisionApproved()) {
        let div = this._PermitVersion.Divisions[0];
        div.Sections.forEach(sec => {
          let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
          if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
            if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
              let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
              if (logSectionObj == undefined) {
                let obj: PermitActivityLog = {
                  PermitActivityLogId: 0,
                  PermitId: this._permitObj.PermitId,
                  ResponseTagNumber: sec.PermitTypeSectionId,
                  PermitStateId: (value == 0) ? Status.SECTIONAPPROVED : Status.SECTIONREJECTED,
                  PerformedBy: this._currentLoggedInUser.LoginId,
                  PerformedByName: null,
                  PerformedDate: new Date(),
                  Permit: null,
                  PermitState: null,
                  PermitRevisionId: this._permitObj.PermitRevisionId
                }
                this._permitActivityLog.push(obj);
              }
            }
          }
        });
      }
      else {
        let permitLevelApprovalList = this._permitTypeLevelApprovalList.filter(x => x.ApprovalSequence == null || x.ApprovalSequence == this._activePermitLevelSequence);
        permitLevelApprovalList.forEach(item => {
          if (this._currentLoggedInUserRoles.find(x => x.RoleId == item.Approver)) {
            let logPermitLevelObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == item.PermitTypeLevelApprovalId && x.PermitStateId == Status.PERMITLEVELAPPROVED);
            if (logPermitLevelObj == undefined) {
              let obj: PermitActivityLog = {
                PermitActivityLogId: 0,
                PermitId: this._permitObj.PermitId,
                ResponseTagNumber: item.PermitTypeLevelApprovalId,
                PermitStateId: (value == 0) ? Status.PERMITLEVELAPPROVED : Status.PERMITLEVELREJECTED,
                PerformedBy: this._currentLoggedInUser.LoginId,
                PerformedByName: null,
                PerformedDate: new Date(),
                Permit: null,
                PermitState: null,
                PermitRevisionId: this._permitObj.PermitRevisionId
              }
              this._permitActivityLog.push(obj);
            }
          }
        })
      }
    }
  }

  onUploadResponseImage(event, questionId) {
    let fileFormatNotAllowed: boolean = false;


    if (event.files.length == 0) {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.NoFileFoundToUpload') });
      return;
    }
    else {
      let allowedFileTypeList: string[] = []
      allowedFileTypeList = this._allowedFileTypes.split(',');
      event.files.forEach(file => {
        if (!allowedFileTypeList.includes(file.type)) {
          fileFormatNotAllowed = true
        }
      });
    }
    if (fileFormatNotAllowed) {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.FileFormatNotSupported') });
      this.responseAttachments = [];
      return;
    }
    else {
      event.files.forEach(file => {
        this._mediaService.AttachMediaFiles(file).subscribe((res: HttpResponse<any>) => {
          if (res.status == 200) {
            this.onOK();
            this._messageService.add({ key: 'success', sticky: true, severity: 'info', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.MediaMessageUploadSuccessful') });
            this.responseAttachedFiles = [];
            this.SetAttachedFilesArray(res.body, questionId, true);
          }
          this.responseAttachments = [];
        },
          (err) => {
            this.responseAttachedFiles = [];
            this.responseAttachments = [];
            this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.MediaMessageUploadError') });
          }
        );
      });
    }

  }

  // getBase64Image(img) {
  //   var canvas = document.createElement("canvas");
  //   canvas.width = img.width;
  //   canvas.height = img.height;
  //   var ctx = canvas.getContext("2d");
  //   ctx.drawImage(img, 0, 0);
  //   var dataURL = canvas.toDataURL("image/png");

  //   return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  // }
  //var base64 = getBase64Image(document.getElementById("imageid"));

  //Set Attached Image
  SetAttachedFilesArray(azurefileid: number, questionId: number, isActive: boolean) {
    //this.responseImageFiles = [];
    // this.finalAttachmentList = []
    this._mediaService.getFileProperties(azurefileid).subscribe(
      (res: HttpResponse<any>) => {
        if (res.body.uri != null && res.body.uri != '') {
          this._commonService.getMediaBlob(this.EscapeSpecialCharacters(res.body.uri)).subscribe(
            (data: any) => {
              let base64Image = "data:image/png;base64," + data.toString();
              let thumbnailImage = this.sanitizer.bypassSecurityTrustUrl(base64Image);
              this.responseAttachedFiles = [];
              this.responseAttachmentFiles = [];
              this.responseAttachedFiles.push({ source: res.body.uri, thumbnail: thumbnailImage });
              this.responseAttachmentFiles.push({
                AzureFileId: azurefileid,
                ImageArray: this.responseAttachedFiles,
                questionId: questionId,
                Active: isActive,
                ContentType: res.body.contentType,
                BinaryFile: base64Image
              });
            });
        }
        // this.finalAttachmentList = this.responseAttachmentFiles;
      }
    );
  }

  //Delete Attached Image
  DeleteAttachedFile(azureFileId) {
    this._confirmationService.confirm({
      message: this.translate.instant('Permit.FileDeleteMsg'),
      header: this.translate.instant('Permit.FileDeleteHdr'),
      key: 'deleteResponseImage',
      icon: 'pi pi-info-circle',
      accept: () => {
        // if (this._ObjArrResponseAttachment.ResponseAttachmentId > 0) {
        //   this._ObjArrResponseAttachment.AzureFileId = 0;
        // }
        // else {
        //   this._ObjArrResponseAttachment = new ResponseAttachment();
        // }
        // this.finalAttachmentList = []
        // this.finalAttachmentList = this.responseImageFiles.filter(x=>x.AzureFileId != azureFileId)
        // this.responseImageFiles = []
        // this.responseImageFiles = this.finalAttachmentList
        // this.responseAttachedImages = [];
        // this.responseImages = [];
        // this.finalAttachmentList.find(x=>x.azureFileId == azureFileId).Active = false;
        this.responseAttachmentFiles.find(x => x.AzureFileId == azureFileId).Active = false;
      },
      reject: () => {
      }
    });
  }

  checkRowForDeletion(rowIndex, tableFieldType, tableData, deletedIndexes): string {
    let index = deletedIndexes.find(x => x == rowIndex);
    //Row Not Deleted
    if (index == undefined || index == null || parseInt(index) < 0) {
      return "ACTIVE";
    }
    else {
      //IF ROw DELETED
      if (this.hasData(rowIndex, tableFieldType, tableData)) {
        if (this._permitObj.PermitId == 0) {
          return "SKIP";
        }
        else {
          return "INACTIVE";

        }

      }
      else {
        if (this._permitObj.PermitId == 0) {
          return "SKIP";
        }
        else {
          return "INACTIVE";

        }
      }

    }
  }

  hasData(rowIndex, tableFieldType, tableData): boolean {

    for (let header of tableFieldType) {
      let obj = (tableData[rowIndex][header.ColumnName] as TableTypeResponse);

      if (obj.SelectedAnswerOptionId != undefined && obj.SelectedAnswerOptionId != null && obj.SelectedAnswerOptionId != 0) {
        return true;
      }
      // else if (obj.ResponseText != undefined && obj.ResponseText != null && obj.ResponseText.trim() != "") {
      else if (obj.ResponseText != undefined && obj.ResponseText != null && obj.ResponseText != '') {
        return true;
      }
      else if (obj.SelectedAnswerCheckBoxForTable != undefined && obj.SelectedAnswerCheckBoxForTable != null && obj.SelectedAnswerCheckBoxForTable.length > 0) {
        return true;
      }
    }

    return false;
  }

  resetRadioButton(question: PermitTypeQuestion) {
    if (question) {
      question.SelectedAnswerOptionID = 0;
    }
  }

  resetRadioButtonForTable(question: PermitTypeQuestion, rowIndex: number) {
    if (question) {
      if (question.AnswerTypeId == 9) {
        question.TableFieldType.forEach((fields: PermitTypeTableFieldType) => {
          if (fields.AnswerTypeId == 4) {
            question.TableData[rowIndex][fields.ColumnName].SelectedAnswerOptionId = 0;
          }
        })
      }
      question.SelectedAnswerOptionID = 0;
    }

  }

  IsRowDeleted(rowIndex, deletedIndexes) {
    let index = deletedIndexes.find(x => x == rowIndex);
    //Row Not Deleted
    if (index == undefined || index == null || parseInt(index) < 0) {
      return false;
    }
    return true;
  }

  GetRowCount(table): number {
    let rowCount = 0;
    for (let row of table.rows) {
      if (!(row as HTMLTableRowElement).hidden) {
        rowCount++;
      }
    }
    return rowCount;
  }

  showStatusPopUp() {
    this.displayStatusPopUp = true;
  }

  StatusPopupClose() {
    this.displayStatusPopUp = false;
  }

  GetPermitLevelStatus() {

    let divisionStatusObj = {
      DivisionName: 'Permit Level Approval',
      DivisionMessage: null,
      IsApproved: 0,
      Approver: null,
      SectionList: null
    }

    let sectionStatusList: any[] = [];

    let count = 0;
    for (let i = 0; i < this._permitTypeLevelApprovalList.length; i++) {

      let permitTypeLevelApprovalObj = this._permitTypeLevelApprovalList[i];
      let logObj = this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == permitTypeLevelApprovalObj.PermitTypeLevelApprovalId && x.PermitStateId == Status.PERMITLEVELAPPROVED);

      if (logObj == undefined) {
        // if (permitTypeLevelApprovalObj.ApprovalSequence == null || permitTypeLevelApprovalObj.ApprovalSequence == this._activePermitLevelSequence) {
        let msg = '(Pending with ' + permitTypeLevelApprovalObj.RoleName + ')';
        let approver = null;
        let sectionStatusObj: any = {
          SectionName: 'Approver ' + (++count),
          SectionMessage: msg,
          IsApproved: 2,
          Approver: approver
        }
        sectionStatusList.push(sectionStatusObj);
        // }
      }
      else {
        let msg = '(Approved by <a><b>' + logObj.PerformedByName + '</a></b> on ' + this.tolocalTimeString(logObj.PerformedDate) + ')';
        let approver = logObj.PerformedBy;
        let sectionStatusObj: any = {
          SectionName: 'Approver ' + (++count),
          SectionMessage: msg,
          IsApproved: 1,
          Approver: approver
        }
        sectionStatusList.push(sectionStatusObj);
      }
    }
    if (sectionStatusList.length == 0) {
      return null;
    }
    else {
      divisionStatusObj.SectionList = sectionStatusList;
      return divisionStatusObj;
    }

  }

  GetDataForPermitStatus() {
    this.permitApprovalStatus = null;
    if (this._permitObj.PermitId > 0) {
      let divisionStatusList: any[] = [];
      let divisionStatusObj: any;

      this._PermitVersion.Divisions.forEach(div => {
        if (this._permitObj.PermitStateId == 2) {
          if (div.DivisionName.toLocaleLowerCase().startsWith('permit creation')) {
            divisionStatusObj = this.GetApprovalStatusData(div);
          }
        }
        else if (this._permitObj.PermitStateId == 3) {
          divisionStatusObj = this.GetApprovalStatusData(div);
        }

        if (divisionStatusObj != null) {
          divisionStatusList.push(divisionStatusObj);
          divisionStatusObj = null;
        }

        if (div.DivisionName.toLocaleLowerCase().startsWith('permit creation')) {
          divisionStatusObj = this.GetPermitLevelStatus();
          if (divisionStatusObj != null && divisionStatusList.length != 0) {
            divisionStatusList.push(divisionStatusObj);
          }
          divisionStatusObj = null;
        }

      })

      this.permitApprovalStatus = {
        PermitNumber: this._permitObj.PermitNumber,
        PermitState: this._permitObj.PermitState.PermitStateName,
        DivisionStatusList: divisionStatusList
      }
    }
  }

  GetApprovalStatusData(div: PermitTypeDivision) {
    let sectionStatusList: any[] = [];
    let divisionStatusObj: any;
    divisionStatusObj = {
      DivisionName: div.DivisionName,
      DivisionMessage: null,
      IsApproved: 0,
      Approver: null,
      SectionList: null
    }
    div.Sections.forEach(sec => {
      if (sec.Approver) {
        let msg: string;
        msg = this.GetSectionWiseApprovalMessage(sec, divisionStatusObj.IsApproved);
        let approver: string = this.GetSectionApprovedByUser(sec, divisionStatusObj.IsApproved);
        let sectionStatusObj: any = {
          SectionName: sec.SectionName,
          SectionMessage: msg,
          IsApproved: (msg.startsWith('(Pending') == true) ? 2 : ((msg.startsWith('(Approved') == true) ? 1 : 0),
          Approver: approver
        }
        sectionStatusList.push(sectionStatusObj);
      }
    })
    divisionStatusObj.SectionList = sectionStatusList;

    return divisionStatusObj;
  }

  GetDivisionApprovedByUser(div: PermitTypeDivision): string {
    if (div.Approver != null) {
      let logDivisionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == div.PermitTypeDivisionId && x.PermitStateId == Status.DIVISIONAPPROVED);
      if (logDivisionObj) {
        return logDivisionObj.PerformedBy;
      }
    }
    return null;
  }

  GetSectionApprovedByUser(sec: PermitTypeSection, isDivvisionApproved): string {
    if (isDivvisionApproved == 1) {
      return null;
    }
    else if (sec.Approver != null) {
      let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
      if (logSectionObj) {
        return logSectionObj.PerformedBy;
      }
    }
    return null;
  }

  GetDivisionWiseApprovalMessage(div: PermitTypeDivision): string {
    let msg: string;

    if (div.Approver == null) {
      msg = 'Approval not required'
    }

    else if (div.Approver != null) {

      let count = 0;
      div.Sections.forEach(sec => {
        if (sec.Approver != null) {
          let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
          if (logSectionObj == undefined) {
            count++;
          }
        }
      });

      if (count == 0) {
        msg = 'Approval not required';
        return '(' + msg + ')';
      }

      let logDivisionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == div.PermitTypeDivisionId && x.PermitStateId == Status.DIVISIONAPPROVED);
      if (logDivisionObj) {
        msg = 'Approved by <a><b>' + logDivisionObj.PerformedByName + '</b></a> on ' + this.tolocalTimeString(logDivisionObj.PerformedDate)
      }
      else {
        msg = 'Pending with ' + this.getRoleName(div.Approver);
      }
    }
    return '(' + msg + ')';
  }

  GetSectionWiseApprovalMessage(sec: PermitTypeSection, isDivvisionApproved: number): string {
    let msg: string;

    if (isDivvisionApproved == 1 || sec.Approver == null) {
      msg = 'Approval not required'
    }

    else if (sec.Approver != null) {
      let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
      if (logSectionObj) {
        msg = 'Approved by <a><b>' + logSectionObj.PerformedByName + '</b></a> on ' + this.tolocalTimeString(logSectionObj.PerformedDate)
      }
      else {
        msg = 'Pending with ' + this.getRoleName(sec.Approver);
      }
    }
    return '(' + msg + ')';
  }

  GetApproverDetails(loginId) {

    let _approverUserDetailList = localStorage.getItem("_approverUserDetailList") != null ? JSON.parse(localStorage.getItem("_approverUserDetailList")) : [];

    if (_approverUserDetailList) {
      let userObj = _approverUserDetailList.find(x => x.LoginId == loginId);
      if (userObj) {
        this._approverUserDetail = userObj;
        this._showApproverDetail = true;
      }
      else {
        this._userService.getUserByLoginId(loginId).subscribe((data: any) => {
          if (data) {
            let azurefileid = data.Attachment == null ? null : data.Attachment.AzureFileId;
            if (azurefileid != null && azurefileid != 0) {
              this._mediaService.getFileProperties(azurefileid).subscribe(
                (res: HttpResponse<any>) => {
                  if (res.body.uri != null && res.body.uri != '') {
                    this._approverUserDetail = {
                      LoginId: data.LoginId,
                      FullName: data.LastName.toUpperCase() + ', ' + data.FirstName.toUpperCase(),
                      MobileNumber: data.MobileNumber,
                      EmailAddress: data.EmailAddress,
                      SiteName: data.Site != null ? data.Site.SiteName : null,
                      ApproverUserProfilePicture: null
                    }
                    if (res.body.contentType.indexOf('image/') != -1) {
                      this._approverUserDetail.ApproverUserProfilePicture = res.body.thumbUri;
                    }
                    this._showApproverDetail = true;
                    _approverUserDetailList.push(this._approverUserDetail);
                    localStorage.setItem("_approverUserDetailList", JSON.stringify(_approverUserDetailList));
                  }
                }
              );
            }
            else {
              this._approverUserDetail = {
                LoginId: data.LoginId,
                FullName: data.LastName.toUpperCase() + ', ' + data.FirstName.toUpperCase(),
                MobileNumber: data.MobileNumber,
                EmailAddress: data.EmailAddress,
                SiteName: data.Site != null ? data.Site.SiteName : null,
                ApproverUserProfilePicture: null
              }
              this._showApproverDetail = true;
              _approverUserDetailList.push(this._approverUserDetail);
              localStorage.setItem("_approverUserDetailList", JSON.stringify(_approverUserDetailList));
            }
          }
        })
      }
    }
  }

  backToStatusView() {
    this._showApproverDetail = false;
    this._approverUserDetail = null;
  }

  getRoleName(roleId) {
    let roleObj = this.allRoles.find(x => x.RoleId == roleId);
    return (roleObj != undefined && roleObj.RoleName != null) ? roleObj.RoleName : roleId;
  }

  OnStatusPanelHide() {
    this.enableStatusTab();
    this.backToStatusView();
  }

  OLD_showCloseButton() {

    if (this._permitObj && this._permitObj.PermitStateId == Status.ACTIVE && this._currentLoggedInUserRoles) {
      let secList: PermitTypeSection[] = this._PermitVersion.Divisions[2].Sections;

      if (secList.length == 0) {
        return false;
      }

      else {
        let count = 0;
        this._PermitVersion.Divisions[1].Sections.forEach(sec => {
          sec.Questions.forEach(ques => {
            if (sec.Approver != null) {
              let response = this._permitObj.Responses.find(res => res.PermitTypeQuestionId == ques.PermitTypeQuestionId);
              if (response != undefined && response.Approved != true) {
                count++;
              }
            }
          })
        })

        if (count == 0) {

          let approverCount = 0;
          secList.forEach(sec => {
            if (sec.Approver != null) {
              approverCount++;
            }
          });
          if (approverCount == 0) {
            if (this._currentLoggedInUser.LoginId == this._permitObj.CreatedBy) {
              return true;
            }
          }
          else {
            let count = 0;
            secList.forEach(sec => {
              let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
              if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
                if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
                  let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
                  if (logSectionObj == undefined) {
                    count++;
                  }
                }
              }
            });
            if (count > 0) {
              return true;
            }
          }
        }
        return false;
      }
    }


    // if (this._permitObj && this._permitObj.PermitStateId == Status.ACTIVE && this._currentLoggedInUserRoles && this._PermitVersion.Divisions[2].Approver != null) {
    //   let count = 0;
    //   let div = this._PermitVersion.Divisions[1];
    //   div.Sections.forEach(sec => {
    //     sec.Questions.forEach(ques => {
    //       if (div.Approver != null || sec.Approver != null) {
    //         let response = this._permitObj.Responses.find(res => res.PermitTypeQuestionId == ques.PermitTypeQuestionId);
    //         if (response != undefined && response.Approved != true) {
    //           count++;
    //         }
    //       }
    //     })
    //   })
    //   if (count == 0) {
    //     if (this._currentLoggedInUserRoles.find(x => x.RoleId == this._PermitVersion.Divisions[2].Approver)) {
    //       return true;
    //     }
    //   }
    // }
    // return false;
  }

  OLD_ClosePermit() {
    this._confirmationService.confirm({
      message: this.translate.instant('Permit.ConfirmCloseMsg'),
      header: this.translate.instant('Permit.ConfirmCloseHeader'),
      key: 'confirmClose',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._displayCommentPopUp = false;
        this.BindCloseState();
        this._permitObj.PermitActivityLogs = this.RemoveDuplicateLogs();
        this.BindCloseResponse();
        this._permitObj.PermitStateId = this.IsLastDivisionApproved() == true ? Status.CLOSED : this._permitObj.PermitStateId;
        this._permitObj.PermitState = null;
        this.SaveValues();
      },
      reject: () => {
      }
    });
  }

  BindCloseState() {
    let div = this._PermitVersion.Divisions[2];
    div.Sections.forEach(sec => {
      let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
      if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
        if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
          let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
          if (logSectionObj == undefined) {
            let obj: PermitActivityLog = {
              PermitActivityLogId: 0,
              PermitId: this._permitObj.PermitId,
              ResponseTagNumber: sec.PermitTypeSectionId,
              PermitStateId: Status.SECTIONAPPROVED,
              PerformedBy: this._currentLoggedInUser.LoginId,
              PerformedByName: null,
              PerformedDate: new Date(),
              Permit: null,
              PermitState: null,
              PermitRevisionId: this._permitObj.PermitRevisionId
            }
            this._permitActivityLog.push(obj);
          }
        }
      }
    })
  }

  BindCloseResponse() {
    let div = this._PermitVersion.Divisions[2];
    this._permitObj.Responses.forEach(res => {
      div.Sections.forEach(sec => {
        let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
        if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
          if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
            if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId)) {
              let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
              if (logSectionObj != undefined) {
                res.Approved = true;
              }
            }
          }
        }
      })
    })
  }

  IsLastDivisionApproved() {
    let count = 0;
    let div = this._PermitVersion.Divisions[2];
    div.Sections.forEach(sec => {
      sec.Questions.forEach(ques => {
        if (sec.Approver != null && this._permitObj.Responses.find(res => res.PermitTypeQuestionId == ques.PermitTypeQuestionId && res.Approved != true)) {
          count++;
        }
      })
    })
    if (count > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  toogleCommentBoxVisibility() {
    if (this.commentBoxIsVisible) {
      this.commentBoxIsVisible = false;
    }
    else {
      this.commentBoxIsVisible = true;
    }
  }

  hideCommentBox() {
    this.commentBoxIsVisible = false;
  }

  showCommentBox() {
    this.commentBoxIsVisible = true;
  }

  receiveCommentsCount($event) {
    this._commentsCount = $event;
  }

  createNewPermit() {
    this._copyPermit = false;
    this._resetFlag = true;
    this.getPermitVersionDetails(this._permitTypeId);

  }

  cancleADD() {
    this._copyPermit = false;
  }

  GetPreviousPermits() {
    this._displayPermitSelectPopUp = false;
    this._permitService.getPermitsByPermitType(this._permitTypeId).subscribe((permits: Permit[]) => {
      this._permits = permits;
      if (this._permits.length > 0) {
        this._copyPermit = true;
        this._permits.forEach(p => {
          this._permitNumbers.push(p.PermitNumber);
        });
        this._selectedPermitId = 0 // to be fetched once user selects the permit number from the pop up
        this.popUpHeader = this.translate.instant('Permit.ChoosePermit');
      }
      else {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.CopyError') });
      }
      this._resetFlag = false;
    });
  }

  CancelCopyPermit() {
    this._displayPermitSelectPopUp = false;
  }

  filterPermits(event) {

    this.filteredPermits = [];
    this._permits.forEach(permit => {
      if (permit.PermitNumber.toLowerCase().includes(event.query.toLowerCase())) {
        this.filteredPermits.push(permit.PermitNumber)
      }
    });
  }

  SelectPermit(permit: string) {
    this._selectedPermit = this._permits.find(x => x.PermitNumber.toLowerCase() == permit.toLowerCase());
    this.responseAttachmentFiles = []
    this.getPermit(this._selectedPermit.PermitId, true, false);
  }

  GetPermitCreatedByUserDetails(permitId: number) {
    this.permitCreatedByUser = new PermitCreatedByUserViewModel();
    this._userService.getPermitCreatedByUserDetails(permitId).subscribe((data: PermitCreatedByUserViewModel) => {
      this.permitCreatedByUser = data;
      this.permitCreatedByUserPdf = this.deepCopy(data);
      if (this.permitCreatedByUser) {
        this.userProfilePicture = this.GetMediaForUser(this.permitCreatedByUser.Attachment);
        this.GetPermitStatus(permitId);
      }
    });
  }

  GetMediaForUser(attachment: Attachment) {
    let azurefileid = attachment == null ? null : attachment.AzureFileId;
    if (azurefileid != null && azurefileid != 0) {
      this._mediaService.getFileProperties(azurefileid).subscribe(
        (res: HttpResponse<any>) => {
          if (res.body.uri != null && res.body.uri != '') {
            if (res.body.contentType.indexOf('image/') != -1) {
              this.userProfilePicture = res.body.thumbUri;
            }
          }
        }
      );
    }
  }

  GetPermitStatus(permitId: number) {
    this._permitService.getPermitStatus(permitId).subscribe((data: any) => {
      this.permitApprovalStatus = data;
    });
  }

  enableStatusTab() {
    this.showStatus = true;
  }

  enableUserTab() {
    this.showStatus = false;
    this.backToStatusView();
  }

  TakeOver(isTakeOver: boolean) {
    //debugger;
    if (this._PermitVersion) {
      for (let division of this._PermitVersion.Divisions) {
        for (let section of division.Sections) {
          for (let question of section.Questions) {
            if (question.AnswerTypeId == 12) {

              this.entryLeaderName = question.AnswerText;
            }

          }
        }
      }
    }
    if (isTakeOver) {
      this.takeOverMessage = this.translate.instant('Permit.TakeOverHeaderPart1');
      //this.takeOverHeader = this.translate.instant('Permit.TakeOverHeader');
    }
    else {
      this.takeOverMessage = this.translate.instant('Permit.ReallocateHeaderPart1') +
        this.entryLeaderName.split(', ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(', ') + '?';
      //this.takeOverHeader = this.translate.instant('Permit.ReallocateHeader');
    }
    this._confirmationService.confirm({
      message: this.takeOverMessage,
      //+ this.translate.instant('Permit.TakeOverPart3'),
      header: this.translate.instant('confirmationHeader'),
      key: 'deleteResponseImage',
      icon: 'pi pi-info-circle',
      accept: () => {
        //console.log(this._PermitVersion);
        if (this._PermitVersion) {
          for (let division of this._PermitVersion.Divisions) {
            for (let section of division.Sections) {
              for (let question of section.Questions) {
                if (question.AnswerTypeId == 12) {
                  if (isTakeOver) {
                    question.AnswerText = localStorage.getItem("_userName").toUpperCase();
                  }
                  else {
                    question.AnswerText = this.entryLeaderName.toUpperCase();
                  }
                }

              }
            }
          }
        }

        let cmnt = new Comment();
        cmnt.CommentId = 0;
        cmnt.PermitId = this._permitObj.PermitId;
        if (isTakeOver) {
          cmnt.CommentText = "Take Over completed";
        }
        else {
          cmnt.CommentText = "Reallocation completed";
        }
        cmnt.Permit = null;
        cmnt.Active = true;
        cmnt.CreatedBy = localStorage.getItem("_loginId");
        cmnt.ModifiedBy = '';
        cmnt.CreatedDate = new Date();
        cmnt.ModifiedDate = null;

        this._permitService.addPermitComment(cmnt).subscribe(comment => {
          if (comment.status == 200) {
            this.updateEntryLeaderQuestion();
            //TODO: Change log
            //_changeLogService.setChangeLog()
          }
        });
        //this.isBtnEntryLeaderVisible = false;
      },
      reject: () => {
        if (!isTakeOver) {
          if (this._PermitVersion) {
            for (let division of this._PermitVersion.Divisions) {
              for (let section of division.Sections) {
                for (let question of section.Questions) {
                  if (question.AnswerTypeId == 12) {

                    question.AnswerText = this.previousEntryleader;
                  }

                }
              }
            }
          }
        }
      }
    });
  }

  updateEntryLeaderQuestion() {
    if (this._permitObj.SiteId || (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null)) {
      this.AssignValues();
    }
    // console.log(this._permitObj)
    this._permitService.updateEntryLeaderQuestion(this._permitObj).subscribe((data: HttpResponse<any>) => {
      if (data.status === 200) {

        // console.log("Return Data : ",data);
        this.getPermit(data.body, false, false);
      }
      else {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.UpdatePermitError') });
      }
    });
  }

  OpenApproverComment(value) {
    let list = this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId == Status.SECTIONAPPROVE || x.PermitStateId == Status.PERMITLEVELAPPROVE);
    if (list.length > 0) {
      let roles: number[] = [];
      list.forEach(x => { roles.push(x.RoleId) });
      roles = roles.filter(x => x != null); // remove null values
      roles = Array.from(new Set(roles)); // remove duplicate values
      this._permitControlService.getPermitTypeControl(this._permitObj?.PermitTypeVersionId).subscribe((permitControl: PermitControl) => {
        if (permitControl) {
          this.isLMSIntegrationEnabled=permitControl?.LmsIntegration;
        }
      });
      //this.isLMSIntegrationEnabled=this._permitObj.PermitTypeVersion?.PermitTypeControl?.LmsIntegration;
      if(this.isLMSIntegrationEnabled == true){
      this._commonService.GetTrainingStatusForUser(this._permitObj.PermitId, roles).subscribe((data: RoleTrainingStateViewModel[]) => {
        if (data.length > 0) {
          this._trainingNotPresentForRole = data.filter(x => x.Status == false);
          let objList = data.filter(x => x.Status == true);
          if (objList.length == 0) {
            this._displayLMSErrorForUser = true;
            // this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorTrainingNotPresentForUser') });
          }
          else {
            // this._trainingNotPresentForRole = data.filter(x => x.Status == false);
            // 1 - Approve
            // 2 - Reject
            // 3 - Close 
            this._commentFlag = value;
            this._approverComments = null;
            this._displayCommentPopUp = true;
          }
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorTrainingStatus') });
        }
      },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorLMSServer') });
        });
      }
      else {
        // this._trainingNotPresentForRole = data.filter(x => x.Status == false);
        // 1 - Approve
        // 2 - Reject
        // 3 - Close 
        this._commentFlag = value;
        this._approverComments = null;
        this._displayCommentPopUp = true;
      }
    }
    else if (this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId == Status.PERMITCLOSE).length > 0) {
      this._commentFlag = value;
      this._approverComments = null;
      this._displayCommentPopUp = true;
    }
  }

  AddComment() {
    if (!(this._approverComments == null || this._approverComments.trim() == '')) {
      if (this._commentFlag == 1) {
        this.ApprovePermit();
      }
      else if (this._commentFlag == 2) {
        this.RejectPermit();
      }
      else if (this._commentFlag == 3) {
        this.ClosePermit();
      }
    }
    else {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.CommentRequired') });
    }
  }

  CancelComment() {
    this._displayCommentPopUp = false;
    this._approverComments = null;
  }

  SaveApproverComments() {
    let approverCommentObj = new Comment();
    approverCommentObj.CommentId = 0;
    approverCommentObj.PermitId = this._permitObj.PermitId;
    approverCommentObj.CommentText = this._approverComments;
    approverCommentObj.Permit = null;
    approverCommentObj.Active = true;
    approverCommentObj.CreatedBy = this._currentLoggedInUser.LoginId;
    approverCommentObj.ModifiedBy = null;
    approverCommentObj.CreatedDate = new Date();
    approverCommentObj.ModifiedDate = null;

    this._permitService.addPermitComment(approverCommentObj).subscribe(comment => {
      if (comment.status == 200) {
        this._approverComments = null;
      }
      else {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SaveCommentError') });
        this._approverComments = null;
      }
    });
  }

  revalidatePermit() {

    if (this._permitControl.rolesForRevalidation.length > 0) {
      let roles: number[] = [];
      for (let i = 0; i < this._permitControl.rolesForRevalidation.length; i++) {
        if (this._currentLoggedInUserRoles.includes(this._permitControl.rolesForRevalidation[i])) {
          roles.push(this._permitControl.rolesForRevalidation[i].RoleId);
        }
      }
      roles = Array.from(new Set(roles));
      this._permitControlService.getPermitTypeControl(this._permitObj?.PermitTypeVersionId).subscribe((permitControl: PermitControl) => {
        if (permitControl) {
          this.isLMSIntegrationEnabled=permitControl?.LmsIntegration;
        }
      });
      //this.isLMSIntegrationEnabled=this._permitObj.PermitTypeVersion?.PermitTypeControl?.LmsIntegration;
      if(this.isLMSIntegrationEnabled == true){
      this._commonService.GetTrainingStatusForUser(this._permitObj.PermitId, roles).subscribe((data: RoleTrainingStateViewModel[]) => {
        if (data.length > 0) {
          let objList = data.filter(x => x.Status == true);
          this._trainingNotPresentForRole = data.filter(x => x.Status == false);
          if (objList.length == 0) {
            this._displayLMSErrorForUser = true;
            // this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorTrainingNotPresentForUser') });
          }
          else {
            this.revalidate();
          }
        }
        else {
          this.revalidate();
        }
      },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorLMSServer') });
        });
      }
      else {
        this.revalidate();
      }


    }
    else {
      this.revalidate();
    }
  }

  revalidate() {
    this._revalidateService.revalidatePermit(this._permitObj.PermitTypeId, this._permitObj.PermitId, PermitStates.REVALIDATED, false, this.isRevalidateBeforeLapse).subscribe((permitRevision: PermitRevision) => {
      if (permitRevision) {
        this.responseAttachmentFiles = []
        this.getPermit(this._permitObj.PermitId, false, false);
        this._canBeRevalidated = false;
        this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.RevalidationSuccessful') });
      }
      else {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.RevalidationError') });
      }
    });
  }

  GetMaxOccurance(permitId: number) {
    this._revalidateService.getPermitRevisionById(permitId).subscribe((permitRevision: PermitRevision) => {
      if (permitRevision) {
        this.revisionCounter = permitRevision.Revision;
      }
    });
  }










  CheckAnsOptionWidth(answerOptions: PermitTypeAnswerOption[]) {
    let ansWidthCounter = 0;
    answerOptions.forEach(ao => {
      if (ao.AnswerOptionWidth) {
        ansWidthCounter++;
      }
    });
    if (ansWidthCounter > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  OpenApproverPopup() {
    this.displayuserforrole = true;
    this.ddlUserForRole = 0;
    this._sectionId = [];
    let roleGroups: RoleGroupApproval[] = [];
    if (this._permitObj.SiteId || (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null)) {
      // this.getApproverRoles();
    }
  }

  getApproverRoles() {
    this._permitTypeVersionService.getUserForPermitRoles(this.userHierarchy.SiteId, this._PermitVersion.PermitTypeVersionId, this._permitId).subscribe((userrolegroup: RoleGroupApproval[]) => {

      this._permitObj.RoleGroupApproval = this.deepCopy(userrolegroup);
      this._permitObj.RoleGroupApproval.forEach(rga => {
        if (!rga.UserDetails) {
          rga.UserDetails = [];
        }
      })
      //ritu add code here for getting roles
      userrolegroup.forEach(urg => {
        if (urg.UserDetails) {

          urg.UserDetails = urg.UserDetails.filter(x => x.Active == true);
        }
        else {
          urg.UserDetails = [];
        }
      });
      userrolegroup.forEach(rfu => {
        rfu.ApproverInfo = this.GetApproverInfo(rfu);
      });
      this._roleForUser = userrolegroup;

      if (this._mailDistributionRequired) {
        this.AttachPdfToMail(this._permitObj.PermitId);
        this._mailDistributionRequired = false;
      }
    });
  }

  getPermitVersionDetails(permitTypeId) {
    this._permitTypeVersionService.getPermitTypeVersionWithActiveQuestions(permitTypeId, true).subscribe((permitTypeVersion: PermitTypeVersion[]) => {
      if (permitTypeVersion.length > 0) {
        this._PermitVersionList = permitTypeVersion;
        this._PermitVersion = new PermitTypeVersion();
        this._PermitVersion = permitTypeVersion.find(p => p.PublishStatus == true);
        this._PermitVersionPdf = this.deepCopy(this._PermitVersion);
        this._selectedSectionToPrint = [];
        this.setPrintableSections();
        this.setDefaultValues();
        this.getPermiTypeDetails(this._PermitVersion.PermitTypeId);
        if (this._permitId > 0) {
          this.responseAttachmentFiles = [];
          this.getPermit(this._permitId, false, false);
        }
        else {
          this.getApproverRoles();
          this.GetInitialDataForWorkflow();
          this.AddRowsToTables();
        }
        this.rows = [];
        this.rows.push({ Prop: "Prop" });
      }
      else {
        this._messageService.clear('error');
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.NoVersionAvailable') });
      }
    });
  }

  getPermit(permitId: number, permitCloneFlag: boolean, isMailRequred: boolean) {
    //debugger;
    if (permitCloneFlag) {
      this._permitService.getPermit(permitId).subscribe((permit: Permit) => {
        if (permit) {
          this._permitObj = permit;
          this._permitObj.PermitIssuedDateTime = this.adjustForTimezone(this._permitObj.PermitIssuedDateTime);
          this._permitObj.PermitExpiryDateTime = this.adjustForTimezone(this._permitObj.PermitExpiryDateTime);

          this.PopulateResponsesForPermit(permitCloneFlag);
          //this.PopulateAttachmentForPermit();
          this.nullifyPermitDetails();
          this._permitObj.PermitWorkflowViewModel = new PermitWorkflowViewModel();
          this.GetInitialDataForWorkflow();
          this.getApproverRoles();
          // this.EnableGeoTag();
        }
      });
    }
    else {
      if (permitId > 0) {
        this._permitService.getPermit(permitId).subscribe((permit: Permit) => {
          if (permit != undefined) {
            this.isMailRequired = isMailRequred;
            this._permitObj = new Permit();
            this._permitObj = permit;
            this._permitObj.PermitIssuedDateTime = this.adjustForTimezone(this._permitObj.PermitIssuedDateTime);
            this._permitObj.PermitExpiryDateTime = this.adjustForTimezone(this._permitObj.PermitExpiryDateTime);
            this._permitPdf = this.deepCopy(permit);
            this._mailDistributionRequired = isMailRequred;
            this._PermitVersion = this.fetchVersionBasedOnPermit();
            this._PermitVersionPdf = this.deepCopy(this.fetchVersionBasedOnPermit());
            this._commentsCount = this._permitObj.CommentsCount;
            this.setCoordinates(this._permitObj.PermitId);
            if (this._currentLoggedInUserRoles != undefined) {
              //debugger;
              if (this._permitObj.PermitStateId == Status.ACTIVE) {
                this.isPermitActive = true;
              }
              //this._PermitVersion = this.fetchVersionBasedOnPermit();
              this.getPermitControlsForPermitVersion();
              this.PopulateResponsesForPermit(false);
              this.PopulateAttachmentForPermit();
              this.GetPermitCreatedByUserDetails(this._permitObj.PermitId);
              this.GetMaxOccurance(permitId);
              this._permitWorkflowViewModel = this._permitObj.PermitWorkflowViewModel;
              this.EnableGeoTag();
              this.getApproverRoles();
              // if (isMailReuqired) {
              //   this.AttachPdfToMail(this._permitObj.PermitId);
              // }
            }
            // debugger;
            if (this._objArrPermitTypeIds.includes(this._permitObj.PermitTypeId)) {
              this.isConfinedSpace = true;
            }
            if (this.isEntryLeader && this.isPermitActive && this.hasEntryLeaderQuestion && this.isConfinedSpace) {
              this.isBtnEntryLeaderVisible = true;
            }
            // if(this.isEntryLeader && this.hasEntryLeaderQuestion && this.isConfinedSpace && !this.isPermitActive){
            //   if (this._PermitVersion) {
            //     for (let division of this._PermitVersion.Divisions) {
            //       for (let section of division.Sections) {
            //         for (let question of section.Questions) {
            //           if (question.AnswerTypeId == 12) {

            //             question.AnswerText = localStorage.getItem("_userName").toUpperCase();
            //           }

            //         }
            //       }
            //     }
            //   }
            // }
          }
          else {
            this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.PermitNotFoundError') });
            let redirectURL = '/permit/' + this._permitTypeId + '/0';
            this._router.navigate([redirectURL]);
          }
        });
      }
    }
  }

  EnableGeoTag() {
    if (this.geoTag) {
      if (this._permitObj.PermitStateId == Status.ACTIVE || this._permitObj.PermitStateId == Status.DRAFT || this._permitObj.PermitStateId == Status.AWAITINGEDITORRESPONSE || this._permitObj.PermitStateId == Status.PENDINGAPPROVAL) {
        this.geoTag = true;
      }
      else {
        this.geoTag = false;
      }
    }
  }

  GetInitialDataForWorkflow() {
    let sectionList: PermitTypeSection[] = this._PermitVersion.Divisions[0].Sections;
    let permitWorkFlowList: PermitWorkFlow[] = [];
    for (let sectionObj of sectionList) {
      let userRole = this._currentLoggedInUserRoles.filter(x => x.RoleId == sectionObj.Editor);
      if (sectionObj.Editor == null || userRole.length != 0) {
        let obj: PermitWorkFlow = {
          PermitWorkFlowId: 0,
          PermitId: 0,
          TagId: sectionObj.PermitTypeSectionId,
          RoleId: sectionObj.Editor,
          PermitStateId: Status.SECTIONEDIT,
          ActionTaken: null,
          DivisionNumber: 1,
          Sequence: null,
          Active: true,
          CreatedBy: null,
          CreatedDate: new Date(),
          ModifiedBy: null,
          ModifiedDate: null
        }
        permitWorkFlowList.push(obj);
      }
    }

    if (this._currentLoggedInUser && permitWorkFlowList.length > 0) {
      this._permitWorkflowViewModel = {
        PermitWorkFlows: permitWorkFlowList,
        Export: true,             // button visible & disabled
        SaveSubmit: true,         // button visible & enabled
        ApproveReject: false,     // button visible & enabled
        Close: false,
        User: false            // button not visible
      }
    }
    else if (this._permitObj.PermitId == 0 || this._permitObj.PermitId == null || this._permitObj.PermitId == undefined) {
      this._permitWorkflowViewModel = {
        PermitWorkFlows: [],
        Export: true,             // button visible & disabled
        SaveSubmit: true,         // button visible & enabled
        ApproveReject: false,     // button visible & enabled
        Close: false,
        User: false          // button not visible
      }
    }
  }

  showInputElementsForEditor(divisionObj, sectionObj, questionObj) {
    if (this._currentLoggedInUser && this._permitWorkflowViewModel) {
      let objList = this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.TagId == sectionObj.PermitTypeSectionId && x.PermitStateId == 21);
      if (objList.length > 0) {
        return false;
      }
    }
    return true;
  }

  showConfigureApproverButton() {
    if (this._permitObj.PermitStateId == null || this._permitObj.PermitStateId == undefined || this._permitObj.PermitStateId == 1) {
      if (this._roleForUser && this._roleForUser.length > 0) {
        let value = this.showSaveSubmitButton();
        if (value == true) {
          return true;
        }
      }
    }
    return false;
  }

  showExportButton() {
    if (this._currentLoggedInUser && this._permitWorkflowViewModel) {
      return this._permitWorkflowViewModel.Export;
    }
  }

  showSaveSubmitButton() {
    if (this._PermitVersion) {
      if (this._permitObj.PermitId == 0 || this._permitObj.PermitId == null || this._permitObj.PermitId == undefined) {
        return true;
      }
      if (this._currentLoggedInUser.LoginId == this._permitObj.CreatedBy && this._permitObj.PermitStateId == 1 && this._permitWorkflowViewModel && this._permitWorkflowViewModel.PermitWorkFlows.length == 0) {
        return true;
      }
      if (this._currentLoggedInUser && this._permitWorkflowViewModel) {
        return this._permitWorkflowViewModel.SaveSubmit;
      }
    }
    return false;
  }

  showApproveRejectButton() {
    if (this._currentLoggedInUser && this._permitWorkflowViewModel) {
      return this._permitWorkflowViewModel.ApproveReject;
    }
  }

  showCloseButton() {
    // if (this._currentLoggedInUser && this._permitWorkflowViewModel) {
    //   return this._permitWorkflowViewModel.Close;
    // }
    //new features added
    let retvalue = false;
    if (this._PermitVersion) {
      if (this._permitObj.PermitStateId == 1 || this._permitObj.PermitStateId == 17 || this._permitObj.PermitStateId == 2 || this._permitObj.PermitStateId == undefined
        || this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId != Status.PERMITCLOSE).length > 0 || !this._permitWorkflowViewModel.PermitWorkFlows) {
        retvalue = false;
      }
      else {
        if (this._permitControl != null) {
          if (this._permitControl.ClosedBy == true && (this._permitControl.rolesForClosedBy.filter(x => x.Active == true).length != 0)) {
            this._permitControl.rolesForClosedBy.filter(x => x.Active == true).forEach(obj => {
              if (this._currentLoggedInUserRoles.find(x => x.RoleId == obj.RoleId) && this._permitControl.ClosedBy && this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId == Status.PERMITCLOSE).length > 0) {
                retvalue = true;
              }
              else {
                //comment 1
                retvalue = false;
              }
            });
          }
          else {
            retvalue = true;
          }
        }
        else {
          retvalue = true;
        }
      }
    }
    return retvalue;
  }


  showApproverButton() {
    if (this._currentLoggedInUser && this._permitObj.PermitStateId == 1) {
      return true;
    }
    else {
      return false
    }
  }

  SaveResponse() {
    this.count = 0
    if (this._permitObj.SiteId || (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null)) {
      this.getPermiTypeDetails(this._PermitVersion.PermitTypeVersionId)
      this.AssignValues();
      if (this.geoTag) {
        this.FetchMapCoordinates(false);
      }

      if (this._permitWorkflowViewModel.PermitWorkFlows.length > 0) {
        this._permitWorkflowViewModel.PermitWorkFlows.forEach(obj => {
          if (obj.PermitStateId == Status.SECTIONEDIT) {
            obj.ActionTaken = 1;
            obj.ModifiedBy = localStorage.getItem("_loginId");
            obj.ModifiedDate = new Date();
          }
        });
      }
      else {
        let obj = new PermitWorkFlow();
        obj.CreatedDate = new Date();
        obj.ActionTaken = 1;
        obj.ModifiedBy = localStorage.getItem("_loginId");
        obj.ModifiedDate = new Date();
        let list: PermitWorkFlow[] = [];
        list.push(obj);
        this._permitWorkflowViewModel.PermitWorkFlows = list;
      }

      this._permitObj.PermitWorkflowViewModel = this._permitWorkflowViewModel;
      this._permitObj.RoleGroupApproval = this._roleGroupApprover;
      this._buttonSaveClicked = true;
      this._buttonSubmitClicked = false;
      this._buttonApproveClicked = false;
      this._buttonRejectClicked = false;
      if (!this.geoTag) {
        this.SaveValues();
      }
    }
    else {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
    }
  }

  ShowApproverOnSubmit() {
    if ((this._permitObj.PermitStateId == null || this._permitObj.PermitStateId == undefined || this._permitObj.PermitStateId == 1) && (this._roleForUser && this._roleForUser.length > 0)) {
      if (this.CheckIfApproversConfigured()) {
        this._submitButtonClicked = true;
        this.OpenApproverPopup();
      }
      else {
        this.SubmitPermitResponse();
      }
    }
    else {
      this.SubmitPermitResponse();
    }
  }

  CheckIfApproversConfigured(): boolean {
    for (let roleUserGroup of this._permitObj.RoleGroupApproval) {
      let userDetails = roleUserGroup.UserDetails;
      let flag = 0;
      for (let userdetail of userDetails) {
        if (userdetail.Active == true) {
          flag = 1;
          break;
        }
      }
      if (flag == 0) {
        return true;
      }
    }
    return false;
  }

  SubmitPermitResponse() {

    let list = this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId == Status.SECTIONEDIT && x.RoleId != null);
    if (list.length > 0) {
      let roles: number[] = [];
      list.forEach(x => { roles.push(x.RoleId) });
      roles = roles.filter(x => x != null); // remove null values
      roles = Array.from(new Set(roles)); // remove duplicate values
      this._permitControlService.getPermitTypeControl(this._permitObj?.PermitTypeVersionId).subscribe((permitControl: PermitControl) => {
        if (permitControl) {
          this.isLMSIntegrationEnabled=permitControl?.LmsIntegration;
        }
      });
      //this.isLMSIntegrationEnabled=this._permitObj.PermitTypeVersion?.PermitTypeControl?.LmsIntegration;
      if (this._permitObj.PermitId && this._permitObj.PermitId > 0) {
        if(this.isLMSIntegrationEnabled == true){
        this._commonService.GetTrainingStatusForUser(this._permitObj.PermitId, roles).subscribe((data: RoleTrainingStateViewModel[]) => {
          if (data.length > 0) {
            this._trainingNotPresentForRole = data.filter(x => x.Status == false);
            let objList = data.filter(x => x.Status == true);
            if (this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId == Status.SECTIONEDIT && x.RoleId == null).length == 0 && objList.length == 0) {
              this._displayLMSErrorForUser = true;
              // this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorTrainingNotPresentForUser') });
            }
            else{
            this.SubmitPermit();
            }
          }
          else {
            this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorTrainingStatus') });
          }
        },
          error => {
            this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorLMSServer') });
          });
        }
        else{
          this.SubmitPermit();
          }
      }
      else {
        if (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null) {
          if(this.isLMSIntegrationEnabled == true){
            this._commonService.GetTrainingStatusForUserBySite(this.userHierarchy.SiteId, this._permitTypeId, roles).subscribe((data: RoleTrainingStateViewModel[]) => {
              if (data.length > 0) {
                this._trainingNotPresentForRole = data.filter(x => x.Status == false);
              let objList = data.filter(x => x.Status == true);    
              if (this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId == Status.SECTIONEDIT && x.RoleId == null).length == 0 && objList.length == 0) {
                this._displayLMSErrorForUser = true;
              }
              else
              {
              this.SubmitPermit();
              }
              }
              else {
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorTrainingStatus') });
              }
            },
              error => {
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorLMSServer') });
              });
            }
            else{
              this.SubmitPermit();
            }
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
        }
      }


    }
    else {
      if (this.ErrorCount() == 0) {
        this._confirmationService.confirm({
          message: this.translate.instant('Permit.ConfirmSumbitMsg'),
          header: this.translate.instant('Permit.ConfirmSubmitHeader'),
          key: 'confirmSubmit',
          icon: 'pi pi-info-circle',
          accept: () => {
            this.SubmitResponse();
          },
          reject: () => {
          }
        });
      }
    }
  }


  SubmitPermit() {
    // this._trainingNotPresentForRole = data.filter(x => x.Status == false);
    // let objList = data.filter(x => x.Status == true);
    // let isGisPresent: boolean = false;
    // if (this._permitControl.GeoTag == true) {
    //   this._indexDbService.getRecord("mapJson", "mapJson_1").subscribe((data) => {
    //     let mapViewModel: GISMapViewModel;
    //     const mapValueFromSession = data;
    //     mapViewModel = mapValueFromSession;
    //     if (mapViewModel.features[0].coordinates.length > 0) {
    //       isGisPresent = true;
    //       this._indexDbService.getRecord("mapJson", "screengrab").subscribe((gis_snip) => {
    //         const mapSnip = gis_snip.screengrab;
    //         if (isGisPresent) {
    //         this.UploadGisSnip(mapSnip, mapViewModel);
    //         }
    //         else {
    //           this._messageService.clear('error');
    //           this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.LocationNotFound') });
    //         }
    //       })

    //     }



    //   },
    //   (error) => {

    //     this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.LocationNotFound') });
    //   }
    // );

    // }
    // if (this._permitControl.GeoTag == true) {
    //   let mapViewModel: GISMapViewModel;
    //   const mapValueFromSession = sessionStorage.getItem('mapJson');
    //   mapViewModel = JSON.parse(mapValueFromSession);
    //   //mapViewModel.PermitId = this.permitIdToCoordinate;
    //   if (mapViewModel == null || mapViewModel.features[0].coordinates == null) {
    //     this._messageService.clear('error');
    //     this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.LocationNotFound') });
    //     return;
    //   }

    // }
    // if (this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId == Status.SECTIONEDIT && x.RoleId == null).length == 0 && objList.length == 0) {
    //   this._displayLMSErrorForUser = true;
    //   // this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorTrainingNotPresentForUser') });
    // }
    // else {
      // this._trainingNotPresentForRole = data.filter(x => x.Status == false);
      if (this.ErrorCount() == 0) {
        this._confirmationService.confirm({
          message: this.translate.instant('Permit.ConfirmSumbitMsg'),
          header: this.translate.instant('Permit.ConfirmSubmitHeader'),
          key: 'confirmSubmit',
          icon: 'pi pi-info-circle',
          accept: () => {
            this.SubmitResponse();
          },
          reject: () => {
          }
        });
      }
    //}
  }


  FetchMapCoordinates(submit: boolean) {
    let isGisPresent: boolean = false;
    if (this._permitControl != null) {
      if (this._permitControl.GeoTag == true) {
        this._indexDbService.getRecord("mapJson", "mapJson_1").subscribe((data) => {
          let mapViewModel: GISMapViewModel;
          const mapValueFromSession = data;
          mapViewModel = mapValueFromSession;
          if (mapViewModel.features[0].coordinates && mapViewModel.features[0].coordinates.length > 0) {
            isGisPresent = true;
            this._indexDbService.getRecord("mapJson", "screengrab").subscribe((gis_snip) => {
              const mapSnip = gis_snip.screengrab;
              if (isGisPresent) {
                this.count++;
                this.UploadGisSnip(mapSnip, mapViewModel);
              }
              else {
                this.count++;
                if (!submit) {
                  this.SaveValues();
                }
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.LocationNotFound') });
              }
            })

          }
          else {
            if (!submit) {
              this.SaveValues();
            }
            else {
              this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.LocationNotFound') });
            }

          }


        },
          (error) => {
            if (submit) {
              this.count++;
              this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.LocationNotFound') });
            }
            else {
              this.SaveValues();
            }
          }
        );

      }
    }

  }


  SubmitResponse() {
    this._userButton = true;
    this.count = 0;
    if (this._permitObj.SiteId || (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null)) {
      this.AssignValues();
      if (this.geoTag) {
        this.FetchMapCoordinates(true);
      }

      if (this._permitWorkflowViewModel.PermitWorkFlows.length > 0) {
        this._permitWorkflowViewModel.PermitWorkFlows.forEach(obj => {
          if (this._trainingNotPresentForRole.filter(x => x.RoleId == obj.RoleId).length == 0) {
            if (obj.PermitStateId == Status.SECTIONEDIT) {
              obj.ActionTaken = 2;
              obj.ModifiedBy = localStorage.getItem("_loginId");
              obj.ModifiedDate = new Date();
            }
          }
        });
      }
      else {
        let obj = new PermitWorkFlow();
        obj.CreatedDate = new Date();
        obj.ActionTaken = 2;
        obj.ModifiedBy = localStorage.getItem("_loginId");
        obj.ModifiedDate = new Date();
        let list: PermitWorkFlow[] = [];
        list.push(obj);
        this._permitWorkflowViewModel.PermitWorkFlows = list;
      }
      this._permitObj.PermitWorkflowViewModel = this._permitWorkflowViewModel;
      this._permitObj.RoleGroupApproval = this._roleGroupApprover;
      this._buttonSaveClicked = false;
      this._buttonSubmitClicked = true;
      this._buttonApproveClicked = false;
      this._buttonRejectClicked = false;
      if (!this.geoTag) {
        this.SaveValues();
      }
    }
    else {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
    }
  }

  ErrorCount() {
    let errorCount: number = 0;

    if (this._permitWorkflowViewModel.PermitWorkFlows.length == 0) {
      return errorCount;

    }

    let permitDivision = new PermitTypeDivision();
    let permitWorkflowList = this._permitWorkflowViewModel.PermitWorkFlows;
    let divisionNumber = permitWorkflowList.length > 0 ? permitWorkflowList[0].DivisionNumber : null;

    if (divisionNumber == 1) {
      permitDivision = this._PermitVersion.Divisions.find(x => x.DivisionName.toUpperCase().startsWith('PERMIT CREATION'));
    }
    else if (divisionNumber == 2) {
      permitDivision = this._PermitVersion.Divisions.find(x => x.DivisionName.toUpperCase().startsWith('ACTIVE PERMIT'));
    }
    else if (divisionNumber == 3) {
      permitDivision = this._PermitVersion.Divisions.find(x => x.DivisionName.toUpperCase().startsWith('PERMIT CLOSURE'));
    }

    if (permitDivision) {
      for (let section of permitDivision.Sections) {
        if (permitWorkflowList.length > 0 && permitWorkflowList.find(x => x.TagId == section.PermitTypeSectionId)) {
          for (let question of section.Questions) {
            // Non table question
            if (question.AnswerTypeId != 9) {
              if (question.Mandatory) {
                if (question.AnswerTypeId == 1) {

                  if (question.AnswerOptions[0].DynamicDataSource) {
                    if (question.AnswerText == undefined || question.AnswerText == '0') {
                      question.MandatoryValidationSatisfied = false
                      return this.AddErrorCount(errorCount, 1);
                    }
                    else {
                      question.MandatoryValidationSatisfied = true
                    }
                  }
                  else {
                    if (question.SelectedAnswerOptionID == undefined || question.SelectedAnswerOptionID == 0) {
                      question.MandatoryValidationSatisfied = false
                      return this.AddErrorCount(errorCount, 1);
                    }
                    else {
                      question.MandatoryValidationSatisfied = true
                    }
                  }

                }
                if (question.AnswerTypeId == 2) {
                  if (question.SelectedAnswerCheckBox == undefined || question.SelectedAnswerCheckBox.length == 0) {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 1);
                  }
                  else {
                    var flag = 0;
                    question.SelectedAnswerCheckBox.forEach(item => {
                      if (item) {
                        flag = 1;
                      }
                    });
                    if (flag == 0) {
                      question.MandatoryValidationSatisfied = false
                      return this.AddErrorCount(errorCount, 1);
                    }
                    else {
                      question.MandatoryValidationSatisfied = true
                    }
                  }
                }
                if (question.AnswerTypeId == 3) {
                  if (question.AnswerText == undefined) {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 2);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                }
                if (question.AnswerTypeId == 4) {
                  if (question.SelectedAnswerOptionID == undefined || question.SelectedAnswerOptionID == 0) {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 1);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                }
                if (question.AnswerTypeId == 6) {
                  if (question.AnswerText == undefined) {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 2);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                }

                if (question.AnswerTypeId == 11) {
                  if (question.FieldDesc == undefined || question.FieldDesc.trim() == "") {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 2);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                  if (question.FieldValue == undefined || question.FieldValue.trim() == "") {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 2);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                }

                if (question.AnswerTypeId == 12) {
                  if (question.AnswerText == undefined) {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 2);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                }

                if (question.AnswerTypeId == 7 || question.AnswerTypeId == 10) {
                  if (question.AnswerText == undefined) {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 2);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                }

                if (question.AnswerTypeId == 10) {
                  if (question.AnswerText == undefined) {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 2);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                }
              }
            }
            else {
              let isTableMandatory: boolean = false;
              for (let header of question.TableFieldType) {
                if (header.Mandatory) {
                  isTableMandatory = true;
                }
              }
              if (isTableMandatory && this.GetRowCount(document.getElementById("TableId" + question.PermitTypeQuestionId)) < 2) {
                question.MandatoryValidationSatisfied = false
                return this.AddErrorCount(errorCount, 3);
              }

              //For table 
              let deletedIndexes: number[];
              deletedIndexes = [];
              for (let rowIndex in question.TableData) {
                if (rowIndex.toString().startsWith('deleted')) {
                  if (question.TableData[rowIndex.toString()] == "hidden") {
                    deletedIndexes.push(parseInt(rowIndex.toString().replace("deleted", "")))
                  }
                }
              }
              for (let rowIndex in question.TableData) {
                if (rowIndex.toString().startsWith('delete') == false) {
                  if (!this.IsRowDeleted(rowIndex, deletedIndexes)) {
                    for (let header of question.TableFieldType) {
                      if (header.Mandatory) {

                        if (header.AnswerTypeId == 1) {

                          if (header.AnswerOptions[0].DynamicDataSource) {
                            if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == "") {
                              header.MandatoryValidationSatisfied = false
                              return this.AddErrorCount(errorCount, 1);
                            }
                            else {
                              header.MandatoryValidationSatisfied = true
                            }
                          }
                          else {
                            if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                              header.MandatoryValidationSatisfied = false
                              return this.AddErrorCount(errorCount, 1);
                            }
                            else {
                              header.MandatoryValidationSatisfied = true
                            }
                          }

                          if (header.AnswerOptions[0].DynamicDataSource) {
                            if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == "") {
                              header.MandatoryValidationSatisfied = false
                              return this.AddErrorCount(errorCount, 1);
                            }
                            else {
                              header.MandatoryValidationSatisfied = true
                            }
                          }
                          else {
                            if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                              header.MandatoryValidationSatisfied = false
                              return this.AddErrorCount(errorCount, 2);
                            }
                            else {
                              header.MandatoryValidationSatisfied = true
                            }
                          }

                        }
                        if (header.AnswerTypeId == 2) {
                          if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable.length == 0) {
                            header.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 1);
                          }
                          else {
                            header.MandatoryValidationSatisfied = true
                          }
                        }
                        if (header.AnswerTypeId == 3) {
                          if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                            header.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 2);
                          }
                          else {
                            header.MandatoryValidationSatisfied = true
                          }
                        }
                        if (header.AnswerTypeId == 4) {
                          if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                            header.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 1);
                          }
                          else {
                            header.MandatoryValidationSatisfied = true
                          }
                        }
                        if (header.AnswerTypeId == 6) {
                          if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                            header.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 2);
                          }
                          else {
                            header.MandatoryValidationSatisfied = true
                          }
                        }

                        if (header.AnswerTypeId == 7) {
                          if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                            header.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 1);
                          }
                          else {
                            header.MandatoryValidationSatisfied = true
                          }
                        }

                        if (header.AnswerTypeId == 10) {
                          if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                            header.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 1);
                          }
                          else {
                            header.MandatoryValidationSatisfied = true
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return errorCount;
  }

  AddErrorCount(errorCount: number, flag: number) {
    this._messageService.clear('error');
    if (flag == 1) {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
    }
    else if (flag == 2) {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
    }
    else {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTableBlank') });
    }
    errorCount++;
    return errorCount;
  }

  ApprovePermit() {
    this._displayCommentPopUp = false;
    this._permitWorkflowViewModel.PermitWorkFlows.forEach(obj => {
      if (this._trainingNotPresentForRole.filter(x => x.RoleId == obj.RoleId).length == 0) {
        if (obj.PermitStateId == Status.SECTIONAPPROVE || obj.PermitStateId == Status.PERMITLEVELAPPROVE) {
          obj.ActionTaken = 3;
          obj.ModifiedBy = localStorage.getItem("_loginId");
          obj.ModifiedDate = new Date();
        }
      }
    });
    this._permitObj.PermitWorkflowViewModel = this._permitWorkflowViewModel;
    this._buttonSaveClicked = false;
    this._buttonSubmitClicked = false;
    this._buttonApproveClicked = true;
    this._buttonRejectClicked = false;
    this._buttonUserApproverClicked = false;
    if (!this.geoTag) {
      this.SaveValues();
    }
    else {
      this.FetchMapCoordinates(true);
    }
  }

  RejectPermit() {
    this._displayCommentPopUp = false;
    this._permitWorkflowViewModel.PermitWorkFlows.forEach(obj => {
      if (this._trainingNotPresentForRole.filter(x => x.RoleId == obj.RoleId).length == 0) {
        if (obj.PermitStateId == Status.SECTIONAPPROVE || obj.PermitStateId == Status.PERMITLEVELAPPROVE) {
          obj.ActionTaken = 4;
          obj.ModifiedBy = localStorage.getItem("_loginId");
          obj.ModifiedDate = new Date();
        }
      }
    });
    this._permitObj.PermitWorkflowViewModel = this._permitWorkflowViewModel;
    this._buttonSaveClicked = false;
    this._buttonSubmitClicked = false;
    this._buttonApproveClicked = false;
    this._buttonRejectClicked = true;
    this._buttonUserApproverClicked = false;
    this.SaveValues();
  }

  ClosePermit() {
    this._confirmationService.confirm({
      message: this.translate.instant('Permit.ConfirmCloseMsg'),
      header: this.translate.instant('Permit.ConfirmCloseHeader'),
      key: 'confirmClose',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._displayCommentPopUp = false;
        this._displayCommentPopUp = false;
        this._permitWorkflowViewModel.PermitWorkFlows.forEach(obj => {
          if (obj.PermitStateId == Status.PERMITCLOSE) {
            obj.ActionTaken = 5;
            obj.ModifiedBy = localStorage.getItem("_loginId");
            obj.ModifiedDate = new Date();
          }
        });
        this._permitObj.PermitWorkflowViewModel = this._permitWorkflowViewModel;
        this.SaveValues();
      },
      reject: () => {
      }
    });
  }


  SaveValues() {
    if (this._permitObj.PermitId > 0) {
      let languageId = localStorage.getItem("_languageID");
      this._permitObj.LanguageId = languageId;
      this._permitService.updatePermit(this._permitObj).subscribe((data: HttpResponse<any>) => {
        if (data.status === 200) {
          if (!(this._approverComments == null || this._approverComments.trim() == '')) {
            this.SaveApproverComments();
          }
          this.responseAttachmentFiles = [];
          this.getPermit(data.body.Item1, false, data.body.Item4);
          this.ShowPermitSuccessMessage(data.body.Item2);
          // if (data.body.Item4) {
          //   this.AttachPdfToMail(data.body.Item1);
          // }
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.UpdatePermitError') });
        }
      });
    }
    else {
      this._permitService.addPermit(this._permitObj).subscribe((data: HttpResponse<any>) => {
        if (data.status === 200) {
          this.responseAttachmentFiles = []
          this.getPermit(data.body.Item1, false, data.body.Item4);
          let redirectURL = 'permit/' + this._permitTypeId + '/' + data.body.Item1;
          this.ShowPermitSuccessMessage(data.body.Item2);
          if (!data.body.Item4) {
            this._router.navigate([redirectURL]);
          }
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.CreatePermitError') });
        }
      });
    }
    //}

  }

  SavePermit() {
    if (this._permitObj.PermitId > 0) {
      // mapViewModel.PermitId = this._permitId;
      let languageId = localStorage.getItem("_languageID");
      this._permitObj.LanguageId = languageId;
      this._permitService.updatePermit(this._permitObj).subscribe((data: HttpResponse<any>) => {
        if (data.status === 200) {
          if (!(this._approverComments == null || this._approverComments.trim() == '')) {
            this.SaveApproverComments();
          }
          this.responseAttachmentFiles = [];
          this.getPermit(data.body.Item1, false, data.body.Item4);
          this.ShowPermitSuccessMessage(data.body.Item2);
          // if (data.body.Item4) {
          //   this.AttachPdfToMail(data.body.Item1);
          // }
        }
        else {

          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.UpdatePermitError') });
        }
      });
    }
    else {
      this._permitService.addPermit(this._permitObj).subscribe((data: HttpResponse<any>) => {
        if (data.status === 200) {
          this.responseAttachmentFiles = []
          this.getPermit(data.body.Item1, false, data.body.Item4);
          let redirectURL = 'permit/' + this._permitTypeId + '/' + data.body.Item1;
          this.ShowPermitSuccessMessage(data.body.Item2);
          if (!data.body.Item4) {
            this._router.navigate([redirectURL]);
          }
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.CreatePermitError') });
        }
      });
    }
  }

  UploadGisSnip(locationSnip: string, mapViewModel: GISMapViewModel): any {
    //let count=0;
    var block = locationSnip.split(";");
    var contentType = block[0].split(":")[1];
    var extension = contentType.split("/")[1];
    var dataURI = block[1].split(",")[1];
    let imagePath: string;
    let fileName = 'gis_snip.' + extension;
    const imageBlob = this.dataURItoBlob(dataURI, contentType);
    let imageFile: File = new File([imageBlob], fileName, { type: contentType });
    this._mediaService.AttachMediaFiles(imageFile).subscribe((res: HttpResponse<any>) => {
      if (res.status == 200) {
        if (this._permitObj.PermitId > 0) {
          mapViewModel.PermitId = this._permitId;
        }
        mapViewModel.AzureFileId = res.body;
        this._indexDbService.getRecord("mapJson", "site").subscribe((data) => {
          mapViewModel.SiteName = data.sitename;
          this._permitObj.GISMapViewModel = mapViewModel;
          this.SavePermit();
        });

      }
    },
      () => {
      }
    );
  }

  dataURItoBlob(dataURI, contentType) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: contentType });
    return blob;
  }

  ShowPermitSuccessMessage(permitStateId: number) {
    if (permitStateId == Status.DRAFT) {
      if (this._buttonRejectClicked) {
        this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.RejectSuccess') });
      }
      else {
        this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.PermitSavedAsDraft') });
      }
    }
    else if (permitStateId == Status.CLOSED) {
      this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.ClosePermitSuccess') });
    }
    else if (this._buttonSaveClicked) {
      this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.ResponseSaved') });
    }
    else if (this._buttonSubmitClicked) {
      this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.ResponseSubmitted') });
    }
    else if (this._buttonApproveClicked) {
      this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.ApprovedSuccess') });
    }
    else if (this._buttonRejectClicked) {
      this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.RejectSuccess') });
    }
  }


  downloadAttachment(attachmentFile) {
    if (attachmentFile) {

      window.open(attachmentFile[0].source);
    }

    return false;
  }


  showGeoTagButton() {
    let showGeoTag: boolean = false;
    if (this.geoTag) {
      showGeoTag = true;
      switch (this._permitObj.PermitStateId) {
        case PermitStates.PERMITCLOSE, PermitStates.LAPSED, PermitStates.REJECTED,
          PermitStates.CANCELLED, PermitStates.PERMITLEVELREJECTED, PermitStates.SUSPENDED:
          showGeoTag = false;
          break;
        default:
          showGeoTag = true;
          break;
      }
      return showGeoTag;
    }
    else {
      return showGeoTag;
    }
  }

  async exportPdf(event) {
    let sectionNodes: TreeNode[] = [];
    this._pritableSectionTreeNode = [];
    this._pritableSectionTreeNode.push(this.makePermitTreeNode('Permit', 'permitonly', []));
    this._pritableSectionTreeNode.push(this.makePermitTreeNode('Attachment', 'attachmentonly', []));
    if (this._printableSections && this._printableSections.length > 0) {
      this._printableSections.forEach(sectionName => {
        sectionNodes.push(this.assignSectionToTreeNode(sectionName));
      });
      this._pritableSectionTreeNode.push(this.makePermitTreeNode('Permit Sections', 'permitsection', sectionNodes));
    }
    this.showdownloadmodal = true;
  }

  async downloadFiles(event) {
    let permitOnly = this._selectedSectionToPrint.filter(x => x.data == 'permitonly');
    let attacmentOnly = this._selectedSectionToPrint.filter(x => x.data == 'attachmentonly');
    let permitSections = this._selectedSectionToPrint.filter(x => x.data != 'permitsection' &&
      x.data != 'permitonly' && x.data != 'attachmentonly');
    if (permitSections && permitSections.length > 0) {
      this._sectionToPrint = [];
      permitSections.forEach(sectionNode => {
        this._sectionToPrint.push(sectionNode.data.toString());
      });
      this.findLastSection();
      //this.childPDFComponent.BindSectionForPrint(this._sectionToPrint);
    }
    if ((permitOnly && permitOnly.length > 0) || (permitSections && permitSections.length > 0)) {
      if (this._permitObj.PermitStateId != 3 && this._permitObj.PermitStateId != 2 && this._permitObj.PermitStateId != 20 && this._permitObj.PermitStateId != 1) {
        return;
      }
      this._spinner.show();
      // Enforcing the loader to show for 10 seconds
      await this.DelayExecution();

      if (permitOnly.length > 0) {
        let fileName = this._permitObj.PermitNumber.toString() + '_Export';
        this.pdfExport.saveAs(fileName);
      }
      if (permitSections.length > 0) {
        let selectedFileName = this._permitObj.PermitNumber.toString() + '_Partial_Export';
        this.pdfSelectedExport.saveAs(selectedFileName);
      }

      this.showdownloadmodal = false;
      this._spinner.hide();
    }
    if (attacmentOnly && attacmentOnly.length > 0) {
      this._spinner.show();
      let countImage = 0;
      let permitNumberToExport = this._permitObj.PermitNumber.toString();
      this.responseAttachmentFiles.forEach(function (downloadfiles) {
        if (downloadfiles.ContentType.indexOf('image/') >= 0) {
          countImage++;
          saveAs(downloadfiles.BinaryFile, permitNumberToExport + "_image_" + countImage.toString() + ".png");
        }
        else if (downloadfiles.ContentType.indexOf('/pdf') >= 0) {
          countImage++;
          saveAs(downloadfiles.BinaryFile, permitNumberToExport + "_pdf_" + countImage.toString() + ".pdf");
        }
        else {
          window.open(downloadfiles.ImageArray[0].source);
        }
      });
      this.showdownloadmodal = false;
      this._spinner.hide();
    }
  }
  myshow() {
    this.showdownloadmodal = false;
  }

  TriggerMail(event) {
    if (event) {
      this._mailDistributionRequired = true;
    }
  }

  AttachPdfToMail(permitId) {
    this.pdfExport.export().then((group: Group) => exportPDF(group)).then((dataUri) => {
      var block = dataUri.split(";");
      var contentType = block[0].split(":")[1];
      var extension = contentType.split("/")[1];
      var dataURI = block[1].split(",")[1];
      let imagePath: string;
      let fileName = 'active_permit.' + extension;
      const pdfBlob = this.dataURItoBlob(dataURI, contentType);
      let pdfFile: File = new File([pdfBlob], fileName, { type: contentType });
      this._mediaService.AttachMediaFiles(pdfFile).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) {
          this._permitService.sendConfiguredEmail(permitId, res.body).subscribe((data) => {
            let redirectURL = '/permit/' + this._permitTypeId + '/' + permitId;
            this._router.navigate([redirectURL]);
          });
        }
      },
        () => {
        }
      );
    });
  }


  InitializeDb() {
    this._indexDbService.openDBAsync("myDB", 1).forEach(
      (readyState: string) => {
        console.log('IndexedDB service: opening db: ' + readyState);
        this.ClearDb();
      }, null
    );


  }
  ClearDb() {
    this._indexDbService.clearObjectStoreAsync("mapJson").subscribe((data) => {
      console.log("Cleared Object Store");
    })
  }

  GetApproverInfo(userRole: RoleGroupApproval): SelectItem[] {
    let userInfoforDropDownValue: SelectItem[] = [];
    userRole.UserShortInfo.forEach((us) => {
      let userDetails: any = {};
      let lstUserDetails: RoleGroupUserDetails[] = [];
      if (userRole.UserDetails) {
        lstUserDetails = userRole.UserDetails.filter(x => x.LoginId == us.LoginId);
      }

      if (lstUserDetails.length > 0) {
        userDetails = lstUserDetails[0];
      }
      else {
        userDetails = {
          LoginId: us.LoginId,
          RoleGroupApprovalId: 0,
          RoleGroupUserDetailsId: 0
        }
      }
      userInfoforDropDownValue.push({ label: us.UserName, value: userDetails });
    });

    return userInfoforDropDownValue;

  }
  AddUserPopupCancel() {
    this.displayuserforrole = false;
    this._submitButtonClicked = false;
  }
  deepCopy(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
      }
      return copy;
    }
    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  setPrintableSections() {
    let sectionNames: string[] = [];
    if (this._PermitVersion && this._PermitVersion.Divisions && this._PermitVersion.Divisions.length > 0) {
      this._PermitVersion.Divisions.forEach(divisions => {
        if (divisions.Sections && divisions.Sections.length > 0) {
          divisions.Sections.forEach(section => {
            if (section.Printable) {
              sectionNames.push(section.SectionName);
            }
            this._allSectionNames.push(section.SectionName);
          });
        }
      });
    }
    this._printableSections = [];
    this._printableSections = sectionNames;
  }

  private assignSectionToTreeNode(name: string): TreeNode {
    return {
      label: name,
      data: name,
      leaf: true
    }
  }

  private makePermitTreeNode(label: string, data: string, childNodes: TreeNode[]): TreeNode {
    return {
      label: label,
      data: data,
      leaf: true,
      expanded: true,
      children: childNodes
    }
  }

  private findLastSection() {
    this._sectionToPrint;
    this._allSectionNames;
    let maxIndex = 0;
    if (this._sectionToPrint && this._sectionToPrint.length > 0) {
      this._sectionToPrint.forEach(sectionName => {
        let secIndex = this._allSectionNames.indexOf(sectionName);
        if (secIndex > maxIndex) {
          maxIndex = secIndex;
          this.lastSectionName = sectionName;
        }
      });
    }
  }

  adjustForTimezone(date: Date): Date {
    try {
      if (date) {
        let dateString: string = date.toString();
        dateString = dateString.toLowerCase().indexOf('z') > 0 ? dateString : dateString + 'Z';
        let convertedDate = new Date(dateString);
        return convertedDate;
      }
      else {
        return null;
      }
    }
    catch (ex) {
      return null;
    }
  }

  tolocalTimeString(obj: Date): string {
    try {
      if (obj) {
        let dateString = obj.toString();
        dateString = dateString.toLowerCase().indexOf('z') > 0 ? dateString : dateString + 'Z';
        let dateObj: Date = new Date(dateString);
        return this._datePipe.transform(dateObj, 'dd-MMM-yyyy hh:mm:ss a');
      }
      else {
        return '';
      }
    }
    catch (ex) {
      return '';
    }
  }
}
