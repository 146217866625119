import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Role } from '../ViewModel/Role';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { environment } from 'src/environments/environment';
import { Contractor } from '../ViewModel/Contractor';
import { HelperService } from './helper.service';


@Injectable()
export class ContractorService {
  
    getAllContractorURL = environment.WEBAPIURL + 'contractor/contractors';
    AddBulkURL = environment.WEBAPIURL + 'DataUpload/bulk-contractor';

  constructor(private _http: HttpClient, private _helperService: HelperService) { }

  //get all Contractor deatils
  getAllContractor(objVMHierarchySearch: HierarchySearchViewModel) {
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Contractor[]>(this.getAllContractorURL,{params: params});
  }

  addBulkValue(Contractor) {  
    this._helperService._contractors=[];
    return this._http.post(this.AddBulkURL, Contractor,{observe: 'response'});
   }

  // add new contractor
  addContractor(contractor: Contractor) {
    this._helperService._contractors=[];
    return this._http.post(this.getAllContractorURL, contractor, { observe: 'response' });
  }

  // edit/update existing contractor
  editContractor(contractor: Contractor) {
    this._helperService._contractors=[];
    return this._http.put(this.getAllContractorURL, contractor, { observe: 'response'});
  }

  
 

}
