import { Region } from './Region';
import { Attributes } from './Attributes';
import { HierarchyTranslation } from './HierarchyTranslation';
import { BU } from './BU';
import { Site } from './Site';
import { SubSite } from './SubSite';
import { Department } from './Department';
import { Crew } from './Crew';
import { SRU } from './sru';


export class SubBU {
        SubBUId: number;
        BUId: number;
        RegionId: number;
        RegionName: string;
        BUName: string;
        SubBUName: string;
        Active: boolean;
        CreatedBy: string;
        CreatedDate: Date;
        ModifiedBy: string;
        ModifiedDate: Date;
        BU?: BU | null;
        Region?: Region | null;
        Attributes?: Attributes[] | null;
        DefaultLanguageId: number;
        IsEditable: boolean;
        HierarchyTranslations?: HierarchyTranslation[] | null;
        Sites?: Site[] | null;
        SubSites?: SubSite[] | null;
        SRUs?: SRU[] | null;
        Departments?: Department[] | null;
        Crews?: Crew[] | null;
}

