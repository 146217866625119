import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PermitTypeVersion } from '../ViewModel/PermitBuilder/PermitTypeVersion';
import { environment } from 'src/environments/environment';
import { PermitTypeQuestion } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeQuestion';
import { UserForRoleGroupViewModel, RoleGroupApproval } from '../ViewModel/UserForRoleGroupViewModel';
import { HelperService } from './helper.service';
import { ClonePermit } from '../ViewModel/ClonePermit';

@Injectable()
export class PermitTypeVersionService {
 
  
  constructor(private _http: HttpClient,private _helperService: HelperService) { }
  private _permitTypeVersionUrl = environment.WEBAPIURL + 'permit-type-version';
  private _permitTypeLatestVersionUrl = environment.WEBAPIURL + 'permit-type-version-latest';
  private _permitTypePrintMultipleUrl = environment.WEBAPIURL + 'permit-type-print-multiple';
  private _permitTypeVersionByIdUrl = environment.WEBAPIURL + 'permit-type-by-version';
  private _permitTypeVersionsListUrl = environment.WEBAPIURL + 'permit-type-version-list';
  private _permitTypeCloneUrl = environment.WEBAPIURL + 'permit-clone';
  private _permitTypeVersionWithIdUrl = environment.WEBAPIURL + 'permit-version';
  private _permitQusetionUrl=environment.WEBAPIURL+'permit-questions';
  private _userForRoleGroupUrl=environment.WEBAPIURL +'PermitAction/user-role-groups';
  private _permitTypeVersionPublishedUrl = environment.WEBAPIURL + 'permit-type-version-published';

  getPermitTypePublishedVersionOnly(permitTypeId:number,permitId:number){
    return this._http.get<PermitTypeVersion[]>(this._permitTypeVersionPublishedUrl+'/'+permitTypeId+'/' +permitId);
  }
  getPermitTypeVersion(permitTypeId){
    return this._http.get<PermitTypeVersion[]>(this._permitTypeVersionUrl+'/'+permitTypeId);
  }
  getPermitTypeVersionsList(permitTypeId){
    return this._http.get<PermitTypeVersion[]>(this._permitTypeVersionsListUrl+'/'+permitTypeId);
  }

  getPermitTypeVersionByVersionId(permitTypeVersionId){
    return this._http.get<PermitTypeVersion[]>(this._permitTypeVersionByIdUrl+'/'+permitTypeVersionId);
  }
  getPermitTypeVersionWithPermitTypeVersionId(permitTypeVersionId){
    return this._http.get<PermitTypeVersion>(this._permitTypeVersionWithIdUrl+'/'+permitTypeVersionId);
  }
  getPermitTypeVersionWithActiveQuestions(permitTypeId:number,active:boolean){
    return this._http.get<PermitTypeVersion[]>(this._permitTypeVersionUrl+'/'+permitTypeId+'/' +active);
  }
  getPermitTypeLatestVersion(permitTypeId:number, permitId:number, active:boolean){
    return this._http.get<PermitTypeVersion[]>(this._permitTypeLatestVersionUrl+'/'+permitTypeId+'/' +permitId+'/' +active+'/');
  }
  getPermitTypeForPrintMultiplePermit(permitTypeId:number, permitId:number, active:boolean){
    return this._http.get<PermitTypeVersion[]>(this._permitTypePrintMultipleUrl+'/'+permitTypeId+'/' +permitId+'/' +active+'/');
  }
  getUserForPermitRoles(siteId,permitTypeVersionId,permitId){
    //let params = new HttpParams().set("roleIds", this._helperService.EscapeSpecialCharacters(JSON.stringify(roleIds)));
    return this._http.get<RoleGroupApproval[]>(this._userForRoleGroupUrl+'/'+siteId+'/'+permitTypeVersionId+'/'+permitId + '/' + localStorage.getItem("_languageID"));
  }

  addPermitTypeVersion(permitTypeVersion:PermitTypeVersion){
    return this._http.post<PermitTypeVersion>(this._permitTypeVersionUrl, permitTypeVersion,{observe: 'response'});
  }

  // The update section hasn't been implemented as of now
  updatePermitTypeVersion(permitTypeVersion:PermitTypeVersion){
    return this._http.put(this._permitTypeVersionUrl, permitTypeVersion,{observe: 'response'});
  }

  //Get Permit Questions
  getPermitQuestions(permitVersionId){
    return this._http.get<PermitTypeQuestion[]>(this._permitQusetionUrl+'/'+permitVersionId);
  }

  clonePermit(clonePermitObj: ClonePermit){
    return this._http.post(this._permitTypeCloneUrl, clonePermitObj,{observe: 'response'});
  }
}
