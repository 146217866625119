import { Role } from './Role';
import { User } from './User';
import { UpdateStamp } from './UpdateStamp';
import { BU } from './BU';
import { Region } from './Region';
import { SubBU } from './SubBU';
import { Site } from './Site';
import { SubSite } from './SubSite';
import { SRU } from './sru';
import { Department } from './Department';
import { Crew } from './Crew';

export class UserRole extends UpdateStamp
{
    UserRoleId: number;
    RoleId: number;
     UserId: number;
    BUId:number;
    RegionId:number;
    SubBUId: number;
    SiteId:number;
    SubSiteId:number;
    SRUId: number;
    DepartmentId:number;
    CrewId:number;
    BU:BU;
    Region:Region;
    SubBU:SubBU;
    Site:Site;
    SubSite:SubSite;
    SRU:SRU;
    Department:Department;
    Crew:Crew;
    User:User;
    Role:Role;



}