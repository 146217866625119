import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError  } from 'rxjs/operators';
import { Common } from '../ViewModel/Common';
import { environment } from 'src/environments/environment';

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class MediaService {
  _common = new Common();
  mediaGetURL = environment.MediaWebApiUrl + 'File';
  mediaGetArrayURL = environment.MediaWebApiUrl + 'Files';
  mediaUploadURL = environment.MediaWebApiUrl + 'File/Create';
  mediaDeleteURL = environment.MediaWebApiUrl + 'File/Delete';
  attachmentFileURL = environment.WEBAPIURL + 'common/upload-media';
  

  constructor(private _http: HttpClient ) {       
  }

  getFileProperties(AzureFileId: number) {
    
    return this._http.post<any>(this.mediaGetURL +"/" + AzureFileId, AzureFileId, {observe: 'response'});
  }
  getFilePropertiesArray(AzureFileArrayId:any[]) {
    return this._http.post<any>(this.mediaGetArrayURL,AzureFileArrayId, {observe: 'response'});
  }

  UploadMediaFiles(AzureFile: File) {
    const formData = new FormData();
    formData.append('file', AzureFile);
    return this._http.post<any>(this.mediaUploadURL, formData, {observe: 'response'});
  }

  AttachMediaFiles(AzureFile: File) {
    const formData = new FormData();
    formData.append('file', AzureFile);
    return this._http.post<any>(this.attachmentFileURL, formData, {observe: 'response'});
  }

  getFileByUri (callUri: string) {    
    return this._http.get<any>(callUri);
  }

  DeleteMediaFileById(AzureFileId: number) { 
    return this._http.post<any>(this.mediaDeleteURL+'/'+AzureFileId, { observe: 'response' });
  }

}
  
  // async UploadMediaFiles(AzureFile: File): Promise<any> {
  //   try {
  //     this._common = new Common();
  //     const formData = new FormData();
  //     formData.append('file', AzureFile);
  //     // return this._http.post<any>(this.mediaUploadURL, formData, {
  //     //   observe: 'response',
  //     //   headers: this._common.mediaPostHeader
  //     // })
  //     return await this._http.post<any>(this.mediaUploadURL, formData, {
  //       // observe: 'response',
  //       headers: this._common.mediaPostHeader
  //     }).toPromise();
  //   } 
  //   catch (error) {
  //     await this.handleError(error);
  //   }
  //     // .pipe(
  //     //   catchError(this.handleError)
  //     // );
  // }
