import { Injectable, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
declare let pendo: any;
@Injectable()
export class PendoService {
    pendoApiUrl: string;
    enablePendoApi: boolean = false;
    pendo: any;

    constructor(private ngZone: NgZone, private httpClient: HttpClient) {
        this.pendoApiUrl = environment.pendoApiSettings.webApiKey;
        // environment.pendoApiSettings.webApiKey; // get pendoapi key from environment file
        this.enablePendoApi = environment.pendoApiSettings.enablePendoApi;
        // = environment.pendoApiSettings.enablePendoApi; // whether need to enable pendo api or not
    }
    // add the pendo js if it is enable to use 
    Pendoinitialize() {
        if (this.enablePendoApi) {
            (function (apiKey) { (function (p, e, n, d, o) { var v, w, x, y, z; o = p[d] = p[d] || {}; o._q = o._q || []; v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w)(function (m) { o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); }; })(v[w]); y = e.createElement(n); y.async = !0; y.src = 'https://content.pendo.alcoa.com/agent/static/' + apiKey + '/pendo.js'; z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z); })(window, document, 'script', 'pendo'); })(this.pendoApiUrl);
            this.ngZone.runOutsideAngular(() => {
                pendo.initialize({
                    visitor: {
                        id: localStorage.getItem('_emailId'),   // User Id -- firstname.lastname
                        //email: localStorage.getItem('email'),   // Recommended if using Pendo Feedback, or NPS Email
                        //full_name: localStorage.getItem('userName')   // Recommended if using Pendo Feedback
                        WPS_Language: localStorage.getItem("LanguageCode") == null ?
                            "en" : localStorage.getItem("LanguageCode"),
                        WPS_LastVisited_Web: (new Date()).toISOString()
                    },                    
                    account: {
                        id: "" //'ACCOUNT-UNIQUE-ID' // Highly recommended
                    }                    
                });                
            });
        }        
    }
}
