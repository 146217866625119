import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Message } from 'primeng/api';
import { Common } from '../ViewModel/Common';
import { Region } from '../ViewModel/Region';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';


@Injectable()
export class RegionService {        
    getAllRegionURL = environment.WEBAPIURL + 'region/regions'; 
    AddBulkURL = environment.WEBAPIURL + 'DataUpload/bulk-regions';
    
    getRegionDropdownURL = environment.WEBAPIURL + 'Region/GetRegionDropdown';

  constructor(private _http: HttpClient, private _helperService: HelperService) {}

//Excel value bulk upload
  addBulkValue(Region) {
    localStorage.setItem("_hierarchtTree", null);
    return this._http.post(this.AddBulkURL, Region, { observe: 'response' });
   }

  //Get All the Region Details
  getAllRegion() { 
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.Active = true;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Region[]>(this.getAllRegionURL, { params: params });
  }
  
  //Get region dropdown details
  getRegionDropdown() {    
    return this._http.get<Region[]>(this.getRegionDropdownURL);
  }

  //Get All the BU Details By Region
  getRegionByBU(BUId){    
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = BUId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Region[]>(this.getAllRegionURL, { params: params });
  }

  GetRegionChildren(buId,regionId){
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = buId;
    objVMHierarchySearch.RegionId=regionId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Region[]>(this.getAllRegionURL,{ params: params });
  }

  //Get All the BU Details By Region
  getRegionByBUByRole(BUId){    
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = BUId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Region[]>(this.getAllRegionURL,{ params: params });
  }

  //Add new Region
  addRegion(Region:Region) { 
    localStorage.setItem("_hierarchtTree", null); 
    Region.DefaultLanguageId=+localStorage.getItem("_languageID");
   return this._http.post(this.getAllRegionURL, Region,{observe: 'response'});
  }

  //Update Region
  editRegion (_objRegion) {  
    localStorage.setItem("_hierarchtTree", null);
    _objRegion.DefaultLanguageId=+localStorage.getItem("_languageID");
    return this._http.put(this.getAllRegionURL, _objRegion,{observe: 'response'});
  }

  //Search Region
  searchRegion ( BUId,RegionName,isActive) { 
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = BUId;
    objVMHierarchySearch.RegionName = RegionName;
    objVMHierarchySearch.Active = isActive;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Region[]>(this.getAllRegionURL,{ params: params });
  }

  
}
