import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { PermitService } from '../Services/permit.service';
import { MessageService, TreeNode } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { WOOperationTree } from '../ViewModel/WOOperationTreeViewModel';

@Component({
  selector: 'app-select-operation',
  templateUrl: './select-operation.component.html',
  styleUrls: ['./select-operation.component.css']
})
export class SelectOperationComponent implements OnInit {

  constructor(private _permitService: PermitService, private _messageService: MessageService, private translate: TranslateService) { }

  @Input() SiteId: number;
  @Output() ObjSelectedOperation = new EventEmitter<WOOperationTree[]>();
  @ViewChild('OPSeqSearchInput') OPSeqSearchInput: ElementRef;
  ddlSelectedWO: string;
  OP_loading: boolean = true;
  _EAMOPSeqDetails: any[] = [];
  selectedOPDesc: string = "";
  oPNumberList: string[] = [];
  oPDescList: string[] = [];
  _first = 1;
  _last = 20;
  OP_first: number = 0;
  EAMOPListFlag:boolean = false;
  _txtOP = '';
  cols: any[];
  _selectedOperations: TreeNode[];
  _selectedWoNumber: string[] = [];
  _operationData: TreeNode[] = [];
  
  ngOnInit() {
  }

  loadOperationSequence(_WOList: any[]) {
    this._selectedWoNumber = [];
    _WOList.forEach(element => {
      this._selectedWoNumber.push(element.WorkOrderNumber);
    });

    let siteId = this.SiteId;
    this._permitService.getOperationSequence(this._selectedWoNumber, 'null', siteId, 1, 50).subscribe(data => {
      if(data && data.OperationList){
        this._EAMOPSeqDetails = data.OperationList;
        this._operationData = this.createTreeNodeFromOperationData(this._EAMOPSeqDetails);

      }      
      this.OP_loading = false;
    }, error => {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: error.error });
    });
  }

  createTreeNodeFromOperationData(operationList: any[]): TreeNode[] {
    let returnTreeNodeList: TreeNode[] = [];
    this._selectedWoNumber.forEach(WONumber => {
      let returnTreeNode: TreeNode;
      let operations: any[] = operationList.filter(x => x.WorkOrderNumber == WONumber);
      let ChildOPList: WOOperationTree[] = [];
      operations.forEach(operation => {
        let WOOPTree: WOOperationTree = new WOOperationTree();
        WOOPTree.WorkOrderNumber = operation.WorkOrderNumber;
        WOOPTree.OperationNumber = operation.OperationSeqNum;
        WOOPTree.AssignedDept = operation.AssignedDepartment;
        WOOPTree.Description = operation.Description;
        WOOPTree.OperationCompleted = operation.OperationCompleted;
        WOOPTree.StartDate = operation.StartDate;
        WOOPTree.CompletionDate = operation.CompletionDate;
        ChildOPList.push(WOOPTree);
      });
      let childNodes = this.operationToTreeNodeArray(ChildOPList);
      returnTreeNode = this.WorkOrderToTreeNode(WONumber, childNodes);
      returnTreeNodeList.push(returnTreeNode);
    });  
    return returnTreeNodeList;
  }

  operationToTreeNodeArray(ChildOPList: WOOperationTree[]) : TreeNode[] {
    let returnTreeNode: TreeNode[] = [];
    ChildOPList.forEach(element => {
      returnTreeNode.push(this.OperationToTreeNode(element));
    });
    return returnTreeNode;
  }

  OperationToTreeNode(OP: WOOperationTree): TreeNode {
    return {
      label: OP.OperationNumber,
      data: OP
    }
  }

  WorkOrderToTreeNode(WONumber: string, childNodes: TreeNode[]): TreeNode {
    let WOOPTree: WOOperationTree = new WOOperationTree();
    WOOPTree.WorkOrderNumber = '';
    WOOPTree.OperationNumber = WONumber;
    WOOPTree.AssignedDept = '';
    WOOPTree.Description = '';
    WOOPTree.OperationCompleted = '';
    WOOPTree.StartDate = '';
    WOOPTree.CompletionDate = '';
    return {
      label: WONumber,
      data: WOOPTree,
      children: childNodes,
      expanded: true
    }
  }

  clearSelection(){
    this._selectedOperations = [];
  }

  OnSelectOPDone() {
    let OPList: WOOperationTree[] = [];
    if(this._selectedOperations && this._selectedOperations.length > 0) {
      this._selectedOperations.forEach(element => {
        if(element.data && !element.expanded){
          OPList.push(element.data);
        }        
      });
    }
    this.ObjSelectedOperation.emit(OPList);
  }

}
