import {Injectable} from '@angular/core';

import { HttpClient, HttpParams} from '@angular/common/http';
import {HierarchySearchViewModel} from '../ViewModel/HierarchySearchViewModel';

import { Category } from '../ViewModel/Category';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';


@Injectable()
export class CategoryService {
             
    getAllCategoryURL = environment.WEBAPIURL + 'Category/categories';
    getAllCategoryDashboardURL = environment.WEBAPIURL + 'Category/categories-dashboard';
    getAllCategoryByRoleURL = environment.WEBAPIURL + 'BU/BU';
    searchCategoryURL='../../assets/test.json';


    AddBulkURL = environment.WEBAPIURL + 'DataUpload/AddBulkBU';
    addUpdateTranslationURL = environment.WEBAPIURL + 'Category/categories';   

  constructor(private _http: HttpClient, private _helperService: HelperService) { }

  //Excel value bulk upload
  addBulkValue(BU) {   
    return this._http.post(this.AddBulkURL, BU,{observe: 'response'});
  }


  //Get All the BU Details
  getAllCategory() {   
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.Active = true;
    objVMHierarchySearch.CategoryName="";
    objVMHierarchySearch.CategoryDescription="";
    objVMHierarchySearch.BuId=0;
    objVMHierarchySearch.CategoryId=0;
    objVMHierarchySearch.SubBuId=0;
    objVMHierarchySearch.RegionId=0;
    objVMHierarchySearch.SiteId=0;
    objVMHierarchySearch.SubSiteId=0;
    objVMHierarchySearch.CrewId=0;
    objVMHierarchySearch.DepartmentId=0;
    objVMHierarchySearch.SruId=0;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch)); 
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Category[]>(this.getAllCategoryURL,{params:params});
  }

  //Get All the BU Details
  getAllBUByRole() {        
    return this._http.get<Category[]>(this.getAllCategoryByRoleURL);
  } 
  

  //Add Category
  addCategory(category:Category) {
    this._helperService._categories = [];
    return this._http.post(this.getAllCategoryURL, category,{observe: 'response'});
   }
 
   // update Category
   editCategory (Category:Category) { 
    this._helperService._categories = [];
     return this._http.put(this.getAllCategoryURL, Category,{observe: 'response'});
   }

  
  searchCategory ( CategoryName,isActive, CategoryDesription) {  
    let objVMHierarchySearch=new HierarchySearchViewModel();
    
    objVMHierarchySearch.CategoryName= CategoryName;
    objVMHierarchySearch.CategoryDescription= CategoryDesription;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    objVMHierarchySearch.Active = isActive;
    
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Category[]>(this.getAllCategoryURL, {params: params});      
  }

  searchCategoryForDashboard() {  
    let languageId = localStorage.getItem("_languageID");
    return this._http.get<Category[]>(this.getAllCategoryDashboardURL + "/" + languageId);      
  }

  //Add Update BU Translations
  AddUpdateBUTranslations(bu: Category) {
    return this._http.post(this.addUpdateTranslationURL, bu, { observe: 'response' });
  }
  GetCategoryBasedOnPermit ( regionId,siteId) {  
    let languageId = localStorage.getItem("_languageID");
     return this._http.get<Category[]>(this.getAllCategoryURL +"/"+regionId+"/"+siteId+"/"+languageId);
      
  }
}
