import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ChangeLog } from '../ViewModel/Dashboard/ChangeLog';

@Injectable({
  providedIn: 'root'
})
export class ChangelogService {

  private changeLogURL=environment.WEBAPIURL + 'change-log'
  private EntryLeaderChangeLogURL=environment.WEBAPIURL + 'change-log-leader'
  constructor(private _http:HttpClient) { }

  GetChangeLogDeatils(permitId, languageId) {
    return this._http.get<ChangeLog[]>(this.changeLogURL + '/' + permitId + '/' + languageId);
  }
  GetEntryLeaderChangeLogDeatils(permitId) {
    return this._http.get<ChangeLog[]>(this.EntryLeaderChangeLogURL +'/'+ permitId);
  }

  //Add new Change Log
  addChangeLog(changeLog:ChangeLog) {
    return this._http.post(this.changeLogURL, changeLog, { observe: 'response' });
  }

  //Update Change Log
  editChangeLog(changeLog:ChangeLog) {
    return this._http.put(this.changeLogURL, changeLog, { observe: 'response' });
  }
}
