import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode, MessageService, LazyLoadEvent } from 'primeng/api';
import { Asset, ExtAssetResponse, AssetDetails, ParentAsset } from '../ViewModel/VMAsset';
import { PermitService } from '../Services/permit.service';

@Component({
  selector: 'app-asset-tree',
  templateUrl: './asset-tree.component.html',
  styleUrls: ['./asset-tree.component.css'],
  providers: [PermitService, MessageService]
})
export class AssetTreeComponent implements OnInit {
  _node: TreeNode;
  _asset: Asset[] = [];
  _files: TreeNode[];
  cols: any[];
  totalRecords: number;
  loading: boolean;
  selectedNode: string;
  selectedNode2: TreeNode;
  _basicAsset: Asset[];
  _searchText: string = '';
  _selectedFiles: TreeNode[] = [];
  _hierarchyNode: TreeNode[] = [];
  _selectedAssetNode: TreeNode;
  _selectedAsset: Asset;
  values: any[];
  isActive=true;
  assetSearchResults:AssetDetails[]=[];
  first = 0;
  gridRowCount=14;
  isAssetReadyToLoad: boolean = false;
  overAllSelectedAsset: Asset;

  @Input() parentData: any;
  @Input() SiteId: number;
  assetDetails:AssetDetails[]=[];
  isMinLengthInvalid:boolean=false;
  @Output() ObjSelectedAsset = new EventEmitter<Asset>();
  constructor(private _permitService: PermitService, private _messageService: MessageService) { }

  ngOnInit() {
    //let siteId = this.SiteId;
    //this.loadNodes(siteId);
  }

  loadBaicAssetNode(){
    this.resetPopUp();
    this.loadNodes(this.SiteId);
  }

  ResetsearchAsset()
  {
    this._files=[];
    this._asset=[];
    this._searchText="";
    this.first=0;
    let siteId = this.SiteId;
    this.loadNodes(siteId);
    this.isMinLengthInvalid=false;
  }
  expandRecursive(node: TreeNode, isExpand: boolean, assetNo: string) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        if (node.data.AssetNumber == assetNo) {
          this._selectedAssetNode = node;
          isExpand = false;

        }
        this.expandRecursive(childNode, isExpand, assetNo);
      });
    } 
    else {
      if (node.data.AssetNumber == assetNo) {
        this._selectedAssetNode = node;
        isExpand = false;

      }
    }
  }

  expandAll(assetNo) {
    this._files.forEach(node => {
      this.expandRecursive(node, true, assetNo);
    });
  }

  resetPopUp(){
    this._searchText = '';
    this._asset = [];
    this.overAllSelectedAsset = null;
  }

  loadNodes(siteId) {
    this.loading = true;
    this._permitService.getNonCSEParentAsset(siteId.toString()).subscribe(
      (data: ParentAsset) => {
        let assetArray: Asset[] = [];
        if (data && data.ParentAssetList.length > 0) {
          data.ParentAssetList.forEach(x => {
            if (x.AssetNumber && x.Description) {
              let asset: Asset = new Asset();
              asset.AssetNumber = x.AssetNumber;
              asset.AssetDescription = x.Description;
              assetArray.push(asset);
            }
          })
          this.totalRecords = assetArray.length;
        }
        this._basicAsset = [];
        this._basicAsset = assetArray;
        this._files = [];
        this._files = this.populateTreeNodeData(assetArray);
        this.loading = false;
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: 'Error Message', detail: 'Error while getting asset details' });
      }
    );
  }

  keyDownFunction(event) {
    this.isMinLengthInvalid=false;
    if (event.keyCode == 13) {
      if (this.first == 0)
        this.first = this.first + 1;
      this.searchAsset(this.first,this.gridRowCount);
    }
  }

  populateTreeNodeData(primaryAssets: Asset[]): TreeNode[] {
    let assetTreeNodes: TreeNode[] = [];
    for (let i = 0; i < primaryAssets.length; i++) {
      assetTreeNodes.push(this.assetToTreeNode(primaryAssets[i]));
    }
    return assetTreeNodes;
  }

  private assetToTreeNode(asset: Asset): TreeNode {
    return {
      label: asset.AssetNumber + ' - ' + asset.AssetDescription,
      data: asset,
      leaf: false
    }
  }

  nodeSelect(event) {
    let asset: Asset = new Asset();
    let assetList: Asset[] = [];
    if (event && event.node && event.node.data) {
      asset = event.node.data;
      assetList.push(asset);
      this.overAllSelectedAsset = asset;
      this._selectedAsset = new Asset();
    }    
    this._asset = [];
    this._asset = assetList;
    this.isActive= true;
    this.totalRecords=this._asset.length;
    this.isMinLengthInvalid=false;
  }

  onTableRowSelect(event) {
    let asset: Asset = new Asset();
    let assetList: Asset[] = [];
    if (event && event.data) { 
      asset = event.data;
      assetList.push(asset);
      this.overAllSelectedAsset = asset;
      this._selectedAssetNode = null;
    }
  }

  onNodeExpand(event) {
    this.loading = true;
    const node = event.node;
    this.isMinLengthInvalid = false;
    let siteId = this.SiteId;
    this._permitService.getNonCSEChildAsset(siteId.toString(), node.data.AssetNumber).subscribe(
      (data: AssetDetails[]) => {
        let assetArray: Asset[] = [];
        data.forEach(x => {
          let asset: Asset = new Asset();
          asset.AssetNumber = x.AssetNumber;
          asset.AssetDescription = x.Description;
          asset.Maintainable = x.IsMaintainable
          assetArray.push(asset);
        });
        node.children = this.populateTreeNodeData(assetArray);
        this._files = [...this._files];
        this.loading = false;
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: 'Error Message', detail: 'Error while searching Asset' });
      }
    );
  }

  searchAsset(pageIndex: number, pageSize: number, calledFromSearch: boolean = false) {
    this.isAssetReadyToLoad = true;
    if (this._searchText.length < 3 && this._searchText.length > 0) {
      this.isMinLengthInvalid = true;
      return false;
    }

    this.loading = true;
    let siteId = this.SiteId;
    if (calledFromSearch && pageIndex == 0) {
      pageIndex = pageIndex + 1;
    }
    this._permitService.getNonCSEAssetSearch(this._searchText, siteId.toString(), pageIndex.toString(), pageSize.toString()).subscribe(
      (data: ExtAssetResponse) => {
        let assetArray: Asset[] = [];
        if (data.SearchResult) {
          data.SearchResult.forEach(x => {
            let asset: Asset = new Asset();
            asset.AssetNumber = x.AssetNumber;
            asset.AssetDescription = x.Description;
            asset.Maintainable = x.IsMaintainable
            assetArray.push(asset);
          });
          this.totalRecords = data.ResultCount;
          this._asset = [];
          this._asset = assetArray;
        }
        this.loading = false;
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: 'Error Message', detail: 'Error while searching Asset' });
      }
    );
  }

  onRowSelect(objAsset) {
    let assetNo = objAsset.data.AssetNumber;
    let siteId = this.SiteId;
    this.isActive=objAsset.data.Maintainable;
  }

  onOKError()
  {    
    this._messageService.clear('error');   
  }

  OnSelectAssetClick() {
    this.ObjSelectedAsset.emit(this.overAllSelectedAsset);
    return true;
    // if (this._selectedAsset) {
    //   this.ObjSelectedAsset.emit(this._selectedAsset);
    //   return true;
    // }

    // if (this._asset.length > 0) {
    //   this.ObjSelectedAsset.emit(this._asset[0]);
    //   return true;
    // }
  }

  loadSearchResults(event: LazyLoadEvent)
  {
    if(this.isAssetReadyToLoad){
      this.searchAsset(event.first, this.gridRowCount);
    }      
  }
}

