import { UpdateStamp } from "./UpdateStamp";

export class PermitOperationSequence extends UpdateStamp{
    PermitOperationSequenceId: number;
    AssetWorkOrderID: number;
    OperationSequence: string;
    OperationDesc: string;
    StartDate:Date;
    CompletionDate: Date;
    ConvertedStartDate:string;
    ConvertedCompletionDate:string;
}