import { Component, OnChanges, OnDestroy, enableProdMode, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Spinkit, SpinnerVisibilityService } from 'ng-http-loader';
import { MenuService } from './Services/menu.service';
import { Menu } from './ViewModel/Menu';
import { Router, RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { HierarchyTree } from 'src/app/ViewModel/HierarchyTree';
import { User } from './ViewModel/User';
import { UserService } from './Services/user.service';
import { MessageService } from 'primeng/api';
import { CommonService } from './Services/common.service';
import { HelperService } from './Services/helper.service';
import { Languages } from './ViewModel/Languages';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { HierarchySearchViewModel } from './ViewModel/HierarchySearchViewModel';
import { PermitTypeService } from './Services/permit-type.service';
import { PermitTypeViewModel } from './ViewModel/PermitBuilder/PermitType';
import { interval, Subscription, Observable, timer, Subject } from 'rxjs';
// import { map } from 'rxjs/operators'
import { MsalBroadcastService, MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
// import { Client } from "@microsoft/microsoft-graph-client";
// import * as jwt_decode from "jwt-decode";
import { DashboardViewModel } from './ViewModel/Dashboard/DashboardViewModel';
import { DashboardService } from './Services/dashboard.service';
import { MediaService } from './Services/media.service';
// import { HttpResponse } from '@angular/common/http';
import { Attachment } from './ViewModel/Attachment';
// import { url } from 'inspector';
import { environment } from '../environments/environment';
//import { SignalRService } from './Services/signal-r.service';
import { NotificationService } from './Services/notification.service';
// import { BU } from './ViewModel/BU';
import { InitializeAppViewModel } from './ViewModel/InitializeAppViewModel';
import { InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { PendoService } from './Services/pendo.service';

if (environment.production) {
  enableProdMode();
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MenuService, MsalBroadcastService, MsalService, PendoService]
})
export class AppComponent implements OnChanges, OnDestroy {
  csePermitTypeId: number;
  refreshInterval$: Observable<number> = timer(3420000);

  ngOnDestroy() {
    this._destroying$.next(null);
    this._destroying$.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.timerSubscriptions) {
      this.timerSubscriptions.unsubscribe();
    }
  }

  public spinkit = Spinkit;
  title = '';
  local = '';
  currenturl: string = "";
  //console.log(window.innerHeight)
  containerHeight = window.innerHeight - 64;
  containerSideBarHeight = window.innerHeight - 87;
  isSideNavCollapsed: boolean = true;
  isAuthorizedUser: boolean = false;
  _menus: Menu[] = [];
  currentToken: string = '';
  treeValue: HierarchyTree;
  nodeValueFromParent: HierarchyTree;
  selectedHierarchyName = '';
  showHierarchy: boolean = true;
  isMenuVisible: boolean = false;
  userImage: any;
  _profileInitials: string = '';
  _userid = 0;
  _ObjArrUserTypeAttachment = new Attachment();

  // To check if user has changed the default language
  // Has been initialized with 1 since the default language id is English
  _userLanguageBeforeUpdate: number = 1;

  userImageFile: any = "";
  //_languagesId: number;
  _languages: Languages[] = [];
  _languageId: number;
  _langId: string;
  _permitTypeId: number = 0;
  sub: any;
  loggedinUserName: string = '';
  loggedInUser: User;
  loggedIn: boolean;
  isTokenSet: boolean;
  //user: User
  _users: User[] = [];
  _permitType: PermitTypeViewModel;
  _permitTypeName: string;
  _allMenuItems: Menu[] = [];
  home: { icon: string; };
  _dashboardList: DashboardViewModel[] = [];
  notificationCount: string = '0';
  _userGroups: string;
  _supAdmin: boolean = false;
  _userAdmin: boolean = false;
  _admin: boolean = false;
  // Authentication
  private subscription: Subscription;
  private timerSubscriptions: Subscription;
  public authenticated: boolean = false;
  isExistingUser: boolean = false;
  public user: User;
  public access_token: string;
  private isLoadingCompleted = false;
  private isSubscribed: boolean = false;
  public isIframe: boolean;
  public launcherExpand: boolean = false;
  bgFullScreenImage: boolean = false;
  bgFullScreenGrey: boolean = false;
  request = {
    //scopes: environment.consentScopes
    scopes: environment.apiScope
  };
  CheckLoadFlg = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private translate: TranslateService, private _menuService: MenuService, private _mediaService: MediaService,
    _spinner: SpinnerVisibilityService, private router: Router, private _userService: UserService, private _messageService: MessageService, private _commonService: CommonService,
    private _permitTypeService: PermitTypeService, private _helperService: HelperService, private titleService: Title, private broadcastService: MsalBroadcastService, private sanitizer: DomSanitizer,
    private authService: MsalService, private _dashBoardService: DashboardService,  private _notificationService: NotificationService, private pendoService: PendoService) {
    // if (!(this.authService.instance.getAllAccounts().length>0)) {
    //   this.authService.loginRedirect(this.request);
    // }
    this.isIframe = window !== window.parent && !window.opener;
    translate.setDefaultLang('en');
    localStorage.setItem("_page_load", "initiated");
    router.events.subscribe(
      (event: RouterEvent): void => {
        if (event instanceof RouteConfigLoadStart) {
          _spinner.show();
        }
        else if (event instanceof NavigationEnd) {
          if (localStorage.getItem("_languageID")) {
          }
        }
        else if (event instanceof RouteConfigLoadEnd) {
          _spinner.hide();
        }
        if (event instanceof NavigationEnd) {
          this.setBackgroundByRoute();
        }
      }
    );
  }

  acquireSilentToken() {
    this.authService.acquireTokenSilent(this.request)
      .subscribe((response) => {
        this.authenticated = true;
        this.GetUserDetails();
        this.getUser();
      }
        , error => {
          // could also check if err instance of InteractionRequiredAuthError if you can import the class.
          if (error.name === "InteractionRequiredAuthError") {
            return this.authService.acquireTokenPopup(this.request)
              .subscribe((resp) => {
                // get access token from response
                this.authenticated = true;
                this.GetUserDetails();
                this.getUser();
              }
                , err => {
                  // handle error
                  return this.authService.loginRedirect(this.request);
                });
          }
          else {
            return this.authService.loginRedirect(this.request);
          }
        });
  }

  ngOnInit() {
    //Set Default Language
    // Redirect if URL is with /Code
    this.router.events.pipe(filter((event: any) => event instanceof NavigationStart)).subscribe((data: NavigationStart) => {
      if (data.url === '/code') {
        this.router.navigate(['/home']);
      }
    });
    this.translate.use('en');
    // Getting current URL name    
    localStorage.setItem("_hierarchtTree", null);
    this.currenturl = window.location.href;
    if (!this.currenturl.includes("home") && !this.currenturl.toLocaleLowerCase().includes("admin")) {
      if (!this.currenturl.includes("access_token")) {
        if (!this.currenturl.includes("id_token")) {
          if (!localStorage.getItem("loginRequestUrl")) {
            localStorage.setItem("loginRequestUrl", this.currenturl);
          }
        }
      }
    }
    this.authService.instance.handleRedirectPromise()
      .then((tokenResponse) => {
        if (!tokenResponse) {
          const accounts = this.authService.instance.getAllAccounts();
          if (accounts.length === 0) {
            // Waiting for interaction to be completed
            this.broadcastService.inProgress$
              .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
              )
              .subscribe(() => {
                this.authService.loginRedirect(this.request);
              });
          }
        } else {
          this.loggedIn = true;
          this.isTokenSet = true;
        }
      })
      .catch(err => {
        console.error(err);
      });

    if (this.authService.instance.getAllAccounts().length > 0) {
      let activeAccount = this.authService.instance.getActiveAccount();

      if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
        let accounts = this.authService.instance.getAllAccounts();
        this.authService.instance.setActiveAccount(accounts[0]);
        activeAccount = this.authService.instance.getActiveAccount();
      }
    }
    else {
      this.broadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
        )
        .subscribe(() => {
          this.authService.loginRedirect(this.request);
        });
    }

    if (this.authService.instance.getAllAccounts().length > 0) {
      let activeAccount = this.authService.instance.getActiveAccount();
      let gUsername = activeAccount.username.replace(/@.*$/, "");
      console.log("this.authService.getAccount() - gUsername: " + gUsername);
      let gSamAccountName = activeAccount.idTokenClaims.samAccountName;
      console.log("this.authService.getAccount() - gSamAccountName: " + gSamAccountName);
      let loginId = this.getActualUserId(gUsername, gSamAccountName);
      console.log("this.authService.getAccount() - loginId: " + loginId);
      //let loginId = this.authService.getAccount().userName.replace(/@.*$/, "");
      let username = activeAccount.name.replace(/ *\([^)]*\) */g, "");
      let email = activeAccount.username;
      // let sid_tok = activeAccount.sid;
      localStorage.setItem("_loginId", loginId);
      localStorage.setItem("_userName", username);
      localStorage.setItem("_emailId", email);
      localStorage.setItem("_gUsername", gUsername);
      // localStorage.setItem("_sid", sid_tok);      
      localStorage.setItem("_notificationCount", '');
      this.loggedinUserName = username;
      this.loggedIn = true;
      this.isTokenSet = true;
      this.CheckLoadFlg = true;
      this.acquireSilentToken();
      //This one is added to solve not able login from ipad or external Alcoa Network

      //Pendo Initialization
      this.pendoService.Pendoinitialize();


    } else {
      // user is not logged in, you will need to log them in to acquire a token
      // this.authService.loginRedirect(this.request);
    }

    this._helperService.notificationCountEvent.subscribe((data: string) => {
      if (data != '') {
        this.notificationCount = data;
      }
    });

    this.Getlocal();
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    //this.GetBreadcrumbsMenu();
  }



  GetBreadcrumbsMenu() {
    let routeArray = this.router.url.split('/');

    if (this.router.url.indexOf('/permit/') <= 0) {
      this._menuService.breadcrumbMenu = [];
      this._menuService.breadcrumbMenu.push({ icon: 'pi pi-home', routerLink: ['/'] });
      for (var routeIndex = 0; routeIndex < routeArray.length; routeIndex++) {

        if (routeArray[routeIndex] != "") {

          if (routeArray[1] == "manage-dashboard") {
            this.showHierarchy = false;
            this._menuService.breadcrumbMenu.push({ label: this.GetBreadCrumMapping(routeArray[routeIndex]), routerLink: ['/dashboard'] });
          }

          // this.breadcrumbMenu.push({ label: routeArray[routeIndex], routerLink: [routeArray[routeIndex]] });
          else if (routeArray[routeIndex] == "permit") {
            this._menuService.breadcrumbMenu.push({ label: this.GetBreadCrumMapping(routeArray[routeIndex]), routerLink: ['/createpermit'] });
            break;
          }

          else if (routeArray[routeIndex] == "permitmanagement") {
            this._menuService.breadcrumbMenu.push({ label: "Permit Management", routerLink: ['/permitmanagement'] });
            break;
          }
          else {
            this._menuService.breadcrumbMenu.push({ label: this.GetBreadCrumMapping(routeArray[routeIndex]), routerLink: [routeArray[routeIndex]] });
          }

        }
      }
    }
    else {
      return "";
    }
  }

  async getAccessToken() {
    let result = await this.authService.acquireTokenSilent(this.request)
      .subscribe((reason) => {
      });
    return result;
  }

  private async getUser() {
    let groups = JSON.parse('{"groupIds": ["' + environment.WPSSUPADM + '","' + environment.WPSUSRADM + '"]}');

    this._commonService.getUserGroups(groups).subscribe((res: any) => {
      if (res) {
        let memberGroups = (res.body.value);
        localStorage.setItem("supAdmin", '');
        localStorage.setItem("usrAdmin", '');
        memberGroups.forEach(element => {

          //check for super admin    
          if (element === environment.WPSSUPADM) {
            this._admin = true;
            localStorage.setItem("supAdmin", environment.WPSSUPADM);
          }
          if (element === environment.WPSUSRADM) {
            this._admin = true;
            localStorage.setItem("usrAdmin", environment.WPSUSRADM);
          }
        });
        localStorage.setItem("roleSet", "Y");
      }
      else { localStorage.setItem("roleSet", "N"); }
    })
  }

  GetBreadCrumMapping(routeIndexValue: string): string {
    let item;
    item = this._allMenuItems.find(x => x.routerLink.toLocaleLowerCase() == routeIndexValue.toLocaleLowerCase())
    if (item != undefined) {
      return item == null ? '' : item.MenuName;
    }
  }

  ngDoCheck() {
    this.csePermitTypeId = environment.CSE
    this.showHierarchy = this.ToggleHierarchyMenu();
    this.isMenuVisible = this.ShowSideMenuIcon();
    //console.log(this.isSideNavCollapsed, '##' , this.isMenuVisible);
    //this.showHierarchy = this.router.url.startsWith('/user-self-service') ? false : true;
  }


  ToggleHierarchyMenu(): boolean {
    if (this.router.url.startsWith('/admin/')) {
      this.setActive(document.getElementById('Admin'));
      return false;
    }
    else if (this.router.url.startsWith('/user-self-service')) {
      this.setActive(document.getElementById('SelfUser'));
      return false;
    }
    else if (this.router.url.startsWith('/permit')) {
      return false;
    }
    else if (this.router.url.includes('/manage-dashboard/')) {
      return false;
    }
    else {
      this.setActive(null);
      return true;
    }
  }

  RedirectToHome() {
    this.router.navigate(['/home'])
  }

  logout() {
    this.authService.logout();
  }

  toggleSidebar() {
    if (this.isSideNavCollapsed) {
      this.isSideNavCollapsed = false;
    }
    else {
      this.isSideNavCollapsed = true;
    }
  }
  Getlocal() {
    let instance = environment.INSTANCE;
    this.local = instance ? instance : "Unknown";
  }

  getPermiTypeDetails(permitTypeId) {
    let hierarchySearch = new HierarchySearchViewModel();
    hierarchySearch.PermitTypeId = permitTypeId;
    this._permitTypeService.getPermitTypes(hierarchySearch).subscribe(permitType => {
      this._permitType = permitType.find(p => p.PermitType.PermitTypeId == permitTypeId);
      if (this._permitType != undefined) {
        this._permitTypeName = this._permitType.PermitType.PermitTypeName
        this._permitTypeId = this._permitType.PermitType.PermitTypeId;
      }
    })
  }

  ExpandSideMenu() {
    let matchingurl = '/manage-dashboard/' + this.csePermitTypeId
    if (this.router.url.startsWith(matchingurl) || this.router.url.startsWith('/entryleader') || this.router.url.startsWith('/firecrew') || this.router.url.startsWith('/viewregister')) {
      this.isSideNavCollapsed = false;
    }
    else {
      this.isSideNavCollapsed = true;
    }
  }

  ShowSideMenuIcon() {
    let matchingurl = '/manage-dashboard/' + this.csePermitTypeId
    if (this.router.url.startsWith(matchingurl) || this.router.url.startsWith('/entryleader') || this.router.url.startsWith('/firecrew') || this.router.url.startsWith('/viewregister')) {
      return true;
    }
    return false;
  }

  GetAdminMenu() {

    this._menuService.getleftMenu(localStorage.getItem("_languageID")).subscribe(result => {
      this._menus = [];
      this._menus = result;
      this._menus.forEach(menu => {
        menu.routerLink = menu.routerLink.toLocaleLowerCase()
      });
    })
  }

  receiveNode($event) {
    this.treeValue = $event;
    this.selectedHierarchyName = this.getSelectedHierarchyNameOnTreeSelect();
    this._helperService._userCurrentHierarchy = this.treeValue;
    this._commonService.setUserHierarchy(this.treeValue);

    let user = new User();
    user.BUId = Number(this.treeValue.BuId);
    user.BUName = this.treeValue.BUName
    user.RegionId = Number(this.treeValue.RegionId);
    user.RegionName = this.treeValue.RegionName;
    user.SubBUId = Number(this.treeValue.SubBuId);
    user.SubBUName = this.treeValue.SubBUName;
    user.SiteId = Number(this.treeValue.SiteId);
    user.SiteName = this.treeValue.SiteName
    user.SubSiteId = Number(this.treeValue.SubSiteId);
    user.SubSiteName = this.treeValue.SubSiteName
    user.SRUId = Number(this.treeValue.SubSiteId);
    user.SRUName = this.treeValue.SRUName
    user.DepartmentId = Number(this.treeValue.DepartmentId);
    user.DeptName = this.treeValue.DeptName
    user.CrewId = Number(this.treeValue.CrewId);
    user.CrewName = this.treeValue.CrewName;
    user.LoginId = this.lID;
    user.UserId = this.uID;


    this._userService.getUserByLoginId(this.lID).subscribe(res => {
      user.FirstName = res.FirstName;
      user.LastName = res.LastName;;
      user.MiddleName = res.MiddleName;
      user.EmailAddress = res.EmailAddress;
      user.UserRoles = res.UserRoles
      user.CountryCode = res.CountryCode;
      user.MobileNumber = res.MobileNumber
      user.PayrollNo = res.PayrollNo
      user.EBICardNumber = res.EBICardNumber
      user.Active = res.Active;
      user.CreatedDate = res.CreatedDate
      user.CreatedBy = res.CreatedBy;
      user.ModifiedDate = res.ModifiedDate;
      user.ModifiedBy = res.ModifiedBy;
      user.ContractorId = res.ContractorId;
      user.LanguageId = res.LanguageId;

      this._userService.editUsers(user).subscribe(res => {
        if (res.status === 200)
          console.log('ok')
      },
        err => {
          console.log(err)
        })
    })
  }
  onTreeLoad() {
    if (localStorage.getItem("_loginId") && this.loggedIn && localStorage.getItem("_AccessToken")) {
      this.GetUserDetails();
    }
  }
  getSelectedHierarchyNameOnTreeSelect(): string {
    let val = '';
    // if (this.treeValue.BUName != '') {
    //   val = val + ' ' + this.treeValue.BUName + ' >'
    // }
    if (this.treeValue.RegionName != '') {
      val = val + ' ' + this.treeValue.RegionName + ' >'
    }
    if (this.treeValue.SubBUName != '') {
      val = val + ' ' + this.treeValue.SubBUName + ' >'
    }
    if (this.treeValue.SiteName != '') {
      val = val + ' ' + this.treeValue.SiteName + ' >'
    }
    if (this.treeValue.SubSiteName != '') {
      val = val + ' ' + this.treeValue.SubSiteName + ' >'
    }
    if (this.treeValue.SRUName != '') {
      val = val + ' ' + this.treeValue.SRUName + ' >'
    }
    if (this.treeValue.DeptName != '') {
      val = val + ' ' + this.treeValue.DeptName + ' >'
    }
    if (this.treeValue.CrewName != '') {
      val = val + ' ' + this.treeValue.CrewName + ' >'
    }
    if (val.lastIndexOf('>') == val.length - 1) {
      val = val.substring(0, val.length - 1).toUpperCase();
    }
    return val;
  }

  //loggedUserId: string
  GetNotificationCount(nCount: string) {
    this.notificationCount = nCount;
    this._helperService._notificationCount = +nCount;
  }

  EscapeSpecialCharacters(input: string): string {
    let inputText = input;
    let specialCharArray = [
      { 'Character': '!', 'Code': 'U0021' },
      { 'Character': '#', 'Code': 'U0023' },
      { 'Character': '$', 'Code': 'U0024' },
      { 'Character': '%', 'Code': 'U0025' },
      { 'Character': '&', 'Code': 'U0026' },
      { 'Character': '(', 'Code': 'U0028' },
      { 'Character': ')', 'Code': 'U0029' },
      { 'Character': '*', 'Code': 'U002A' },
      { 'Character': '+', 'Code': 'U002B' },
      { 'Character': '-', 'Code': 'U002D' },
      { 'Character': '/', 'Code': 'U002F' },
      { 'Character': '?', 'Code': 'U003F' },
      { 'Character': '@', 'Code': 'U0040' },
      { 'Character': '\\', 'Code': 'U005C' },
      { 'Character': '=', 'Code': 'U003D' },
      { 'Character': '_', 'Code': 'U005F' }
    ]

    let charArray = Array.from(inputText);
    let stringArray = [];
    for (let objChar of charArray) {
      let obj = specialCharArray.find(x => x.Character == objChar);
      stringArray.push(obj == undefined ? objChar : obj.Code);
    }

    inputText = stringArray.join('');

    return inputText;
  }

  getUserImage(azurefileid: number) {
    try {
      this._commonService.getUserProfileImage().subscribe((response: Blob) => {
        this.blobToBase64(response).then(res => {
          this.userImage = res;
        });
      }, error => {
        console.log(error);
        console.log('No user image presents in Microsoft Graph !')
        this.userImage = null;
        let username = localStorage.getItem("_userName").split(',');
        if (username.length == 1) {
          let obj = username[0].trim();
          if (obj.length > 1) {
            this._profileInitials = obj.substring(0, 2);
          }
        }
        else if (username.length > 1) {
          let ob1 = username[0].trim();
          let ob2 = username[1].trim();
          if (ob1 && ob2) {
            this._profileInitials = ob2[0] + ob1[0];
          }
        }
      });
    }
    catch (ex) {
      console.log('Error while getting images from Microsoft Graph !');
      this.userImage = null;
      let username = localStorage.getItem("_userName").split(',');
      if (username.length == 1) {
        let obj = username[0].trim();
        if (obj.length > 1) {
          this._profileInitials = obj.substring(0, 2);
        }
      }
      else if (username.length > 1) {
        let ob1 = username[0].trim();
        let ob2 = username[1].trim();
        if (ob1 && ob2) {
          this._profileInitials = ob2[0] + ob1[0];
        }
      }
    }
  }

  blobToBase64(blob): Promise<any> {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  getLocalStorage() {
    if (localStorage.getItem('_languageID') && this._helperService._treeData.length > 0) {
      return true;
    }
    return false;
  }

  uID: number = 0;
  lID: string = "";
  GetUserDetails() {
    let userLoginId: string;
    userLoginId = localStorage.getItem("_loginId");
    this.getUserImage(0);
    //console.log("_loginId: " + userLoginId);
    // Must Remove before check in
    // userLoginId = 'xxxxfoc'
    // this._userService.getUserByLoginId(userLoginId).subscribe((data: User) => {
    this._commonService.getInitializationDetails(userLoginId).subscribe((data: InitializeAppViewModel) => {
      this.CheckLoadFlg = true;
      this.loggedInUser = data.User;
      this._helperService._loggedInUser = this.loggedInUser;
      this.uID = data.User ? data.User.UserId : 0;
      this.lID = data.User ? data.User.LoginId : "";

      if (this.loggedInUser && this.loggedInUser != undefined && this.loggedInUser != null) {
        this._languageId = this.loggedInUser.LanguageId;
        localStorage.setItem("LanguageCode", this.loggedInUser.Languages.Code);
        localStorage.setItem("_languageID", this._languageId.toString());
        this.setUserHierarchy(this.loggedInUser);
        //this.getUserImage(0);
        if (this.loggedInUser.Languages.Code == 'en') {
          this.setTitle('My Permits');
          this.title = this.titleService.getTitle();
        }
        if (this.loggedInUser.Languages.Code == 'pt') {
          this.setTitle('Sistema de Permissão');
          this.title = this.titleService.getTitle();
        }
        if (this.loggedInUser.Languages.Code == 'fr') {
          this.setTitle('Mes Permis');
          this.title = this.titleService.getTitle();
        }
        if (this.loggedInUser.Languages.Code == 'es-ES') {
          this.setTitle('Sistema de permisos');
          this.title = this.titleService.getTitle();
        }
        if (this.loggedInUser.Languages.Code == 'is') {
          this.setTitle('Permit System');

          this.title = this.titleService.getTitle();
        }
        if (this.loggedInUser.Languages.Code == 'nb') {
          this.setTitle('Permit System');

          this.title = this.titleService.getTitle();
        }
        localStorage.setItem("_page_load", "completed");
        this.translate.use(this.loggedInUser.Languages.Code);
        this._commonService.setSelfServiceData(this.loggedInUser);
        this.isExistingUser = true;
        if (localStorage.getItem("_loginId")) {
          //this.GetNotificationCount(userLoginId);
          this.GetNotificationCount(data.NotificationCount);
        }

        this._helperService._treeData = [];
        this._helperService._treeData = data.BU;
        localStorage.setItem("_hierarchtTree", JSON.stringify(data.BU));

        if (this._helperService._languages.length == 0) {

          this._helperService._languages = [];
          this._helperService._languages = data.Languages.sort((a, b) => a.LanguageId - b.LanguageId);
        }
        let redirectUrl = localStorage.getItem("loginRequestUrl");
        if (redirectUrl) {
          localStorage.removeItem("loginRequestUrl")
          if (!redirectUrl.endsWith("home")) {
            this.router.navigate([redirectUrl.replace(window.location.origin, "")]);
          }
        }
      }

      else {
        localStorage.setItem("_page_load", "completed");
        localStorage.setItem("_languageID", "1");
        localStorage.setItem("LanguageCode", 'en');
        this.translate.use('en');
        this.setTitle('Permit System');
        this.title = this.titleService.getTitle();
        this.isExistingUser = false;
        if (this._helperService._languages.length == 0) {

          this._helperService._languages = [];
          this._helperService._languages = data.Languages.sort((a, b) => a.LanguageId - b.LanguageId);
          this.router.navigate(['/user-self-service']);
        }
      }
    },
      (error) => {
        // window.location.href = "/home";
        console.log('Error while initializing app !');
        // this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.searchErrorUser') });
      });
    //}
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  setUserHierarchy(loggedInUser) {
    this.nodeValueFromParent = new HierarchyTree();
    this.nodeValueFromParent.BuId = loggedInUser.BUId;
    this.nodeValueFromParent.RegionId = loggedInUser.RegionId;
    this.nodeValueFromParent.SubBuId = loggedInUser.SubBUId;
    this.nodeValueFromParent.SiteId = loggedInUser.SiteId;
    this.nodeValueFromParent.SubSiteId = loggedInUser.SubSiteId;
    this.nodeValueFromParent.SruId = loggedInUser.SRUId;
    this.nodeValueFromParent.DepartmentId = loggedInUser.DepartmentId;
    this.nodeValueFromParent.CrewId = loggedInUser.CrewId;
    this.nodeValueFromParent.BUName = loggedInUser.BU.BUName;
    this.nodeValueFromParent.RegionName = loggedInUser.Region ? loggedInUser.Region.RegionName : null;
    this.nodeValueFromParent.SubBUName = loggedInUser.SubBU ? loggedInUser.SubBU.SubBUName : null;
    this.nodeValueFromParent.SiteName = loggedInUser.Site ? loggedInUser.Site.SiteName : null;
    this.nodeValueFromParent.SiteAbbreviation = loggedInUser.Site ? loggedInUser.Site.SiteAbbreviation : null
    this.nodeValueFromParent.SubSiteName = loggedInUser.SubSite ? loggedInUser.SubSite.SubSiteName : null;
    this.nodeValueFromParent.SRUName = loggedInUser.SRU ? loggedInUser.SRU.SRUName : null;
    this.nodeValueFromParent.DeptName = loggedInUser.Department ? loggedInUser.Department.DeptName : null;
    this.nodeValueFromParent.CrewName = loggedInUser.Crew ? loggedInUser.Crew.CrewName : null;
    this._commonService.setUserHierarchy(this.nodeValueFromParent);

    this.selectedHierarchyName = this.getLoggedInUserHierarchy(loggedInUser);
  }

  getLoggedInUserHierarchy(loggedInUser): string {
    let val = '';
    // if (loggedInUser.BU != undefined && loggedInUser.BU.BUName != '') {
    //   val = val + ' ' + loggedInUser.BU.BUName + ' >'
    // }
    if (loggedInUser.Region != undefined && loggedInUser.Region.RegionName != '') {
      val = val + ' ' + loggedInUser.Region.RegionName + ' >'
    }
    if (loggedInUser.SubBU != undefined && loggedInUser.SubBU.SubBUName != '') {
      val = val + ' ' + loggedInUser.SubBU.SubBUName + ' >'
    }
    if (loggedInUser.Site != undefined && loggedInUser.Site.SiteName != '') {
      val = val + ' ' + loggedInUser.Site.SiteName + ' >'
    }
    if (loggedInUser.SubSite != undefined && loggedInUser.SubSite.SubSiteName != '') {
      val = val + ' ' + loggedInUser.SubSite.SubSiteName + ' >'
    }
    if (loggedInUser.SRU != undefined && loggedInUser.SRU.SRUName != '') {
      val = val + ' ' + loggedInUser.SRU.SRUName + ' >'
    }
    if (loggedInUser.Department != undefined && loggedInUser.Department.DeptName != '') {
      val = val + ' ' + loggedInUser.Department.DeptName + ' >'
    }
    if (loggedInUser.Crew != undefined && loggedInUser.Crew.CrewName != '') {
      val = val + ' ' + loggedInUser.Crew.CrewName + ' >'
    }
    if (val.lastIndexOf('>') == val.length - 1) {
      val = val.substring(0, val.length - 1).toUpperCase();
    }
    return val;
  }

  hideTree(elementToHide, event, clickedElement, elTarget) {
    if (clickedElement.innerHTML.trim() != "") {
      elementToHide.hide(event, elTarget);
    }
  }

  setActive(menuItem) {
    let menuItems = (document.getElementsByClassName("clsMenuItem") as HTMLCollectionOf<HTMLAnchorElement>);
    for (var i = 0; i < menuItems.length; i++) {
      menuItems[i].style.backgroundColor = "#175887";
    }
    // if (menuItem != null) {
    //   // (menuItem as HTMLUListElement).style.backgroundColor = "#359FD9";
    // }

  }

  showLauncher() {
    this.launcherExpand = true;
  }

  hideLauncher() {
    this.launcherExpand = false;
  }

  featureNotAvailableMessage(imgRef) {
    this._messageService.clear('error');
    this._messageService.add({ key: 'info', sticky: true, severity: 'info', summary: this.translate.instant('infoMsgHeader'), detail: this.translate.instant('FeatureNotAvailable') });
    return false;
  }
  onOK() {
    this._messageService.clear('error');
    this._messageService.clear('success');
    this._messageService.clear('info');
  }

  setBackgroundByRoute() {
    this.bgFullScreenImage = false;
    this.bgFullScreenGrey = false;

    let route = this.router.url;
    let isPathContainsSpecials = route.match(/\/(.*?)\//);
    if (isPathContainsSpecials) {
      route = isPathContainsSpecials[1];
    }

    if (route.toLowerCase().includes("home") || route.toLowerCase().includes("notification")) {
      this.bgFullScreenImage = true;
    }
    else if (route.toLowerCase().includes("createpermit")) {
      this.bgFullScreenGrey = true;
    }
    else if (route.toLowerCase().includes("user-self-service")) {
      this.bgFullScreenGrey = true;
    }
  }
  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  getUniqueNameCheckSam(decodedData) {
    var uniqueName;
    if (decodedData.samAccountName == null) {
      uniqueName = decodedData.unique_name;
    }
    else {
      uniqueName = decodedData.samAccountName + "@alcoa.com";
    }
    return uniqueName;
  }

  getActualUserId(userName, samAccountName) {
    var uniqueName;
    if (samAccountName != null) {
      uniqueName = samAccountName;
    }
    else {
      uniqueName = userName;
    }
    return uniqueName;
  }
}
