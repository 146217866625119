import { BU } from './BU';
import { Region } from './Region';
import { SubBU } from './SubBU';
import { Site } from './Site';
import { SubSite } from './SubSite';
import { Attributes } from './Attributes';
import { HierarchyTranslation } from './HierarchyTranslation';
import { Department } from './Department';
import { Crew } from './Crew';


export class SRU {     
    BUName:string;
    BUId:number;
    RegionId: number;
    RegionName: string;
    SubBUId: number;
    SubBUName: string;
    SiteId:number;
    SiteName:String;
    SubSiteId:number;
    SubSiteName: string;
    SRUId: number;
    SRUName: string;
    Active: boolean;
    CreatedBy: string;
    CreatedDate: Date;
    ModifiedBy: string;
    ModifiedDate: Date;
    DefaultLanguageId:number;
    BU?: BU | null;
    Region?: Region | null;
    SubBU?: SubBU | null;
    Site?: Site | null;
    SubSite?: SubSite | null;
    Attributes?: Attributes[]|null;
    HierarchyTranslations?: HierarchyTranslation[] | null;
    Departments?:Department[]|null;
    Crews?:Crew[]|null;
}