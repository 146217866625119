import { ErrorHandler, Injectable, Injector, Component } from '@angular/core';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-error',
  template: '<p-toast position="top-right" life="5000" key="error" (onClose)="onOK()" [modal]="true" [baseZIndex]="6000">'+
            '<ng-template let-message pTemplate="message">'+
                '<div class="toast-header">'+
                    '<h5 style="font-weight:bold;text-indent:7px"> {{message.summary}}</h5>'+
                '</div>'+
                '<div style="text-align: center;">'+
                    '<h5>'+
                        '<i style="color: red; font-size: 28px !important;" class="fa fa-exclamation-triangle"'+
                            'aria-hidden="true"></i>'+
                        '{{message.detail}}'+
                    '</h5>'+
                '</div>'+
                '<div class="grid ui-fluid">'+
                    '<div class="col-12" style="text-align: center;">'+
                        '<button id="btnSaveData" class="btn btn-primary" (click)="onOK();" type="button"> OK </button>'+
                    '</div>'+
                '</div>'+
            '</ng-template>'+
          '</p-toast>',
  styles: [':host ::ng-deep .p-toast .p-toast-message {'+ 
          'border: 1px solid #165788 !important;'+ 
          'border-radius: 3px;'+ 
          'background: #f4f4f4 !important;'+ 
          '-moz-box-shadow: 3px 3px 12px #165788;'+ 
          '-webkit-box-shadow: 3px 3px 12px #165788;'+ 
          'box-shadow: 3px 3px 12px #165788;'+ 
        '}'+ 
        ':host ::ng-deep .p-toast .p-toast-message-error {'+
        'background-color: #f8b7bd !important;'+
    '}'+
        ':host ::ng-deep .p-toast .p-toast-message-content {'+ 
          'padding: 0px !important;'+ 
        '}'+ 
 
        ':host ::ng-deep .p-toast .p-toast-close-icon {'+ 
          'color:black !important;'+ 
          '/* margin-top: 10px; */'+ 
        '}'

        // '.toast-header{'+ 
          // 'background-color: #165788;'+ 
          // 'color: #FFF;'+ 
          // 'padding: 1px 1px 1px 10px !important;'+  
        // '}'
      
      ]

})

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector, private _messageService: MessageService) { }

  handleError(error) {
    if (error instanceof Error) {
      console.log(error.message);
      console.log(error);
      // this._messageService.clear('error');
      // this._messageService.add({key: 'error', sticky: true, severity:'error', summary: 'Error Occurred', detail: error.message });
    }
    else {
      throw error;
    }
    
  }

  onOK() {
    this._messageService.clear('error');
  }
}



