import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
// import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { BU } from 'src/app/ViewModel/BU';
import { Region } from 'src/app/ViewModel/Region';
import { SubBU } from 'src/app/ViewModel/SubBU';
import { Site } from 'src/app/ViewModel/Site';
import { SubSite } from 'src/app/ViewModel/SubSite';
import { SRU } from 'src/app/ViewModel/sru';
import { Department } from 'src/app/ViewModel/Department';
import { Crew } from 'src/app/ViewModel/Crew';
import { SubBUService } from 'src/app/Services/subbu.service';
import { CrewService } from 'src/app/Services/crew.service';
import { TranslateService } from '@ngx-translate/core';
import { DepartmentService } from 'src/app/Services/department.service';
import { SRUService } from 'src/app/Services/sru.service';
import { SubSiteService } from 'src/app/Services/subsite.service';
import { SiteService } from 'src/app/Services/site.service';
import { RegionService } from 'src/app/Services/region.service';
import { BUService } from 'src/app/Services/bu.service';
import { MessageService } from 'primeng/api';
import { HelperService } from 'src/app/Services/helper.service';
import { Subscription } from 'rxjs';
import { HierarchySearchViewModel } from '../../ViewModel/HierarchySearchViewModel';
import { ContractorService } from '../../Services/contractor.service';
import { Contractor } from '../../ViewModel/Contractor';
import { CategoryService } from '../../Services/category.service';
import { Category } from '../../ViewModel/Category';

@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.css'],

})
export class SearchPanelComponent implements OnInit, OnDestroy {
  //private httpSubscription: Subscription;
  
  ngOnDestroy(): void {   
    this._helperService.ddlBU=0;
    this._helperService.ddlRegion=0;
    this._helperService.ddlSubBU=0;
    this._helperService.ddlSite=0;
    this._helperService.ddlSubSite=0;
    this._helperService.ddlSru=0;
    this._helperService.ddlDept=0;
    this._helperService.ddlCrew=0;

    this._helperService._objArrRegions=[];
    this._helperService._objArrSubBU=[];
    this._helperService._objArrSite=[];
    this._helperService._objArrSubSite=[];
    this._helperService._objArrSRU=[];
    this._helperService._objArrDept=[];
    this._helperService._objArrCrew=[];
  }


  

  ddlBUFlag = false;
  _objArrContractors: Contractor[] = [];

  @Input() applyClearSearch:boolean;
  @Output() sendTreeValuesforBU = new EventEmitter<Contractor[]>();

  constructor(
    private contractorService:ContractorService,
    private categoryService:CategoryService,
    private _helperService:HelperService,
    private _subBUService: SubBUService, 
  private _crewService: CrewService, 
  private translate: TranslateService, 
  private _deptService: DepartmentService, 
  private _sruService: SRUService, 
  private _subSiteService: SubSiteService, 
  private _siteService: SiteService, 
  private _regionService: RegionService, 
  private _buService: BUService,
  private _messageService:MessageService ) { }

  ngOnInit() {
    this.getAllBU();
  }

  // Get All BU Details
  getAllBU(): void {
    this._buService.getAllBU().subscribe(
      (data: BU[]) => {
        this._helperService._objArrBU = data;
        this._helperService._objArrBU.sort((a, b) => a.BUName.localeCompare(b.BUName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorBU') });
      });
  }

  onSelectBU(event: any) {
    this._helperService.ddlBU > 0 ? (this.ddlBUFlag = false) : (this.ddlBUFlag = true)
    this._helperService.ddlRegion = 0;
    this._helperService.ddlSubBU = 0;
    this._helperService.ddlSite = 0;
    this._helperService.ddlSubSite = 0;
    this._helperService.ddlSru = 0;
    this._helperService.ddlDept = 0;
    this._helperService._objArrRegions = [];
    this._helperService._objArrSubBU = [];
    this._helperService._objArrSite = [];
    this._helperService._objArrSubSite = [];
    this._helperService._objArrSRU = [];
    this._helperService._objArrDept = [];
    if (this._helperService.ddlBU > 0) {
      this.getRegionByBU(event.target.value);
      this.SearchContractor(event.target.value,this._helperService.ddlRegion,this._helperService.ddlSubBU,this._helperService.ddlSite,this._helperService.ddlSubSite,this._helperService.ddlSru,this._helperService.ddlDept,this._helperService.ddlCrew);
    this.SearchCategory();
    }
  }
  SearchContractor(_objBUId,_objRegionId,_objSubBUId,_objSiteId,_objSubSiteId,_objSruId,_objDeptId,_objCrewId){
   
    
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = _objBUId
     objVMHierarchySearch.RegionId = _objRegionId;
    objVMHierarchySearch.SubBuId = _objSubBUId;
     objVMHierarchySearch.SiteId = _objSiteId;
     objVMHierarchySearch.SubSiteId = _objSubSiteId;
     objVMHierarchySearch.SruId = _objSruId;
    objVMHierarchySearch.DepartmentId = _objDeptId;
     objVMHierarchySearch.CrewId = _objCrewId;
    // objVMHierarchySearch.ContractorName = this.trimTxtContractorName;
    objVMHierarchySearch.Active = true;

    this.contractorService.getAllContractor(objVMHierarchySearch).subscribe(
      
        (data: Contractor[]) => {
          this._objArrContractors=[];
          this._helperService._objArrContractors = data.sort((a,b) => a.ContractorName.localeCompare(b.ContractorName));
          //this.sendTreeValuesforBU.emit(this._objArrContractors);
        // this._objTblArrContractors = data;
        // this.objArrContractorsAdd =data.sort((a,b) => a.ContractorName.localeCompare(b.ContractorName));
         
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: 'Error Message', detail: this.translate.instant('Contractor.searchErrorContractor') });
        }
      );
  }
  onSelectRegion(event: any) {
    this._helperService.ddlSubBU = 0;
    this._helperService.ddlSite = 0;
    this._helperService.ddlSubSite = 0;
    this._helperService.ddlSru = 0;
    this._helperService.ddlDept = 0;
    this._helperService._objArrSubBU = [];
    this._helperService._objArrSite = [];
    this._helperService._objArrSubSite = [];
    this._helperService._objArrSRU = [];
    this._helperService._objArrDept = [];
    if (this._helperService.ddlRegion > 0) {
      this.getSubBUByRegion(this._helperService.ddlBU, event.target.value);
      this.SearchContractor(this._helperService.ddlBU, event.target.value,this._helperService.ddlSubBU,this._helperService.ddlSite,this._helperService.ddlSubSite,this._helperService.ddlSru,this._helperService.ddlDept,this._helperService.ddlCrew)
    this.SearchCategory();
    }
  }
  
  onSelectSubBU(event: any) {
    this._helperService.ddlSite = 0;
    this._helperService.ddlSubSite = 0;
    this._helperService.ddlSru = 0;
    this._helperService.ddlDept = 0;
    this._helperService._objArrSite = [];
    this._helperService._objArrSubSite = [];
    this._helperService._objArrSRU = [];
    this._helperService._objArrDept = [];
    if (this._helperService.ddlSubBU > 0) {
      this.getSiteBysubBU(this._helperService.ddlBU, this._helperService.ddlRegion, event.target.value);
      this.SearchContractor(this._helperService.ddlBU, this._helperService.ddlRegion, event.target.value,this._helperService.ddlSite,this._helperService.ddlSubSite,this._helperService.ddlSru,this._helperService.ddlDept,this._helperService.ddlCrew)
    this.SearchCategory();
    }
  }
  onSelectSite(event: any) {
    this._helperService.ddlSubSite = 0;
    this._helperService.ddlSru = 0;
    this._helperService.ddlDept = 0;
    this._helperService._objArrSubSite = [];
    this._helperService._objArrSRU = [];
    this._helperService._objArrDept = [];
    if (this._helperService.ddlSite > 0) {
      this.getSubSiteBySite(this._helperService.ddlBU, this._helperService.ddlRegion, this._helperService.ddlSubBU, event.target.value);
      this.SearchContractor(this._helperService.ddlBU, this._helperService.ddlRegion,this._helperService.ddlSubBU, event.target.value,this._helperService.ddlSubSite,this._helperService.ddlSru,this._helperService.ddlDept,this._helperService.ddlCrew)
    this.SearchCategory();
    }
  }
  onSelectSubSite(event: any) {
    this._helperService.ddlSru = 0;
    this._helperService.ddlDept = 0;
    this._helperService._objArrSRU = [];
    this._helperService._objArrDept = [];
    if (this._helperService.ddlSubSite > 0) {
      this.getSruBySubSite(this._helperService.ddlBU, this._helperService.ddlRegion, this._helperService.ddlSubBU, this._helperService.ddlSubSite, event.target.value);
      this.SearchContractor(this._helperService.ddlBU, this._helperService.ddlRegion,this._helperService.ddlSubBU, this._helperService.ddlSubSite,event.target.value,this._helperService.ddlSru,this._helperService.ddlDept,this._helperService.ddlCrew)
      this.SearchCategory();
    }
  }
  onSelectSru(event: any) {
    if (this._helperService.ddlSru > 0) {
      this.getDepartmentBySRU(this._helperService.ddlBU, this._helperService.ddlRegion, this._helperService.ddlSubBU, this._helperService.ddlSubSite, this._helperService.ddlSru, event.target.value);
      this.SearchContractor(this._helperService.ddlBU, this._helperService.ddlRegion,this._helperService.ddlSubBU, this._helperService.ddlSubSite,this._helperService.ddlSru,event.target.value,this._helperService.ddlDept,this._helperService.ddlCrew)
      this.SearchCategory();
    }
  }
  onSelectDept(event:any){
    if (this._helperService.ddlSru > 0) {
      this.getCrewByDept(this._helperService.ddlBU, this._helperService.ddlRegion, this._helperService.ddlSubBU, this._helperService.ddlSubSite, this._helperService.ddlSru,this._helperService.ddlDept, event.target.value);
      this.SearchContractor(this._helperService.ddlBU, this._helperService.ddlRegion,this._helperService.ddlSubBU,this._helperService.ddlSite, this._helperService.ddlSubSite,this._helperService.ddlSru,event.target.value,this._helperService.ddlCrew)
      this.SearchCategory();
    }
  }

  onSelectCrew(event:any){
    if(this._helperService.ddlDept>0){
      this.SearchContractor(this._helperService.ddlBU, this._helperService.ddlRegion,this._helperService.ddlSubBU,this._helperService.ddlSite, this._helperService.ddlSubSite,this._helperService.ddlSru,this._helperService.ddlDept,event.target.value)
    this.SearchCategory();
    }
  }

  getRegionByBU(_objBUId): void {

    if (this._helperService.ddlBU != 0) {
      this._buService.GetBUChildrens(_objBUId).subscribe(
        (data: BU[]) => {
          this._helperService._objArrRegions = data[0].Regions;
          this._helperService._objArrSubBU = data[0].SubBUs;
          this._helperService._objArrSite = data[0].Sites;
          this._helperService._objArrSubSite = data[0].SubSites;
          this._helperService._objArrSRU = data[0].SRUs;
          this._helperService._objArrDept = data[0].Departments;
          this._helperService._objArrCrew=data[0].Crews;
          this._helperService._objArrRegions.sort((a, b) => a.RegionName.localeCompare(b.RegionName));
          this._helperService._objArrSubBU.sort((a, b) => a.SubBUName.localeCompare(b.SubBUName));
          this._helperService._objArrSite.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
          this._helperService._objArrSubSite.sort((a, b) => a.SubSiteName.localeCompare(b.SubSiteName));
          this._helperService._objArrSRU.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._helperService._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._helperService._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));


        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingRegion') });
        }
      );
    }
  }

  getSubBUByRegion(_objBUId, _objRegionId): void {
    if (this._helperService.ddlRegion != 0) {
      this._regionService.GetRegionChildren(_objBUId, _objRegionId).subscribe(
        (data: Region[]) => {
          let region: Region;
          region = data.filter(x => x.RegionId == _objRegionId)[0];
          this._helperService._objArrSubBU = region.SubBUs;
          this._helperService._objArrSite = region.Sites;
          this._helperService._objArrSubSite = region.SubSites;
          this._helperService._objArrSRU = region.SRUs;
          this._helperService._objArrDept = region.Departments;
          this._helperService._objArrCrew=region.Crews;
          this._helperService._objArrSubBU.sort((a, b) => a.SubBUName.localeCompare(b.SubBUName));
          this._helperService._objArrSite.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
          this._helperService._objArrSubSite.sort((a, b) => a.SubSiteName.localeCompare(b.SubSiteName));
          this._helperService._objArrSRU.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._helperService._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._helperService._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));

        },
        error => {
          // this.ErrorMsgAdmin = [];
          // this.ErrorMsgAdmin.push({ severity: 'error', summary: 'Error Message', detail: 'Error while getting Sub BU details.' });
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingSubBU') });
        }

      );
    }
  }
  getSiteBysubBU(_objBUId, _objRegionId, _objSubBuId): void {
    if (this._helperService.ddlSubBU != 0) {
      this._subBUService.GetSubBuChildren(_objBUId, _objRegionId, _objSubBuId).subscribe(
        (data: SubBU[]) => {
          let subbu = data.filter(x => x.SubBUId == _objSubBuId)[0];
          this._helperService._objArrSite = subbu.Sites;
          this._helperService._objArrSubSite = subbu.SubSites;
          this._helperService._objArrSRU = subbu.SRUs;
          this._helperService._objArrDept = subbu.Departments;
          this._helperService._objArrCrew=subbu.Crews;
          this._helperService._objArrSite.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
          this._helperService._objArrSubSite.sort((a, b) => a.SubSiteName.localeCompare(b.SubSiteName));
          this._helperService._objArrSRU.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._helperService._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._helperService._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingSite') });
        }
      );
    }

  }
  getSubSiteBySite(_objBUId, _objRegionId, _objSubBUId, _objSiteId): void {
    if (this._helperService.ddlSite != 0) {
      this._siteService.GetSiteChildren(_objBUId, _objRegionId, _objSubBUId, _objSiteId).subscribe(
        (data: Site[]) => {
          let site = data.filter(x => x.SiteId == _objSiteId)[0]
          this._helperService._objArrSubSite = site.SubSites;
          this._helperService._objArrSRU = site.SRUs;
          this._helperService._objArrDept = site.Departments;
          this._helperService._objArrCrew=site.Crews;
          this._helperService._objArrSubSite.sort((a, b) => a.SubSiteName.localeCompare(b.SubSiteName));
          this._helperService._objArrSRU.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._helperService._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._helperService._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));

        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetSubSite') });
        }
      );
    }
  }

  getSruBySubSite(_objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSiteId): void {
    if (this._helperService.ddlSubSite != 0) {
      this._subSiteService.GetSubSiteChildren(_objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSiteId).subscribe(
        (data: SubSite[]) => {
          let subSite = data.filter(x => x.SubSiteId == _objSubSiteId)[0]
          this._helperService._objArrSRU = subSite.SRUs;
          this._helperService._objArrDept = subSite.Departments;
          this._helperService._objArrCrew=subSite.Crews;
          this._helperService._objArrSRU.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._helperService._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._helperService._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
        },
        error => {

          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetSRU') });
        }
      );
    }
  }

  getDepartmentBySRU(_objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSIteId, _objSruId): void {
    if (this._helperService.ddlSru != 0) {
      this._sruService.GetSRUChildrens(_objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSIteId, _objSruId).subscribe(
        (data: SRU[]) => {
          let sru = data.filter(x => x.SRUId == _objSruId)[0]
          this._helperService._objArrDept = sru.Departments;
          this._helperService._objArrCrew=sru.Crews;
          this._helperService._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._helperService._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetDept') });

        }
      );
    }
  }
  // Get All Crew By Depts for Crew dropdown binding
  getCrewByDept(_objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSIteId, _objSruId, _objDeptId): void {
    this._deptService.GetDeptChildrens(_objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSIteId, _objSruId, _objDeptId).subscribe(
      (data: Department[]) => {
        let department=data.filter(x=>x.DepartmentId==_objDeptId)[0];
        this._helperService._objArrCrew = department.Crews;
        this._helperService._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorCrew') });
      }
    );
  }
  SearchCategory() {
    
   
    
    //
    this.categoryService.searchCategory('', true, '').subscribe(

        (data: Category[]) => {
          this._helperService._objArrCategory = [];
          this._helperService._objArrCategory = data.sort((a, b) => a.Category.CategoryName.localeCompare(b.Category.CategoryName));
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Category.searchErrorCat') });
        }
      );
  }

  
}
