export class Asset{
    AssetId: number;
    AssetNumber: string;
    AssetDescription: string;
    ParentAssetNumber: string;
    AssetLevel: number;
    Active:boolean;
    Maintainable:boolean;
}


export class ExtAssetResponse {
    ResultCount: number;
    PageIndex: number;
    PageSize: number;

    SearchResult: AssetDetails[];

}

export class AssetDetails
{
    AssetNumber: string;
    Description: string;
    IsMaintainable:boolean;
}

export class ParentAsset
{
    ParentAssetList: AssetDetails[] = [];
}