import { Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Languages } from 'src/app/ViewModel/Languages';
import { User } from '../ViewModel/User';
import { Contractor } from '../ViewModel/Contractor';
import { UserSearchViewModel } from '../ViewModel/UserSearchViewModel';
import { environment } from 'src/environments/environment';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { HelperService } from './helper.service';
import { PermitCreatedByUserViewModel } from '../ViewModel/PermitCreatedByUserViewModel';
import { UserRole } from '../ViewModel/UserRole';
import { HierarchyTree } from '../ViewModel/HierarchyTree';

@Injectable()
export class UserService {
  
  //getAllBUURL = environment.WEBAPIURL + 'BU/GetBUDropdown';
  //getRegionByBUURL = environment.WEBAPIURL + 'Region/GetRegionByBU';
  getAllContractorURL = environment.WEBAPIURL + 'contractor/contractors';
  //getAllTypeURL = environment.WEBAPIURL + 'Type/GetAllTypes';
  //getAllUsersURL = environment.WEBAPIURL + 'User/user';
  addUsersURL = environment.WEBAPIURL + 'User/user';
  //editUsersURL = environment.WEBAPIURL + 'User/user';
  //searchUsersURL = environment.WEBAPIURL + 'User/user';
  getUsersInfoByIdURL = environment.WEBAPIURL + 'UserInfo/GetUser';
  getAllLanguagesURL = environment.WEBAPIURL + 'UserInfo/GetAllLanguage';
  AddBulkURL = environment.WEBAPIURL + 'DataUpload/bulk-user';
  getAllTypesByRoleURL = environment.WEBAPIURL + 'Type/GetAllTypeByRole';
  getUserByLoginIdURL = environment.WEBAPIURL + 'User/user/';
  getPermitCreatedByUserURL = environment.WEBAPIURL + 'User/permit-created-by/';
  getUserWithRoleBasedOnPermitURL = environment.WEBAPIURL + 'User/user-role-permit/';
  getUserRoleURL = environment.WEBAPIURL + 'User/user-role/'
  entryLeaderURL = environment.WEBAPIURL + 'User/get-entry-leader/'
  private _userUrl = environment.WEBAPIURL + 'User/user';
  getUsersFromAdmin = environment.WEBAPIURL + 'User/UserSearch'

  editUserObjtoKeep: User
  addSelfUser:boolean=false;
  successFlag:boolean =false;
  constructor(private _http: HttpClient, private _helperService: HelperService) { }


  saveUserOkFlag :boolean=false;
  //Excel value bulk upload
  addBulkValue(user) {
    return this._http.post(this.AddBulkURL, user, { observe: 'response' });
  }

  //Get all Users
  getAllusers() {
    let objUserSearch = new UserSearchViewModel();
    objUserSearch.Active = true;
    // let params = new HttpParams().set("userSearchOptions", JSON.stringify(objUserSearch))
    let params = new HttpParams().set("userSearchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objUserSearch)));     
    return this._http.get<User[]>(this._userUrl, {params: params});
  }

  //Get all the languages
  getAllLanguages() {
    return this._http.get<Languages[]>(this.getAllLanguagesURL);
  }

  //Get Region by BU
  // getRegionByBU() {
  //   return this._http.get<Region[]>(this.getRegionByBUURL);
  // }

  //Get all Contractors
  // getAllContractor(objVMHierarchySearch: HierarchySearchViewModel) {
  //   objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
  //   let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))  
  //   return this._http.get<Contractor[]>(this.getAllContractorURL,{params: params});
  // }

  //Add new Users
  addUsers(Users: User) {
    return this._http.post(this._userUrl, Users, { observe: 'response' });
  }

  //Update Users
  editUsers(Users: User) {
    
    return this._http.put(this._userUrl, Users, { observe: 'response' });
  }

  //Search Users
  SearchUsers(BUId, RegionId, SubBUId, SiteId, SubSiteId, SRUId, DepartmentId, CrewId,
    ContractorId, LanguageId, FirstName, MiddleName, LastName, EmailAddress, LoginId, isActive, roleId) {

      if (isActive==null){
isActive=true;

      }
    let objUserSearch = new UserSearchViewModel();

    
    objUserSearch.BuId = BUId;
    objUserSearch.RegionId = RegionId;
    objUserSearch.SubBuId = SubBUId;
    objUserSearch.SiteId = SiteId;
    objUserSearch.SubSiteId = SubSiteId;
    objUserSearch.SruId = SRUId;
    objUserSearch.DepartmentId = DepartmentId;
    objUserSearch.CrewId = CrewId;
    objUserSearch.ContractorId = ContractorId;
    objUserSearch.languageId = LanguageId;
    objUserSearch.FirstName = FirstName;
    objUserSearch.MiddleName = MiddleName;
    objUserSearch.LastName = LastName;
    objUserSearch.EmailId = EmailAddress;
    objUserSearch.LoginId = LoginId;
    objUserSearch.Active = isActive;
    objUserSearch.RoleId = roleId;

    // let params = new HttpParams().set("userSearchOptions", JSON.stringify(objUserSearch))
    let params = new HttpParams().set("userSearchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objUserSearch)));     
    return this._http.get<User[]>(this._userUrl, {params: params});
  }

  keepEditUserObj(editUserObj) {
    this.editUserObjtoKeep = editUserObj;
  }
  getUserByLoginId(loginId) {
    return this._http.get<User>(this.getUserByLoginIdURL+loginId);
  }

  getPermitCreatedByUserDetails(permitId) {
    return this._http.get<PermitCreatedByUserViewModel>(this.getPermitCreatedByUserURL+permitId);
  }

  getUserWithRoleBasedOnPermit(loginId, permitId) {
    return this._http.get<User>(this.getUserWithRoleBasedOnPermitURL + loginId + '/' + permitId);
  }
  getUserRole(loginId, siteId) {
    return this._http.get<UserRole[]>(this.getUserRoleURL + loginId + '/' + siteId );
  }
  getEntryLeader(_regionId, _siteId){
    return this._http.get<any[]>(this.entryLeaderURL + _regionId + '/' + _siteId );
  }
  //Search Users From Admin
  SearchUsersFromAdmin(BUId, RegionId, SubBUId, SiteId, SubSiteId, SRUId, DepartmentId, CrewId,
    ContractorId, LanguageId, FirstName, MiddleName, LastName, EmailAddress, LoginId, isActive, roleId) {

      if (isActive==null){
isActive=true;

      }
    let objUserSearch = new UserSearchViewModel();

    
    objUserSearch.BuId = BUId;
    objUserSearch.RegionId = RegionId;
    objUserSearch.SubBuId = SubBUId;
    objUserSearch.SiteId = SiteId;
    objUserSearch.SubSiteId = SubSiteId;
    objUserSearch.SruId = SRUId;
    objUserSearch.DepartmentId = DepartmentId;
    objUserSearch.CrewId = CrewId;
    objUserSearch.ContractorId = ContractorId;
    objUserSearch.languageId = LanguageId;
    objUserSearch.FirstName = FirstName;
    objUserSearch.MiddleName = MiddleName;
    objUserSearch.LastName = LastName;
    objUserSearch.EmailId = EmailAddress;
    objUserSearch.LoginId = LoginId;
    objUserSearch.Active = isActive;
    objUserSearch.RoleId = roleId;

    // let params = new HttpParams().set("userSearchOptions", JSON.stringify(objUserSearch))
    let params = new HttpParams().set("userSearchOptionsFromAdmin", this._helperService.EscapeSpecialCharacters(JSON.stringify(objUserSearch)));     
    return this._http.get<User[]>(this.getUsersFromAdmin, {params: params});

  }
}