export class HierarchyTree {

    // Holds the selected node IDs
    BuId?: number;
    RegionId?: number;
    SubBuId?: number;
    SiteId?: number;
    SubSiteId?: number;
    SruId?: number;
    DepartmentId?: number;
    CrewId?: number;
    
    // Holds the selected node names
    BUName: string;
    RegionName: string;
    SubBUName: string;
    SiteName: string;
    SiteAbbreviation: string;
    SubSiteName: string;
    SRUName: string;
    DeptName: string;
    CrewName: string;

    // Holds the selected node label
    Name: string;
}