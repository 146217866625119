import { PermitTypeSubQuestion } from './PermitBuilder/PermitDivision/PermitTypeSubQuestion';
import { UpdateStamp } from './UpdateStamp';

export class SubQuesTableTypeResponse extends UpdateStamp{
    SubQuesTableTypeResponseId:number|null;
    PermitTypeTableSubQuesId: number|null;
    SubQuesResponseId: number;
    SubQuesResponseText?: string|null;
    SelectedAnswerOptionId?: number|null=0;
    SelectedAnswerCheckBoxForTable?:number[]|null;
    SubQuestion?: PermitTypeSubQuestion|null;
    RowIndex?: number;
    PermitTypeTableSubQuesRef: string|null;
    PermitTypeTableSubQuesAnswerOptionRef: string|null;
   
}