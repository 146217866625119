import { UpdateStamp } from "./UpdateStamp";
import { Permit } from '../ViewModel/Permit';
import {PermitOperationSequence} from '../ViewModel/PermitOperationSequence';

export class PermitAssetAndWorkOrder extends UpdateStamp{
    AssetWorkOrderID: number;
    PermitId: number;
    AssetNo: string;
    AssetDesc: string;
    workOrderNo: string;
    workOrderDesc: string;
    WOStatus: string;
    oPNumber: string;
    oPdesc: string;
    AllOperationSelected?: boolean;
    PermitOperationSequences :PermitOperationSequence[];
    Permit : Permit;
}