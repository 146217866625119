import { Injectable } from '@angular/core';
import { DynamicDataSource } from '../ViewModel/DynamicDataSource';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Common } from '../ViewModel/Common';
import { Table } from '../ViewModel/Table';
import { catchError } from 'rxjs/operators';
import { Column } from '../ViewModel/Column';
import { environment } from 'src/environments/environment';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { DynamicDataSourceOption } from '../ViewModel/DynamicDataSourceOption';
@Injectable()
export class DynamicDataSourceService {
  //_http: any;
  _common = new Common();
  _column: string[] = [];
  getAllTableURL = this._common.DomainURL + 'tables';
  getColumnUrl = this._common.DomainURL + 'columns';
  saveDynamicUrl = this._common.DomainURL + 'dynamic';
  searchDynamicURL = this._common.DomainURL + 'dynamic';
  dynamicURL = this._common.DomainURL + 'dynamic';
  dynamicdataSourceOptions = environment.WEBAPIURL + 'dynamic-data-source-options/';
  constructor(private _http: HttpClient) {
  }

  //Search BU
  searchDynamicDatasource(dynamicName, isActive) {
    return this._http.get<DynamicDataSource[]>(this.searchDynamicURL, {
      params: new HttpParams().set("dynamicName", dynamicName).set("Active", isActive)
    })
      .pipe(
        catchError(this._common.handleError)
      );
  }

  AddDynamic(request: DynamicDataSource) {
    return this._http.post(this.saveDynamicUrl, request, { observe: 'response' })
      .pipe(
        catchError(this._common.handleError)
      );
  }
  editDynamic(request: DynamicDataSource) {
    return this._http.put(this.dynamicURL, request, { observe: 'response' });
  }

  getAllTable() {
    return this._http.get<Table[]>(this.getAllTableURL, {

    })
      .pipe(
        catchError(this._common.handleError)
      );
  }

  getColumn(tablename) {
    let params = new HttpParams().set("tablename", tablename);
    return this._http.get<Column[]>(this.getColumnUrl, {
      params: params
    })
      .pipe(
        catchError(this._common.handleError)
      );
  }
  getDataSourceOptions(dataSourceId) {
    //languageId:Number;
    let objVMHierarchySearch = new HierarchySearchViewModel();
    
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    let languageId = objVMHierarchySearch.languageId;
    // return this._http.get<DynamicDataSourceOption[]>(this.dynamicdataSourceOptions+dataSourceId+'/'+objVMHierarchySearch.languageId);
    return this._http.get<DynamicDataSourceOption[]>(this.dynamicdataSourceOptions+dataSourceId+'/'+languageId);
  }

  // getDataSourceOptionsByHierarchy(dataSourceId,SubSiteId,siteId,SubBuId,BuId,RegionId) {
  //   if (SubSiteId == null) 
  //     SubSiteId=0;
    
  //   if (siteId == null) 
  //     siteId=0;
    
  //   if (BuId == null) 
  //     BuId=0;
    
  //   if (SubBuId == null) 
  //     SubBuId=0;
    
  //   if (RegionId == null) 
  //     RegionId=0;
    
  //   //languageId:Number;
  //   let objVMHierarchySearch = new HierarchySearchViewModel();
  //   debugger;
  //   objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
  //   let languageId = objVMHierarchySearch.languageId;
  //    return this._http.get<DynamicDataSourceOption[]>(this.dynamicdataSourceOptions+dataSourceId+'/'+languageId +'/'+SubSiteId +'/'+siteId +'/'+SubBuId +'/'+BuId +'/'+RegionId);
  // }
}

