import {Injectable} from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { SRU } from '../ViewModel/sru';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';




@Injectable()
export class SRUService {
           
    getAllSruUrl = environment.WEBAPIURL + 'SRU/srus';  
    getSruDropdownURL = environment.WEBAPIURL + 'SRU/GetSRUDropdown'; 
    getSruBySubSiteUrl = environment.WEBAPIURL + 'SRU/GetSRUBySubSite'; 
    getSruBySubSiteByRoleUrl = environment.WEBAPIURL + 'SRU/srus/';
    saveSruURL= environment.WEBAPIURL + 'SRU/srus';
    editSruURL = environment.WEBAPIURL + 'SRU/srus';
    AddBulkURL = environment.WEBAPIURL + 'DataUpload/bulk-sru';
    addUpdateTranslationURL = environment.WEBAPIURL + 'SRU/AddUpdateSRUTranslations';   

  constructor(private _http: HttpClient, private _helperService: HelperService) {}

  //Excel value bulk upload
  addBulkValue(SRU) {  
    localStorage.setItem("_hierarchtTree", null);
    return this._http.post(this.AddBulkURL, SRU,{observe: 'response'});
   }

  //Get All the SRU Details
  getAllSru() { 
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.Active = true;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch)); 
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    return this._http.get<SRU[]>(this.getAllSruUrl,{params:params});
  }

  GetSRUChildrens(_objBuId,_objRegionId,_objSubBuId,_objSiteId,_objSubSiteID,_objSruId){
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = _objBuId;
    objVMHierarchySearch.RegionId = _objRegionId;
    objVMHierarchySearch.SubBuId = _objSubBuId;
    objVMHierarchySearch.SiteId = _objSiteId;
    objVMHierarchySearch.SubSiteId = _objSubSiteID;
    objVMHierarchySearch.SruId = _objSruId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));   
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<SRU[]>(this.getAllSruUrl,{params:params});
  }

  //Get SRU Dropdown Details
  getSruDropdown() {    
    return this._http.get<SRU[]>(this.getSruDropdownURL);
  }

  //Get All the Sru Details By SubSite
  getSruBySubSite(SubSiteId){    
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.SubSiteId = SubSiteId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    // return this._http.get<SRU[]>(this.getSruBySubSiteUrl+'?SubSiteId='+SubSiteId,{headers: this._common.Headers}).pipe(
    //    catchError(this._common.handleError)
    // );
    return this._http.get<SRU[]>(this.getAllSruUrl,{params: params});
  }

  //Get All the Sru Details By SubSite
  getSruBySubSiteByRole(SubSiteId){    
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.SubSiteId = SubSiteId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    // return this._http.get<SRU[]>(this.getSruBySubSiteByRoleUrl+SubSiteId+'/srus',{headers: this._common.Headers}).pipe(
    //    catchError(this._common.handleError)
    // );
    return this._http.get<SRU[]>(this.getAllSruUrl,{params: params});
  }

  //Add Update SRU Translations
  AddUpdateSRUTranslations(sru: SRU) {
    return this._http.post(this.addUpdateTranslationURL, sru, { observe: 'response' });
  }

  //Get SRU By BU ,Region , SubBUId and SiteId and subsite
  getSruByParent(BUId, RegionId, SubBUId, SiteId,SubSiteId) {   
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.SubSiteId = SubSiteId;
    objVMHierarchySearch.BuId = BUId
    objVMHierarchySearch.RegionId = RegionId
    objVMHierarchySearch.SubBuId = SubBUId
    objVMHierarchySearch.SiteId = SiteId
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));      
    return this._http.get<SRU[]>(this.getAllSruUrl,{params: params});
  }

  //Get SRU By BU ,Region , SubBUId and SiteId and subsite
  getSruByParentByRole(BUId, RegionId, SubBUId, SiteId,SubSiteId) { 
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.SubSiteId = SubSiteId;
    objVMHierarchySearch.BuId = BUId
    objVMHierarchySearch.RegionId = RegionId
    objVMHierarchySearch.SubBuId = SubBUId
    objVMHierarchySearch.SiteId = SiteId
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))   
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<SRU[]>(this.getAllSruUrl,{params: params});
  }

  //Add new Sru
  addSru(SRU:SRU) {  
    localStorage.setItem("_hierarchtTree", null);
    SRU.DefaultLanguageId=+localStorage.getItem("_languageID"); 
   return this._http.post(this.saveSruURL, SRU,{observe: 'response'});
  }

  //Update SRU
  editSru (SRU:SRU) { 
    localStorage.setItem("_hierarchtTree", null);
    SRU.DefaultLanguageId=+localStorage.getItem("_languageID"); 
    return this._http.put(this.editSruURL, SRU,{observe: 'response'});
  }

  //Search SRU
  searchSRU ( _objBuId,_objRegionId,_objSubBuId,_objSiteId,_objSubSiteID,_objSruName,isActive) { 
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = _objBuId;
    objVMHierarchySearch.RegionId = _objRegionId;
    objVMHierarchySearch.SubBuId = _objSubBuId;
    objVMHierarchySearch.SiteId = _objSiteId;
    objVMHierarchySearch.SubSiteId = _objSubSiteID;
    objVMHierarchySearch.SruName = _objSruName;
    objVMHierarchySearch.Active = isActive;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));    
    return this._http.get<SRU[]>(this.getAllSruUrl,{params:params});
  }

  getSruByID(objVMHierarchySearch:HierarchySearchViewModel) { 
    objVMHierarchySearch.Active = true;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch)); 
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    return this._http.get<SRU[]>(this.getAllSruUrl,{params:params});
  }


  
}


  