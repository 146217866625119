import {DomSanitizer} from "@angular/platform-browser";
import{Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "safeHtmlforPDF"})
export class SafeHtmlPdf implements PipeTransform{
    constructor(private sanitizer: DomSanitizer){}
    transform(value: string){
        if(value!=undefined && value!= null && value.includes('<span style="font-size: 30px; color: red; ">')){
             value = value.substring(0,value.indexOf('<span style="font-size: 30px; color: red; ">'))+value.substring(value.lastIndexOf('</span>')+7)
            }
        return this.sanitizer.bypassSecurityTrustHtml(value)
    }
}