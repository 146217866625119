import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { headersToString } from 'selenium-webdriver/http';
import { Injectable } from "@angular/core";

@Injectable()
export class HttpRequestHandler implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let userName: string
        userName = localStorage.getItem("_loginId")
        return next.handle(
            (request.url.endsWith('upload-media'))
            ?
            request.clone({
            headers: request.headers
            .append('Access-Control-Allow-Origin', '*')
            .append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE')
            .append('Ocp-Apim-Subscription-Key', environment.AZURESUBSCRIPTIONKEY)
            .append('Ocp-Apim-Trace', environment.APIM_Trace)
            .append('Username', (userName==undefined || userName==null ? '' : userName))
            .append('Access-Control-Expose-Headers', '*')
            .append('Source', 'WPS')
            }): 
                (request.url.endsWith('GetImageByte') ?
                    request.clone({
                    headers: request.headers
                    .append('Content-Type', 'application/json')
                    .append('Accept', 'application/json')
                    .append('Access-Control-Allow-Origin', '*')
                    .append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE')
                    .append('Ocp-Apim-Subscription-Key', environment.AZURESUBSCRIPTIONKEY)
                    .append('Ocp-Apim-Trace', environment.APIM_Trace)
                    .append('Username', (userName==undefined || userName==null ? '' : userName))
                    .append('Access-Control-Expose-Headers', '*')
                    .append('Source', 'WPS')
                }):
                request.clone({
                    headers: request.headers
                    .append('Content-Type', 'application/json')
                    .append('Access-Control-Allow-Origin', '*')
                    .append('Access-Control-Allow-Methods', 'GET, POST, PATCH, PUT, DELETE')
                    .append('Ocp-Apim-Subscription-Key', environment.AZURESUBSCRIPTIONKEY)
                    .append('Ocp-Apim-Trace', environment.APIM_Trace)
                    .append('Username', (userName==undefined || userName==null ? '' : userName))
                    .append('Access-Control-Expose-Headers', '*')
                    .append('Source', 'WPS')
                })
            )
        )
          .pipe(
            catchError((error: HttpErrorResponse) => {
                console.log('An error occurred:', error.message);
                return throwError(error);
            })
        );
    }
}
    
