import { Attributes } from './Attributes';
import { BU } from './BU';
import { HierarchyTranslation } from './HierarchyTranslation';
import { SubBU } from './SubBU';
import { Site } from './Site';
import { SubSite } from './SubSite';
import { SRU } from './sru';
import { Department } from './Department';
import { Crew } from './Crew';


export class Region {   
    RegionId: number;
    BUName:string;
    BUId:number;
    RegionName: string;
    Active: boolean;
    CreatedBy: string;
    CreatedDate: Date;
    ModifiedBy: string;
    ModifiedDate: Date;
    BU?: BU | null;
    Attributes?: Attributes[]|null;
    IsEditable:boolean;
    DefaultLanguageId:number;
    HierarchyTranslations?: HierarchyTranslation[] | null;
    SubBUs?: SubBU[] | null;
    Sites?: Site[] | null;
    SubSites?: SubSite[] | null;
    SRUs?: SRU[] | null;
    Departments?:Department[]|null;
    Crews?:Crew[]|null;
    GlobalRegionKey: string;
}
