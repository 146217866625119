import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Message, TreeNode } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/Services/common.service';
import { SubBUService } from 'src/app/Services/subbu.service';
import { BUService } from 'src/app/Services/bu.service';
import { RegionService } from 'src/app/Services/region.service';
import { SiteService } from 'src/app/Services/site.service';
import { SubSiteService } from 'src/app/Services/subsite.service';
import { SRUService } from 'src/app/Services/sru.service';
import { DepartmentService } from 'src/app/Services/department.service';
import { CrewService } from 'src/app/Services/crew.service';
import { ExcelService } from 'src/app/Services/excel.service';
import { PDFService } from 'src/app/Services/pdf.service';
import { BU } from 'src/app/ViewModel/BU';
import { Region } from 'src/app/ViewModel/Region';
import { SubBU } from 'src/app/ViewModel/SubBU';
import { Site } from 'src/app/ViewModel/Site';
import { SubSite } from 'src/app/ViewModel/SubSite';
import { SRU } from 'src/app/ViewModel/sru';
import { Department } from 'src/app/ViewModel/Department';
import { Crew } from 'src/app/ViewModel/Crew';
import { Languages } from 'src/app/ViewModel/Languages';
import { Role } from 'src/app/ViewModel/Role';
import { UserRole } from 'src/app/ViewModel/UserRole';
import { User } from 'src/app/ViewModel/User';
import { RoleService } from 'src/app/Services/role.service';
import { UserService } from 'src/app/Services/user.service';
import { Contractor } from 'src/app/ViewModel/Contractor';
import { HierarchyTree } from 'src/app/ViewModel/HierarchyTree';
import { UserHierarchyRoles } from 'src/app/ViewModel/UserHierarchyRole';
import { HierarchyTreeComponent } from '../hierarchy-tree/hierarchy-tree.component';
import { UserSearchViewModel } from 'src/app/ViewModel/UserSearchViewModel';
import { Router } from '@angular/router';
import { HierarchySearchViewModel } from 'src/app/ViewModel/HierarchySearchViewModel';
import { ContractorService } from '../../Services/contractor.service';
import { HelperService } from 'src/app/Services/helper.service';



@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
  providers: []
})

export class UserProfileComponent implements OnInit, OnDestroy {


  // @Input() displayUserAddPopup: boolean;
  @Output() popupFlag = new EventEmitter<boolean>();
  isAdminUser: boolean;
  isNewUser: boolean;
  isExistingUser: boolean;

  ErrorMsgAdmin: Message[] = [];
  _objCountryCodes: number[] = [];
  _objArrBU: BU[] = [];
  _objArrRegions: Region[] = [];
  _objArrSubBU: SubBU[] = [];
  _objArrSite: Site[] = [];
  _objArrSubSite: SubSite[] = [];
  _objArrSRU: SRU[] = [];
  _objArrDept: Department[] = [];
  _objArrCrew: Crew[] = [];
  _objArrRoles: Role[] = [];
  _objArrLanguages: Languages[] = [];
  _objArrContractors: Contractor[] = [];
  _objTblArrBU: BU[] = [];
  arrContractor: Contractor[] = [];
  _objTblArrRegions: Region[] = [];
  _objTblArrSubBU: SubBU[] = [];
  _objTblArrSite: Site[] = [];
  _objTblArrSubSite: SubSite[] = [];
  _objTblArrSRU: SRU[] = [];
  _objTblArrDept: Department[] = [];
  _objTblArrCrew: Crew[] = [];
  _objTblArrRoles: Role[] = [];
  _objTblArrLanguages: Languages[] = [];
  _objTblArrContractors: Contractor[] = [];
  _roleAdd: UserRole[] = [];
  _editrole: UserRole[] = [];
  _editUser: User = null;
  _editingUsers: User = null;
  _navigateToLayout: boolean = false;
  ddlRoleIdForSafety = 0;
  ddlRoleId = '';
  display = false;
  ddlRegion = 0;
  ddlBU = 0;
  ddlSite = 0;
  ddllang = 0;
  ddllangFlag = false;
  ddlSubBU = 0;
  filteredRoles: any[] = [];
  //ddlSubBUFlag=false;
  ddlSubSite = 0;
  ddlSru = 0;
  ddlDept = 0;
  ddlCrew = 0;
  ddlContractor = 0;
  ddlRole = 0;
  ddltblBU = 0;
  ddltblRegion = 0;
  ddltblSubBU = 0;
  ddltblSite = 0;
  ddltblSubSite = 0;
  ddltblSRU = 0;
  ddltblDept = 0;
  ddltblCrew = 0;
  ddltblContractor = 0;
  ddltblLanguage = 0;
  ddlRoles = 0;
  _users: User[] = [];
  chkNewType: boolean = false;

  chkActive: Boolean;
  chkActiveValue = '';
  ActiveValue = [{ id: 'All', name: "All" }, { id: 'No', name: "No" }, { id: 'Yes', name: "Yes" }];

  chkTypeLoopId = 0;
  chkTypeId = 0;
  _Active = true;
  AddUserobj = new User();
  txtSruName = '';
  txtLoginId = '';
  txtPayrollNo = '';
  txtContractor = '';
  txtFirstName = '';
  txtMiddleName = '';
  txtLastName = '';
  txtEmailAddress = '';
  isChangedBu = false;
  selectBUIdFlag = false;
  //ddlRegionFlag = false;
  ddlBUFlag = false;
  ddlCrewFlag = false;
  trimtxtFirstName = '';
  trimtxtMiddleName = '';
  trimtxtLastName = '';
  trimtxtEmailAddress = '';
  trimtxtLoginId = '';
  trimtxtPayrollNo = '';
  txtLoginIdFlag = false;
  txtPayrollNoFlag = false;
  txtEmployeeCardNoFlag = false;
  txtFirstNameFlag = false;
  //txtMiddleNameFlag = false;
  txtLastNameFlag = false;
  txtEmailAddressFlag = false;
  txtMobileNumberFlag = false;
  txtMobileNumberAdd: string
  txtCountryCodeFlag = false;
  txtCountryCodeAdd: string
  isEditing = false;
  _userFirstName = '';
  _userMiddleName = '';
  _userLastName = '';
  _userEmail = '';
  _userLoginId = '';
  _userPayRoleNo = '';
  _userEBICardNumber = '';
  firstNameFlag = false;
  middleNameFlag = false;
  lastNameFlag = false;
  emailFlag = false;
  loginIdFlag = false;
  PayRollNoFlag = false;
  checked = true;
  errmsg: string;
  ddlgridflag = false;
  isRoleEditable = true;
  _ddlBUId = 0;
  _ddlRegionId = 0;
  _ddlSubBUId = 0;
  _ddlSiteId = 0;
  _ddlSubSiteId = 0;
  _ddlSRUId = 0;
  _ddlDeptId = 0;
  isRoleExist = 0;
  treeValue: HierarchyTree;
  selectedRoleID: number;
  selectedRoleName: string;
  userFinalRoles: UserRole;
  // nodeValueFromUserProfile: HierarchyTree;
  nodeValueFromParent: HierarchyTree;

  saveUserOkFlag: Boolean = false;

  //Add User
  isObserver = false;
  displayUserAddPopup = false;
  ddlBUAdd = 0;
  _objAddArrRegions: Region[] = [];
  ddlRegionAdd = 0;
  _objArrSubBUAdd: SubBU[] = []
  ddlSubBUAdd = 0;
  _objArrSiteAdd: Site[] = [];
  ddlSiteAdd = 0;
  _objArrSubSiteAdd: SubSite[] = [];
  ddlSubSiteAdd = 0;
  _objArrSRUAdd: SRU[] = [];
  ddlSRUAdd = 0;
  _objArrDeptAdd: Department[] = [];
  ddlDeptAdd = 0;
  ddlCrewAdd = 0;
  _objArrCrewAdd: Crew[] = [];
  objArrLanguagesAdd: Languages[] = [];
  objArrContractorsAdd: Contractor[] = [];
  txtFirstNameAdd = "";
  txtLoginIdAdd = "";
  txtEmailAddressAdd = "";
  txtLastNameAdd = "";
  txtPayrollNoAdd = "";
  txtEmployeeCardNoAdd = "";
  txtMiddleNameAdd = "";
  ddllangAdd = 0;
  ddlContractorAdd = 0;
  chkActiveAdd = true;
  attrUserAddHeader = "Add User";
  //Edit User 
  attrUserEditHeader = "Edit User";
  displayUserEditPopup = false;
  EditUserObj = new User();
  userRoles: UserRole[];
  userHierarchyRoles: UserHierarchyRoles[] = [];
  userHierarchyRoleSingle = null;
  userRoleObj: UserHierarchyRoles;
  userRoleArray: UserHierarchyRoles[] = [];
  selectedHierarchyName = '';
  _role = null;
  _userRoles: UserRole[] = [];

  createdBY = '';
  createdDATE: Date;
  modifiedBY = '';
  modifiedDate: Date;
  _gridRowCount: number = 1;
  _gridHeight: number = 100;
  _gridHeightPX: string = '100px';
  _isLoginIdPresent: boolean = false;
  @ViewChild('SearchPanel') elementSearch: ElementRef;
  @ViewChild('HierarchyPanel') hierarchy: HierarchyTreeComponent;

  disabledClearSearch = true;
  usertoAddOrEdit: User;
  //selfServiceUser: User;


  uploadedFiles: any = [];
  _dataExcel: User[] = [];
  AddobjExcel = new User();
  display_popup = false;
  isSearched = false;

  filesTree4: TreeNode[];
  selectedFile2: TreeNode[];

  roleForDropDowns: Role[] = [];
  //_ddlSelectedRole=0;
  selectedRole: Role;

  isHierarcySelected: boolean = false;

  cols: any[]
  cars: any[];

  get selfServiceUser(): User {
    return this._commonService.getSelfServiceData();
  }
  set selfServiceUser(value: User) {
    this._commonService.setSelfServiceData(value);
  }

  displayRoleHierarchyDetailsPopup: boolean
  _pathDetails = [];
  selectedHierarchyNameForPopup: string

  _ddlRegion: number = 0;
  _ddlSite: number = 0;
  _ddlSRU: number = 0;
  _ddlDept: number = 0;
  _ddlCrew: number = 0;
  regionIdOld: number = 0;
  regionIdNew: number = 0;

  constructor(private _commonService: CommonService, private _router: Router, private _subBUService: SubBUService, private _roleService: RoleService, private _crewService: CrewService, private translate: TranslateService, private _deptService: DepartmentService, private _sruService: SRUService, private _subSiteService: SubSiteService, private _siteService: SiteService, private _regionService: RegionService, private _buService: BUService, private _excelService: ExcelService, private _pdfService: PDFService, private _messageService: MessageService, private _userService: UserService, private _contractorService: ContractorService, private _helperService: HelperService) {

  }


  ngOnInit() {

    this.isAdminUser = false;

    // this.getAllBU();
    this.getAllLanguages();
    this.getRolesforDropdown();
    // this.getAllContractors();
    this.cols = [
      { field: 'Action', header: 'Action' },
      { field: 'HierarchyDetails', header: 'Hierarchy Details' },
      { field: 'Role', header: 'Role' }
    ];

    this._editingUsers = null;
    this.isObserver = false;
    //this.assignFieldValues();
    // this.getRolesforDropdown();
  }
  ngOnDestroy(): void {
    this._isLoginIdPresent = false;
  }
  assignFieldValues() {
    this.ddlBUAdd = 0;
    this.ddlRegionAdd = 0;
    this.ddlSubBUAdd = 0;
    this.ddlSiteAdd = 0;
    this.ddlSubSiteAdd = 0;
    this.ddlSRUAdd = 0;
    this.ddlDeptAdd = 0;
    this.ddlCrewAdd = 0;
    // this._objAddArrRegions = [];
    // this._objArrSubBUAdd = [];
    // this._objArrSiteAdd = [];
    // this._objArrSubSiteAdd = [];
    // this._objArrSRUAdd = [];
    // this._objArrDeptAdd = [];
    // this._objArrCrewAdd = [];
    this.txtLoginIdAdd = "";
    this.txtPayrollNoAdd = "";
    this.txtEmployeeCardNoAdd = "";
    this.txtEmailAddressAdd = "";
    this.txtFirstNameAdd = "";
    this.txtMiddleNameAdd = "";
    this.txtLastNameAdd = "";
    this.ddlContractorAdd = 0;
    this.ddllangAdd = 1;
    this.attrUserAddHeader = this.attrUserAddHeader;
    this.ddlBUFlag = false;
    this.firstNameFlag = false;
    this.ddllangFlag = false;
    this.emailFlag = false;
    this.lastNameFlag = false;
    this.loginIdFlag = false;
    this.middleNameFlag = false;
    this.PayRollNoFlag = false;
    this.selectBUIdFlag = false;
    this.txtEmailAddressFlag = false;
    this.txtFirstNameFlag = false;
    this.txtLastNameFlag = false;
    this.txtLoginIdFlag = false;
    this.txtPayrollNoFlag = false;
    this.txtEmployeeCardNoFlag = false;
    this.chkActiveValue = 'All';
    this.selectedRole = undefined;
    this.txtCountryCodeAdd = "";
    this.txtMobileNumberAdd = "";
    //this.getDropdownData(1);
    this.GetDefaulrDropdownValues();
    this.getAllContractors();
    this.getRolesforDropdown();
  }



  clearAddUserObject() {
    this.AddUserobj = new User();
    this.isAdminUser = true;
    this._editingUsers = null;
    this.usertoAddOrEdit = undefined;
    this.isObserver = false;
    // this.assignFieldValues();
    this.selectBUIdFlag = false;
    this.txtEmailAddressFlag = false;
    this.txtFirstNameFlag = false;
    this.txtLastNameFlag = false;
    this.txtLoginIdFlag = false;
    this.txtPayrollNoFlag = false;
    this.txtEmployeeCardNoFlag = false;
    this.userHierarchyRoles = [];
    this.userHierarchyRoleSingle = null;
    this._userRoles = [];
    this.selectedRole = undefined;
    this.treeValue = null;
    this.isHierarcySelected = false;

    if (this.hierarchy != undefined) {
      this.hierarchy.clearSelectedNode();
    }
    this.isExistingUser = false;
    this.isNewUser = true;

  }

  getEditUserObject(objrowdata) {
    // console.log(objrowdata)
    this.hierarchy.clearSelectedNode();
    this.isAdminUser = true;
    this.selectedRole = undefined;
    this.userHierarchyRoles = null;
    this.isHierarcySelected = false;
    this.populateUserDetails(objrowdata, false);
    this.usertoAddOrEdit = objrowdata;
    this.populateRoleDetails(objrowdata);

    this.isNewUser = false;
    this.isExistingUser = true;
    this.treeValue = null;
  }



  populateDropDowns(objrowdata) {

    // this._buService.GetBUChildrens(objrowdata.BUId).subscribe(buChilds => {
    //   this._objArrBU = buChilds
    //   // this.ddlBUAdd = objrowdata.BUId;
    //   this._regionService.GetRegionChildren(objrowdata.BUId, objrowdata.RegionId).subscribe(regionChilds => {
    //     this._objAddArrRegions = regionChilds
    //     // this.ddlRegionAdd = objrowdata.RegionId;
    //     this._subBUService.GetSubBuChildren(objrowdata.BUId, objrowdata.RegionId, objrowdata.SubBUId).subscribe(subBuChilds => {
    //       this._objArrSubBUAdd = subBuChilds
    //       // this.ddlSubBUAdd = objrowdata.SubBUId;
    //       this._siteService.GetSiteChildren(objrowdata.BUId, objrowdata.RegionId, objrowdata.SubBUId, objrowdata.SiteId).subscribe(siteChilds => {
    //         this._objArrSiteAdd = siteChilds
    //         // this.ddlSiteAdd = objrowdata.SiteId;
    //         this._subSiteService.GetSubSiteChildren(objrowdata.BUId, objrowdata.RegionId, objrowdata.SubBUId, objrowdata.SiteId, objrowdata.SubSiteId).subscribe(subsiteChilds => {
    //           this._objArrSubSiteAdd = subsiteChilds
    //           // this.ddlSubSiteAdd = objrowdata.SRUId;

    //           this._sruService.GetSRUChildrens(objrowdata.BUId, objrowdata.RegionId, objrowdata.SubBUId, objrowdata.SiteId, objrowdata.SubSiteId, objrowdata.SruId).subscribe(sruChilds => {
    //             this._objArrSRUAdd = sruChilds
    //             // this.ddlSRUAdd = objrowdata.SRUId;
    //             this._deptService.GetDeptChildrens(objrowdata.BUId, objrowdata.RegionId, objrowdata.SubBUId, objrowdata.SiteId, objrowdata.SubSiteId, objrowdata.SruId, objrowdata.DepartmentId).subscribe(deptChilds => {
    //               this._objArrDeptAdd = deptChilds
    //               // this.ddlDeptAdd = objrowdata.DepartmentId;
    //             })
    //           })
    //         })
    //       })
    //     })
    //   })
    // });
    // this.getRegionByBU(this.ddlgridflag, objrowdata.BUId);
    // this.getsubBUByRegion(this.ddlgridflag, objrowdata.BUId, objrowdata.RegionId);
    // this.getSiteBySubBU(this.ddlgridflag, objrowdata.BUId, objrowdata.RegionId, objrowdata.SubBUId);
    // this.getSubSiteBySite(this.ddlgridflag, objrowdata.BUId, objrowdata.RegionId, objrowdata.SubBUId, objrowdata.SiteId);
    // this.getSruBySubSite(this.ddlgridflag, objrowdata.BUId, objrowdata.RegionId, objrowdata.SubBUId, objrowdata.SiteId, objrowdata.SubSiteId);
    // this.getDeptBySru(this.ddlgridflag, objrowdata.BUId, objrowdata.RegionId, objrowdata.SubBUId, objrowdata.SiteId, objrowdata.SubSiteId, objrowdata.SRUId);
    // this.getCrewByDept(this.ddlgridflag, objrowdata.DepartmentId);
    this.getDropdownDataforEditPopupLoadScreen(objrowdata.BUId, objrowdata.RegionId, objrowdata.SubBUId, objrowdata.SiteId, objrowdata.SubSiteId, objrowdata.SRUId, objrowdata.DepartmentId)
  }

  populateUserDetails(objrowdata, selfserviceFlag) {
    // this.getDropdownDataforEditPopupLoadScreen(objrowdata.BUId, objrowdata.RegionId, objrowdata.SubBUId,
    //   objrowdata.SiteId, objrowdata.SubSiteId, objrowdata.SRUId, objrowdata.DepartmentId);
    this.getDropdownDataForEdit(objrowdata.BUId);

    this.ddlBUAdd = objrowdata.BUId;
    this.ddlRegionAdd = objrowdata.RegionId;
    this.regionIdOld = objrowdata.RegionId;
    this.ddlSubBUAdd = objrowdata.SubBUId;
    this.ddlSiteAdd = objrowdata.SiteId;
    this.ddlSubSiteAdd = objrowdata.SubSiteId;
    this.ddlSRUAdd = objrowdata.SRUId;
    this.ddlDeptAdd = objrowdata.DepartmentId;
    this.ddlCrewAdd = objrowdata.CrewId;
    this.ddllangAdd = objrowdata.LanguageId;
    this.txtLoginIdAdd = objrowdata.LoginId;
    if (this.txtLoginIdAdd) {
      this._isLoginIdPresent = true;
    }
    this.txtPayrollNoAdd = objrowdata.PayrollNo;
    this.txtEmployeeCardNoAdd = objrowdata.EBICardNumber
    this.ddlContractorAdd = objrowdata.ContractorId;
    this.txtFirstNameAdd = objrowdata.FirstName;
    this.txtMiddleNameAdd = objrowdata.MiddleName;
    this.txtLastNameAdd = objrowdata.LastName;
    this.txtEmailAddressAdd = objrowdata.EmailAddress;
    this.txtCountryCodeAdd =  objrowdata.CountryCode != null ? objrowdata.CountryCode.toString():"";
    this.txtMobileNumberAdd = objrowdata.MobileNumber != null ? objrowdata.MobileNumber.toString(): "";
    this.chkActiveAdd = objrowdata.Active;
    if (selfserviceFlag) {
      this.usertoAddOrEdit = objrowdata;
    }
    else {
      this.usertoAddOrEdit = undefined;
    }

  }
















  getSelectedHierarchyNameOnEditPopUp(objrowdata): string {
    let val = '';
    // if (objrowdata.BU.BUName != null) {
    //   val = objrowdata.BU.BUName
    // }
    if (objrowdata.Region.RegionName != null) {
      val = objrowdata.Region.RegionName
    }
    if (objrowdata.SubBU.SubBUName != null) {
      val = objrowdata.SubBU.SubBUName
    }
    if (objrowdata.Site.SiteName != null) {
      val = objrowdata.Site.SiteName
    }
    if (objrowdata.SubSite.SubSiteName != null) {
      val = objrowdata.SubSite.SubSiteName
    }
    if (objrowdata.SRU.SRUName != null) {
      val = objrowdata.SRU.SRUName
    }
    if (objrowdata.Department.DeptName != null) {
      val = objrowdata.Department.DeptName
    }
    if (objrowdata.Crew.CrewName != null) {
      val = objrowdata.Crew.CrewName
    }

    return val;
  }

  getSelectedHierarchyNameOnAddPopUp(objrowdata): string {
    let val = '';
    if (objrowdata.BUName != null && objrowdata.BUName != "") {
      val = objrowdata.BUName
    }
    if (objrowdata.RegionName != null && objrowdata.RegionName != "") {
      val = objrowdata.RegionName
    }
    if (objrowdata.SubBUName != null && objrowdata.SubBUName != "") {
      val = objrowdata.SubBUName
    }
    if (objrowdata.SiteName != null && objrowdata.SiteName != "") {
      val = objrowdata.SiteName
    }
    if (objrowdata.SubSiteName != null && objrowdata.SubSiteName != "") {
      val = objrowdata.SubSiteName
    }
    if (objrowdata.SRUName != null && objrowdata.SRUName != "") {
      val = objrowdata.SRUName
    }
    if (objrowdata.DeptName != null && objrowdata.DeptName != "") {
      val = objrowdata.DeptName
    }
    if (objrowdata.CrewName != null && objrowdata.CrewName != "") {
      val = objrowdata.CrewName
    }

    return val;
  }



  populateRoleDetails(objrowdata) {
    this._userRoles = JSON.parse(JSON.stringify(objrowdata.UserRoles));
    this.userHierarchyRoles = [];

    objrowdata.UserRoles.forEach(data => {
      let hierarchyName = '';
      hierarchyName = (data.Region.RegionName == null) ? '' : data.Region.RegionName.toString()
        //.concat((data.SubBU.SubBUName == null) ? '' : ' > ' + data.SubBU.SubBUName.toString())
        .concat((data.Site.SiteName == null) ? '' : ' > ' + data.Site.SiteName.toString())
        //.concat((data.SubSite.SubSiteName == null) ? '' : ' > ' + data.SubSite.SubSiteName.toString())
        .concat((data.SRU.SRUName == null) ? '' : ' > ' + data.SRU.SRUName.toString())
        .concat((data.Department.DeptName == null) ? '' : ' > ' + data.Department.DeptName.toString())
        .concat((data.Crew.CrewName == null) ? '' : ' > ' + data.Crew.CrewName.toString())

      this.userHierarchyRoleSingle = new UserHierarchyRoles();
      this.userHierarchyRoleSingle.userRole = data.Role.RoleName;
      this.userHierarchyRoleSingle.userHierarchyName = hierarchyName;
      this.userHierarchyRoleSingle.Active = data.Active;
      // this.userHierarchyRoleSingle.userHierarchyLabel = data.UserHier
      this.userHierarchyRoleSingle.userHierarchyLabel = this.getSelectedHierarchyNameOnEditPopUp(data);


      this.userHierarchyRoleSingle.roleId = data.RoleId
      if (this.userHierarchyRoleSingle.userHierarchyLabel.length > 0) {
        this.userHierarchyRoles.push(this.userHierarchyRoleSingle);
      }
    })
  }
  // Get All BU Details
  getAllBU(): void {
    this._buService.getAllBUByRole().subscribe(
      (data: BU[]) => {
        this._objArrBU = data;
        this._objArrBU = data.sort((a, b) => a.BUName.localeCompare(b.BUName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorBU') });
      });
  }
  // Get All SubBUdropdown Details
  getSubBUDropdown(): void {
    this._subBUService.getSubBUDropdown().subscribe(
      (data: SubBU[]) => {
        this._objTblArrSubBU = data;
        //this._objTblArrSubBU.sort((a,b)=>a.SubBUName.localeCompare(b.SubBUName));

      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorSubBU') });
      });
  }
  // Get All Regiondropdown Details
  getRegionDropdown(): void {
    this._regionService.getRegionByBUByRole(this._ddlBUId).subscribe(
      (data: Region[]) => {
        this._objTblArrRegions = data;
        this._objTblArrRegions = this._objTblArrRegions.sort((a, b) => a.RegionName.localeCompare(b.RegionName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorRegion') });
      });
  }
  // Get All Site Details
  getSiteDropdown(): void {
    this._siteService.getSiteDropdown().subscribe(
      (data: Site[]) => {
        this._objTblArrSite = data.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
        // this._objTblArrSite=this._objTblArrSite.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorSite') });
      });
  }
  // Get SubSite Dropdown
  getSubSiteDropdown(): void {
    this._subSiteService.getSubSiteDropdown().subscribe(
      (data: SubSite[]) => {
        this._objTblArrSubSite = data;
        this._objTblArrSubSite = this._objTblArrSubSite.sort((a, b) => a.SubSiteName.localeCompare(b.SubSiteName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorSubSite') });
      });
  }
  // Get SRU Dropdown Details
  getSRUDropdown(): void {
    this._sruService.getSruDropdown().subscribe(
      (data: SRU[]) => {
        this._objTblArrSRU = data;
        this._objTblArrSRU = this._objTblArrSRU.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
        //this._objTblArrSRU.sort((a,b)=>a.SRUName.localeCompare(b.SRUName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorSRU') });
      });
  }
  // Get Department Dropdown Details
  getDeptDropdown(): void {
    this._deptService.getDeptDropdown().subscribe(
      (data: Department[]) => {
        this._objTblArrDept = data;
        this._objTblArrDept = this._objTblArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
        //this._objTblArrDept.sort((a,b)=>a.DeptName.localeCompare(b.DeptName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorDepartment') });
      });
  }

  // Get Crew Dropdown Details
  getCrewDropdown(): void {
    this._crewService.getCrewDropdown().subscribe(
      (data: Crew[]) => {
        this._objTblArrCrew = data;
        this._objTblArrCrew = this._objTblArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
        //this._objTblArrCrew.sort((a,b)=>a.CrewName.localeCompare(b.CrewName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorCrew') });
      });
  }


  /*********************************************************************** */
  /* ADD All the Excel Details into the Grid --------------------------------------------*/
  /*********************************************************************** */
  text: any;
  JSONData: any;

  getRegionByBU(_objBUId): void {

    if (this.ddlBU != 0) {
      this._buService.GetBUChildrens(_objBUId).subscribe(
        (data: BU[]) => {

          this._objArrRegions = data[0].Regions;
          this._objArrSubBU = data[0].SubBUs;
          this._objArrSite = data[0].Sites;
          this._objArrSubSite = data[0].SubSites;
          this._objArrSRU = data[0].SRUs;
          this._objArrDept = data[0].Departments;
          this._objArrCrew = data[0].Crews;
          this._objArrRegions = this._objArrRegions.sort((a, b) => a.RegionName.localeCompare(b.RegionName));
          this._objArrSubBU = this._objArrSubBU.sort((a, b) => a.SubBUName.localeCompare(b.SubBUName));
          this._objArrSite = this._objArrSite.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
          this._objArrSubSite = this._objArrSubSite.sort((a, b) => a.SubSiteName.localeCompare(b.SubSiteName));
          this._objArrSRU = this._objArrSRU.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._objArrDept = this._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._objArrCrew = this._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));

        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingRegion') });
        }
      );
    }
    if (this.ddlBUAdd != 0) {
      this._buService.GetBUChildrens(this.ddlBUAdd).subscribe(
        (data: BU[]) => {
          this._objAddArrRegions = data[0].Regions;
          this._objArrSubBUAdd = data[0].SubBUs;
          this._objArrSiteAdd = data[0].Sites;
          this._objArrSubSiteAdd = data[0].SubSites;
          this._objArrSRUAdd = data[0].SRUs;
          this._objArrDeptAdd = data[0].Departments;
          this._objArrCrewAdd = data[0].Crews;
          this._objAddArrRegions = this._objAddArrRegions.sort((a, b) => a.RegionName.localeCompare(b.RegionName));
          this._objArrSubBUAdd = this._objArrSubBUAdd.sort((a, b) => a.SubBUName.localeCompare(b.SubBUName));
          this._objArrSiteAdd = this._objArrSiteAdd.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
          this._objArrSubSiteAdd = this._objArrSubSiteAdd.sort((a, b) => a.SubSiteName.localeCompare(b.SubSiteName));
          this._objArrSRUAdd = this._objArrSRUAdd.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._objArrDeptAdd = this._objArrDeptAdd.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._objArrCrewAdd = this._objArrCrewAdd.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingRegion') });
        }
      );
    }
  }

  // Get All SubBU By Region for SubBU dropdown binding
  getRegionByParent(ddlgridflag, _objBUId): void {
    this._regionService.getRegionByBU(_objBUId).subscribe(
      (data: Region[]) => {

        this._objTblArrRegions = data;
        this._objTblArrRegions = this._objTblArrRegions.sort((a, b) => a.RegionName.localeCompare(b.RegionName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorRegion') });
      }
    );

  }
  // Get All SubBU By Region for SubBU dropdown binding
  getsubBUByRegion(ddlgridflag, _objBUId, _objRegionId): void {
    if (this.ddlRegion != 0) {
      this._regionService.GetRegionChildren(_objBUId, _objRegionId).subscribe(
        (data: Region[]) => {
          let region: Region;
          region = data.filter(x => x.RegionId == _objRegionId)[0];
          this._objArrSubBU = region.SubBUs;
          this._objArrSite = region.Sites;
          this._objArrSubSite = region.SubSites;
          this._objArrSRU = region.SRUs;
          this._objArrDept = region.Departments;
          this._objArrCrew = region.Crews;
          this._objArrSubBU = this._objArrSubBU.sort((a, b) => a.SubBUName.localeCompare(b.SubBUName));
          this._objArrSite = this._objArrSite.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
          this._objArrSubSite = this._objArrSubSite.sort((a, b) => a.SubSiteName.localeCompare(b.SubSiteName));
          this._objArrSRU = this._objArrSRU.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._objArrDept = this._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._objArrCrew = this._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
          // this.getSiteByParent(_objBUId, _objRegionId, 0);
          // this.getSubSiteByParent(_objBUId, _objRegionId, 0, 0);
        },
        error => {
          // this.ErrorMsgAdmin = [];
          // this.ErrorMsgAdmin.push({ severity: 'error', summary: 'Error Message', detail: 'Error while getting Sub BU details.' });
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingSubBU') });
        }

      );
    }
    if (this.ddlRegionAdd != 0) {
      this._regionService.GetRegionChildren(_objBUId, _objRegionId).subscribe(
        (data: Region[]) => {
          let region: Region;
          region = data.filter(x => x.RegionId == _objRegionId)[0];
          this._objArrSubBUAdd = region.SubBUs;
          this._objArrSiteAdd = region.Sites;
          this._objArrSubBUAdd = region.SubBUs;
          this._objArrSiteAdd = region.Sites;
          this._objArrSubSiteAdd = region.SubSites;
          this._objArrSRUAdd = region.SRUs;
          this._objArrDeptAdd = region.Departments;
          this._objArrCrewAdd = region.Crews;
          this._objArrSubBUAdd = this._objArrSubBUAdd.sort((a, b) => a.SubBUName.localeCompare(b.SubBUName));
          this._objArrSiteAdd = this._objArrSiteAdd.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
          this._objArrSubSiteAdd = this._objArrSubSiteAdd.sort((a, b) => a.SubSiteName.localeCompare(b.SubSiteName));
          this._objArrSRUAdd = this._objArrSRUAdd.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._objArrDeptAdd = this._objArrDeptAdd.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._objArrCrewAdd = this._objArrCrewAdd.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingSubBU') });
        }

      );
    }
  }

  // Get All Site By SubBU for Site dropdown binding
  getSiteBySubBU(ddlgridflag, _objBUId, _objRegionId, _objSubBUId): void {
    if (this.ddlSubBU != 0) {
      this._subBUService.GetSubBuChildren(_objBUId, _objRegionId, _objSubBUId).subscribe(
        (data: SubBU[]) => {
          let subbu = data.filter(x => x.SubBUId == _objSubBUId)[0];
          this._objArrSite = subbu.Sites;
          this._objArrSubSite = subbu.SubSites;
          this._objArrSRU = subbu.SRUs;
          this._objArrDept = subbu.Departments;
          this._objArrCrew = subbu.Crews;
          this._objArrSite = this._objArrSite.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
          this._objArrSubSite = this._objArrSubSite.sort((a, b) => a.SubSiteName.localeCompare(b.SubSiteName));
          this._objArrSRU = this._objArrSRU.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._objArrDept = this._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._objArrCrew = this._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingSite') });
        }
      );
    }
    if (this.ddlSubBUAdd != 0) {
      this._subBUService.GetSubBuChildren(_objBUId, _objRegionId, _objSubBUId).subscribe(
        (data: SubBU[]) => {
          let subbu = data.filter(x => x.SubBUId == _objSubBUId)[0];
          this._objArrSiteAdd = subbu.Sites;
          this._objArrSubSiteAdd = subbu.SubSites;
          this._objArrSRUAdd = subbu.SRUs;
          this._objArrDeptAdd = subbu.Departments;
          this._objArrSiteAdd = this._objArrSiteAdd.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
          this._objArrSubSiteAdd = this._objArrSubSiteAdd.sort((a, b) => a.SubSiteName.localeCompare(b.SubSiteName));
          this._objArrSRUAdd = this._objArrSRUAdd.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._objArrDeptAdd = this._objArrDeptAdd.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._objArrCrewAdd = this._objArrCrewAdd.sort((a, b) => a.CrewName.localeCompare(b.CrewName));


        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingSite') });
        }
      );
    }
  }

  // Get All SubSite By Site for SubSite dropdown binding
  getSubSiteBySite(ddlgridflag, _objBUId, _objRegionId, _objSubBUId, _objSiteId): void {


    if (this.ddlSite != 0) {
      this._siteService.GetSiteChildren(_objBUId, _objRegionId, _objSubBUId, _objSiteId).subscribe(
        (data: Site[]) => {
          let site = data.filter(x => x.SiteId == _objSiteId)[0]
          this._objArrSubSite = site.SubSites;
          this._objArrSRU = site.SRUs;
          this._objArrDept = site.Departments;
          this._objArrCrew = site.Crews;
          this._objArrSubSite = this._objArrSubSite.sort((a, b) => a.SubSiteName.localeCompare(b.SubSiteName));
          this._objArrSRU = this._objArrSRU.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._objArrDept = this._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._objArrCrew = this._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetSubSite') });
        }
      );
    }
    if (this.ddlSiteAdd != 0) {
      this._siteService.GetSiteChildren(_objBUId, _objRegionId, _objSubBUId, _objSiteId).subscribe(
        (data: Site[]) => {
          let site = data.filter(x => x.SiteId == _objSiteId)[0]
          this._objArrSubSiteAdd = site.SubSites;
          this._objArrSRUAdd = site.SRUs;
          this._objArrDeptAdd = site.Departments;
          this._objArrCrewAdd = site.Crews;
          this._objArrSubSiteAdd = this._objArrSubSiteAdd.sort((a, b) => a.SubSiteName.localeCompare(b.SubSiteName));
          this._objArrSRUAdd = this._objArrSRUAdd.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._objArrDeptAdd = this._objArrDeptAdd.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._objArrCrewAdd = this._objArrCrewAdd.sort((a, b) => a.CrewName.localeCompare(b.CrewName));

        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetSubSite') });
        }
      );
    }
  }
  // Get All SRU By SubSite for SRU dropdown binding
  getSruBySubSite(ddlgridflag, _objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSiteId): void {
    if (this.ddlSubSite != 0) {
      this._subSiteService.GetSubSiteChildren(_objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSiteId).subscribe(
        (data: SubSite[]) => {
          let subSite = data.filter(x => x.SubSiteId == _objSubSiteId)[0]
          this._objArrSRU = subSite.SRUs;
          this._objArrDept = subSite.Departments;
          this._objArrCrew = subSite.Crews;
          this._objArrSRU = this._objArrSRU.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._objArrDept = this._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._objArrCrew = this._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
        },
        error => {

          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetSRU') });
        }
      );
    }
    if (this.ddlSubSiteAdd != 0) {
      this._subSiteService.GetSubSiteChildren(_objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSiteId).subscribe(
        (data: SubSite[]) => {
          let subSite = data.filter(x => x.SubSiteId == _objSubSiteId)[0]
          this._objArrSRUAdd = subSite.SRUs;
          this._objArrDeptAdd = subSite.Departments;
          this._objArrCrewAdd = subSite.Crews;
          this._objArrSRUAdd = this._objArrSRUAdd.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
          this._objArrDeptAdd = this._objArrDeptAdd.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._objArrCrewAdd = this._objArrCrewAdd.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
        },
        error => {

          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetSRU') });
        }
      );
    }
  }

  // Get All Depts By SRU for Department dropdown binding
  getDeptBySru(ddlgridflag, _objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSiteId, _objSruId): void {

    if (this.ddlSru != 0) {
      this._sruService.GetSRUChildrens(_objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSiteId, _objSruId).subscribe(
        (data: SRU[]) => {
          let sru = data.filter(x => x.SRUId == _objSruId)[0]
          this._objArrDept = sru.Departments;
          this._objArrCrew = sru.Crews;
          this._objArrDept = this._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._objArrCrew = this._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetDept') });

        }
      );
    }
    if (this.ddlSRUAdd != 0) {
      this._sruService.GetSRUChildrens(_objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSiteId, _objSruId).subscribe(
        (data: SRU[]) => {
          let sru = data.filter(x => x.SRUId == _objSruId)[0]
          // this._objArrDept = sru.Departments;
          this._objArrDeptAdd = sru.Departments;
          this._objArrCrewAdd = sru.Crews;
          this._objArrDeptAdd = this._objArrDeptAdd.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
          this._objArrCrewAdd = this._objArrCrewAdd.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetDept') });

        }
      );
    }
  }

  // Get All Crew By Depts for Crew dropdown binding
  getCrewByDept(ddlgridflag, _objDeptId): void {
    this._crewService.getCrewByDept(_objDeptId).subscribe(
      (data: Crew[]) => {
        this._objArrCrew = data;
        if (ddlgridflag) {
          this._objArrCrew = data;
          this._objArrCrew = this._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
        } else if (this.ddlDeptAdd > 0) {
          this._objArrCrewAdd = data;
          this._objArrCrewAdd = this._objArrCrewAdd.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
        }
        //this._objArrCrew.sort((a,b)=>a.CrewName.localeCompare(b.CrewName));
        if (!ddlgridflag) {

          this._objTblArrCrew = data;
          //this._objTblArrCrew.sort((a,b)=>a.CrewName.localeCompare(b.CrewName));
        }
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorCrew') });
      }
    );
  }

  // Get All SubBU By Region for SubBU dropdown binding
  getSubBUByParent(ddlgridflag, _objBUId, _objRegionId): void {
    this._subBUService.getSubBUByParent(_objBUId, _objRegionId).subscribe(
      (data: SubBU[]) => {
        if (ddlgridflag) {
          this._objArrSubBU = data;
        } else if (this.ddlBUAdd > 0) {
          this._objArrSubBUAdd = data;
        }
        if (!ddlgridflag) {
          this._objTblArrSubBU = data;
          //this._objTblArrSubBU.sort((a,b)=>a.SubBUName.localeCompare(b.SubBUName));

        }
        //this._objArrSubBU.sort((a,b)=>a.SubBUName.localeCompare(b.SubBUName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorSubBU') });
      }
    );
  }

  // Get All Site By BU,Region,SubBU for Site dropdown binding
  getSiteByParent(ddlgridflag, _objBUId, _objRegionId, _objSubBUId): void {
    this._siteService.getSiteByParent(_objBUId, _objRegionId, _objSubBUId).subscribe(
      (data: Site[]) => {
        if (ddlgridflag) {
          this._objArrSite = data;
        } else if (this.ddlBUAdd > 0) {
          this._objArrSiteAdd = data;
        }
        if (!ddlgridflag) {
          this._objTblArrSite = data;
          //this._objTblArrSite.sort((a,b)=>a.SiteName.localeCompare(b.SiteName));
        }
        //this._objArrSite.sort((a,b)=>a.SiteName.localeCompare(b.SiteName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorSite') });
      }
    );
  }

  // Get All SubSite By BU,Region,SubBU,SIte for SubSite dropdown binding
  getSubSiteByParent(ddlgridflag, _objBUId, _objRegionId, _objSubBUId, _objSiteId): void {
    this._subSiteService.getSubSiteByParent(_objBUId, _objRegionId, _objSubBUId, _objSiteId).subscribe(
      (data: SubSite[]) => {
        if (ddlgridflag) {
          this._objArrSubSite = data;
        } else if (this.ddlBUAdd > 0) {
          this._objArrSubSiteAdd = data;
        }
        if (!ddlgridflag) {
          this._objTblArrSubSite = data;
          //this._objTblArrSubSite.sort((a,b)=>a.SubSiteName.localeCompare(b.SubSiteName));
        }
        //this._objArrSubSite.sort((a,b)=>a.SubSiteName.localeCompare(b.SubSiteName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorSubSite') });
      }
    );
  }

  // Get All SRU By BU,Region,SubBU,SIte for SubSite dropdown binding
  getSRUByParent(ddlgridflag, _objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSiteId): void {
    this._sruService.getSruByParent(_objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSiteId).subscribe(
      (data: SRU[]) => {
        if (ddlgridflag) {
          this._objArrSRU = data;

        } else if (this.ddlBUAdd > 0) {
          this._objArrSRUAdd = data;
        }
        if (!ddlgridflag) {
          this._objTblArrSRU = data;
          //this._objTblArrSRU.sort((a,b)=>a.SRUName.localeCompare(b.SRUName));
        }
        //this._objArrSRU.sort((a,b)=>a.SRUName.localeCompare(b.SRUName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorSRU') });
      }
    );
  }

  // Get All Department By BU,Region,SubBU,Site, Subsite for Department dropdown binding
  getDepartmentByParent(ddlgridflag, _objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSiteId, _objSruId): void {
    this._deptService.getDepartmentByParent(_objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSiteId, _objSruId).subscribe(
      (data: Department[]) => {
        //this._objArrDept = data;
        if (ddlgridflag) {
          this._objArrDept = data;
          this._objArrDept = this._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
        }
        else if (this.ddlBUAdd > 0) {
          this._objArrDeptAdd = data;
          this._objArrDeptAdd = this._objArrDeptAdd.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
        }
        if (!ddlgridflag) {
          this._objTblArrDept = data;
          //this._objTblArrDept.sort((a,b)=>a.DeptName.localeCompare(b.DeptName));
        }
        //this._objArrDept.sort((a,b)=>a.DeptName.localeCompare(b.DeptName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorDepartment') });
      }
    );
  }

  // Get All Crew By BU,Region,SubBU,Site, Subsite,SRU, Dept for Crew dropdown binding
  getCrewByParent(ddlgridflag, _objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSiteId, _objSruId, _objDeptId): void {
    this._crewService.getCrewByParent(_objBUId, _objRegionId, _objSubBUId, _objSiteId, _objSubSiteId, _objSruId, _objDeptId).subscribe(
      (data: Crew[]) => {
        if (ddlgridflag) {
          this._objArrCrew = data;
        } else if (this.ddlBUAdd > 0) {
          this._objArrCrewAdd = data;
        }
        if (!ddlgridflag) {
          this._objTblArrCrew = data;
          //this._objTblArrCrew.sort((a,b)=>a.CrewName.localeCompare(b.CrewName));
        }
        //this._objArrCrew.sort((a,b)=>a.CrewName.localeCompare(b.CrewName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorCrew') });
      }
    );
  }

  getAllContractors(): void {

    if (this._helperService._contractors.length == 0) {
      let searchObj = new HierarchySearchViewModel();
      searchObj.Active = true;

      this._contractorService.getAllContractor(searchObj).subscribe(
        (data: Contractor[]) => {
          this._helperService._contractors = data.sort((a, b) => a.ContractorName.localeCompare(b.ContractorName));
          this.objArrContractorsAdd = data.sort((a, b) => a.ContractorName.localeCompare(b.ContractorName));
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorContractor') });
        }
      );
    }
    else {
      this.objArrContractorsAdd = this._helperService._contractors.sort((a, b) => a.ContractorName.localeCompare(b.ContractorName));
    }
  }

  // Get All Languages for Language dropdown binding
  getAllLanguages(): void {
    this._objArrLanguages = this._helperService._languages;
    this._objTblArrLanguages = this._helperService._languages;
    this.objArrLanguagesAdd = this._helperService._languages;

  }

  // Get All dropdown data for edit popup dropdowns binding
  getDropdownDataforEditPopupLoadScreen(BUId, RegionId, SubBUId, SiteId, SubSiteId, SRUId, DepartmentId): void {
    this.ClearSelectionOnChange(2);
    this._objAddArrRegions = [];
    this._objArrSubBUAdd = [];
    this._objArrSiteAdd = [];
    this._objArrSubSiteAdd = [];
    this._objArrSRUAdd = [];
    this._objArrDeptAdd = [];
    this._objArrCrewAdd = [];

    this._regionService.getRegionByBU(BUId).subscribe(
      (data: Region[]) => {
        this._objAddArrRegions = data;
        this._objAddArrRegions = this._objAddArrRegions.sort((a, b) => a.RegionName.localeCompare(b.RegionName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorRegion') });
      }
    );

    this._subBUService.getSubBUByParent(BUId, RegionId).subscribe(
      (data: SubBU[]) => {
        this._objArrSubBUAdd = data;
        this._objArrSubBUAdd = this._objArrSubBUAdd.sort((a, b) => a.SubBUName.localeCompare(b.SubBUName));
        //this._objTblArrSubBU.sort((a,b)=>a.SubBUName.localeCompare(b.SubBUName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorSubBU') });
      }
    );

    this._siteService.getSiteByParent(BUId, RegionId, SubBUId).subscribe(
      (data: Site[]) => {
        this._objArrSiteAdd = data;
        this._objArrSiteAdd = this._objArrSiteAdd.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorSite') });
      }
    );

    this._subSiteService.getSubSiteByParent(BUId, RegionId, SubBUId, SiteId).subscribe(
      (data: SubSite[]) => {
        this._objArrSubSiteAdd = data;
        this._objArrSubSiteAdd = this._objArrSubSiteAdd.sort((a, b) => a.SubSiteName.localeCompare(b.SubSiteName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorSubSite') });
      }
    );

    this._sruService.getSruByParent(BUId, RegionId, SubBUId, SiteId, SubSiteId).subscribe(
      (data: SRU[]) => {
        this._objArrSRUAdd = data;
        this._objArrSRUAdd = this._objArrSRUAdd.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorSRU') });
      }
    );

    this._deptService.getDepartmentByParent(BUId, RegionId, SubBUId, SiteId, SubSiteId, SRUId).subscribe(
      (data: Department[]) => {
        this._objArrDeptAdd = data;
        this._objArrDeptAdd = this._objArrDeptAdd.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorDepartment') });
      }
    );

    this._crewService.getCrewByParent(BUId, RegionId, SubBUId, SiteId, SubSiteId, SRUId, DepartmentId).subscribe(
      (data: Crew[]) => {
        this._objArrCrewAdd = data;
        this._objArrCrewAdd = this._objArrCrewAdd.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorCrew') });
      }
    );
  }

  /*********************************************************************** */
  /*Add and Search Functionality --------------------------------------------*/
  /*********************************************************************** */
  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.SearchUser();
    }
  }

  receiveNode($event) {
    this.isHierarcySelected = true;
    this.treeValue = $event;
    //  this.getRolesforHierarchy(this.treeValue);
  }

  getRolesforDropdown() {
    if (this._helperService._roles.length > 0) {
      this.roleForDropDowns = this._helperService._roles;
      this.roleForDropDowns = this.roleForDropDowns.sort((a, b) => a.RoleName.localeCompare(b.RoleName));
    }
    else {
      this._roleService.getAllRoles().subscribe((data: Role[]) => {
        this._helperService._roles = data;
        this.roleForDropDowns = data;
        this.roleForDropDowns = this.roleForDropDowns.sort((a, b) => a.RoleName.localeCompare(b.RoleName));
      });
    }
  }




  getSelectedHierarchyNameOnTreeSelect(): string {
    let val = '';
    // if (this.treeValue.BUName != '') {
    //   val = val + ' ' + this.treeValue.BUName + ' >'
    // }
    if (this.treeValue.RegionName != '') {
      val = val + ' ' + this.treeValue.RegionName + ' >'
    }
    if (this.treeValue.SubBUName != '') {
      val = val + ' ' + this.treeValue.SubBUName + ' >'
    }
    if (this.treeValue.SiteName != '') {
      val = val + ' ' + this.treeValue.SiteName + ' >'
    }
    if (this.treeValue.SubSiteName != '') {
      val = val + ' ' + this.treeValue.SubSiteName + ' >'
    }
    if (this.treeValue.SRUName != '') {
      val = val + ' ' + this.treeValue.SRUName + ' >'
    }
    if (this.treeValue.DeptName != '') {
      val = val + ' ' + this.treeValue.DeptName + ' >'
    }
    if (this.treeValue.CrewName != '') {
      val = val + ' ' + this.treeValue.CrewName + ' >'
    }
    if (val.lastIndexOf('>') == val.length - 1) {
      val = val.substring(0, val.length - 1);
    }

    return val;
  }

  onRoleChange(event) {
    // this.selectedRoleID = event.value.RoleId;
    // this.selectedRoleName = event.value.RoleName;
    this.selectedRole = event;
    this.selectedHierarchyName = this.getSelectedHierarchyNameOnTreeSelect();
    //this.selectedHierarchyNameForPopup = this.selectedHierarchyName

    //   userHierarchyRoles: UserHierarchyRoles[];
    // userHierarchyRoleSingle: UserHierarchyRoles;
    this.userHierarchyRoleSingle = new UserHierarchyRoles();
    if (this.usertoAddOrEdit != undefined && this.usertoAddOrEdit.UserId > 0) {
      this.usertoAddOrEdit.UserRoles = [];
      let userroles = new UserRole;
      userroles.RoleId = this.selectedRole.RoleId;
      userroles.UserId = this.usertoAddOrEdit.UserId;

      this.userHierarchyRoleSingle.userId = this.usertoAddOrEdit.UserId;
      //this.userHierarchyRoleSingle.userHierarchyName = this.treeValue.Name;
      this.userHierarchyRoleSingle.userHierarchyName = this.selectedHierarchyName;
      this.userHierarchyRoleSingle.userHierarchyLabel = this.treeValue.Name;
      this.userHierarchyRoleSingle.userRole = this.selectedRole.RoleName;
      this.userHierarchyRoleSingle.roleId = userroles.RoleId;

      this.usertoAddOrEdit.UserRoles.push(userroles)

    }
    else {
      let userroles = new UserRole;
      userroles.RoleId = this.selectedRoleID;
      //userroles.UserId = this.usertoAddOrEdit.UserId;
      //this.userHierarchyRoleSingle.userId = this.usertoAddOrEdit.UserId;
      // this.userHierarchyRoleSingle.userHierarchyName = this.treeValue.Name;
      this.userHierarchyRoleSingle.userHierarchyName = this.selectedHierarchyName
      this.userHierarchyRoleSingle.userHierarchyLabel = this.treeValue.Name;
      this.userHierarchyRoleSingle.userRole = this.selectedRole.RoleName;
      this.userHierarchyRoleSingle.roleId = this.selectedRole.RoleId;
    }

  }

  validateInputs(): boolean {
    this.isEditing = false;
    //this.isObserver=false;

    // if (this.ddlBUAdd === 0) {
    //   this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.validationBU') });

    //   this.ddlBUFlag = true;
    //   return false;
    // }
    // this.ddlBUFlag = false;


    if (this.txtLoginIdAdd.trim() === '') {
      this.ddlBUFlag = false;
      this._messageService.clear();
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.validationLoginID') });
      this.txtLoginIdFlag = true;
      return false;
    }
    this.txtLoginIdFlag = false;


    if (this.txtFirstNameAdd.trim() === '') {
      this.ddlBUFlag = false;
      this._messageService.clear();
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.validationFirstName') });
      this.txtFirstNameFlag = true;
      return false;
    }
    this.txtFirstNameFlag = false;


    if (this.txtLastNameAdd.trim() === '') {
      this.ddlBUFlag = false;
      this._messageService.clear();
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.validationLastName') });
      this.txtLastNameFlag = true;
      return false;
    }
    this.txtLastNameFlag = false;

    if (this.txtEmailAddressAdd.trim() === '') {
      this.ddlBUFlag = false;
      this._messageService.clear();
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.validationEmailAddress') });
      this.txtEmailAddressFlag = true;
      return false;
    }
    this.txtEmailAddressFlag = false;

    let emailRegex = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$");
    // let emailRegexAu = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$");
    if (emailRegex.test(this.txtEmailAddressAdd.toLowerCase()) == false) {
      this._messageService.clear();
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.validationEmailAddressFormat') });
      this.txtEmailAddressFlag = true;
      return false;
    }
    this.txtEmailAddressFlag = false;
    this.txtCountryCodeFlag = false;
    this.txtMobileNumberFlag = false;
  }




  addRole(): any {
    let roleId
    let duplicateRoleCount = 0;
    this.createdBY = localStorage.getItem("_loginId");
    if (this.selectedRole == undefined) {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.validationRole') });
    }
    else {
      if (this.treeValue == null) {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.validationHierForRole') });

      }
      else {
        this._userRoles.forEach(x => {
          roleId = x.RoleId;
          if (x.BUId == this.treeValue.BuId && x.RegionId == this.treeValue.RegionId && x.SubBUId == this.treeValue.SubBuId && x.SiteId == this.treeValue.SiteId &&
            x.SubSiteId == this.treeValue.SubSiteId && x.SRUId == this.treeValue.SruId && x.DepartmentId == this.treeValue.DepartmentId && x.CrewId == this.treeValue.CrewId && roleId == this.selectedRole.RoleId && x.Active) {
            duplicateRoleCount++;
          }
        });
        if (duplicateRoleCount > 0) {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.RoleAlreadyExists') });
        }
        else {
          let hierarchyName = '';
          if (this.treeValue.RegionId != null) {
            hierarchyName = hierarchyName + ' ' + this.treeValue.RegionName + ' >'
          }
          if (this.treeValue.SubBuId != null) {
            hierarchyName = hierarchyName + ' ' + this.treeValue.SubBUName + ' >'
          }
          if (this.treeValue.SiteId != null) {
            hierarchyName = hierarchyName + ' ' + this.treeValue.SiteName + ' >'
          }
          if (this.treeValue.SubSiteId != null) {
            hierarchyName = hierarchyName + ' ' + this.treeValue.SubSiteName + ' >'
          }
          if (this.treeValue.SruId != null) {
            hierarchyName = hierarchyName + ' ' + this.treeValue.SRUName + ' >'
          }
          if (this.treeValue.DepartmentId != null) {
            hierarchyName = hierarchyName + ' ' + this.treeValue.DeptName + ' >'
          }
          if (this.treeValue.CrewId != null) {
            hierarchyName = hierarchyName + ' ' + this.treeValue.CrewName + ' >'
          }
          if (hierarchyName.lastIndexOf('>') == hierarchyName.length - 1) {
            hierarchyName = hierarchyName.substring(0, hierarchyName.length - 1);
          }

          this.userHierarchyRoleSingle = new UserHierarchyRoles();
          this.userHierarchyRoleSingle.userRole = this.selectedRole.RoleName;
          this.userHierarchyRoleSingle.userHierarchyName = hierarchyName;
          hierarchyName = '';
          // this.userHierarchyRoleSingle.userHierarchyLabel = data.UserHier
          this.userHierarchyRoleSingle.userHierarchyLabel = this.getSelectedHierarchyNameOnAddPopUp(this.treeValue);
          this.userHierarchyRoleSingle.roleId = this.selectedRole.RoleId
          this.userHierarchyRoleSingle.Active = true;
          this.userHierarchyRoles.push(this.userHierarchyRoleSingle);
          this.SaveRole(this.treeValue);
        }
      }
    }
    //  }

  }

  deleteRole(rowToDelete) {
    this.userHierarchyRoles.splice(
      this.userHierarchyRoles.indexOf(this.userHierarchyRoles.find(x => x.roleId == rowToDelete.roleId && x.Active == true)), 1
    );
    this._userRoles.find(x => x.RoleId == rowToDelete.roleId && x.Active == true).Active = false;
  }

  openRolePopup(rowData) {
    this.displayRoleHierarchyDetailsPopup = true;
    this._pathDetails = rowData.userHierarchyName.split(' > ');
  }

  saveUser() {
    let isValidationSuccessfull: boolean
    isValidationSuccessfull = (this.validateInputs() == undefined) ? true : false
    if (isValidationSuccessfull) {
      if (this.txtFirstNameAdd != '' && this.txtEmailAddressAdd != '' && this.txtLastNameAdd != '') {
        this.AddUserobj.UserId = (this.usertoAddOrEdit == undefined) ? 0 : this.usertoAddOrEdit.UserId
        this.AddUserobj.FirstName = this.txtFirstNameAdd.trim();
        this.AddUserobj.MiddleName = this.txtMiddleNameAdd.trim();
        this.AddUserobj.LastName = this.txtLastNameAdd.trim();
        this.AddUserobj.EmailAddress = this.txtEmailAddressAdd.trim();
        this.AddUserobj.CountryCode = this.txtCountryCodeAdd;
        this.AddUserobj.MobileNumber = this.txtMobileNumberAdd;
        this.AddUserobj.PayrollNo = (this.txtPayrollNoAdd != '' && this.txtPayrollNoAdd != null ? this.txtPayrollNoAdd : this.txtPayrollNoAdd);
        if (this.AddUserobj.PayrollNo == null) {
          this.AddUserobj.PayrollNo = null;
        }
        this.AddUserobj.EBICardNumber = Number(this.txtEmployeeCardNoAdd != '' && this.txtEmployeeCardNoAdd != null ? this.txtEmployeeCardNoAdd : this.txtEmployeeCardNoAdd)
        if (this.AddUserobj.EBICardNumber == 0) {
          this.AddUserobj.EBICardNumber = null;
        }

        this.AddUserobj.LoginId = this.txtLoginIdAdd.trim();
        this.AddUserobj.BUId = Number(this.ddlBUAdd);
        this.AddUserobj.RegionId = Number(this.ddlRegionAdd);
        this.regionIdNew = Number(this.ddlRegionAdd);
        this.AddUserobj.SubBUId = Number(this.ddlSubBUAdd);
        this.AddUserobj.SiteId = Number(this.ddlSiteAdd);
        this.AddUserobj.SubSiteId = Number(this.ddlSubSiteAdd);
        this.AddUserobj.SRUId = Number(this.ddlSRUAdd);
        this.AddUserobj.DepartmentId = Number(this.ddlDeptAdd);
        this.AddUserobj.CrewId = Number(this.ddlCrewAdd);
        this.AddUserobj.LanguageId = Number(this.ddllangAdd);
        this.AddUserobj.ContractorId = Number(this.ddlContractorAdd);
        this.AddUserobj.Active = Boolean(this.chkActiveAdd);
        this.AddUserobj.CreatedBy = this.createdBY;
        this.AddUserobj.ModifiedBy = '';
        this.AddUserobj.CreatedDate = new Date();
        this.AddUserobj.ModifiedDate = null;
        //this.SaveRole();
        this.AddUserobj.UserRoles = this._userRoles;
        if (this.AddUserobj.UserId > 0) {
          this._userService.editUsers(this.AddUserobj).subscribe((res: HttpResponse<any>) => {
            if (res.status === 200) {
              if(this.regionIdOld == this.regionIdNew) {
                this.clearAllFields();
                this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Users.updateSuccessUser') });
                this._users = [];
                this._users = res.body;
                this.displayUserEditPopup = false;
                this.CloseAddUserPopup();
                setTimeout(() => {
                  this._messageService.clear('success');
                }, 5000);
              }
              else {
                this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Users.updateSuccessUser') });
                setTimeout(() => {
                  this._messageService.clear('success');
                  window.location.reload();
                }, 3000);
              }              
            }
            else {
              this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorupdateuser') });
              this.CloseAddUserPopup();
            }
          },
            (error: HttpErrorResponse) => {
              if (error.status == 409) {
                this.errmsg = JSON.stringify(error.error).replace('"', '').replace('"', '')
                if (this.errmsg == "Duplicate Entry") {
                  this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorDuplicateUser') });
                  this.CloseAddUserPopup();
                }

                else if (this.errmsg == "Inactive User") {
                  this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorInactiveUser') });
                  this.CloseAddUserPopup();
                  //this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: 'Validation error message', detail: JSON.stringify(error.error).replace('"', '').replace('"', '') });
                }

              }
              else {
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorAddUser') });
                this.CloseAddUserPopup();
              }
            });
        }
        else {
          this._userService.addUsers(this.AddUserobj).subscribe((res: HttpResponse<any>) => {
            if (res.status === 200) {
              this.clearAllFields();
              this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Users.successUser') });

              // this.getAllusers();
              this._users = [];
              this._users = res.body;
              this.displayUserAddPopup = false;
              this.CloseAddUserPopup();
              setTimeout(() => {
                this._messageService.clear('success');
              }, 5000);
            }
            else {
              this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorAddUser') });
              this.CloseAddUserPopup();
            }
          },
            (error: HttpErrorResponse) => {
              if (error.status == 409) {
                this.errmsg = JSON.stringify(error.error).replace('"', '').replace('"', '')
                if (this.errmsg == "Duplicate Entry") {
                  this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorDuplicateUser') });
                  this.CloseAddUserPopup();
                }
                else if (this.errmsg == "Inactive User") {
                  this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorInactiveUser') });
                  this.CloseAddUserPopup();
                  //this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: 'Validation error message', detail: JSON.stringify(error.error).replace('"', '').replace('"', '') });
                }

              }
              else {
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.errorAddUser') });
                this.CloseAddUserPopup();
              }
            });
        }

      }
    }
  }

  /*********************************************************************** */
  /* Close Redirect Success popup Function Implementation --------------------------------------------*/
  /*********************************************************************** */
  RedirectSucessPopup() {
    this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Users.successUser') });
    this._userService.successFlag = true;
  }

  /*********************************************************************** */
  /* Close Save User popup Function Implementation --------------------------------------------*/
  /*********************************************************************** */
  CloseAddUserPopup() {
    this.popupFlag.emit(false);
    this.displayUserEditPopup = false;
    this._isLoginIdPresent = false;
  }


  SaveUserSucessPopup() {
    this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Users.successUsers') });
    this._userService.saveUserOkFlag = true;
  }


  /*********************************************************************** */
  /* Table Row Edit Functionality --------------------------------------------*/
  /*********************************************************************** */

  EditUserDetails(rowData) {
    this._userFirstName = rowData.FirstName;
    this._userMiddleName = rowData.MiddleName;
    this._userLastName = rowData.LastName;
    this._userEmail = rowData.EmailAddress;
    this._Active = rowData.Active;
    this._userLoginId = rowData.LoginId;
    this._userPayRoleNo = rowData.PayrollNo;
    this._userEBICardNumber = rowData.EBICardNumber;
    if (rowData.BUId > 0) {
      this.getRegionByBU(rowData.BUId);
    }
    else {
      this.getRegionDropdown();
      this.getSiteDropdown();
      this.getSubSiteDropdown();
      this.getSRUDropdown();
      this.getDeptDropdown();
      this.getCrewDropdown();
    }
    if (!this.isEditing) {
      rowData.isEditable = !rowData.isEditable;
      this.isEditing = true;
    }
  }

  EditUserCancel(rowData) {
    rowData.FirstName = this._userFirstName;
    rowData.MiddleName = this._userMiddleName;
    rowData.LastName = this._userLastName;
    rowData.LoginId = this._userLoginId;
    rowData.PayrollNo = this._userPayRoleNo;
    rowData.EBICardNumber = this._userEBICardNumber;
    rowData.EmailAddress = this._userEmail;
    rowData.Active = this._Active;

    rowData.isEditable = !rowData.isEditable;
    this.isEditing = false;
  }

  onRowEdit(UserData: User, event): void {
    if (event.keyCode === 9) {
      this.SetModColUser(UserData);
    }
  }

  SetModColUser(UserData: User): any {
    if (UserData.FirstName == '') {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.validationFirstName') });
      this.firstNameFlag = true;
      return false;
    }
    this.firstNameFlag = false;

    if (UserData.MiddleName == "") {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.validationMiddle') });
      this.middleNameFlag = true;
      return false;
    }
    this.middleNameFlag = false;

    if (UserData.LastName == "") {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.validationLastName') });
      this.lastNameFlag = true;
      return false;
    }
    this.lastNameFlag = false;

    if (UserData.EmailAddress == "") {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.validationEmailAddress') });
      this.emailFlag = true;
      return false;
    }
    this.emailFlag = false;
  }


  // Dropdown change events
  onSelectBU(event: any, ddlgridflag) {
    if (ddlgridflag) {    
      this.ddlRegionAdd = 0;
      this.ddlSubBUAdd = 0;
      this.ddlSiteAdd = 0;
      this.ddlSubSiteAdd = 0;
      this.ddlSRUAdd = 0;
      this.ddlDeptAdd = 0;
      this._objArrRegions = [];
      this._objArrSubBU = [];
      this._objArrSite = [];
      this._objArrSubSite = [];
      this._objArrSRU = [];
      this._objArrDept = [];
      if (this.ddlBU > 0) {
        this.getRegionByBU(event.target.value);
      }
    }
    else {
      this.EditUserObj.BUId = event.target.value;
      this._ddlBUId = 0;
      this.EditUserObj.RegionId = 0;
      this.EditUserObj.SubBUId = 0;
      this.EditUserObj.SiteId = 0;
      this.EditUserObj.SubSiteId = 0;
      this.EditUserObj.SRUId = 0;
      this.EditUserObj.DepartmentId = 0;
      this.EditUserObj.CrewId = 0;
      this.getRegionByBU(event.target.value);
    }
  }
  onSelectBUAdd(event: any, ddlgridflag) {
    this.ClearSelectionOnChange(2);
    this.ddlRegionAdd = 0;
    this.ddlSubBUAdd = 0;
    this.ddlSiteAdd = 0;
    this.ddlSubSiteAdd = 0;
    this.ddlSRUAdd = 0;
    this.ddlDeptAdd = 0;
    this.ddlBUAdd > 0 ? (this.ddlBUFlag = false) : (this.ddlBUFlag = true);
    this.getRegionByBU(event.target.value);
  }

  onSelectRegion_OLD(event: any, ddlgridflag) {
    this.ClearSelectionOnChange(3);
    if (ddlgridflag) {
      this.ddlSubBU = 0;
      this.ddlSite = 0;
      this.ddlSubSite = 0;
      this.ddlSru = 0;
      this.ddlDept = 0;
      this._objArrSubBU = [];
      this._objArrSite = [];
      this._objArrSubSite = [];
      this._objArrSRU = [];
      this._objArrDept = [];
      if (this.ddlRegion > 0) {
        this.getsubBUByRegion(ddlgridflag, this.ddlBU, event.target.value);
      }
    }
    else {
      this.EditUserObj.RegionId = event.target.value;
      this._ddlRegionId = 0;
      this.EditUserObj.SubBUId = 0;
      this.EditUserObj.SiteId = 0;
      this.EditUserObj.SubSiteId = 0;
      this.EditUserObj.SRUId = 0;
      this.EditUserObj.DepartmentId = 0;
      this.EditUserObj.CrewId = 0;
      this.getsubBUByRegion(ddlgridflag, this.EditUserObj.BUId, event.target.value);
      if (this.EditUserObj.RegionId == 0) {
        this.EditUserObj.SubBUId = 0;
        this.EditUserObj.SiteId = 0;
        this.EditUserObj.SubSiteId = 0;
        this.EditUserObj.SRUId = 0;
        this.EditUserObj.DepartmentId = 0;
        this.EditUserObj.CrewId = 0;
      }
    }
  }

  SearchContractors(_objRegionId, _objSiteId, _objSruId, _objDeptId, _objCrewId) {
    let objVMHierarchySearch = new HierarchySearchViewModel();
    objVMHierarchySearch.RegionId = _objRegionId
    objVMHierarchySearch.SiteId = _objSiteId;
    objVMHierarchySearch.SruId = _objSruId;
    objVMHierarchySearch.DepartmentId = _objDeptId;
    objVMHierarchySearch.Active = true;
    this.FilterContractors(objVMHierarchySearch);    
  }

  FilterContractors(obj: HierarchySearchViewModel) {
    this.objArrContractorsAdd = this._helperService._contractors.filter(x =>
      x.RegionId == ((obj.RegionId == null || obj.RegionId == 0) ? x.RegionId : obj.RegionId) &&
      x.SiteId == ((obj.SiteId == null || obj.SiteId == 0) ? x.SiteId : obj.SiteId) &&
      x.SRUId == ((obj.SruId == null || obj.SruId == 0) ? x.SRUId : obj.SruId) &&
      x.DepartmentId == ((obj.DepartmentId == null || obj.DepartmentId == 0) ? x.DepartmentId : obj.DepartmentId) &&
      x.CrewId == ((obj.CrewId == null || obj.CrewId == 0) ? x.CrewId : obj.CrewId) &&
      x.Active == ((obj.Active == null) ? x.Active : obj.Active)
    );
  }

  onSelectRegionAdd(event: any, ddlgridflag) {
    this.ClearSelectionOnChange(3);
    this.ddlSubBU = 0;
    this.ddlSite = 0;
    this.ddlSubSite = 0;
    this.ddlSru = 0;
    this.ddlDept = 0;
    this.getsubBUByRegion(ddlgridflag, this.ddlBUAdd, event.target.value);
    this.SearchContractors(event.target.value, this.ddlSiteAdd, this.ddlSRUAdd, this.ddlDeptAdd, this.ddlCrewAdd)
  }
  onSelectSubBU(event: any, ddlgridflag) {
    this.ClearSelectionOnChange(4);
    if (ddlgridflag) {
      this.ddlSite = 0;
      this.ddlSubSite = 0;
      this.ddlSru = 0;
      this.ddlDept = 0;
      this._objArrSite = [];
      this._objArrSubSite = []
      this._objArrSRU = [];
      this._objArrDept = [];
      if (this.ddlSubBU > 0) {
        this.getSiteBySubBU(ddlgridflag, this.ddlBU, this.ddlRegion, event.target.value);
      }
    }
    else {
      this.EditUserObj.SubBUId = event.target.value;
      this._ddlSubBUId = 0;
      this.EditUserObj.SiteId = 0;
      this.EditUserObj.SubSiteId = 0;
      this.EditUserObj.SRUId = 0;
      this.EditUserObj.DepartmentId = 0;
      this.EditUserObj.CrewId = 0;
      this.getSiteBySubBU(ddlgridflag, this.EditUserObj.BUId, this.EditUserObj.RegionId, event.target.value);

      if (this.EditUserObj.SubBUId == 0) {
        this.EditUserObj.SiteId = 0;
        this.EditUserObj.SubSiteId = 0;
        this.EditUserObj.SRUId = 0;
        this.EditUserObj.DepartmentId = 0;
        this.EditUserObj.CrewId = 0;
      }
    }
  }
  onSelectSubBUAdd(event: any, ddlgridflag) {
    this.ClearSelectionOnChange(4);
    this.ddlSite = 0;
    this.ddlSubSite = 0;
    this.ddlSru = 0;
    this.ddlDept = 0;
    this.getSiteBySubBU(ddlgridflag, this.ddlBUAdd, this.ddlRegionAdd, event.target.value);
  }

  onSelectSite_OLD(event: any, ddlgridflag) {
    this.ClearSelectionOnChange(5);
    if (ddlgridflag) {
      this.ddlSubSite = 0;
      this.ddlSru = 0;
      this.ddlDept = 0;
      this._objArrSubSite = [];
      this._objArrSRU = [];
      this._objArrDept = [];
      if (this.ddlSite > 0) {
        this.getSubSiteBySite(ddlgridflag, this.ddlBU, this.ddlRegion, this.ddlSubBU, event.target.value);
      }
    }
    else {
      this._ddlSiteId = 0;
      this.getSubSiteBySite(ddlgridflag, this.EditUserObj.BUId, this.EditUserObj.RegionId, this.EditUserObj.SubBUId, event.target.value);
      this.EditUserObj.SiteId = event.target.value;
      this.EditUserObj.SubSiteId = 0;
      this.EditUserObj.SRUId = 0;
      this.EditUserObj.DepartmentId = 0;
      this.EditUserObj.CrewId = 0;
    }
  }

  onSelectSiteAdd(event: any, ddlgridflag) {
    this.ClearSelectionOnChange(5);
    this.ddlSubSite = 0;
    this.ddlSru = 0;
    this.ddlDept = 0;
    this.getSubSiteBySite(ddlgridflag, this.ddlBUAdd, this.ddlRegionAdd, this.ddlSubBUAdd, event.target.value);
    this.SearchContractors(this.ddlRegionAdd, event.target.value, this.ddlSRUAdd, this.ddlDeptAdd, this.ddlCrewAdd);
  }

  onSelectSubSite(event: any, ddlgridflag) {
    this.ClearSelectionOnChange(6);
    if (ddlgridflag) {
      this.ddlSru = 0;
      this.ddlDept = 0;
      this._objArrSRU = [];
      this._objArrDept = [];
      if (this.ddlSubSite > 0) {
        this.getSruBySubSite(ddlgridflag, this.ddlBU, this.ddlRegion, this.ddlSubBU, this.ddlSite, event.target.value);
      }
    }
    else {
      this.EditUserObj.SubSiteId = event.target.value;
      this._ddlSubSiteId = 0;
      this.getSruBySubSite(ddlgridflag, this.EditUserObj.BUId, this.EditUserObj.RegionId, this.EditUserObj.SubBUId, this.EditUserObj.SiteId, event.target.value);
      this.EditUserObj.SRUId = 0;
      this.EditUserObj.DepartmentId = 0;
      this.EditUserObj.CrewId = 0;
    }
  }

  onSelectSubSiteAdd(event: any, ddlgridflag) {
    this.ClearSelectionOnChange(6);
    this.ddlSru = 0;
    this.ddlDept = 0;
    this.getSruBySubSite(ddlgridflag, this.ddlBUAdd, this.ddlRegionAdd, this.ddlSubBUAdd, this.ddlSiteAdd, event.target.value);
  }

  onSelectSru(event: any, ddlgridflag) {
    this.ClearSelectionOnChange(7);
    if (ddlgridflag) {
      this.ddlDept = 0;
      this._objArrDept = [];
      if (this.ddlSru > 0) {
        this.getDeptBySru(ddlgridflag, this.ddlBU, this.ddlRegion, this.ddlSubBU, this.ddlSite, this.ddlSubSite, event.target.value);
        this.SearchContractors(this.ddlRegion, this.ddlSite, event.target.value, this.ddlDept, this.ddlCrew);
      }
    }
    else {
      this.EditUserObj.SRUId = event.target.value;
      this._ddlSRUId = 0;
      this.getDeptBySru(ddlgridflag, this.EditUserObj.BUId, this.EditUserObj.RegionId, this.EditUserObj.SubBUId, this.EditUserObj.SiteId, this.EditUserObj.SubSiteId, event.target.value);
      this.SearchContractors(this.EditUserObj.RegionId, this.EditUserObj.SiteId, event.target.value, this.EditUserObj.DepartmentId, this.EditUserObj.CrewId);
      this.EditUserObj.DepartmentId = 0;
      this.EditUserObj.CrewId = 0;
    }
  }

  onSelectSRUAdd(event: any, ddlgridflag) {
    this.ClearSelectionOnChange(7);
    this.ddlDept = 0;
    this.getDeptBySru(ddlgridflag, this.ddlBUAdd, this.ddlRegionAdd, this.ddlSubBUAdd, this.ddlSiteAdd, this.ddlSubSiteAdd, event.target.value);
    this.SearchContractors(this.ddlRegionAdd, this.ddlSiteAdd, event.target.value, this.ddlDeptAdd, this.ddlCrewAdd);

  }
  onSelectDept_OLD(event: any, ddlgridflag) {
    this.ClearSelectionOnChange(8);
    if (ddlgridflag) {
      if (this.ddlDept > 0) {
        this.getCrewByDept(ddlgridflag, event.target.value);
      }
    }
    else {
      this.EditUserObj.DepartmentId = event.target.value;
      this.getCrewByDept(ddlgridflag, event.target.value);
      if (this.EditUserObj.DepartmentId == 0) {
        this.EditUserObj.CrewId = 0;
      }
    }
  }

  onSelectDeptAdd(event: any, ddlgridflag) {
    this.ClearSelectionOnChange(8);
    this.getCrewByDept(ddlgridflag, event.target.value);
    this.SearchContractors(this.ddlRegionAdd, this.ddlSiteAdd, this.ddlSRUAdd, event.target.value, this.ddlCrewAdd);
  }

  onSelectCrewAdd(event: any, ddlgridflag) {
    if (this.ddlCrewAdd != 0) {
      this.ddlCrewFlag = false;
    }
    else {
      this.ddlCrewFlag = true;
    }
    this.SearchContractors(this.ddlRegionAdd, this.ddlSiteAdd, this.ddlSRUAdd, this.ddlDeptAdd, event.target.value);
  }

  SearchUser() {
    this.disabledClearSearch = false;
    this.ddlBUFlag = false;
    this.txtLoginIdFlag = false;
    this.txtFirstNameFlag = false;
    this.txtLastNameFlag = false;
    this.txtEmailAddressFlag = false;

    if (this.chkActiveValue === 'All') {
      this.chkActive = null;
    } else if (this.chkActiveValue == 'No') {
      this.chkActive = false;
    } else {
      this.chkActive = true;
    }
    if (this.ddlRoles == 0) {
      this.ddlRoles = null;
    }

    if (this.txtFirstName != '') {
      this.trimtxtFirstName = this.txtFirstName.trim();
    } else {
      this.trimtxtFirstName = this.txtFirstName;
    }
    if (this.txtMiddleName != '') {
      this.trimtxtMiddleName = this.txtMiddleName.trim();
    } else {
      this.trimtxtMiddleName = this.txtMiddleName;
    }

    if (this.txtLastName != '') {
      this.trimtxtLastName = this.txtLastName.trim();
    } else {
      this.trimtxtLastName = this.txtLastName;
    }
    if (this.txtEmailAddress != '') {
      this.trimtxtEmailAddress = this.txtEmailAddress.trim();
    } else {
      this.trimtxtEmailAddress = this.txtEmailAddress;
    }
    if (this.txtLoginId != '') {
      this.trimtxtLoginId = this.txtLoginId.trim();
    } else {
      this.trimtxtLoginId = this.txtLoginId;
    }
    if (this.txtPayrollNo != '') {
      this.trimtxtPayrollNo = this.txtPayrollNo.trim();
    } else {
      this.trimtxtPayrollNo = this.txtPayrollNo;
    }
    this._userService.SearchUsers(this.ddlBU, this.ddlRegion, this.ddlSubBU, this.ddlSite, this.ddlSubSite, this.ddlSru, this.ddlDept,
      this.ddlCrew, this.ddlContractor, this.ddllang, this.trimtxtFirstName, this.trimtxtMiddleName, this.trimtxtLastName,
      this.trimtxtEmailAddress, this.trimtxtLoginId, this.chkActive, this.ddlRole).subscribe(
        (data: User[]) => {
          this._users = [];
          this._users = data;
          this.isSearched = true;
        },
        error => {
          this.isSearched = false;
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.searchErrorUser') });
        }
      );
    if (this.ddlRoles == null) {
      this.ddlRoles = 0;
    }
  }
  EditUserPopupCancel() {
    this.displayUserEditPopup = false;
  }
  CancelAddUser() {
    this.ddlBUAdd = 0;
    this._objAddArrRegions = [];
    this._objArrSubBUAdd = [];
    this._objArrSiteAdd = [];
    this._objArrSubSiteAdd = [];
    this._objArrSRUAdd = [];
    this._objArrDeptAdd = [];
    this._objArrCrewAdd = [];
    this.txtLoginIdAdd = "";
    this.txtEmailAddressAdd = "";
    this.txtFirstNameAdd = "";
    this.txtMiddleNameAdd = "";
    this.txtLastNameAdd = "";
    this.ddlContractorAdd = 0;
    this.ddllangAdd = 0;
    this.attrUserAddHeader = this.attrUserAddHeader;
    this.displayUserAddPopup = false;
  }

  ClearUserSearch() {
    this.disabledClearSearch = true;
    this._objArrRegions = [];
    this._objArrSubBU = [];
    this._objArrSite = [];
    this._objArrSubSite = [];
    this._objArrSRU = [];
    this._objArrDept = [];
    this._objArrCrew = [];
    this.ddlBU = 0;
    this.ddllang = 0;
    this.ddlContractor = 0;
    this.txtLoginId = '';
    this.txtPayrollNo = '';
    this.txtFirstName = "";
    this.txtMiddleName = "";
    this.txtLastName = "";
    this.txtEmailAddress = "";
    this.chkActive = true;
    this.chkActiveAdd = true;
    this.chkActiveValue = 'All';
    this._users = [];
    this.ddlRoles = 0;

  }
  /*********************************************************************** */
  /* ADD Role Attributies Functionality --------------------------------------------*/
  /*********************************************************************** */

  OpenRoleModal(objrowdata) {
    this._editingUsers = objrowdata;
    if (objrowdata != undefined && objrowdata != null) {
      this._roleAdd = [];
      this._roleAdd = this._editingUsers.UserRoles;
    }
    else {
      this._roleAdd = [];
    }
    if (objrowdata != undefined && !objrowdata.isEditable) {
      this.isRoleEditable = false;
    }
    else {
      this.isRoleEditable = true;
    }
    this.display = true;
  }

  SaveRole(TreeHierarchy) {
    if (this.selectedRole != undefined) {
      this._role = new Object;
      this._role.BUId = TreeHierarchy.BuId;
      this._role.SubBUId = TreeHierarchy.SubBuId;
      this._role.SiteId = TreeHierarchy.SiteId;
      this._role.RegionId = TreeHierarchy.RegionId;
      this._role.DepartmentId = TreeHierarchy.DepartmentId;
      this._role.SRUId = TreeHierarchy.SruId;
      this._role.CrewId = TreeHierarchy.CrewId;
      this._role.SubSiteId = TreeHierarchy.SubSiteId;
      this._role.Active = true;
      this._role.CreatedBy = ''
      this._role.CreatedDate = new Date();
      this._role.ModifiedBy = ''
      this._role.ModifiedDate = new Date();
      this._role.Role = this.selectedRole;
      this._role.RoleId = this.selectedRole.RoleId;
      this._role.UserId = (this.usertoAddOrEdit == undefined) ? 0 : this.usertoAddOrEdit.UserId;
      this._role.UserRoleId = 0;
      this._userRoles.push(this._role);
      this.selectedRole = undefined;
    }
  }

  CancelRole() {
    this.display = false;
  }

  onOK() {
    this._messageService.clear('error');
    this._messageService.clear('success');

    if (!this.isAdminUser) {
      this._router.navigate(['']);
    }
  }

  exportAsXLSX(): void {
    if (this._users.length === 0) {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.exportMsguser') });
    } else {
      const columns = [];
      const rows = [];
      this._users.forEach(objUser => {
        var obj = {};
        obj['Login Id'] = objUser.LoginId;
        obj['Clock No'] = objUser.PayrollNo;
        obj['First Name'] = objUser.FirstName;
        obj['Middle Name'] = objUser.MiddleName == null ? '' : objUser.MiddleName;
        obj['Last Name'] = objUser.LastName;
        obj['BU'] = objUser.BUName;
        obj['Region'] = objUser.RegionName == null ? '' : objUser.RegionName;
        obj['Sub BU'] = objUser.SubBUName == null ? '' : objUser.SubBUName;
        obj['Site'] = objUser.SiteName == null ? '' : objUser.SiteName;
        obj['Sub Site'] = objUser.SubSiteName == null ? '' : objUser.SubSiteName;
        obj['SRU'] = objUser.SRUName == null ? '' : objUser.SRUName;
        obj['Department'] = objUser.DeptName == null ? '' : objUser.DeptName;
        obj['Crew'] = objUser.CrewName == null ? '' : objUser.CrewName;
        obj['Language'] = objUser.Languages == null ? '' : objUser.Languages;
        obj['Contractor'] = objUser.Contractor == null ? '' : objUser.Contractor;
        obj['EmailAddress'] = objUser.EmailAddress == null ? '' : objUser.EmailAddress;
        if (objUser.Active == true)
          obj['Active'] = 'Yes';
        else
          obj['Active'] = 'No';
        rows.push(obj);
      });

      Object.keys(rows[0]).forEach(key =>
        columns.push({ title: key, dataKey: key })
      );
      this._excelService.exportAsExcelFile(rows, 'UserList');
    }
  }
  exportToPdf() {
    if (this._users.length === 0) {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Users.exportMsguser') });
    } else {
      const columns = [];
      const rows = [];
      this._users.forEach(objUser => {
        var obj = {};
        obj['LoginId'] = objUser.LoginId;
        obj['ClockNo'] = objUser.PayrollNo;
        obj['Name'] = objUser.FirstName + ' ' + (objUser.MiddleName == null ? '' : objUser.MiddleName + ' ') + (objUser.LastName == null ? '' : objUser.LastName);
        obj['BU'] = objUser.BUName;
        obj['Region'] = objUser.RegionName == null ? '' : objUser.RegionName;
        obj['SubBU'] = objUser.SubBUName == null ? '' : objUser.SubBUName;
        obj['Site'] = objUser.SiteName == null ? '' : objUser.SiteName;
        obj['SubSite'] = objUser.SubSiteName == null ? '' : objUser.SubSiteName;
        obj['SRU'] = objUser.SRUName == null ? '' : objUser.SRUName;
        obj['Department'] = objUser.DeptName == null ? '' : objUser.DeptName;
        obj['Crew'] = objUser.CrewName == null ? '' : objUser.CrewName;
        obj['Language'] = objUser.Languages == null ? '' : objUser.Languages;
        obj['Contractor'] = objUser.Contractor == null ? '' : objUser.Contractor;
        obj['EmailAddress'] = objUser.EmailAddress == null ? '' : objUser.EmailAddress;
        if (objUser.Active == true)
          obj['Active'] = 'Yes';
        else
          obj['Active'] = 'No';        
        rows.push(obj);
      });
      Object.keys(rows[0]).forEach(key =>
        columns.push({ title: key, dataKey: key })
      );
      this._pdfService.exportToPDFUsers(columns, rows, 'User Details', 'UserList');
    }

  }


  GetDefaulrDropdownValues() {
    if (this._helperService._buChildren.length > 0) {
      this.BindDefaultDropdownValues(this._helperService._buChildren[0]);
    }
    else {
      this._buService.GetBUChildrens(1).subscribe(
        (data: BU[]) => {
          this._helperService._buChildren = data;
          this.BindDefaultDropdownValues(data[0]);
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingRegion') });
        }
      );
    }
  }

  BindDefaultDropdownValues(buObj: BU) {
    this._objArrRegions = buObj.Regions;
    this._objArrSite = buObj.Sites;
    this._objArrSRU = buObj.SRUs;
    this._objArrDept = buObj.Departments;
    this._objArrRegions = buObj.Regions.sort((a, b) => a.RegionName.localeCompare(b.RegionName));
    this._objArrSite = buObj.Sites.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
    this._objArrSRU = buObj.SRUs.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
    this._objArrDept = buObj.Departments.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
  }

  checkNumber(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    else if (Number(event.key) <= 0 && event.target.value.length == 0)
      return false;
    else return true;
  }

  checkMobileNumber(event: any) {
    //const pattern = /[0-9\+\-\ ]/;
    const pattern = /[\d]/; //'[\d]'
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  checkCountryCode(event: any) {
    const pattern = /[\d]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  //Active status change
  checkstatus(event) {
    this.chkActive = event;
  }

  filterRoleSuggest(event) {
    let query = event.query;
    this.filteredRoles = this.filterRole(query, this.roleForDropDowns);
  }

  filterRole(query, roles: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    for (let i = 0; i < roles.length; i++) {
      let role = roles[i];
      if (role.RoleName.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(role);
      }
    }
    return filtered;
  }


  clearAllFields() {
    this.ddlBU = 0;
    this.ddlRegion = 0;
    this.ddlSubBU = 0;
    this.ddlSite = 0;
    this.ddlSubSite = 0;
    this.ddlSru = 0;
    this.ddlDept = 0;
    this.ddlCrew = 0;
    this.ddlContractor = 0;
    this.txtFirstName = "";
    this.txtMiddleName = "";
    this.txtLastName = "";
    this.txtPayrollNo = "";
    this.txtEmailAddress = "";
    this.txtLoginId = "";
    this._objArrRegions = [];
    this._objArrSubBU = [];
    this._objArrSite = [];
    this._objArrSubSite = [];
    this._objArrSRU = [];
    this._objArrDept = [];
    this._objArrCrew = [];
    this.chkActive = true;
  }



  /////////////////////////////////////
  // Common Functions
  /////////////////////////////////////  

  // getGridHeight() {
  //   let searchPanelHeight = 0, searchWidth = 0, tabmenuHeight = 0, tabRowCount = 0;
  //   if (this.elementSearch.nativeElement) {
  //     searchPanelHeight = this.elementSearch.nativeElement.offsetHeight;
  //     searchWidth = this.elementSearch.nativeElement.offsetWidth;
  //   }
  //   searchPanelHeight = searchPanelHeight != 0 ? searchPanelHeight + 21 : 130;
  //   searchWidth = searchWidth != 0 ? searchWidth : 954;
  //   tabRowCount = Math.floor(searchWidth / 954);
  //   tabmenuHeight = (35 * tabRowCount) + (tabRowCount * 2);
  //   // 90px = menu, 43px = footer, 35px = tab height 127px = search panel
  //   // 47px = search panel button, 30px = grid header Height 31px = pager, 46px = grid panel
  //   // overall padding = 30px, Width of menu with padding = 954px
  //   let gridHeight = window.innerHeight - (90 + 43 + tabmenuHeight + searchPanelHeight + 47 + 30 + 31 + 46 + 30);
  //   this._gridHeight = gridHeight;
  //   this._gridHeightPX = (gridHeight - 35).toString() + 'px';
  //   this._gridRowCount = (Math.floor(gridHeight / 30) - 1);
  //   this._gridRowCount = this._gridRowCount <= 1 ? 1 : this._gridRowCount;
  // }


  ClearSelectionOnChange(value: number) {
    switch (value) {
      case 1: this.ddlBU = 0;
        this._objArrBU = [];
      case 2: this.ddlRegion = 0;
        this.ddlRegionAdd = 0;
        this._objArrRegions = [];
        this._objAddArrRegions = [];
      case 3: this.ddlSubBU = 0;
        this.ddlSubBUAdd = 0;
        this._objArrSubBU = [];
        this._objArrSubBUAdd = [];
      case 4: this.ddlSite = 0;
        this.ddlSiteAdd = 0;
        this._objArrSite = [];
        this._objArrSiteAdd = [];
      case 5: this.ddlSubSite = 0;
        this.ddlSubSiteAdd = 0;
        this._objArrSubSite = [];
        this._objArrSubSiteAdd = [];
      case 6: this.ddlSru = 0;
        this.ddlSRUAdd = 0;
        this._objArrSRU = [];
        this._objArrSRUAdd = [];
      case 7: this.ddlDept = 0;
        this.ddlDeptAdd = 0;
        this._objArrDept = [];
        this._objArrDeptAdd = [];
      case 8: this.ddlCrew = 0;
        this.ddlCrewAdd = 0;
        this._objArrCrew = [];
        this._objArrCrewAdd = [];
    }
  }


  // Methods for Hierarchy Change

  getDropdownData(_objBUId): void {

    this._buService.GetBUChildrens(_objBUId).subscribe(
      (data: BU[]) => {
        this._objArrRegions = data[0].Regions;
        this._objArrSite = data[0].Sites;
        this._objArrSRU = data[0].SRUs;
        this._objArrDept = data[0].Departments;
        this._objArrCrew = data[0].Crews;
        this._objArrRegions = data[0].Regions.sort((a, b) => a.RegionName.localeCompare(b.RegionName));
        this._objArrSite = data[0].Sites.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
        this._objArrSRU = data[0].SRUs.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
        this._objArrDept = data[0].Departments.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
        this._objArrCrew = data[0].Crews.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingRegion') });
      }
    );
  }

  onSelectRegion(event) {
    this.disabledClearSearch = false;
    this._ddlSite = 0;
    this._ddlSRU = 0;
    this._ddlDept = 0;
    this._ddlCrew = 0;
    this._objArrSite = [];
    this._objArrSRU = [];
    this._objArrDept = [];
    this._objArrDept = [];
    this._objArrCrew = [];
    if (this.ddlRegionAdd > 0) {
      this.getRegionChildren(event.target.value);
      this.SearchContractors(event.target.value, this.ddlSiteAdd, this.ddlSRUAdd, this.ddlDeptAdd, this.ddlCrewAdd)
    }
  }

  getRegionChildren(regionId) {
    this._regionService.GetRegionChildren(0, regionId).subscribe(
      (data: Region[]) => {
        let region: Region;
        region = data.filter(x => x.RegionId == regionId)[0];
        this._objArrSite = region.Sites;
        this._objArrSRU = region.SRUs;
        this._objArrDept = region.Departments;
        this._objArrCrew = region.Crews;
        this._objArrSite = this._objArrSite.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
        this._objArrSRU = this._objArrSRU.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
        this._objArrDept = this._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
        this._objArrCrew = this._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingRegion') });
      }
    );
  }

  onSelectSite(event) {
    this.disabledClearSearch = false;
    this.ddlSRUAdd = 0;
    this.ddlDeptAdd = 0;
    this.ddlCrewAdd = 0;
    this._objArrSRU = [];
    this._objArrDept = [];
    this._objArrCrew = [];
    if (this.ddlSiteAdd > 0) {
      this.getSiteChildren(this.ddlRegionAdd, event.target.value);
      this.SearchContractors(this.ddlRegionAdd, event.target.value, this.ddlSRUAdd, this.ddlDeptAdd, this.ddlCrewAdd)
    }
  }

  getSiteChildren(regionId, siteId) {
    this._siteService.GetSiteChildren(0, regionId, 0, siteId).subscribe(
      (data: Site[]) => {
        let site = data.filter(x => x.SiteId == siteId)[0];
        this._objArrSRU = site.SRUs;
        this._objArrDept = site.Departments;
        this._objArrCrew = site.Crews;
        this._objArrSRU = this._objArrSRU.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
        this._objArrDept = this._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
        this._objArrCrew = this._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetSite') });
      }
    );
  }

  onSelectSRU(event) {
    this.disabledClearSearch = false;
    this.ddlDeptAdd = 0;
    this.ddlCrewAdd = 0;
    this._objArrDept = [];
    this._objArrCrew = [];
    if (this.ddlSRUAdd > 0) {
      this.getSRUChildren(this.ddlRegionAdd, this.ddlSiteAdd, event.target.value);
      this.SearchContractors(this.ddlRegionAdd, this.ddlSiteAdd, event.target.value, this.ddlDeptAdd, this.ddlCrewAdd);
    }
  }

  getSRUChildren(regionId, siteId, sruId) {
    this._sruService.GetSRUChildrens(0, regionId, 0, siteId, 0, sruId).subscribe(
      (data: SRU[]) => {
        let sru = data.filter(x => x.SRUId == sruId)[0];
        this._objArrDept = sru.Departments;
        this._objArrCrew = sru.Crews;
        this._objArrDept = this._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
        this._objArrCrew = this._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetSRU') });

      }
    );
  }

  onSelectDept(event) {
    this.disabledClearSearch = false;
    this.ddlCrewAdd = 0;
    this._objArrCrew = [];
    if (this.ddlDeptAdd > 0) {
      this.getDepartmentChildren(this.ddlRegionAdd, this.ddlSiteAdd, this.ddlSRUAdd, event.target.value);
      this.SearchContractors(this.ddlRegionAdd, this.ddlSiteAdd, this.ddlSRUAdd, event.target.value, this.ddlCrewAdd);
    }
  }
  onSelectCrew(event) {
    if (this._ddlCrew > 0) {
      this.SearchContractors(this._ddlRegion, this._ddlSite, this._ddlSRU, this._ddlDept, event.target.value);
    }
  }

  getDepartmentChildren(regionId, siteId, sruId, departmentId) {
    this._deptService.GetDeptChildrens(0, regionId, 0, siteId, 0, sruId, departmentId).subscribe(
      (data: Department[]) => {
        let dept = data.filter(x => x.DepartmentId == departmentId)[0];
        this._objArrCrew = dept.Crews;
        this._objArrCrew = this._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetDept') });

      }
    )
  }

  getDropdownDataForEdit(_objBUId): void {
    this._buService.GetBUChildrens(_objBUId).subscribe(
      (data: BU[]) => {
        this._objArrRegions = data[0].Regions;
        this._objArrRegions = data[0].Regions.sort((a, b) => a.RegionName.localeCompare(b.RegionName));
        this.getRegionChildrenForEdit(this.ddlRegionAdd)
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingRegion') });
      }
    );
  }

  getRegionChildrenForEdit(regionId) {
    this._regionService.GetRegionChildren(0, regionId).subscribe(
      (data: Region[]) => {
        let region: Region;
        region = data.filter(x => x.RegionId == regionId)[0];
        this._objArrSite = region.Sites;
        this._objArrSite = this._objArrSite.sort((a, b) => a.SiteName.localeCompare(b.SiteName));
        this.getSiteChildrenForEdit(this.ddlRegionAdd, this.ddlSiteAdd)
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingRegion') });
      }
    );
  }

  getSiteChildrenForEdit(regionId, siteId) {
    this._siteService.GetSiteChildren(0, regionId, 0, siteId).subscribe(
      (data: Site[]) => {
        let site = data.filter(x => x.SiteId == siteId)[0];
        this._objArrSRU = site.SRUs;
        this._objArrSRU = this._objArrSRU.sort((a, b) => a.SRUName.localeCompare(b.SRUName));
        this.getSRUChildrenForEdit(this.ddlRegionAdd, this.ddlSiteAdd, this.ddlSRUAdd)
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetSite') });
      }
    );
  }

  getSRUChildrenForEdit(regionId, siteId, sruId) {
    this._sruService.GetSRUChildrens(0, regionId, 0, siteId, 0, sruId).subscribe(
      (data: SRU[]) => {
        let sru = data.filter(x => x.SRUId == sruId)[0];
        this._objArrDept = sru.Departments;
        this._objArrDept = this._objArrDept.sort((a, b) => a.DeptName.localeCompare(b.DeptName));
        this.getDepartmentChildrenForEdit(this.ddlRegionAdd, this.ddlSiteAdd, this.ddlSRUAdd, this.ddlDeptAdd)
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetSRU') });

      }
    );
  }

  getDepartmentChildrenForEdit(regionId, siteId, sruId, departmentId) {
    this._deptService.GetDeptChildrens(0, regionId, 0, siteId, 0, sruId, departmentId).subscribe(
      (data: Department[]) => {
        let dept = data.filter(x => x.DepartmentId == departmentId)[0];
        this._objArrCrew = dept.Crews;
        this._objArrCrew = this._objArrCrew.sort((a, b) => a.CrewName.localeCompare(b.CrewName));
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Crew.errorgetDept') });

      }
    )
  }

}
