import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GISMapViewModel } from '../ViewModel/Map/GISMapViewModel';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GeoTagService {
    constructor(private _http: HttpClient) { }
    geotagURL = environment.WEBAPIURL + 'PermitAction/geo-tag';

    addGeoTag(mapViewModel: GISMapViewModel) {
        return this._http.post(this.geotagURL, mapViewModel, { observe: 'response' });
    }
    getGeoTag(permitId) {
        return this._http.get(this.geotagURL+'/'+permitId);
    }
    getGEoTagSiteWise(siteId) {
        return this._http.get(this.geotagURL+'/map/'+siteId);
    }
}