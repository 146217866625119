import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { PermitTypeQuestion } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeQuestion';
import { PermitCreatedByUserViewModel } from '../ViewModel/PermitCreatedByUserViewModel';
import { Permit } from '../ViewModel/Permit';
import { PermitTypeVersion } from '../ViewModel/PermitBuilder/PermitTypeVersion';
import { PermitTypeAnswerOption } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeAnswerOption';
import { PermitTypeDivision } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeDivision';
import { PermitTypeSection } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeSection';
import { TableTypeResponse } from '../ViewModel/TableTypeResponse';
import { SafeHtmlPipe } from '../Pipes/SafeHtmlPipe';
import { DefaultPipeModule } from '../DefaultPipeModule';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-work-print-pdf',
  templateUrl: './work-print-pdf.component.html',
  styleUrls: ['./work-print-pdf.component.css']
})
export class WorkPrintPdfComponent implements OnInit, OnChanges {
  @Input() _PermitVersion: PermitTypeVersion;
  @Input() permitTypeName: string;
  @Input() permitTypeImage: any;
  @Input() _permitBackColor: string;
  @Input() _permitForeColor: string;
  @Input() previewFlag: string;
  @Input() revalidationHours: Number;
  @Input() revalidationPeriod: Number;
  @Input() checkRevalidation: boolean;
  @Input() _permitObj: Permit = new Permit();
  @Input() _showImage: boolean;
  @Input() _screenGrab: any;
  @Input() preview: boolean;
  @Input() permitCreatedByUser: PermitCreatedByUserViewModel = new PermitCreatedByUserViewModel();
  @Input() responseAttachmentFiles: any[] = [];
  @Input() MailRequired: boolean;
  @Output() TriggereMail = new EventEmitter<boolean>();
  au: any;
  questionFontSize: number = 0;
  sectionFontSize: number = 0;
  headerFontSize: number = 0;
  isMailRequired: boolean = false;
  permitPdfStatus: string='DRAFT';
  _excelContentType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  _pdfContentType: string = 'application/pdf'
  _wordContentType: string = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  _pptContentType: string = ''
  // _imageContentType: string = ''
  _excelThumbnailIconPath: string = '../../assets/Images/excel-icon.png'
  _pdfThumbnailIconPath: string = '../../assets/Images/pdf-icon.png'
  _imageThumbnailIconPath: string = '../../assets/Images/image-icon.png'
  _wordThumbnailIconPath: string = '../../assets/Images/word-icon.png'
  _pptThumbnailIconPath: string = '../../assets/Images/ppt-icon.png'

  data: any[] = []

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes._PermitVersion) {
      if (!changes._PermitVersion.isFirstChange()) {
        if (changes._PermitVersion.currentValue) {
          //debugger;
          this._PermitVersion = changes._PermitVersion.currentValue;
          this.questionFontSize = this._PermitVersion.PermitType.QuestionFontSize + 5;
          this.sectionFontSize = this._PermitVersion.PermitType.SectionHeaderFontSize + 5;
          this.headerFontSize = this._PermitVersion.PermitType.HeaderFontSize + 5;
        }
      }
    }
    if (changes.preview != undefined) {
      if (!changes.preview.isFirstChange()) {
        //debugger;
        this.preview = changes.preview.currentValue;
      }
    }
    if (changes.permitTypeName != undefined) {
      if (!changes.permitTypeName.isFirstChange()) {
        this.permitTypeName = changes.permitTypeName.currentValue;
      }
    }

  //  if (this._permitObj.PermitStateId != 3) {
  //     this.permitPdfStatus = "DRAFT";
  //   } 
 

    if (changes._permitBackColor != undefined) {
      if (!changes._permitBackColor.isFirstChange()) {
        this._permitBackColor = changes._permitBackColor.currentValue;
      }
    }
    if (changes._permitForeColor != undefined) {
      if (!changes._permitForeColor.isFirstChange()) {
        this._permitForeColor = changes._permitForeColor.currentValue;
      }
    }
    if (changes.permitTypeImage != undefined) {
      if (!changes.permitTypeImage.isFirstChange()) {
        this.permitTypeImage = changes.permitTypeImage.currentValue;
      }
    }
    if (changes.previewFlag != undefined) {
      if (!changes.previewFlag.isFirstChange()) {
        this.previewFlag = changes.previewFlag.currentValue;
      }
    }
    if (changes.revalidationHours != undefined) {
      if (!changes.revalidationHours.isFirstChange()) {
        this.revalidationHours = changes.revalidationHours.currentValue;
      }
    }
    if (changes._screenGrab != undefined) {
      if (!changes._screenGrab.isFirstChange()) {
        this._screenGrab = changes._screenGrab.currentValue;
      }
    }
    if (changes.MailRequired != undefined) {
      if (!changes.MailRequired.isFirstChange()) {
        this.isMailRequired = changes.MailRequired.currentValue;
      }
    }
    if (changes._permitObj != undefined) {
      if (!changes._permitObj.isFirstChange()) {
        this._permitObj = new Permit();
        if (changes._permitObj.currentValue) {
          this._permitObj = changes._permitObj.currentValue;
          this.PopulateResponsesForPermit();
        }
      }
    }
    if (changes.permitCreatedByUser != undefined) {
      if (!changes.permitCreatedByUser.isFirstChange()) {
        if (this.permitCreatedByUser && changes.permitCreatedByUser.currentValue && this.permitCreatedByUser.LoginId != changes.permitCreatedByUser.currentValue.LoginId) {
          this.permitCreatedByUser = changes.permitCreatedByUser.currentValue;
        }
        else if (!this.permitCreatedByUser && changes.permitCreatedByUser.currentValue) {
          this.permitCreatedByUser = changes.permitCreatedByUser.currentValue;
        }
      }
    }

    if (changes.responseAttachmentFiles) {
      if (!changes.responseAttachmentFiles.isFirstChange()) {
        if (changes.responseAttachmentFiles.currentValue) {
          this.responseAttachmentFiles = changes.responseAttachmentFiles.currentValue;
        }
      }
    }
  }

  constructor(private sanitizer: DomSanitizer) { 

  }

  clsBorderColor: string = "#dbdbdb";
  ngOnInit() {
    for (let index = 0; index < 1000; index++) {
      this.data.push({
        'productId': index,
        'unitPrice': 100,
        'qty': 1,
        'total': 100
      })

    }
    //this.permitContainerHeight = window.innerHeight - 280;
    this.getLocalCalender();
    if (this._PermitVersion) {
      if (this._PermitVersion.PermitType) {
        this.questionFontSize = this._PermitVersion.PermitType.QuestionFontSize + 5;
        this.sectionFontSize = this._PermitVersion.PermitType.SectionHeaderFontSize + 5;
        this.headerFontSize = this._PermitVersion.PermitType.HeaderFontSize + 5;
      }
    }
  }
  getLocalCalender() {
    this.au = {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',

      clear: 'Clear'
    }
  }
  CheckAnsOptionWidth(answerOptions: PermitTypeAnswerOption[]) {
    let ansWidthCounter = 0;
    answerOptions.forEach(ao => {
      if (ao.AnswerOptionWidth) {
        ansWidthCounter++;
      }
    });
    if (ansWidthCounter > 0) {
      return true;
    }
    else {
      return false;
    }
  }
  getAnswerOption(question: PermitTypeQuestion) {
    // if (this._allDynamicDatasourceOptions != undefined) {
    //   let obj = this._allDynamicDatasourceOptions.find(x => x.permitTypeQuestionId == question.PermitTypeQuestionId)
    //   if (obj != undefined) {
    //     return obj.dynamicDataSourceOptions;
    //   }
    // }
    // else {
    //   return [];
    // }
  }

  PopulateResponsesForPermit() {
    //debugger;
    let permitDivisions = this._PermitVersion.Divisions;
    this._PermitVersion.Divisions = [];
    permitDivisions.forEach((div: PermitTypeDivision) => {
      div.Sections.forEach((sec: PermitTypeSection) => {
        sec.Questions.forEach((ques: PermitTypeQuestion) => {
          if (ques) {
            if (ques.AnswerTypeId == 2) {
              ques.SelectedAnswerCheckBox = this.bindCheckBoxResponses(ques);
            }
            else if (ques.AnswerTypeId == 9) {
              ques.TableData = [];
              let tableTypeResponses: TableTypeResponse[] = [];
              let deletedTableTypeResponses: TableTypeResponse[] = [];

              this._permitObj.Responses.forEach(x => {
                if (x.PermitTypeQuestionId == ques.PermitTypeQuestionId) {
                  tableTypeResponses = x.TableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                  deletedTableTypeResponses = x.TableTypeResponse.filter(x => x.Active == false);

                  if (tableTypeResponses.length > 0) {
                    ques.TableData = [];
                    const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                    let distinctRowIndexes = new Set(indexArray);
                    let counter = 0;

                    const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                    let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                    distinctRowIndexes.forEach(idx => {
                      let rowCheck = 0;
                      deletedDistinctRowIndexes.forEach(function (value) {
                        if (idx == value) {
                          rowCheck++;
                        }
                      });
                      // if (rowCheck == 0) {
                      //   ques.TableData["deleted" + counter] = "visible";
                      // }
                      // else {
                      //   ques.TableData["deleted" + counter] = "hidden";
                      // }

                      ques.TableData[counter] = [];

                      for (let header of ques.TableFieldType) {
                        {
                          ques.TableData[counter][header.ColumnName] = {};
                        }
                      }

                      for (let header of ques.TableFieldType) {
                        if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                          //(ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId;
                          if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                            (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).ResponseText;
                          }
                          else {
                            (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId;

                            let tableTypeResponseObj = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx);
                            if (tableTypeResponseObj) {
                              let headerObj = header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponseObj.SelectedAnswerOptionId);
                              if (headerObj) {
                                (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = headerObj.OptionText;
                              }
                            }

                            // (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText;
                          }
                        }
                        else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                          (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).ResponseText;
                        }
                        else if (header.AnswerTypeId == 2) {
                          let responses: number[] = []
                          tableTypeResponses.filter(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).forEach(x => {
                            if (x.SelectedAnswerOptionId != null) {
                              responses.push(x.SelectedAnswerOptionId);
                            }
                          });
                          (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                        }
                      }
                      counter++;
                    });
                  }
                }
              });
            }
            else if (ques.AnswerTypeId == 11) {
              let response = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId);
              let ansText = response ? response.ResponseText : "";
              if (ansText != undefined && ansText != null && ansText.trim() != "") {
                let data = ansText.split("@@@");
                ques.FieldValue = data[0];
                ques.FieldDesc = data[1];
              }
            }
            else if (ques.AnswerTypeId == 13) {
              let response = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId);
              let ansText = response ? response.ResponseText : "";
              if (ansText != undefined && ansText != null && ansText.trim() != "") {
                let data = ansText.split("@@@");
                ques.Approver = data[0];
                ques.TimeStamp = data[1];
                ques.ContactNumber = data[2];
              }
            }
           // else if (ques.AnswerTypeId == 1) {
             else {
              ques.SelectedAnswerOptionID = this.bindSelectedAnswerOptionId(ques);
              ques.AnswerText = this.bindResponseText(ques);
            }
          }
        })
      })
    });
    this._PermitVersion.Divisions = permitDivisions;
    //debugger;
    if (this.isMailRequired) {
      if (this._permitObj.PermitStateId == 3 || this._permitObj.PermitStateId == 17) {
        this.TriggereMail.emit(true);
      }
    }
  }

  bindCheckBoxResponses(ques: PermitTypeQuestion): number[] {
    let responses = this._permitObj.Responses.filter(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId);
    let arrayForCheckBox: number[] = [];
    responses.forEach(response => {
      arrayForCheckBox.push(response.SelectedAnswerOptionId);
    });
    return arrayForCheckBox;
  }

  // Method to get responses for a permit
  bindResponseText(ques: PermitTypeQuestion) {
    let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseText;
    if (ques.AnswerTypeId == 10 || ques.AnswerTypeId == 7) {
      ansText = this.formatAMPM(ansText);
    }
    return ansText;
  }

  formatAMPM(obj): string {
    if (obj == null || obj == undefined) {
      return null;
    }

    let date: Date = new Date(obj);

    let MMM: string;
    var d = date.getDate();

    var m = date.getMonth();
    var y = date.getFullYear();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    switch (m) {
      case 0: MMM = 'Jan';
        break;
      case 1: MMM = 'Feb';
        break;
      case 2: MMM = 'Mar';
        break;
      case 3: MMM = 'Apr';
        break;
      case 4: MMM = 'May';
        break;
      case 5: MMM = 'Jun';
        break;
      case 6: MMM = 'Jul';
        break;
      case 7: MMM = 'Aug';
        break;
      case 8: MMM = 'Sep';
        break;
      case 9: MMM = 'Oct';
        break;
      case 10: MMM = 'Nov';
        break;
      case 11: MMM = 'Dec';
        break;
    }
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    //minutes = minutes < 10 ? '0'+minutes : minutes;
    var strDateTime = d + '-' + MMM + '-' + y + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    if (hours == 12 && minutes == 0 && seconds == 0) {
      strDateTime = d + '-' + MMM + '-' + y;
    }
    return strDateTime;
  }

  bindSelectedAnswerOptionId(ques: PermitTypeQuestion): number {
    if (this._permitObj.Responses.length > 0) {
      let ansID = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).SelectedAnswerOptionId;
      return ansID;
    }
    return 0;
  }
}
