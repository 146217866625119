import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of, Observable, Subscription, interval } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from './ViewModel/User';
import { TranslateService } from '@ngx-translate/core';
import { AuthGuardService } from './auth-guard.service';
@Injectable()
export class RoleGuardService implements CanActivate {
    supAdmin: string;
    usrAdmin: string;

    constructor(public router: Router, private _http: HttpClient, private translate: TranslateService, private _authGuard: AuthGuardService) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this._authGuard.canActivate(route, state).pipe(map(e => {
            if (!e){
              return false;              
            }
            else{
                this.supAdmin = localStorage.getItem("supAdmin");
                this.usrAdmin = localStorage.getItem("usrAdmin");
                if(this.supAdmin === environment.WPSSUPADM || this.usrAdmin === environment.WPSUSRADM)
                {
                    return true;
                }
                else {
                    //this.translate.use('en');
                    this.router.navigate(['/home']);
                    return false;
                  }
               
            }
        }),
        catchError((err) => {
            this.router.navigate(['/home']);
            return of(false);
          })
        );
    }


}