import { UpdateStamp } from '../UpdateStamp';
import { PermitTypeDivision } from './PermitDivision/PermitTypeDivision';
import { PermitControl } from './PermitControl/PermitControl';
import { PermitType } from './PermitType';

export class PermitTypeVersion extends UpdateStamp {
    PermitTypeVersionId: number;
    PermitTypeId: number;
    Version: number;
    PermitType:PermitType;
    PermitTypeControl?: PermitControl;
    Divisions?: PermitTypeDivision[] | null;
    PublishStatus:boolean;
    //PermitType: boolean
}