import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Common } from '../ViewModel/Common';
import { Menu } from '../ViewModel/Menu';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MenuItem } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  adminMenuURL = environment.WEBAPIURL +'Menu/admin/menus';
  leftMenuURL = environment.WEBAPIURL + 'Menu/left/menus';
  rightMenuURL = environment.WEBAPIURL + 'Menu/GetRightMenu';
  allMenuURL = environment.WEBAPIURL + 'Menu/menus';
  head:any;
  selectedTextService:string;
  breadcrumbMenu: MenuItem[];
  
  constructor(private _http: HttpClient) {   
  }

  getAdminMenu(languageId) {
    return this._http.get<Menu[]>(this.adminMenuURL + '/' + languageId );
  }
  getleftMenu(languageId){
    return this._http.get<Menu[]>(this.leftMenuURL + '/' + languageId);
  }

  getAllMenus(languageId) {
    return this._http.get<Menu[]>(this.allMenuURL + '/' + languageId);
  }

  
}
