import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Notification } from 'src/app/ViewModel/notification';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private getNotificationURL = environment.WEBAPIURL + 'notification/notifications';
  private getNotificationCountUrl = environment.WEBAPIURL + 'notification/notification-count';
  private getNotificationMarkAsReadUrl = environment.WEBAPIURL + 'notification/notifications-mark-as-read/';

  constructor(private _http: HttpClient, private _helperService: HelperService) {
  }
  
  GetAllNotifications(loggedUserId) {
    return this._http.get<Notification[]>(this.getNotificationURL + '/' + loggedUserId);
  }

  GetNotification(loggedUserId, startIndex, endIndex, filterText) {
    let escapedFilteredText = this._helperService.EscapeSpecialCharacters(filterText);
    return this._http.get<Notification[]>(this.getNotificationURL + '/' + loggedUserId + '/' + startIndex + '/' + endIndex + '?filter=' + escapedFilteredText);
  }


  GetNotificationCount(loggedUserId) {
    return this._http.get<string>(this.getNotificationCountUrl + '/' + loggedUserId);
  }

  SetNotificationMarkAsRead(permitId, loggedUserId) {
    return this._http.put(this.getNotificationMarkAsReadUrl + permitId + '/' + loggedUserId, { observe: 'response' });
  }

}
