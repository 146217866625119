import { Injectable } from '@angular/core';
import { Common } from '../ViewModel/Common';
import { PermitType, PermitTypeViewModel } from '../ViewModel/PermitBuilder/PermitType';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';
import { PermitTypeLevelApproval } from '../ViewModel/PermitBuilder/PermitTypeLevelApproval';
import {CustomPermitDashboardSettings} from 'src/app/ViewModel/PermitBuilder/CustomPermitDashboardSettings';

@Injectable()
export class PermitTypeService {

  constructor(private _http: HttpClient, private _helperService: HelperService) { }

  private _permitTypeUrl = environment.WEBAPIURL + 'permit-types';
  private _checkPermitSuspensionURL = environment.WEBAPIURL + 'suspended';
  private _publishedPermitTypeUrl = environment.WEBAPIURL + 'published-permit-types';
  private _permitlevelApprovalURL = environment.WEBAPIURL + 'permit-type-approval';
  private _permitpublishTypeUrl = environment.WEBAPIURL + 'permit-publishe-types';
  private _permitDashboardHeadersURL = environment.WEBAPIURL + 'permit-type-permitdashboard-headers';
  private _permitPrintConfigURL = environment.WEBAPIURL + 'permit-type-isprintwithoutattachment';


  getPublishedPermitTypes(hierarchySearch: HierarchySearchViewModel) {
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(hierarchySearch)));
    return this._http.get<PermitTypeViewModel[]>(this._publishedPermitTypeUrl, { params: params });
  }

  getPermitTypes(hierarchySearch: HierarchySearchViewModel) {
    hierarchySearch.languageId = +localStorage.getItem('_languageID');
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(hierarchySearch)));
    return this._http.get<PermitTypeViewModel[]>(this._permitTypeUrl, { params: params });
  }

  addPermitType(permitType: PermitType[],PTid) {
    let params = new HttpParams().set("PTid", PTid);
    return this._http.post(this._permitTypeUrl, permitType, { observe: 'response', params:params });
  }
  updatePermitType(permitType: PermitType[]) {
    return this._http.put(this._permitTypeUrl, permitType, { observe: 'response' });
  }

  GetPermitLevelAprroval(permitTypeId) {
    return this._http.get<PermitTypeLevelApproval[]>(this._permitlevelApprovalURL + '/' + permitTypeId);
  }
  AddPermitLevelAprroval(permitTypeLevelApproval: PermitTypeLevelApproval[]) {
    return this._http.post(this._permitlevelApprovalURL, permitTypeLevelApproval, { observe: 'response' });
  }
  checkPermitSuspension(permitTypeIds: number[]) {
    let params = new HttpParams().set("permitTypeIds", this._helperService.EscapeSpecialCharacters(JSON.stringify(permitTypeIds)));
    return this._http.get(this._checkPermitSuspensionURL, { params: params });
  }
  AddPermitDashboardSetting(CustomPermitDashboardSettings: CustomPermitDashboardSettings[]) {
    return this._http.post(this._permitDashboardHeadersURL, CustomPermitDashboardSettings, { observe: 'response' });
  }
  GetCustomizedPermitDashboardHeaders(permitTypeId) {
    return this._http.get<CustomPermitDashboardSettings[]>(this._permitDashboardHeadersURL + '/' + permitTypeId);
  }
  IsPermitPrintWOAttachment(permitTypeId) {
    return this._http.get<boolean>(this._permitPrintConfigURL + '/' + permitTypeId);
  }
}
