import { UpdateStamp } from './UpdateStamp';

import { Permit } from './Permit';
export class Comment extends UpdateStamp {
    CommentId: number;
    PermitId: number;
    CommentText: String;
    Permit?: Permit;


}
