import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Comment } from '../ViewModel/comment';
import { PermitService } from 'src/app/Services/permit.service';
import { Location, DatePipe } from '@angular/common';
@Component({
  selector: 'app-comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.css'],
  providers: [DatePipe]
})
export class CommentBoxComponent implements OnInit {

  constructor(private _permitService: PermitService,private _datePipe:DatePipe) { }
  comments: Comment[];

  @Input() permitId: string;
  @Output() commentsCount = new EventEmitter<number>();

  ngOnInit() {
    this.comments = [];
    this.getComments(this.permitId);
  }
  getComments(permitId) {
    if(this.permitId){
      this._permitService.getPermitComments(this.permitId).subscribe((comments: Comment[]) => {
        this.comments = comments;
        this.commentsCount.emit(this.comments.length);
        this.scrollDown();
  
      });
    }
  }
  sendComment(txtComment) {
    if ((txtComment as HTMLInputElement).value.trim() != "") {
      let cmnt = new Comment();
      cmnt.CommentId = 0;
      cmnt.PermitId = parseInt(this.permitId);
      cmnt.CommentText = (txtComment as HTMLInputElement).value;
      cmnt.Permit = null;
      cmnt.Active = true;
      cmnt.CreatedBy = localStorage.getItem("_loginId");
      cmnt.ModifiedBy = '';
      cmnt.CreatedDate = new Date();
      cmnt.ModifiedDate = null;

      this._permitService.addPermitComment(cmnt).subscribe(comment => {
        if(comment.status==200){
          this.comments=[];
          this.comments=comment.body;
          this.commentsCount.emit(this.comments.length);
          this.scrollDown();
        }
       });
      //this.comments.push(cmnt);
      (txtComment as HTMLInputElement).value = "";
    }
  }
  

  GetFormattedDateTime(obj: Date): string {
    let dateString = obj.toString();
    dateString = dateString.toLowerCase().indexOf('z') > 0 ? dateString : dateString + 'Z';
    let dateObj: Date = new Date(dateString);
    return this._datePipe.transform(dateObj, 'dd-MMM-yyyy hh:mm:ss a');
  }

  keyDownFunction(event,txtComment) {
    if (event.keyCode == 13) {
      this.sendComment(txtComment);
    }
  }

  scrollDown()
  {
    setTimeout(() => {
      
      document.getElementsByClassName("msg_history")[0].scrollTop=document.getElementsByClassName("msg_history")[0].scrollHeight;
    }, 1000);
  }

}
