import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PermitActionViewModel } from '../ViewModel/PermitActionViewModel';
import { HelperService } from './helper.service';
import { SuspensionStatusViewModel } from '../ViewModel/SuspensionStatusViewModel';

@Injectable({
  providedIn: 'root'
})
export class PermitActionService {

  constructor(private _http: HttpClient, private _helperService: HelperService) { }
  private _suspendAllPermitsURL = environment.WEBAPIURL + 'PermitAction/suspend';
  private _unSuspendAllPermitsURL = environment.WEBAPIURL + 'PermitAction/unsuspend';
  // private _getAllSuspendedPermitsURL = environment.WEBAPIURL + 'PermitAction/suspendedPermits';
  private _isSuspendedCheckURL = environment.WEBAPIURL + 'PermitAction/suspended';
  private _isSiteSuspendedURL = environment.WEBAPIURL + 'PermitAction/site-suspended';
  private _isPermitTypeSuspendedForThisSiteURL = environment.WEBAPIURL + 'PermitAction/permit-type-suspended';
  private _isCategorySuspendedForThisSiteURL = environment.WEBAPIURL + 'PermitAction/category-suspended';
  private _checkSuspesionStatusUrl = environment.WEBAPIURL + 'PermitAction/suspension-status';

  suspendAllPermits(permitActionVMObj: PermitActionViewModel) {
    return this._http.put(this._suspendAllPermitsURL, permitActionVMObj, { observe: 'response' });
  }

  unSuspendAllPermits(permitActionVMObj: PermitActionViewModel) {
    return this._http.put(this._unSuspendAllPermitsURL, permitActionVMObj, { observe: 'response' });
  }

  // getSuspendedPermits(suspenedPermitVMObj: SuspendedPermitViewModel) {
  //   let params = new HttpParams().set("searchSuspendedPermit", this._helperService.EscapeSpecialCharacters(JSON.stringify(suspenedPermitVMObj)));
  //   return this._http.get<PermitSuspensionLog[]>(this._getAllSuspendedPermitsURL, { params: params });
  // }

  // isSuspended(siteId: number, permitTypeId) {
  //   return this._http.get<boolean>(this._isSiteSuspendedCheckURL + '/' + siteId + '/' +permitTypeId);
  // }

  isSuspended(permitActionVMObj: PermitActionViewModel) {
    let params = new HttpParams().set("checkIfSuspended", this._helperService.EscapeSpecialCharacters(JSON.stringify(permitActionVMObj)));
    return this._http.get<boolean>(this._isSuspendedCheckURL, { params: params });
  }

  checkSiteSuspension(siteId: number) {
    return this._http.get<boolean>(this._isSiteSuspendedURL + '/' + siteId);
  }

  checkPermitTypeSuspension(siteId: number, permitTypeId: number) {
    return this._http.get<boolean>(this._isPermitTypeSuspendedForThisSiteURL + '/' + siteId + '/' + permitTypeId)
  }

  checkCategorySuspension(siteId: number, categoryId: number) {
    return this._http.get<boolean>(this._isCategorySuspendedForThisSiteURL + '/' + siteId + '/' + categoryId)
  }
  checkSuspensionStatus(siteId: number, categoryId: number, permitTypeId: number) {
    return this._http.get<SuspensionStatusViewModel>(this._checkSuspesionStatusUrl + '/' + siteId + '/' + categoryId + '/' + permitTypeId);
  }

}
