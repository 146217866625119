export class WOOperationTree {
    WorkOrderNumber: string;
    OperationNumber: string;
    AssignedDept: string;
    Description: string;
    OperationCompleted: string;
    StartDate: string;
    CompletionDate: string;
}

export class WOOperationTreeList {
    WOOperationList: WOOperationTree[];
}