// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ENVIRONMENT: "#{ENVIRONMENT}#",
  INSTANCE: "#{INSTANCE}#",
  //WEBAPIURL: 'https://devapi.alcoa.com/wps.TEST/v1/',
  //WEBAPIURL: 'https://permitsystem.testapi.alcoa.com/',
  WEBAPIURL: '#{WEBAPIURL}#',
  USERAPIURL: 'http://localhost:51165/',
  AUTHAPIURL: 'http://localhost:51165/api/Auth',
  // this one points to jobs, because jobs API calls media API internally
  MediaWebApiUrl: 'https://devapi.alcoa.com/utility/media.TEST/v1/',
  AZURESUBSCRIPTIONKEY: '1fb32db63e95420098527f5a46a3f979',
  GoogleMapKey: 'AIzaSyBojQfqRvf9Vgb4UX2nhvPQ_9PSOnzkcwY',
  ArcGISMapKey: 'AAPK2358ad3e27f9414b878ad4a93834fbe1YxbZE7PsvCVJu0tl2G53eQr71dQowHrAUZS71DNaWjY7bVQ5bLQEbJNFpAMnBaI_',
  token: '',//localStorage.getItem("app_token"), 
  APIM_Trace: 'true',
  // AUthentication
  //clientID: '61e3cddf-a952-4a6b-bf73-042f477d14e2',
  clientID: '#{CLIENTID}#',
  authority: 'https://login.microsoftonline.com/37a72eb7-1faf-49d3-9ddc-6b923b751bc0/',
  validateAuthority: true,
  //redirectUri: 'https://permitsystem2.testapps.alcoa.com/',
  redirectUri: '#{REDIRECTURI}#',
  cacheLocation: 'localStorage',
  //postLogoutRedirectUri: 'https://permitsystem.testapps.alcoa.com/',
  postLogoutRedirectUri: '#{POSTLOGOUTREDIRECTURI}#',
  //consentScopes: ['https://permitsystem.testapi.alcoa.com/user_impersonation','group.read.all'],
  //apiScope: ['https://permitsystem.testapi.alcoa.com/user_impersonation'],
  consentScopes: ['#{CONSENTSCOPES}#', 'group.read.all'],
  apiScope: ['#{CONSENTSCOPES}#'],
  graphScope: ['group.read.all'],
  unprotectedResources: ['https://www.microsoft.com/en-us/'],
  graphAPI: 'https://graph.microsoft.com/v1.0/',
  correlationId: '8DECDAE2-ECBD-44E4-92D8-A7B7DB4DB71C',
  CSE: 37,
  ENTRYLEADER: 25,
  FIRECREWROLE: 26,
  FIRECAPTAINROLE: 27,
  // Allowed File Types are doc, docx, xls, xlsx, ppt, pptx, jpg, jpeg, png, gif, pdf.
  ALLOWEDFILETYPES: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint,image/png,image/gif,image/jpeg,image/jpeg',
  // Max attachment file size in bytes
  MAXIMUMATTACHMENTFILESIZE: 100000000, // 10MB
  // WPSSUPADM: 'WPS-SUP-ADM-TEST',
  // WPSUSRADM : 'WPS-USR-ADM-TEST'
  WPSSUPADM: 'fde86c12-c193-450a-a188-d5289e2d6b52',
  WPSUSRADM: '7a6074d6-3548-4a31-b7a7-6a8dbf496045'
  ,
  pendoApiSettings: {
    webApiKey: "dc7ca2b1-81e4-4cdd-6d67-f9efaf9253bb",
    enablePendoApi: true
  }
};
