import { BU } from './BU';
import { Region } from './Region';
import { SubBU } from './SubBU';
import { Site } from './Site';
import { SubSite } from './SubSite';
import { SRU } from './sru';
import { Attributes } from './Attributes';
import { HierarchyTranslation } from './HierarchyTranslation';
import { Crew } from './Crew';


export class Department { 
    DepartmentId: number;
    DeptName: string; 
    BUId:number;
    RegionId: number;
    SubBUId: number;
    SiteId:number;
    SubSiteId:number;
    SRUId:number;
    BUName:string;
    RegionName: string;    
    SubBUName: string;    
    SiteName:String;    
    SubSiteName: string;    
    SRUName: string;
    
    Active: boolean;
    CreatedBy: string;
    CreatedDate: Date;
    ModifiedBy: string;
    ModifiedDate: Date;
    DefaultLanguageId:number;
    BU?: BU | null;
    Region?: Region | null;
    SubBU?: SubBU | null;
    Site?: Site | null;
    SubSite?: SubSite | null;
    SRU?: SRU | null;
    Crews?: Crew[] | null;
    Attributes?: Attributes[]|null;
    HierarchyTranslations?: HierarchyTranslation[] | null;
}