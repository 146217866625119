import { Injectable } from "@angular/core";
@Injectable()
export class HierarchySearchViewModel {

    languageId: number; // Language Id for based on the user

    BuId?: number;
    BuName: string;
    RegionId?: number;
    RegionName: string;
    SubBuId?: number;
    SubBuName: string;
    SiteId?: number;
    SiteName: string;
    SubSiteId?: number;
    SubSiteName: string;
    SruId?: number;
    SruName: string;
    WorkOrderNo : string;
    DepartmentId?: number;
    DepartmentName: string;
    CrewId?: number;
    CrewName: string;
    Active?: boolean;
    //Permit Type
    PermitTypeId?: number;
    PermitTypeName: string;
    Abbreviation: string;

    //Role
    RoleName: string; // To filter search results based on 'Role Name'. Used in Roles screen.

    //Contractor
    ContractorName: string; // To filter search results based on 'Contractor Name'. Used in Contractors screen.

    //Categor
    CategoryId?: number;
    CategoryName: string;
    CategoryDescription: string;
    CategoryIds: number[] | null
    //Dynamic
    DynamicDatasourceName: string;
}