import {Injectable, ErrorHandler} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable, throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Common } from '../ViewModel/Common';
import { Site } from '../ViewModel/Site';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';

@Injectable()
export class SiteService  {
  
  getAllsiteURL = environment.WEBAPIURL + 'Site/sites';
  getSiteDropdownURL = environment.WEBAPIURL + 'Site/GetSiteDropdown';
  addSiteURL = environment.WEBAPIURL + 'Site/Site';
  editSiteURL = environment.WEBAPIURL + 'Site/Site';
  AddBulkURL = environment.WEBAPIURL + 'DataUpload/bulk-site';
  getAllTimeZoneURL = environment.WEBAPIURL + 'Site/getalltimesZone';
constructor(private _http: HttpClient, private _helperService: HelperService) {}

//Excel value bulk upload
addBulkValue(Site) {  
  localStorage.setItem("_hierarchtTree", null);
  return this._http.post(this.AddBulkURL, Site,{observe: 'response'});
 }

// Get All Site Details
getAllsite() {   
  let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.Active = true;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Site[]>(this.getAllsiteURL,{params:params});
  }
  getAllTimeZone(){
    return this._http.get<Site[]>(this.getAllTimeZoneURL);
  }
// Get All Site Details
getSiteDropdown() {    
  return this._http.get<Site[]>(this.getSiteDropdownURL);
}

//Get All the Site Details By SubBU
getSiteBysubBU(SubBUId){
  let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.SubBuId = SubBUId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Site[]>(this.getAllsiteURL,{params:params});
} 

//Get All the Site Details By SubBU By Role
getSiteBysubBUByRole(SubBUId){    
  let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.SubBuId = SubBUId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Site[]>(this.getAllsiteURL,{params:params});
} 

//Get Site By BU ,Region and SubBUId
getSiteByParent(BUId, RegionId, SubBUId) {  
  let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = BUId;
    objVMHierarchySearch.RegionId = RegionId;
    objVMHierarchySearch.SubBuId = SubBUId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
  return this._http.get<Site[]>(this.getAllsiteURL,{params: params});
} 

//Get Site By BU ,Region and SubBUId
getSiteByParentByRole(BUId, RegionId, SubBUId) {
  let objVMHierarchySearch=new HierarchySearchViewModel();
  objVMHierarchySearch.BuId = BUId;
  objVMHierarchySearch.RegionId = RegionId;
  objVMHierarchySearch.SubBuId = SubBUId;
  objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch)); 
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));    
    return this._http.get<Site[]>(this.getAllsiteURL,{params:params});
} 
//Add new Site
addSite (Site: Site) {
  localStorage.setItem("_hierarchtTree", null);
  Site.DefaultLanguageId=+localStorage.getItem("_languageID"); 
    return this._http.post(this.addSiteURL, Site,{observe: 'response'});
  }

//Update Site
  editSite (_objSite: Site) {
    localStorage.setItem("_hierarchtTree", null);
    _objSite.DefaultLanguageId=+localStorage.getItem("_languageID"); 
    return this._http.put(this.editSiteURL, _objSite, { observe: 'response' });
  }

  //Get All Children of Site
  GetSiteChildren(buId,regionId,subBuId,siteId){
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = buId;
    objVMHierarchySearch.RegionId=regionId;
    objVMHierarchySearch.SubBuId=subBuId;
    objVMHierarchySearch.SiteId=siteId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Site[]>(this.getAllsiteURL, { params: params });
  }

  //Search Site
  searchSite (BUId,RegionId, SubBUId , SiteName, isActive) {
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId=BUId;
    objVMHierarchySearch.RegionId=RegionId;
    objVMHierarchySearch.SubBuId=SubBUId;
    objVMHierarchySearch.SiteName=SiteName;
    objVMHierarchySearch.Active = isActive;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    return this._http.get<Site[]>(this.getAllsiteURL, { params: params });
  }
}