import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { PermitService } from '../Services/permit.service';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-select-work-order',
  templateUrl: './select-work-order.component.html',
  styleUrls: ['./select-work-order.component.css']
})
export class SelectWorkOrderComponent implements OnInit {

  constructor(private _permitService: PermitService, private _messageService: MessageService, private translate: TranslateService) { }

  w_loading: boolean = false;
  _first = 1;
  _last = 20;
  workOrder_first: number = 0;
  _isMoreRecordExist: boolean = false;
  workOrderGlobalFilter: string = null;
  workOrderArray: string[] = [];
  _selectedWorkOrder: any[] = [];
  _EAMWorkOrderDetails: any[] = [];
  _EAMWorkOrderDetailsNotFiltered: any[] = [];
  EAMWorkOrderFlag: boolean = false;
  selectedAssetNumber: string;
  timer: any;
  @Input() AssetNumber: string;
  @Input() SiteId: number;
  @Output() ObjSelectedWorkOrder = new EventEmitter<any[]>();
  @ViewChild('WorkOrdersearchInput') WorkOrdersearchInput: ElementRef;
  

  ngOnInit() {
  }

  loadEAMWorkOrderData(event) {
    let siteId = this.SiteId;
    let assetNumber = this.AssetNumber;
    if (event.first / 10 == 0) {
      event.first = 1;
    }
    else {
      event.first = (event.first / 10) + 1;
    }
    this.workOrderGlobalFilter = event.globalFilter;
    if ((event.globalFilter != null || event.globalFilter != "")) {
      if (event.globalFilter.length >= 3) {
        this.w_loading = true;
        this._permitService.getWorkOrderFromEAM(assetNumber, event.globalFilter, siteId, event.first, event.rows).subscribe(data => {
          this._EAMWorkOrderDetails = data;
          this._EAMWorkOrderDetailsNotFiltered = data;
          this.w_loading = false;
          //this._isMoreRecordExist = (data.MoreRecordExists == "Y")
        }, 
        error => {
          this.w_loading = false;
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: error.error });
        });
      }
    }    
  }

  loadWorkOrder(assetNo: string){    
    let siteId = this.SiteId;
    this.selectedAssetNumber = assetNo;
    if(assetNo != "NA") {
      this.w_loading = true;
      this._permitService.getWorkOrderFromEAM(assetNo, "null", siteId, 1, 50).subscribe(data => {
        this._EAMWorkOrderDetails = data;
        this.w_loading = false;
        this._selectedWorkOrder = [];
      }, 
      error => {
        this.w_loading = false;
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: error.error });
      });
    }    
  }

  filterWO(filterText: string) {
    clearTimeout(this.timer); 
    this.timer = setTimeout(() => this.filterWorkOrder(filterText), 500)
  }

  filterWorkOrder(filterText: string) {
    if (this.selectedAssetNumber == "NA") {
      if(filterText && filterText.trim().length > 2) {
        this.w_loading = true;
        this._permitService.getWorkOrderFromEAM("NA", filterText, this.SiteId, 1, 50).subscribe(data => {
          this._EAMWorkOrderDetails = data;
          this.w_loading = false;
          this._selectedWorkOrder = [];
        }, 
        error => {
          this.w_loading = false;
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: error.error });
        });
      }
    }
    else {
      if (!filterText || (filterText && filterText.trim().length == 0)){
        this._EAMWorkOrderDetails = this._EAMWorkOrderDetailsNotFiltered;
      }
      else{
        this._EAMWorkOrderDetails =[];
        this._EAMWorkOrderDetails = this._EAMWorkOrderDetailsNotFiltered.filter(x => x.WorkOrderNumber.toLowerCase().indexOf(filterText) > -1 
          || x.Description.toLowerCase().indexOf(filterText) > -1);        
      }
    }
  }
  
  loadNextEAMWorkOrderData(val) {
    let siteId = this.SiteId;
    let assetNumber = this.AssetNumber;
    if (val == 2 && this._isMoreRecordExist) {
      this._first += 10;
      this._last += 10;
      this.workOrder_first = this._first;
    }
    else {
      if (this._first > 1) {
        this._first -= 10;
        this._last -= 10;
        this.workOrder_first = this._first;
      }
    }
    this._permitService.getWorkOrderFromEAM(assetNumber, this.workOrderGlobalFilter, siteId, this._first, this._last).subscribe(data => {
      this._EAMWorkOrderDetails = data;
      this.w_loading = false;
      // this._isMoreRecordExist = (data.MoreRecordExists == "Y")
    }, error => {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: error.error });
    });
  }

  SelectWorkOrderID(){
    let siteId = this.SiteId;
    let assetNumber = this.AssetNumber;
    this.WorkOrdersearchInput.nativeElement.value = '';
    this._first = 1;
    this.workOrder_first = 0;
    this._last = 10;
    this._permitService.getWorkOrderFromEAM(assetNumber, null, siteId, 1, 10).subscribe(data => {
      console.log(data)
      this._EAMWorkOrderDetails = data;
      this.EAMWorkOrderFlag = true;
      this.w_loading = false;
     // this._isMoreRecordExist = (data.MoreRecordExists == "Y")
    }, error => {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: error.error });
    });
  }
  clearSelection(){
    this._selectedWorkOrder = [];
    this._EAMWorkOrderDetails=[];
    this.WorkOrdersearchInput.nativeElement.value='';
  }

  OnSelectWODone() {
    if(this._selectedWorkOrder.length > 0)
    {
      if(this.selectedAssetNumber == "NA" && this._selectedWorkOrder && this._selectedWorkOrder.length > 0) {
        this._selectedWorkOrder.forEach(element => {
          //element.AssetNumber = "NA"; 
         // element.AssetDescription = "NA";     
        });
      }
      this.ObjSelectedWorkOrder.emit(this._selectedWorkOrder);
    }
    else
    {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('AssetWOOPeration.errorWO') });
    }
    
  }
  
  
 

}
