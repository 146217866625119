import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { PermitTypeQuestion } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeQuestion';
import { PermitCreatedByUserViewModel } from '../ViewModel/PermitCreatedByUserViewModel';
import { Permit } from '../ViewModel/Permit';
import { PermitTypeVersion } from '../ViewModel/PermitBuilder/PermitTypeVersion';
import { PermitTypeAnswerOption } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeAnswerOption';
import { PermitTypeDivision } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeDivision';
import { PermitTypeSection } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeSection';
import { TableTypeResponse } from '../ViewModel/TableTypeResponse';
import { SubQuesTableTypeResponse } from '../ViewModel/SubQuesTableTypeResponse';
import { SafeHtmlPipe } from '../Pipes/SafeHtmlPipe';
import { DefaultPipeModule } from '../DefaultPipeModule';
import { DomSanitizer } from '@angular/platform-browser';
import { PermitTypeSubQuestion } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeSubQuestion';

@Component({
  selector: 'app-print-pdf-selected',
  templateUrl: './print-pdf-selected.component.html',
  styleUrls: ['./print-pdf-selected.component.css']
})
export class PrintPdfSelectedComponent implements OnInit {
  @Input() _PermitVersion: PermitTypeVersion;
  @Input() permitTypeName: string;
  @Input() permitTypeImage: any;
  @Input() _permitBackColor: string;
  @Input() _permitForeColor: string;
  @Input() previewFlag: string;
  @Input() revalidationHours: Number;
  @Input() revalidationPeriod: Number;
  @Input() revalidationCount: Number;
  @Input() checkRevalidation: boolean;
  @Input() _permitObj: Permit = new Permit();
  @Input() _showImage: boolean;
  @Input() _screenGrab: any;
  @Input() preview: boolean;
  @Input() permitCreatedByUser: PermitCreatedByUserViewModel = new PermitCreatedByUserViewModel();
  @Input() responseAttachmentFiles: any[] = [];
  @Input() subQuesResponseAttachmentFiles: any[] = [];
  @Input() MailRequired: boolean;
  @Input() _sectionsToPrint: string[];
  @Input() lastSectionName: string;
  @Output() TriggereMail = new EventEmitter<boolean>();
  au: any;
  questionFontSize: number = 0;
  sectionFontSize: number = 0;
  headerFontSize: number = 0;
  isMailRequired: boolean = false;
  permitPdfStatus: string='DRAFT';
  _excelContentType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  _pdfContentType: string = 'application/pdf';
  _wordContentType: string = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  _pptContentType: string = '';
  // _imageContentType: string = ''
  _excelThumbnailIconPath: string = '../../assets/Images/excel-icon.png';
  _pdfThumbnailIconPath: string = '../../assets/Images/pdf-icon.png';
  _imageThumbnailIconPath: string = '../../assets/Images/image-icon.png';
  _wordThumbnailIconPath: string = '../../assets/Images/word-icon.png';
  _pptThumbnailIconPath: string = '../../assets/Images/ppt-icon.png';
  _sectionSelecetedForPrint: string[];
  lastSectionNameForPageBreak: string;

  data: any[] = [];
  _selectedSectionsForPrint: string[] = [];

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes._PermitVersion) {
      if (!changes._PermitVersion.isFirstChange()) {
        if (changes._PermitVersion.currentValue) {          
          this._PermitVersion = changes._PermitVersion.currentValue;
          this.questionFontSize = this._PermitVersion.PermitType.QuestionFontSize + 5;
          this.sectionFontSize = this._PermitVersion.PermitType.SectionHeaderFontSize + 5;
          this.headerFontSize = this._PermitVersion.PermitType.HeaderFontSize + 5;
        }
      }
    }
    if (changes.preview != undefined) {
      if (!changes.preview.isFirstChange()) {        
        this.preview = changes.preview.currentValue;
      }
    }
    if (changes.permitTypeName != undefined) {
      if (!changes.permitTypeName.isFirstChange()) {
        this.permitTypeName = changes.permitTypeName.currentValue;
      }
    }

  //  if (this._permitObj.PermitStateId != 3) {
  //     this.permitPdfStatus = "DRAFT";
  //   } 
 

    if (changes._permitBackColor != undefined) {
      if (!changes._permitBackColor.isFirstChange()) {
        this._permitBackColor = changes._permitBackColor.currentValue;
      }
    }
    if (changes._permitForeColor != undefined) {
      if (!changes._permitForeColor.isFirstChange()) {
        this._permitForeColor = changes._permitForeColor.currentValue;
      }
    }
    if (changes.permitTypeImage != undefined) {
      if (!changes.permitTypeImage.isFirstChange()) {
        this.permitTypeImage = changes.permitTypeImage.currentValue;
      }
    }
    if (changes.previewFlag != undefined) {
      if (!changes.previewFlag.isFirstChange()) {
        this.previewFlag = changes.previewFlag.currentValue;
      }
    }
    if (changes.revalidationHours != undefined) {
      if (!changes.revalidationHours.isFirstChange()) {
        this.revalidationHours = changes.revalidationHours.currentValue;
      }
    }
    if (changes._screenGrab != undefined) {
      if (!changes._screenGrab.isFirstChange()) {
        this._screenGrab = changes._screenGrab.currentValue;
      }
    }
    if (changes.MailRequired != undefined) {
      if (!changes.MailRequired.isFirstChange()) {
        this.isMailRequired = changes.MailRequired.currentValue;
      }
    }
    if (changes._sectionsToPrint != undefined) {
      if (!changes._sectionsToPrint.isFirstChange()) {
        this._sectionSelecetedForPrint = changes._sectionsToPrint.currentValue;
      }
      // if (this._sectionSelecetedForPrint && this._sectionSelecetedForPrint.includes("Entrant & attendant log")) {
      //   this._sectionSelecetedForPrint.push("Atmospheric monitoring");
      //   changes.lastSectionName.currentValue = "Atmospheric monitoring";
      // }
      if (this._sectionSelecetedForPrint && this._sectionSelecetedForPrint.includes("Entrant & attendant log".toUpperCase())) {
        this._sectionSelecetedForPrint.push("Atmospheric monitoring".toUpperCase());
        changes.lastSectionName.currentValue = "Atmospheric monitoring".toUpperCase();
      }
    }
    if (changes.lastSectionName != undefined) {
      if (!changes.lastSectionName.isFirstChange()) {
        this.lastSectionNameForPageBreak = changes.lastSectionName.currentValue;
      }
    }
    if (changes._permitObj != undefined) {
      if (!changes._permitObj.isFirstChange()) {
        this._permitObj = new Permit();
        if (changes._permitObj.currentValue) {
          this._permitObj = changes._permitObj.currentValue;
          this.PopulateResponsesForPermit();
        }
      }
    }
    if (changes.permitCreatedByUser != undefined) {
      if (!changes.permitCreatedByUser.isFirstChange()) {
        if (this.permitCreatedByUser && changes.permitCreatedByUser.currentValue && this.permitCreatedByUser.LoginId != changes.permitCreatedByUser.currentValue.LoginId) {
          this.permitCreatedByUser = changes.permitCreatedByUser.currentValue;
        }
        else if (!this.permitCreatedByUser && changes.permitCreatedByUser.currentValue) {
          this.permitCreatedByUser = changes.permitCreatedByUser.currentValue;
        }
      }
    }

    if (changes.responseAttachmentFiles) {
      if (!changes.responseAttachmentFiles.isFirstChange()) {
        if (changes.responseAttachmentFiles.currentValue) {
          this.responseAttachmentFiles = changes.responseAttachmentFiles.currentValue;
        }
      }
    }
    if (changes.subQuesResponseAttachmentFiles) {
      if (!changes.subQuesResponseAttachmentFiles.isFirstChange()) {
        if (changes.subQuesResponseAttachmentFiles.currentValue) {
          this.subQuesResponseAttachmentFiles = changes.subQuesResponseAttachmentFiles.currentValue;
        }
      }
    }
  }

  constructor(private sanitizer: DomSanitizer) { 

  }

  clsBorderColor: string = "#dbdbdb";
  ngOnInit() {
    for (let index = 0; index < 1000; index++) {
      this.data.push({
        'productId': index,
        'unitPrice': 100,
        'qty': 1,
        'total': 100
      })

    }
    //this.permitContainerHeight = window.innerHeight - 280;
    this.getLocalCalender();
    if (this._PermitVersion) {
      if (this._PermitVersion.PermitType) {
        this.questionFontSize = this._PermitVersion.PermitType.QuestionFontSize + 5;
        this.sectionFontSize = this._PermitVersion.PermitType.SectionHeaderFontSize + 5;
        this.headerFontSize = this._PermitVersion.PermitType.HeaderFontSize + 5;
      }
    }
  }

  BindSectionForPrint(sections: string[]) {
    this._selectedSectionsForPrint = sections;
  }

  getLocalCalender() {
    this.au = {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',

      clear: 'Clear'
    }
  }
  CheckAnsOptionWidth(answerOptions: PermitTypeAnswerOption[]) {
    let ansWidthCounter = 0;
    answerOptions.forEach(ao => {
      if (ao.AnswerOptionWidth) {
        ansWidthCounter++;
      }
    });
    if (ansWidthCounter > 0) {
      return true;
    }
    else {
      return false;
    }
  }
  getAnswerOption(question: PermitTypeQuestion) {
    // if (this._allDynamicDatasourceOptions != undefined) {
    //   let obj = this._allDynamicDatasourceOptions.find(x => x.permitTypeQuestionId == question.PermitTypeQuestionId)
    //   if (obj != undefined) {
    //     return obj.dynamicDataSourceOptions;
    //   }
    // }
    // else {
    //   return [];
    // }
  }

  PopulateResponsesForPermit() {    
    let permitDivisions = this._PermitVersion.Divisions;   
    this._PermitVersion.Divisions = [];
    permitDivisions.forEach((div: PermitTypeDivision) => {
      div.Sections.forEach((sec: PermitTypeSection) => {
        sec.Questions.forEach((ques: PermitTypeQuestion) => {
          if (ques) {
            if (ques.AnswerTypeId == 2) {
              ques.SelectedAnswerCheckBox = this.bindCheckBoxResponses(ques);
               //For Sub Question Response
               ques.AnswerOptions.forEach(ao=>{
                if(ques.SelectedAnswerCheckBox.includes(ao.PermitTypeAnswerOptionId))
                {
                  ao.IsSelected=true;
                  if( ao.SubQuestions.length > 0)
                  {
                    ao.SubQuestions.forEach(sq=>{
                      if(sq.AnswerTypeId == 2)
                      {
                        sq.SelectedAnswerCheckBox= this.bindCheckBoxResponsesSubQuestion(sq,ques, ao);
                      }
                      else if (sq.AnswerTypeId == 9) {
                        sq.TableData = [];
                        let tableTypeResponses: SubQuesTableTypeResponse[] = [];
                        let deletedTableTypeResponses: SubQuesTableTypeResponse[] = [];
        
                        this._permitObj.Responses.forEach(x => {
                          if (x.PermitTypeQuestionId == ques.PermitTypeQuestionId) {
                            x.SubQuesResponse.forEach(sr=>{
                          if(sr.PermitTypeSubQuestionId==sq.PermitTypeSubQuestionId)
                          {
                            tableTypeResponses = sr.SubQuesTableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                            deletedTableTypeResponses =sr.SubQuesTableTypeResponse.filter(x => x.Active == false);
        
                            if (tableTypeResponses.length > 0) {
                              sq.TableData = [];
                              const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                              let distinctRowIndexes = new Set(indexArray);
                              let counter = 0;
        
                              const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                              let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                              distinctRowIndexes.forEach(idx => {
        
                                let rowCheck = 0;
        
                                deletedDistinctRowIndexes.forEach(function (value) {
        
                                  if (idx == value) {
                                    rowCheck++;
        
                                  }
                                });
                                
                                sq.TableData[counter] = [];
        
                                for (let header of sq.PermitTypeTableSubQues) {
                                  {
                                    sq.TableData[counter][header.ColumnName] = {};
                                  }
                                }
        
                                for (let header of sq.PermitTypeTableSubQues) {
        
                                  if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                    if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                      (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;
                                    }
                                    else {
                                      (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId;

                                      (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = (header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId)) ? header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText : '';
                                    }
                                  }
                                  else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                    (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;
                                  }
                                  else if (header.AnswerTypeId == 2) {
                                    let responses: number[] = []
                                    tableTypeResponses.filter(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).forEach(x => {
                                      if (x.SelectedAnswerOptionId != null) {
                                        responses.push(x.SelectedAnswerOptionId);
                                      }
                                    });
                                    (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                                  }
                                }
                                counter++;
                              });
                            }
                          }
                        })
                          }
                        });
                      }
                      else
                      {
                       
                        let SelectedAnswerOptionIDSQ =this.bindSelectedAnswerOptionIdSubQuestion(sq,ques, ao);
                        sq.SelectedAnswerOptionID= SelectedAnswerOptionIDSQ !=undefined ? SelectedAnswerOptionIDSQ : null ;
                        sq.AnswerText=this.bindResponseTextSubQuestion(sq,ques,ao);
                        
                      }
                      
                    })
                  }
                
                }
                
              })
            }
            else if (ques.AnswerTypeId == 9) {
              ques.TableData = [];
              let tableTypeResponses: TableTypeResponse[] = [];
              let deletedTableTypeResponses: TableTypeResponse[] = [];

              this._permitObj.Responses.forEach(x => {
                if (x.PermitTypeQuestionId == ques.PermitTypeQuestionId) {
                  tableTypeResponses = x.TableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                  deletedTableTypeResponses = x.TableTypeResponse.filter(x => x.Active == false);

                  if (tableTypeResponses.length > 0) {
                    ques.TableData = [];
                    const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                    let distinctRowIndexes = new Set(indexArray);
                    let counter = 0;

                    const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                    let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                    distinctRowIndexes.forEach(idx => {
                      let rowCheck = 0;
                      deletedDistinctRowIndexes.forEach(function (value) {
                        if (idx == value) {
                          rowCheck++;
                        }
                      });
                      // if (rowCheck == 0) {
                      //   ques.TableData["deleted" + counter] = "visible";
                      // }
                      // else {
                      //   ques.TableData["deleted" + counter] = "hidden";
                      // }

                      ques.TableData[counter] = [];

                      for (let header of ques.TableFieldType) {
                        {
                          ques.TableData[counter][header.ColumnName] = {};
                        }
                      }

                      for (let header of ques.TableFieldType) {
                        if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                          //(ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId;
                          if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                            (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).ResponseText;
                          }
                          else {
                            (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId;

                            let tableTypeResponseObj = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx);
                            if (tableTypeResponseObj) {
                              let headerObj = header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponseObj.SelectedAnswerOptionId);
                              if (headerObj) {
                                (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = headerObj.OptionText;
                              }
                            }

                            // (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText;
                          }
                        }
                        else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                          (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).ResponseText;
                        }
                        else if (header.AnswerTypeId == 2) {
                          let responses: number[] = []
                          tableTypeResponses.filter(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).forEach(x => {
                            if (x.SelectedAnswerOptionId != null) {
                              responses.push(x.SelectedAnswerOptionId);
                            }
                          });
                          (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                        }
                      }
                      counter++;
                    });
                  }
                }
              });
            }
            else if (ques.AnswerTypeId == 11) {
              let response = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId);
              let ansText = response ? response.ResponseText : "";
              if (ansText != undefined && ansText != null && ansText.trim() != "") {
                let data = ansText.split("@@@");
                ques.FieldValue = data[0];
                ques.FieldDesc = data[1];
              }
            }
            else if (ques.AnswerTypeId == 13) {
              let response = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId);
              let ansText = response ? response.ResponseText : "";
              if (ansText != undefined && ansText != null && ansText.trim() != "") {
                let data = ansText.split("@@@");
                ques.Approver = data[0];
                ques.TimeStamp = data[1];
                ques.ContactNumber = data[2];
              }
            }
           // else if (ques.AnswerTypeId == 1) {
             else {
              ques.SelectedAnswerOptionID = this.bindSelectedAnswerOptionId(ques);
              ques.AnswerText = this.bindResponseText(ques);
               //Sub Question Section
               if(ques.AnswerTypeId == 1 || ques.AnswerTypeId == 4)  
               {
                 ques.AnswerOptions.forEach(ao=>{
                   if(ao.PermitTypeAnswerOptionId==ques.SelectedAnswerOptionID && ques.SelectedAnswerOptionID != undefined)
                   {
                     ao.IsSelected=true;
                     if( ao.SubQuestions.length > 0)
                     {
                       ao.SubQuestions.forEach(sq=>{
                         if(sq.AnswerTypeId == 2)
                         {
                           sq.SelectedAnswerCheckBox= this.bindCheckBoxResponsesSubQuestion(sq,ques,ao);
                         }
                         else if (sq.AnswerTypeId == 9) {
                          sq.TableData = [];
                          let tableTypeResponses: SubQuesTableTypeResponse[] = [];
                          let deletedTableTypeResponses: SubQuesTableTypeResponse[] = [];
          
                          this._permitObj.Responses.forEach(x => {
                            if (x.PermitTypeQuestionId == ques.PermitTypeQuestionId) {
                              x.SubQuesResponse.forEach(sr=>{
                            if(sr.PermitTypeSubQuestionId==sq.PermitTypeSubQuestionId)
                            {
                              tableTypeResponses = sr.SubQuesTableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                              deletedTableTypeResponses =sr.SubQuesTableTypeResponse.filter(x => x.Active == false);
          
                              if (tableTypeResponses.length > 0) {
                                sq.TableData = [];
                                const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                                let distinctRowIndexes = new Set(indexArray);
                                let counter = 0;
          
                                const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                                let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                                distinctRowIndexes.forEach(idx => {
          
                                  let rowCheck = 0;
          
                                  deletedDistinctRowIndexes.forEach(function (value) {
          
                                    if (idx == value) {
                                      rowCheck++;
          
                                    }
                                  });
                                  
                                  sq.TableData[counter] = [];
          
                                  for (let header of sq.PermitTypeTableSubQues) {
                                    {
                                      sq.TableData[counter][header.ColumnName] = {};
                                    }
                                  }
          
                                  for (let header of sq.PermitTypeTableSubQues) {
          
                                    if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                      if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                        (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;
                                      }
                                      else {
                                        (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId;
  
                                        (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = (header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId)) ? header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText : '';
                                      }
                                    }
                                    else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                      (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;
                                    }
                                    else if (header.AnswerTypeId == 2) {
                                      let responses: number[] = []
                                      tableTypeResponses.filter(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).forEach(x => {
                                        if (x.SelectedAnswerOptionId != null) {
                                          responses.push(x.SelectedAnswerOptionId);
                                        }
                                      });
                                      (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                                    }
                                  }
                                  counter++;
                                });
                              }
                            }
                          })
                            }
                          });
                        }
                         else
                         {
                           let SelectedAnswerOptionIDSQ =this.bindSelectedAnswerOptionIdSubQuestion(sq,ques,ao);
                           sq.SelectedAnswerOptionID= SelectedAnswerOptionIDSQ !=undefined ? SelectedAnswerOptionIDSQ : null ;
                           sq.AnswerText=this.bindResponseTextSubQuestion(sq,ques, ao);
                           
                         }
                         
                       })
                     }
                    
                   }
                 })
               }
            }
          }
        })
      })
    });

    this._PermitVersion.Divisions = permitDivisions;  
    if (this.isMailRequired) {
      if (this._permitObj.PermitStateId == 3 || this._permitObj.PermitStateId == 17) {
        this.TriggereMail.emit(true);
      }
    }
  }

  bindCheckBoxResponsesSubQuestion(sq: PermitTypeSubQuestion,ques: PermitTypeQuestion, ao :PermitTypeAnswerOption): number[] {
    let responses = this._permitObj.Responses.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId && x.SelectedAnswerOptionId==ao.PermitTypeAnswerOptionId).SubQuesResponse.filter(s=>s.PermitTypeSubQuestionId==sq.PermitTypeSubQuestionId);
    let arrayForCheckBoxSQ: number[] = [];
    responses.forEach(response => {
      arrayForCheckBoxSQ.push(response.SelectedAnswerOptionId);
    });
    return arrayForCheckBoxSQ;
  }

  bindSelectedAnswerOptionIdSubQuestion(sq: PermitTypeSubQuestion,ques: PermitTypeQuestion,ao : PermitTypeAnswerOption): number {
    //console.log("bindSelectedAnswerOptionIdSubQuestion" +this._permitObj.Responses);
    let ansID =  this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId==ao.PermitTypeAnswerOptionId).SubQuesResponse.length > 0 ?
     this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId==ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s=>s.PermitTypeSubQuestionId==sq.PermitTypeSubQuestionId).SelectedAnswerOptionId : null;
    return ansID;
    
  }

  bindResponseTextSubQuestion(sq: PermitTypeSubQuestion,ques: PermitTypeQuestion , ao : PermitTypeAnswerOption) {
    let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId==ao.PermitTypeAnswerOptionId).SubQuesResponse.length > 0 ?
    this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId==ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s=>s.PermitTypeSubQuestionId==sq.PermitTypeSubQuestionId).SubQuesResponseText : null;
    if (sq.AnswerTypeId == 10 || sq.AnswerTypeId == 7) {
      ansText = this.formatAMPM(ansText);
    }
    return ansText;
  }
  bindCheckBoxResponses(ques: PermitTypeQuestion): number[] {
    let responses = this._permitObj.Responses.filter(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId);
    let arrayForCheckBox: number[] = [];
    responses.forEach(response => {
      arrayForCheckBox.push(response.SelectedAnswerOptionId);
    });
    return arrayForCheckBox;
  }

  // Method to get responses for a permit
  bindResponseText(ques: PermitTypeQuestion) {
    let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseText;
    if (ques.AnswerTypeId == 10 || ques.AnswerTypeId == 7) {
      let language = parseInt(localStorage.getItem("_languageID"));
      if (language != 3) 
      ansText = this.formatAMPM(ansText);
    }
    return ansText;
  }
  
  formatAMPM(obj): string { 
    if (obj == null || obj == undefined) {
      return null;
    }
    let date: Date = new Date(obj);
    let MMM: string;
    let language = parseInt(localStorage.getItem("_languageID"));
    if ((language == 1 || language == 2 || language == 4 || language == 5 || language == 6) && (Object.prototype.toString.call(date) === "[object Date]" && isNaN(date.getTime()))) {
      let dateStr = obj.split('-');
      if (dateStr == null || dateStr == '') {
        dateStr = obj.split(' ');
      }

      // let MMM: string;
      let monthName = dateStr[1];
      switch (monthName) {
        case 'Jan': MMM = 'Jan';
          break;
        case 'Fév': MMM = 'Feb';
          break;
        case 'Mar': MMM = 'Mar';
          break;
        case 'Avr': MMM = 'Apr';
          break;
        case 'Mai': MMM = 'May';
          break;
        case 'Jui': MMM = 'Jun';
          break;
        case 'Jul': MMM = 'Jul';
          break;
        case 'Aoû': MMM = 'Aug';
          break;
        case 'Sep': MMM = 'Sep';
          break;
        case 'Oct': MMM = 'Oct';
          break;
        case 'Nov': MMM = 'Nov';
          break;
        case 'Déc': MMM = 'Dec';
          break;
      }

      obj = dateStr[0] + '-' + MMM + '-' + dateStr[2];
      let date: Date = new Date(obj);
      var d = date.getDate();

      var m = date.getMonth();
      var y = date.getFullYear();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
    }

    if (!isNaN(date.getTime())) {
      var d = date.getDate();
      var m = date.getMonth();

      var y = date.getFullYear();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      switch (m) {
        case 0: MMM = 'Jan';
          break;
        case 1: MMM = 'Feb';
          break;
        case 2: MMM = 'Mar';
          break;
        case 3: MMM = 'Apr';
          break;
        case 4: MMM = 'May';
          break;
        case 5: MMM = 'Jun';
          break;
        case 6: MMM = 'Jul';
          break;
        case 7: MMM = 'Aug';
          break;
        case 8: MMM = 'Sep';
          break;
        case 9: MMM = 'Oct';
          break;
        case 10: MMM = 'Nov';
          break;
        case 11: MMM = 'Dec';
          break;
      }
      language = parseInt(localStorage.getItem("_languageID"));
      if (language == 3) {
        switch (m) {
          case 0: MMM = 'Jan';
            break;
          case 1: MMM = 'Fév';
            break;
          case 2: MMM = 'Mar';
            break;
          case 3: MMM = 'Avr';
            break;
          case 4: MMM = 'Mai';
            break;
          case 5: MMM = 'Jui';
            break;
          case 6: MMM = 'Jul';
            break;
          case 7: MMM = 'Aoû';
            break;
          case 8: MMM = 'Sep';
            break;
          case 9: MMM = 'Oct';
            break;
          case 10: MMM = 'Nov';
            break;
          case 11: MMM = 'Déc';
            break;
        }
      }
    }
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    //minutes = minutes < 10 ? '0'+minutes : minutes;
    var strDateTime = d + '-' + MMM + '-' + y + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    if (hours == 12 && minutes == 0 && seconds == 0) {
      strDateTime = d + '-' + MMM + '-' + y;
    }
    return strDateTime;
  }

  bindSelectedAnswerOptionId(ques: PermitTypeQuestion): number {
    if (this._permitObj.Responses.length > 0) {
      let ansID = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).SelectedAnswerOptionId;
      return ansID;
    }
    return 0;
  }  

  checkSectionPrintable(sectionName: string){
    try{
      if(this._sectionSelecetedForPrint.indexOf(sectionName) >= 0){
        return true;
      }
      else{
        return false;
      }
    }
    catch(ex){
      return false;
    }
  }
}
