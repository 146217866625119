import { Injectable } from '@angular/core';
import { Permit } from '../ViewModel/Permit';
import { Comment } from '../ViewModel/comment';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MyPermitsViewModel, MyPermitRootOIbject } from '../ViewModel/MyPermitsViewModel';
import { HelperService } from './helper.service';
import { HierarchyTree } from '../ViewModel/HierarchyTree';
import { SecurityDashboardRootObject, ClosedByRoleName } from '../ViewModel/SecurityDashboardViewModel';
import { Common } from '../ViewModel/Common';
import { ParentAsset } from '../ViewModel/VMAsset';
import { PermitIdentifier } from '../ViewModel/PermitIdentifier';

@Injectable({
  providedIn: 'root'
})
export class PermitService {
 _common = new Common();
  constructor(private _http: HttpClient, private _helperService: HelperService) { }
  private _permitUrl = environment.WEBAPIURL + 'permits/permit';
  private _permitsBasedOnPermitTypeUrl = environment.WEBAPIURL + 'permits/permit-type';
  private _permitCountUrl = environment.WEBAPIURL + 'permits/permit-type';
  private _myPermitsUrl = environment.WEBAPIURL + 'permits/my-permits/';
  private _getAllIdentifiers = environment.WEBAPIURL + 'permits/permit-identifier-all/';
  private _filterPermits = environment.WEBAPIURL + 'permits/permit-identifier-filter/';
  private _permitComments = environment.WEBAPIURL + 'permit-comment';
  private _cseRegisterUrl = environment.WEBAPIURL + 'cse-register'
  private _securityDashboardPermitsURL = environment.WEBAPIURL + 'permits/security-dashboard-permits'
  private _permitStatusUrl = environment.WEBAPIURL + 'permits/permit-status';
  private _sendMailUrl = environment.WEBAPIURL + 'permits/mail';
  private _updateEntryleaderUrl = environment.WEBAPIURL + 'permits/permit-entryLeader';
  private _EAMProcURL = environment.WEBAPIURL + 'permits/Cse-Asset';
  private _EAMNonCSEAssetURL = environment.WEBAPIURL + 'permits/NonCse-Asset';
  private _EAMWorkOrderURL = environment.WEBAPIURL + 'permits/work-order';
  private _EAMOPSeqURL = environment.WEBAPIURL + 'permits/op-sequence';
  private _sectionEditorURL = environment.WEBAPIURL + 'permits/getEditorDetails';
  private _NonCSEParentAssetURL = environment.WEBAPIURL + 'permits/non-cse-parent-asset';
  private _NonCSEChildAssetURL = environment.WEBAPIURL + 'permits/non-cse-child-asset';
  private _NonCSEAssetSearchURL = environment.WEBAPIURL + 'permits/non-cse-asset-search';
  private _permitIdentifierUrl = environment.WEBAPIURL + 'permits/permit-identifier';
  private _permitIdentifierByTabUrl = environment.WEBAPIURL + 'permits/permit-identifier-filters';
  private _getAllPermitsUrl = environment.WEBAPIURL + 'permits/getAllPermits';
  private _getDashboardClosedByRoleUrl = environment.WEBAPIURL + 'permits/dashboard-pending-role';
  getAllPermits() {
    return this._http.get<Permit[]>(this._permitUrl);
  }
  // updatePermitStateForSuspend(permitId,permitTypeId){
  //   let languageId = localStorage.getItem("_languageID");
  //   return this._http.put(this._permitUrl + '/' + permitTypeId + '/' + permitId +  '/' + languageId, { observe: 'response' });
  // }
  getPermit(permitId) {
    let loginId = localStorage.getItem("_loginId");
    return this._http.get<Permit>(this._permitUrl + '/' + permitId + '/' + loginId);
  }
  getAssetDetails(siteId) {
    // return this._http.get<any[]>(this.cseRegisterUrl);
    return this._http.get<any[]>(this._cseRegisterUrl + '/' + siteId);
  }
  // getAsset(permitId) {
  //   return this._http.get('../../assets/asset.json');
  // }
  // getEntryLeader() {
  //   return this._http.get('../../assets/entryLeader.json');
  // }
  addPermit(permit: Permit) {
    return this._http.post<Permit>(this._permitUrl, permit, { observe: 'response' });
  }

  updatePermit(permit: Permit) {
    return this._http.put(this._permitUrl, permit, { observe: 'response' });
  }
  updateEntryLeaderQuestion(permit: Permit) {
    return this._http.put(this._updateEntryleaderUrl, permit, { observe: 'response' });
  }

  updatePermitState(permitTypeId, permitId, permitStateId) {
    let languageId = localStorage.getItem("_languageID");
    return this._http.put(this._permitUrl + '/' + permitTypeId + '/' + permitId + '/' + permitStateId + '/' + languageId, { observe: 'response' });
  }

  getPermitsByPermitType(permitTypeId: number,_userSiteId:number=0 ,pageIndex:number=0,rowsCount:number=0) {
    let languageId = localStorage.getItem("_languageID");
    return this._http.get<Permit[]>(this._permitsBasedOnPermitTypeUrl + '/' + permitTypeId + '/' + languageId+'/'+_userSiteId+'/'+pageIndex+'/'+rowsCount)
  }
  getAllIdentifiers(permitTypeId: number) {
    return this._http.get<Permit[]>(this._getAllIdentifiers + permitTypeId+'/'+parseInt(localStorage.getItem("_languageID")))
  }
  getPermitCount(permitTypeId: number,_userSiteId:number=0 ) {
    let languageId = localStorage.getItem("_languageID");
    return this._http.get<Permit[]>(this._permitCountUrl + '/' + permitTypeId + '/' + languageId+'/'+_userSiteId)
  }

  getMyPermits(loginIdList: string[],startIndex, endIndex) {
    let languageId = localStorage.getItem("_languageID");
    let params = new HttpParams().set("loginIds", this._helperService.EscapeSpecialCharacters(JSON.stringify(loginIdList)));
    return this._http.get<MyPermitRootOIbject>(this._myPermitsUrl + languageId+ '/' + startIndex + '/' + endIndex, { params: params });
  }
  
  getPermitComments(permitId) {
    return this._http.get<Comment[]>(this._permitComments + '/' + permitId);
  }

  filterPermits(_filters) {
    return this._http.post<Comment[]>(this._filterPermits, _filters, { observe: 'response' });
  }
  addPermitComment(comment) {
    return this._http.post<Comment[]>(this._permitComments, comment, { observe: 'response' });
  }

  getPermitDetails(selectedHierarchy: any, startIndex, endIndex, loginId, globalFilter, isMyAction, isAllPermits, typeIds, statusIds, areaIds, dateRange,identifier1_Ids,identifier1_Val,identifier2_Ids,identifier2_Val,identifier3_Ids,identifier3_Val,identifier4_Ids,identifier4_Val,identifier5_Ids,identifier5_Val,identifier6_Ids,identifier6_Val,identifier7_Ids,identifier7_Val) {
    selectedHierarchy.languageId = localStorage.getItem("_languageID");
    selectedHierarchy.LogInId = localStorage.getItem("_loginId");
    selectedHierarchy.StartIndex = startIndex;
    selectedHierarchy.EndIndex = endIndex;
    selectedHierarchy.CreatedBy = loginId;
    selectedHierarchy.GlobalFilter = globalFilter;
    selectedHierarchy.MyAction = isMyAction;
    selectedHierarchy.AllPermits = isAllPermits;
    selectedHierarchy.MyPermits = (isMyAction == false && isAllPermits == false) ? true : false;    
    selectedHierarchy.PermitTypeIds = typeIds;
    selectedHierarchy.PermitStateIds = statusIds;
    selectedHierarchy.AreaIds = areaIds;
    selectedHierarchy.DateRange = dateRange;
    selectedHierarchy.identifier1_Ids=identifier1_Ids;
    selectedHierarchy.identifier1_Val=identifier1_Val;
    selectedHierarchy.identifier2_Ids=identifier2_Ids;
    selectedHierarchy.identifier2_Val=identifier2_Val;
    selectedHierarchy.identifier3_Ids=identifier3_Ids;
    selectedHierarchy.identifier3_Val=identifier3_Val;
    selectedHierarchy.identifier4_Ids=identifier4_Ids;
    selectedHierarchy.identifier4_Val=identifier4_Val;
    selectedHierarchy.identifier5_Ids=identifier5_Ids;
    selectedHierarchy.identifier5_Val=identifier5_Val;
    selectedHierarchy.identifier6_Ids=identifier6_Ids;
    selectedHierarchy.identifier6_Val=identifier6_Val;
    selectedHierarchy.identifier7_Ids=identifier7_Ids;
    selectedHierarchy.identifier7_Val=identifier7_Val;
    // let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(selectedHierarchy)));
    // return this._http.get<SecurityDashboardRootObject>(this._securityDashboardPermitsURL, { params: params })
    return this._http.post<SecurityDashboardRootObject>(this._securityDashboardPermitsURL, selectedHierarchy, { observe: 'response' });
  }

  getPermitStatus(permitId) {
    let languageId = localStorage.getItem("_languageID");
    return this._http.get<any>(this._permitStatusUrl + '/' + permitId + '/' + languageId);
  }

  sendConfiguredEmail(permitId, azureFileId) {
    return this._http.get<any>(this._sendMailUrl + '/' + permitId + '/' + azureFileId);
  }
  getProcedureDetailsFromEAM(assetNo: string, siteId)
  {
     return this._http.get<any>(this._EAMProcURL + '/' + assetNo + '/' + siteId);
  }
  getAssetDetailsFromEAM(filterText,siteId,startIndex, endIndex)
  {
   // orgCode = JSON.stringify(orgCode)
     return this._http.get<any>(this._EAMProcURL + '/' +filterText + '/' + siteId + '/' + startIndex + '/' + endIndex );
  }
  getNonCSEAssetDetailsFromEAM(filterText,siteId,startIndex, endIndex)
  {
   // orgCode = JSON.stringify(orgCode)
     return this._http.get<any>(this._EAMNonCSEAssetURL + '/' +filterText + '/' + siteId + '/' + startIndex + '/' + endIndex );
  }
  getWorkOrderFromEAM(assetNo,filterText,siteId,startIndex, endIndex)
  {
     return this._http.get<any>(this._EAMWorkOrderURL + '/' + assetNo + '/' +filterText + '/' + siteId + '/' + startIndex + '/' + endIndex );
  }
  getOperationSequence(woNo: string[], filterText, siteId, startIndex, endIndex) {
    let woStringify = this._helperService.EscapeSpecialCharacters(woNo.join('@@@'));
    return this._http.get<any>(this._EAMOPSeqURL + '/' + woStringify + '/' + filterText + '/' + siteId + '/' + startIndex + '/' + endIndex);
  }
  getEditorsDetails(permitId, stateId){
    return this._http.get<any>(this._sectionEditorURL + '/' + permitId + '/' + stateId);
  }
  getNonCSEParentAsset(siteId: string){
    return this._http.get<any>(this._NonCSEParentAssetURL + '/' + siteId);
  }
  getNonCSEChildAsset(siteId: string, assetNumber: string){
    return this._http.get<any>(this._NonCSEChildAssetURL + '/' + siteId + '/' + assetNumber);
  }
  getNonCSEAssetSearch(searchText: string, siteId: string, pageIndex: string, pageSize: string){
    return this._http.get<any>(this._NonCSEAssetSearchURL + '/' +searchText + '/' + siteId + '/' + pageIndex + '/' + pageSize );
  }
 
  getPermitIdentifier_MasterData(identifierQRef: string) {
    let params = new HttpParams().set("identifierQRef", identifierQRef);
    return this._http.get<PermitIdentifier[]>(this._permitIdentifierUrl, { params: params })
  }
  getPermitIdentifierByTabs(siteId: string, tabName: string, loginId: string, languageId: string, identifierQRef: string){
    let params = new HttpParams()
                  .set("siteId", siteId)
                  .set("tabName", tabName)
                  .set("loginId", loginId)
                  .set("languageId", languageId)
                  .set("identifierQRef", identifierQRef);
    return this._http.get<PermitIdentifier[]>(this._permitIdentifierByTabUrl, { params: params });
  }
  getAllPermitsByPermitTypeId(permitTypeId: number,_userSiteId:number=0 ) {
    let languageId = localStorage.getItem("_languageID");
    return this._http.get<Permit[]>(this._getAllPermitsUrl + '/' + permitTypeId +'/'+_userSiteId+ '/' + languageId);    
  }
  getDashboardClosedByRole(searchOptions: string, languageId: number) {
    let params = new HttpParams()
                  .set("searchOptions", this._helperService.EscapeSpecialCharacters(searchOptions))
                  .set("languageId", languageId.toString());
    return this._http.get<ClosedByRoleName[]>(this._getDashboardClosedByRoleUrl, { params: params })
  }
}
