import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Common } from '../ViewModel/Common';
import { Department } from '../ViewModel/Department';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';



@Injectable()
export class DepartmentService {
           
    getAllDeptUrl = environment.WEBAPIURL + 'Department/departments';
    getDeptDropdownUrl = environment.WEBAPIURL + 'Department/GetDepartmentDropdown';
    addDeptURL= environment.WEBAPIURL + 'Department/Department';
    editDeptURL = environment.WEBAPIURL + 'Department/Department';
    AddBulkURL = environment.WEBAPIURL + 'DataUpload/bulk-department';

    private _departmentUrl = environment.WEBAPIURL + 'Department/departments';


  constructor(private _http: HttpClient, private _helperService: HelperService) {}

  //Excel value bulk upload
  addBulkValue(Department) {  
    localStorage.setItem("_hierarchtTree", null);
    return this._http.post(this.AddBulkURL, Department,{observe: 'response'});
   }

  //Get All the SubSite Details
  getAllDept() {  
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.Active = true;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Department[]>(this._departmentUrl,{params:params});
  }

  GetDeptChildrens(_objBuId,_objRegionId,_objSubBuId,_objSiteId,_objSubSiteID,_objSruId,_objDepartmentId){
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = _objBuId;
    objVMHierarchySearch.RegionId = _objRegionId;
    objVMHierarchySearch.SubBuId = _objSubBuId;
    objVMHierarchySearch.SiteId = _objSiteId;
    objVMHierarchySearch.SubSiteId = _objSubSiteID;
    objVMHierarchySearch.SruId = _objSruId;
    objVMHierarchySearch.DepartmentId = _objDepartmentId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));    
    return this._http.get<Department[]>(this._departmentUrl,{params:params});
  }
  
  //Get Department Dropdown
  getDeptDropdown() {    
    return this._http.get<Department[]>(this.getDeptDropdownUrl);
  }

  //Get All the BU Details By SubSite
  getDeptBySru(SruId){    
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.SruId = SruId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Department[]>(this.getAllDeptUrl,{params: params});
  }

  //Get All the BU Details By SubSite
  getDeptBySruByRole(SruId){   
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.SruId = SruId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch)) 
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));    
    return this._http.get<Department[]>(this.getAllDeptUrl,{params: params});
  }

  //Add new SubSite
  addDept(Department:Department) {  
    localStorage.setItem("_hierarchtTree", null);
    Department.DefaultLanguageId=+localStorage.getItem("_languageID"); 
    return this._http.post(this.addDeptURL, Department, { observe: 'response' });
  }

  //Update Department
  editDept (Department:Department) {   
    localStorage.setItem("_hierarchtTree", null);
    Department.DefaultLanguageId=+localStorage.getItem("_languageID"); 
    return this._http.put(this.editDeptURL, Department,{observe: 'response'});
  }

  //Search Department
  searchDept ( _objBuId,_objRegionId,_objSubBuId,_objSiteId,_objSubSiteID,_objSruId,_objDepartmentName,isActive) { 
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = _objBuId;
    objVMHierarchySearch.RegionId = _objRegionId;
    objVMHierarchySearch.SubBuId = _objSubBuId;
    objVMHierarchySearch.SiteId = _objSiteId;
    objVMHierarchySearch.SubSiteId = _objSubSiteID;
    objVMHierarchySearch.SruId = _objSruId;
    objVMHierarchySearch.DepartmentName = _objDepartmentName;
    objVMHierarchySearch.Active = isActive;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch)); 
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     

    return this._http.get<Department[]>(this._departmentUrl, {params: params});
  }

  //Get SubSite By BU ,Region , SubBUId and SiteId  
    getDepartmentByParent(BUId, RegionId, SubBUId, SiteId,SubSite,SruId) {
    let objVMHierarchySearch=new HierarchySearchViewModel();
      objVMHierarchySearch.BuId=BUId;
      objVMHierarchySearch.RegionId=RegionId;
      objVMHierarchySearch.SubBuId=SubBUId;
      objVMHierarchySearch.SiteId=SiteId;
      objVMHierarchySearch.SubSiteId = SubSite;
      objVMHierarchySearch.SruId=SruId;
      objVMHierarchySearch.DepartmentName="";
      objVMHierarchySearch.Active=true;
      objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
      // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));  
      let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
      return this._http.get<Department[]>(this.getAllDeptUrl, {params: params});
  }

  //Get SubSite By BU ,Region , SubBUId and SiteId By Role
  getDepartmentByParentByRole(BUId, RegionId, SubBUId, SiteId,SubSite,SruId) {
    let objVMHierarchySearch=new HierarchySearchViewModel();
      objVMHierarchySearch.BuId=BUId;
      objVMHierarchySearch.RegionId=RegionId;
      objVMHierarchySearch.SubBuId=SubBUId;
      objVMHierarchySearch.SiteId=SiteId;
      objVMHierarchySearch.SubSiteId = SubSite;
      objVMHierarchySearch.SruId=SruId;
      objVMHierarchySearch.DepartmentName="";
      objVMHierarchySearch.Active=true;
      objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
      // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));
      let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));       
      return this._http.get<Department[]>(this.getAllDeptUrl, {params: params});
  }

}


  