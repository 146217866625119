import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
export class Common {

    public DomainURL: string;
    public Headers: HttpHeaders;
    public MediaHeaders: HttpHeaders;
    public mediaFetchHeader: HttpHeaders;
    public mediaPostHeader: HttpHeaders;
    public token: string;
    public HostURL: string;
    public MediaURL: string;

    app_token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IndVTG1ZZnNxZFF1V3RWXy1oeFZ0REpKWk00USIsImtpZCI6IndVTG1ZZnNxZFF1V3RWXy1oeFZ0REpKWk00USJ9.eyJhdWQiOiJodHRwczovL2dyYXBoLndpbmRvd3MubmV0LyIsImlzcyI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0LzM3YTcyZWI3LTFmYWYtNDlkMy05ZGRjLTZiOTIzYjc1MWJjMC8iLCJpYXQiOjE1NDI4ODYyNDksIm5iZiI6MTU0Mjg4NjI0OSwiZXhwIjoxNTQyODkwMTQ5LCJhaW8iOiI0MlJnWUFpYVhOMVcybW1ZL3F4aDNwR2JWeDRGQUFBPSIsImFwcGlkIjoiOGI1Y2M4MzEtMDgyZC00MDY5LWEyMWItZmVjZDI1MWU5MzBhIiwiYXBwaWRhY3IiOiIxIiwiaWRwIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvMzdhNzJlYjctMWZhZi00OWQzLTlkZGMtNmI5MjNiNzUxYmMwLyIsIm9pZCI6IjYyYTBjMjNmLWY5ZmUtNGI1OS1hMjNkLTg1YmQyODhkYzkxNCIsInN1YiI6IjYyYTBjMjNmLWY5ZmUtNGI1OS1hMjNkLTg1YmQyODhkYzkxNCIsInRlbmFudF9yZWdpb25fc2NvcGUiOiJOQSIsInRpZCI6IjM3YTcyZWI3LTFmYWYtNDlkMy05ZGRjLTZiOTIzYjc1MWJjMCIsInV0aSI6IjJkdk5VQjVpcEVtaF83Nmt3YmQwQUEiLCJ2ZXIiOiIxLjAifQ.MRl3vtUwyaRNKNv3HqJZwlRHNbmbYtVkr-F1y4aAQvbwEQViXkdVWs6fFLc-V-_iA7udjF-CTQS9akx1rLyvSC3wEjjviqKSo5NqJXbz3n9_H0MFvZborDtSCBPu9X7DyCjKp73acXq53HjY1to3SlV6awFyr5EDSZCd4DPmJs3UaFDZHae-bPNZHsfLNvqrIlwLVvJeSZCyrZ2qjXLFoZkGr-lIMIy0742lyygGCcjTSWIxnMnBBR0VQFWXZ1dsvCA5JEnEmOa3WolqXAiLxrZ_ucsqS7OUtr26QGQXobHfFgiRaW5hRVNW_2eN-n36WdGPIpxPPbuJ7eS31ViVJA"

    public constructor() {
        this.DomainURL = environment.WEBAPIURL;
        this.HostURL = window.location.host;
        this.MediaURL = environment.MediaWebApiUrl;

        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Ocp-Apim-Subscription-Key': '',//environment.AZURESUBSCRIPTIONKEY,
            'Authorization': '',//localStorage.getItem("app_token"),
            'Ocp-Apim-Trace': environment.APIM_Trace,
            'User-Login-Id': '',//localStorage.getItem("userID"),
            'Access-Control-Expose-Headers': '*',
        };

        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        const mediaHeaderDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Ocp-Apim-Subscription-Key': '',//environment.AZURESUBSCRIPTIONKEY,
            'Authorization': this.app_token,//localStorage.getItem("app_token"),
            'Ocp-Apim-Trace': environment.APIM_Trace,
            'Username': '',//localStorage.getItem("userID"),
            'Source': 'WPS',//localStorage.getItem("userID")
        };
        const requestMediaOptions = {
            requestmediaheaders: new HttpHeaders(mediaHeaderDict),
        };

        const mediaFetchHeaderDict = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Ocp-Apim-Subscription-Key': environment.AZURESUBSCRIPTIONKEY,
            'Authorization': localStorage.getItem("app_token"),
            'Ocp-Apim-Trace': environment.APIM_Trace,
            'Username': localStorage.getItem("userID"),
            'Source': 'WPS'
        };


        const requestMediaFetchOptions = {
            requestMediaFetchheaders: new HttpHeaders(mediaFetchHeaderDict),
        }

        const mediaPostHeaderDict = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Ocp-Apim-Subscription-Key': environment.AZURESUBSCRIPTIONKEY,
            'Authorization': 'barer_' + this.app_token,//localStorage.getItem("app_token"),
            'Ocp-Apim-Trace': environment.APIM_Trace,
            'Username': 'foc59sr',//localStorage.getItem("userID"),
            'Source': 'WPS',//localStorage.getItem("userID")
        };


        const requestMediaPostOptions = {
            requestMediaPostheaders: new HttpHeaders(mediaPostHeaderDict),
        }

        this.Headers = requestOptions.headers;
        this.MediaHeaders = requestMediaOptions.requestmediaheaders;
        this.mediaFetchHeader = requestMediaFetchOptions.requestMediaFetchheaders;
        this.mediaPostHeader = requestMediaPostOptions.requestMediaPostheaders;
    }

    public handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }

} 