import { UpdateStamp } from './UpdateStamp';

export class Attachment extends UpdateStamp{
    AttachmentId: number;
    AzureFileId : number;
    CategoryId?:number;
    UserId?:number ;
    PermitTypeId?:number;
    ResponseId?: number;
    ImageUri: string;
}
