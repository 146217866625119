import { UpdateStamp } from './UpdateStamp';
import {SubQuesResponseAttachment} from  './SubQuesResponseAttachment';
import { PermitTypeSubQuestion } from './PermitBuilder/PermitDivision/PermitTypeSubQuestion';
import {SubQuesTableTypeResponse} from  './SubQuesTableTypeResponse';

export class SubQuesResponse extends UpdateStamp
{
    SubQuesResponseId: number
    ResponseId :number
    PermitId : number
    PermitTypeSubQuestionId : number
    SubQuesResponseText : string
    SelectedAnswerOptionId : number
    PermitTypeSubQuestionRef:string|null
    PermitTypeSubQuesAnswerOptionRef:string|null
    SubQuestion: PermitTypeSubQuestion
    Approved: boolean
    Edited: boolean
    Rejected: boolean
    SubQuesResponseAttachment : SubQuesResponseAttachment[]
    SubQuesTableTypeResponse : SubQuesTableTypeResponse[]

}