import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Role } from '../ViewModel/Role';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';
import { TrainingStg } from '../ViewModel/TrainingStg';
import { Training } from '../ViewModel/Training';


@Injectable()
export class RoleService {
  
  getRoleURL = environment.WEBAPIURL + 'Role/Roles';

  getAllTypeURL = environment.WEBAPIURL + 'Type/GetAllTypes';
  getAllRolesURL = environment.WEBAPIURL + 'Role/roles';
  getRolesByTypeURL = environment.WEBAPIURL + 'Role/GetRolesByType';
  saveRolesURL = environment.WEBAPIURL + 'Role/roles';
  searchRolesURL = environment.WEBAPIURL + 'Role/Search';
  editRolesURL = environment.WEBAPIURL + 'Role/roles';
  AddBulkURL = environment.WEBAPIURL + 'DataUpload/bulk-role';
  getAllTypesByRoleURL = environment.WEBAPIURL + 'Type/GetAllTypeByRole';

  getAllTrainingStgDataUrl = environment.WEBAPIURL + 'Role/training-stage';
  addUpdateTrainingUrl = environment.WEBAPIURL + 'Role/training';

  constructor(private _http: HttpClient, private _helperService: HelperService) { }

  // fetch/search roles
  getRoles(objVMHierarchySearch: HierarchySearchViewModel) {  
      objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");   
    //  let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
     return this._http.get<Role[]>(this.getRoleURL,{params: params});
  }

  // add new role
  addRole(role: Role) {
    this._helperService._roles = [];
    return this._http.post(this.getRoleURL, role, { observe: 'response' });
  }
  addBulkValue(Role) {
    this._helperService._roles = [];
    return this._http.post(this.AddBulkURL, Role, { observe: 'response' })

  }

  // edit/update existing role
  editRole(role: Role) {
    this._helperService._roles = [];
    return this._http.put(this.getRoleURL, role, { observe: 'response'});
  }

  //get all Roles deatils
  getAllRoles() {
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.Active = true;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch)) 
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));    
    return this._http.get<Role[]>(this.getRoleURL,{params: params}); 
  }

  getAllTrainingStgData() {
    // blank model is being passed as the data will be filtered in the front end
    // need to discuss and change if needed
    let objVMHierarchySearch=new HierarchySearchViewModel();
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));    
    return this._http.get<TrainingStg[]>(this.getAllTrainingStgDataUrl,{params: params});
  }

  addupdateTraining(trainingList: Training[]) {
    return this._http.post(this.addUpdateTrainingUrl, trainingList, { observe: 'response' });
  }
}
