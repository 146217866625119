import { Component, OnInit } from '@angular/core';
import { SelectItem, MessageService, ConfirmationService } from 'primeng/api';
import { Languages } from 'src/app/ViewModel/Languages';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'src/app/Services/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css'],
  providers:[MessageService, ConfirmationService,CommonService]
})
export class LanguageComponent implements OnInit {
  txtlanguage = 'en';
  _languages: SelectItem[] = [];
  objSelectItem:SelectItem;
  selectedLanguage: string;
  language ="";
  infoUserId  ="";
  languageCode:string;
  _languagesList: Languages[] =[];
  constructor(private titleService: Title,private _commonService:CommonService,private _messageService:MessageService,private translate:TranslateService) { }

  ngOnInit() {
    if(this.txtlanguage =="en"){this.setTitle('Permit System');}
    if(this.txtlanguage =="pt"){this.setTitle('Sistema de Permissão');}
    this.getAllLanguages();
  }
  public setTitle( newTitle: string) {   
    this.titleService.setTitle( newTitle );
   
  }
  getAllLanguages(){
    this._commonService.getAllLanguages().subscribe(
      (data: Languages[]) => {
        this._languagesList = [];
        this._languagesList=data;
        for (let lang of  this._languagesList) {
          this._languages.push({label:lang.Language, value:lang.Code});
        }        
        this.infoUserId= '' //localStorage.getItem("_loginId");
        // localStorage.setItem("defaultLanguage", "1");
        this.languageCode = "en";
        //this.getUsersInfoById(this.infoUserId);
      },
      error => {
        this._messageService.add({key: 'error', sticky: true, severity: 'error', summary: 'Error Message', detail: 'Error while getting Role details'});
      }
    ); 
  }
  ChangeLanguage(txtlanguage) {
    this.infoUserId = localStorage.getItem("_loginId");
    if(txtlanguage =="en"){this.setTitle('Permit System');}
    if(txtlanguage =="pt"){this.setTitle('Sistema de Permissão');}   
    this.translate.use(txtlanguage);    
  }
 
}
