import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { BU } from 'src/app/ViewModel/BU'
import { Region } from 'src/app/ViewModel/Region'
import { Site } from 'src/app/ViewModel/Site'
import { SubBU } from 'src/app/ViewModel/SubBU'
import { SubSite } from 'src/app/ViewModel/SubSite'
import { SRU } from 'src/app/ViewModel/sru'
import { Department } from 'src/app/ViewModel/Department'
import { Crew } from 'src/app/ViewModel/Crew'
import { CommonService } from 'src/app/Services/common.service';
import { HierarchyTree } from 'src/app/ViewModel/HierarchyTree';
import { HelperService } from 'src/app/Services/helper.service';
import { Spinkit, SpinnerVisibilityService } from 'ng-http-loader';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hierarchy-tree',
  templateUrl: './hierarchy-tree.component.html',
  styleUrls: ['./hierarchy-tree.component.css'],
  providers: [CommonService]
})
export class HierarchyTreeComponent implements OnInit, OnChanges {
  @Input() nodeValueFromParent = new HierarchyTree();
  @Input() nodeValueFromParentArr: HierarchyTree[] = [];
  @Output() sendNode = new EventEmitter<TreeNode>();
  @Output() sendTreeValue = new EventEmitter<HierarchyTree>();
  @Output() onTreeLoad = new EventEmitter();
  node: TreeNode[] = [];

  @Output() sendTreeValueSelect = new EventEmitter<HierarchyTree[]>();
  @Input() flag = false;
  c: number = 0;
  counter: number = 0;
  onChange: boolean = false;
  prev: TreeNode[] = [];
  copySel: TreeNode[] = []
  public spinkit = Spinkit;
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {

    if (this.nodeValueFromParentArr != undefined) {
      this.BindTreeBasedOnInput();
    }
    else {
      if (!changes.nodeValueFromParent.isFirstChange()) {
        this.nodeValueFromParent = changes.nodeValueFromParent.currentValue;
      }
      if (this.nodeValueFromParent && this.nodeValueFromParent.BuId != null) {
        this.BindTreeBasedOnInput();
      }
      else {
        this.selectedNode = null;
        this.selectedNode = [];
        if (this.node.length > 0) {
          this.node.forEach(obj => {
            obj.expanded = false;
            obj.partialSelected = false;
            this.collapseChildren(obj);
          });
        }
      }
    }

  }

  collapseChildren(node: TreeNode) {
    if (node.children) {
      node.expanded = false;
      node.partialSelected = false;
      for (let child of node.children) {
        this.collapseChildren(child);
      }
    }
  }

  files: TreeNode[];

  selectedNode: TreeNode[];
  selectedNodeArr: TreeNode[] = [];

  hierarchyTreeObj: HierarchyTree;

  _bu: BU[] = [];
  _region: Region[] = [];
  _subBu: SubBU[] = [];
  _site: Site[] = [];
  _subSite: SubSite[] = [];
  _sru: SRU[] = [];
  _department: Department[] = [];
  _crew: Crew[] = [];



  constructor(private _commonService: CommonService, private _helperService: HelperService, private _router: Router) { }


  ngOnInit() {
    let treeValue = this._helperService._treeData;
    if (treeValue == null || treeValue.length == 0) {
      this.getTreeData();
    }
    else {
      this._bu = JSON.parse(JSON.stringify(treeValue));

      let listBU: BU[];
      listBU = this.getBUs();
      this.node = this.getTreeNode(listBU);
      this.BindTreeBasedOnInput();
      this.onTreeLoad.emit();
    }
  }

  BindTreeBasedOnInput() {
    let counter = 0;
    this.selectedNode = null;
    this.selectedNode = [];
    let currentNode: TreeNode;
    let currentNodeArr: TreeNode[] = [];
    this.UnselectEntireTree();
    if (this.nodeValueFromParent && (this.nodeValueFromParent.RegionId != null || this.nodeValueFromParent.SiteId != null || this.nodeValueFromParent.SruId != null || this.nodeValueFromParent.DepartmentId != null || this.nodeValueFromParent.CrewId != null)) {
      if (this.flag) {
        this.selectedNodeArr = this.GetHierarchyBasedOnInputArr(this.nodeValueFromParent, this.node, currentNodeArr);
        this.selectedNodeArr.forEach(i => {
          this.hierarchyTreeArr.push(this.getSelectedData(i));
        })
        this.sendTreeValueSelect.emit(this.hierarchyTreeArr);
        this.copySel = [...this.selectedNodeArr];
        this.prev = [...this.selectedNodeArr]
        this.hierarchyTreeArr = [];
        for (let i = 0; i < this.selectedNodeArr.length - 1; i++)
          this.selectedNodeArr[i].partialSelected = true;
      }
      else {
        this.selectedNode.push(this.GetHierarchyBasedOnInput(this.nodeValueFromParent, this.node, currentNode));
        if (this.selectedNode[0] === undefined || this.selectedNode[0] === null) {
          this.selectedNode = [];          
        }
        else {            
          let currentUrl = this._router.url;
          if (!currentUrl.includes("home")) {
            this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this._router.navigate([currentUrl]);
            });            
          }
          else {
            this._router.navigate(['/home']);           
          }         
        }
      }
    }

    else if (this.nodeValueFromParentArr && this.nodeValueFromParentArr.length > 0) {
      counter += 1;
      this.nodeValueFromParentArr.forEach(i => {
        this.selectedNodeArr = this.GetHierarchyBasedOnInputArr(i, this.node, currentNodeArr);
      })
      if (counter > 0) {
        let copy = [...this.selectedNodeArr];
        for (let i = 0; i < copy.length - 1; i++) {
          for (let j = i + 1; j < copy.length; j++) {
            if (copy[i].label == copy[j].label) {
              copy.splice(j, 1);
            }
          }
        }

        this.selectedNodeArr = [...copy];
      }

      this.selectedNodeArr[this.selectedNodeArr.length - 1].partialSelected = false;
      for (let i = 0; i < this.selectedNodeArr.length - 1; i++)
        this.selectedNodeArr[i].partialSelected = true;

      this.selectedNodeArr.forEach(i => {
        this.hierarchyTreeArr.push(this.getSelectedData(i));
      })
      this.sendTreeValueSelect.emit(this.hierarchyTreeArr);
      this.copySel = [...this.selectedNodeArr];
      this.prev = [...this.selectedNodeArr];
      this.hierarchyTreeArr = [];


    }
  }

  getAllTreeData(): void {
    this._bu = this._helperService._treeData;
    localStorage.setItem("_hierarchtTree", JSON.stringify(this._bu));
    let listBU: BU[];
    listBU = this.getBUs();
    this.node = this.getTreeNode(listBU);
    this.BindTreeBasedOnInput();
    this.onTreeLoad.emit();


  }

  getTreeData() {
    if (this._helperService._treeData.length == 0) {
      this._commonService.getTreeData().subscribe(
        (data: BU[]) => {
          this._helperService._treeData = [];
          this._helperService._treeData = data;
          localStorage.setItem("_hierarchtTree", JSON.stringify(data));
          this.getAllTreeData();
          this._helperService._isHerarchyChanged = false;
        }
      );
    }
  }

  getBUs(): BU[] {
    let buList: BU[] = [];

    for (let i = 0; i < this._bu.length; i++) {
      let buObj = new BU();
      let currBu = this._bu[i];
      buObj = this.getBUChildren(currBu);

      currBu.Regions = buObj.Regions;
      for (let currRegion of currBu.Regions) {
        let regionObj = new Region();
        regionObj = this.getRegionChildren(currRegion.RegionId, currBu);

        currRegion.SubBUs = regionObj.SubBUs;//this.getRegionObject(regionObj, currRegion);
        for (let currSubBu of currRegion.SubBUs) {
          let subBUObj = new SubBU();
          subBUObj = this.getSubBUChildren(currSubBu.SubBUId, currRegion.RegionId, currBu);

          currSubBu.Sites = subBUObj.Sites;// this.getSubBUObject(subBUObj, currSubBu);
          for (let currSite of currSubBu.Sites) {
            let siteObj = new Site();
            siteObj = this.getSiteChildren(currSite.SiteId, currSubBu.SubBUId, currRegion.RegionId, currBu);

            currSite.SubSites = siteObj.SubSites;// this.getSiteObj(siteObj, currSite);            
            for (let currSubSite of currSite.SubSites) {
              let subSiteObj = new SubSite();
              subSiteObj = this.getSubSiteChildren(currSubSite.SubSiteId, currSite.SiteId, currSubBu.SubBUId, currRegion.RegionId, currBu);

              currSubSite.SRUs = subSiteObj.SRUs;// this.getSubSiteObj(subSiteObj, currSubSite);
              for (let currSru of currSubSite.SRUs) {
                let sruObj = new SRU();
                sruObj = this.getSRUChildren(currSru.SRUId, currSubSite.SubSiteId, currSite.SiteId, currSubBu.SubBUId, currRegion.RegionId, currBu);

                currSru.Departments = sruObj.Departments;//this.getSRUObj(sruObj, currSru);
                for (let currDepartment of currSru.Departments) {
                  let departmentObj = new Department();
                  departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, currSubSite.SubSiteId, currSite.SiteId, currSubBu.SubBUId, currRegion.RegionId, currBu);
                  currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
                }

                currSru.Crews = currSru.Crews.filter(x => x.DepartmentId == null);
              }

              currSubSite.Departments = subSiteObj.Departments;
              for (let currDepartment of currSubSite.Departments) {
                let departmentObj = new Department();
                departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, currRegion.RegionId, currBu);
                currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
              }

              currSubSite.Crews = currSubSite.Crews.filter(x => x.DepartmentId == null && x.SRUId == null);
            }

            currSite.SRUs = siteObj.SRUs;
            for (let currSru of currSite.SRUs) {
              let sruObj = new SRU();
              sruObj = this.getSRUChildren(currSru.SRUId, null, null, null, null, currBu);

              currSru.Departments = sruObj.Departments;//this.getSRUObj(sruObj, currSru);
              for (let currDepartment of currSru.Departments) {
                let departmentObj = new Department();
                departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, null, null, null, null, currBu);
                currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
              }

              currSru.Crews = currSru.Crews.filter(x => x.DepartmentId == null);
            }

            currSite.Departments = siteObj.Departments;
            for (let currDepartment of currSite.Departments) {
              let departmentObj = new Department();
              departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, currRegion.RegionId, currBu);
              currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
            }

            currSite.Crews = currSite.Crews.filter(x => x.DepartmentId == null && x.SRUId == null && x.SubSiteId == null);
          }

          currSubBu.SubSites = subBUObj.SubSites;
          for (let currSubSite of currSubBu.SubSites) {
            let subSiteObj = new SubSite();
            subSiteObj = this.getSubSiteChildren(currSubSite.SubSiteId, null, null, currRegion.RegionId, currBu);

            currSubSite.SRUs = subSiteObj.SRUs;// this.getSubSiteObj(subSiteObj, currSubSite);
            for (let currSru of currSubSite.SRUs) {
              let sruObj = new SRU();
              sruObj = this.getSRUChildren(currSru.SRUId, currSubSite.SubSiteId, null, null, currRegion.RegionId, currBu);
              currSru = this.getSRUObj(sruObj, currSru);

              for (let currDepartment of currSru.Departments) {
                let departmentObj = new Department();
                departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, currSubSite.SubSiteId, null, null, currRegion.RegionId, currBu);
                currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
              }
            }

            currSubSite.Departments = subSiteObj.Departments; // this.getSRUObj(sruObj, currSru);
            for (let currDepartment of currSubSite.Departments) {
              let departmentObj = new Department();
              departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, null, currBu);
              currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
            }

            currSubSite.Crews = currSubSite.Crews.filter(x => x.DepartmentId == null && x.SRUId == null);

          }

          currSubBu.SRUs = subBUObj.SRUs;
          for (let currSru of currSubBu.SRUs) {
            let sruObj = new SRU();
            sruObj = this.getSRUChildren(currSru.SRUId, null, null, null, null, currBu);

            currSru.Departments = sruObj.Departments;//this.getSRUObj(sruObj, currSru);
            for (let currDepartment of currSru.Departments) {
              let departmentObj = new Department();
              departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, null, null, null, null, currBu);
              currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
            }

            currSru.Crews = currSru.Crews.filter(x => x.DepartmentId == null);
          }

          currSubBu.Departments = subBUObj.Departments;
          for (let currDepartment of currSubBu.Departments) {
            let departmentObj = new Department();
            departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, currRegion.RegionId, currBu);
            currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
          }

          currSubBu.Crews = currSubBu.Crews.filter(x => x.DepartmentId == null && x.SRUId == null && x.SubSiteId == null && x.SiteId == null && x.SubSiteId == null);
        }

        currRegion.Sites = regionObj.Sites;
        for (let currSite of currRegion.Sites) {
          let siteObj = new Site();
          siteObj = this.getSiteChildren(currSite.SiteId, null, currRegion.RegionId, currBu);

          currSite.SubSites = siteObj.SubSites;//this.getSiteObj(siteObj, currSite);          
          for (let currSubSite of currSite.SubSites) {
            let subSiteObj = new SubSite();
            subSiteObj = this.getSubSiteChildren(currSubSite.SubSiteId, currSite.SiteId, null, currRegion.RegionId, currBu);

            currSubSite.SRUs = subSiteObj.SRUs;//this.getSubSiteObj(subSiteObj, currSubSite);
            for (let currSru of currSubSite.SRUs) {
              let sruObj = new SRU();
              sruObj = this.getSRUChildren(currSru.SRUId, currSubSite.SubSiteId, currSite.SiteId, null, currRegion.RegionId, currBu);

              currSru.Departments = sruObj.Departments;//this.getSRUObj(sruObj, currSru);
              for (let currDepartment of currSru.Departments) {
                let departmentObj = new Department();
                departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, currSubSite.SubSiteId, currSite.SiteId, null, currRegion.RegionId, currBu);
                currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
              }

              currSru.Crews = currSru.Crews.filter(x => x.DepartmentId == null);
            }

            currSubSite.Departments = subSiteObj.Departments;
            for (let currDepartment of currSubSite.Departments) {
              let departmentObj = new Department();
              departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, currRegion.RegionId, currBu);
              currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
            }

            currSubSite.Crews = currSubSite.Crews.filter(x => x.DepartmentId == null && x.SRUId == null);
          }

          currSite.SRUs = siteObj.SRUs;
          for (let currSru of currSite.SRUs) {
            let sruObj = new SRU();
            sruObj = this.getSRUChildren(currSru.SRUId, null, currSite.SiteId, null, currRegion.RegionId, currBu);

            currSru.Departments = sruObj.Departments; // this.getSRUObj(sruObj, currSru);
            for (let currDepartment of currSru.Departments) {
              let departmentObj = new Department();
              departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, null, currSite.SiteId, null, currRegion.RegionId, currBu);
              currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
            }

            currSru.Crews = currSru.Crews.filter(x => x.DepartmentId == null);
          }

          currSite.Departments = siteObj.Departments;
          for (let currDepartment of currSite.Departments) {
            let departmentObj = new Department();
            departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, currRegion.RegionId, currBu);
            currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
          }

          currSite.Crews = currSite.Crews.filter(x => x.DepartmentId == null && x.SRUId == null && x.SubSiteId == null);
        }

        currRegion.SubSites = regionObj.SubSites;
        for (let currSubSite of currRegion.SubSites) {
          let subSiteObj = new SubSite();
          subSiteObj = this.getSubSiteChildren(currSubSite.SubSiteId, null, null, currRegion.RegionId, currBu);

          currSubSite.SRUs = subSiteObj.SRUs;// this.getSubSiteObj(subSiteObj, currSubSite);
          for (let currSru of currSubSite.SRUs) {
            let sruObj = new SRU();
            sruObj = this.getSRUChildren(currSru.SRUId, currSubSite.SubSiteId, null, null, currRegion.RegionId, currBu);
            currSru = this.getSRUObj(sruObj, currSru);

            for (let currDepartment of currSru.Departments) {
              let departmentObj = new Department();
              departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, currSubSite.SubSiteId, null, null, currRegion.RegionId, currBu);
              currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
            }
          }

          currSubSite.Departments = subSiteObj.Departments; // this.getSRUObj(sruObj, currSru);
          for (let currDepartment of currSubSite.Departments) {
            let departmentObj = new Department();
            departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, null, currBu);
            currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
          }

          currSubSite.Crews = currSubSite.Crews.filter(x => x.DepartmentId == null && x.SRUId == null);

        }

        currRegion.SRUs = regionObj.SRUs;
        for (let currSru of currRegion.SRUs) {
          let sruObj = new SRU();
          sruObj = this.getSRUChildren(currSru.SRUId, null, null, null, currRegion.RegionId, currBu);

          currSru.Departments = sruObj.Departments; // this.getSRUObj(sruObj, currSru);
          for (let currDepartment of currSru.Departments) {
            let departmentObj = new Department();
            departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, null, null, null, null, currBu);
            currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
          }

          currSru.Crews = currSru.Crews.filter(x => x.DepartmentId == null);
        }

        currRegion.Departments = regionObj.Departments;
        for (let currDepartment of currRegion.Departments) {
          let departmentObj = new Department();
          departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, currRegion.RegionId, currBu);
          currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
        }

        currRegion.Crews = currRegion.Crews.filter(x => x.DepartmentId == null);
      }

      currBu.SubBUs = buObj.SubBUs;
      for (let currSubBu of currBu.SubBUs) {
        let subBUObj = new SubBU();
        subBUObj = this.getSubBUChildren(currSubBu.SubBUId, null, currBu);

        currSubBu.Sites = subBUObj.Sites;// this.getSubBUObject(subBUObj, currSubBu);
        for (let currSite of currSubBu.Sites) {
          let siteObj = new Site();
          siteObj = this.getSiteChildren(currSite.SiteId, currSubBu.SubBUId, null, currBu);

          currSite.SubSites = siteObj.SubSites;// this.getSiteObj(siteObj, currSite);
          for (let currSubSite of currSite.SubSites) {
            let subSiteObj = new SubSite();
            subSiteObj = this.getSubSiteChildren(currSubSite.SubSiteId, null, null, null, currBu);

            currSubSite.SRUs = subSiteObj.SRUs;// this.getSubSiteObj(subSiteObj, currSubSite);
            for (let currSru of currSubSite.SRUs) {
              let sruObj = new SRU();
              sruObj = this.getSRUChildren(currSru.SRUId, null, null, null, null, currBu);

              currSru.Departments = sruObj.Departments; // this.getSRUObj(sruObj, currSru);
              for (let currDepartment of currSru.Departments) {
                let departmentObj = new Department();
                departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, null, null, null, null, currBu);
                currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
              }

              currSru.Crews = currSru.Crews.filter(x => x.DepartmentId == null);
            }

            currSite.Departments = subSiteObj.Departments;
            for (let currDepartment of currSite.Departments) {
              let departmentObj = new Department();
              departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, null, currBu);
              currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
            }

            currSubSite.Crews = currSubSite.Crews.filter(x => x.DepartmentId == null && x.SRUId == null);
          }

          currSite.SRUs = siteObj.SRUs;
          for (let currSru of currSite.SRUs) {
            let sruObj = new SRU();
            sruObj = this.getSRUChildren(currSru.SRUId, null, null, null, null, currBu);

            currSru.Departments = sruObj.Departments; // this.getSRUObj(sruObj, currSru);
            for (let currDepartment of currSru.Departments) {
              let departmentObj = new Department();
              departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, null, null, null, null, currBu);
              currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
            }

            currSru.Crews = currSru.Crews.filter(x => x.DepartmentId == null);
          }

          currSite.Departments = siteObj.Departments;
          for (let currDepartment of currSite.Departments) {
            let departmentObj = new Department();
            departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, null, currBu);
            currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
          }

          currSite.Crews = currSite.Crews.filter(x => x.DepartmentId == null && x.SRUId == null && x.SubSiteId == null);

        }

        currSubBu.SubSites = subBUObj.SubSites;// this.getSiteObj(siteObj, currSite);        
        for (let currSubSite of currSubBu.SubSites) {
          let subSiteObj = new SubSite();
          subSiteObj = this.getSubSiteChildren(currSubSite.SubSiteId, null, null, null, currBu);

          currSubSite.SRUs = subSiteObj.SRUs;// this.getSubSiteObj(subSiteObj, currSubSite);
          for (let currSru of currSubSite.SRUs) {
            let sruObj = new SRU();
            sruObj = this.getSRUChildren(currSru.SRUId, null, null, null, null, currBu);

            currSru.Departments = sruObj.Departments; // this.getSRUObj(sruObj, currSru);
            for (let currDepartment of currSru.Departments) {
              let departmentObj = new Department();
              departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, null, null, null, null, currBu);
              currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
            }

            currSru.Crews = currSru.Crews.filter(x => x.DepartmentId == null);
          }

          currSubSite.Departments = subSiteObj.Departments;
          for (let currDepartment of currSubSite.Departments) {
            let departmentObj = new Department();
            departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, null, currBu);
            currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
          }

          currSubSite.Crews = currSubSite.Crews.filter(x => x.DepartmentId == null && x.SRUId == null);

        }

        currSubBu.SRUs = subBUObj.SRUs;//this.getSubSiteObj(subSiteObj, currSubSite);
        for (let currSru of currSubBu.SRUs) {
          let sruObj = new SRU();
          sruObj = this.getSRUChildren(currSru.SRUId, null, null, null, null, currBu);

          currSru.Departments = sruObj.Departments; // this.getSRUObj(sruObj, currSru);
          for (let currDepartment of currSru.Departments) {
            let departmentObj = new Department();
            departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, null, null, null, null, currBu);
            currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
          }

          currSru.Crews = currSru.Crews.filter(x => x.DepartmentId == null);
        }

        currSubBu.Departments = subBUObj.Departments;//this.getSRUObj(sruObj, currSru);
        for (let currDepartment of currSubBu.Departments) {
          let departmentObj = new Department();
          departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, null, currBu);
          currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
        }

        currSubBu.Crews = currSubBu.Crews.filter(x => x.DepartmentId == null && x.SRUId == null && x.SubSiteId == null && x.SiteId == null);
      }

      currBu.Sites = buObj.Sites;
      for (let currSite of currBu.Sites) {
        let siteObj = new Site();
        siteObj = this.getSiteChildren(currSite.SiteId, null, null, currBu);

        currSite.SubSites = siteObj.SubSites;// this.getSiteObj(siteObj, currSite);        
        for (let currSubSite of currSite.SubSites) {
          let subSiteObj = new SubSite();
          subSiteObj = this.getSubSiteChildren(currSubSite.SubSiteId, currSite.SiteId, null, null, currBu);

          currSubSite.SRUs = subSiteObj.SRUs// this.getSubSiteObj(subSiteObj, currSubSite);
          for (let currSru of currSite.SRUs) {
            let sruObj = new SRU();
            sruObj = this.getSRUChildren(currSru.SRUId, null, null, null, null, currBu);
            currSru.Departments = sruObj.Departments; // this.getSRUObj(sruObj, currSru);

            for (let currDepartment of currSru.Departments) {
              let departmentObj = new Department();
              departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, null, null, null, null, currBu);
              currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
            }

            currSru.Crews = currSite.Crews.filter(x => x.DepartmentId == null);
          }

          currSubSite.Departments = subSiteObj.Departments;//this.getSRUObj(sruObj, currSru);
          for (let currDepartment of currSite.Departments) {
            let departmentObj = new Department();
            departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, null, currBu);
            currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
          }

          currSubSite.Crews = currSubSite.Crews.filter(x => x.DepartmentId == null);

        }

        currSite.SRUs = siteObj.SRUs;//this.getSubSiteObj(subSiteObj, currSubSite);
        for (let currSru of currSite.SRUs) {
          let sruObj = new SRU();
          sruObj = this.getSRUChildren(currSru.SRUId, null, null, null, null, currBu);
          currSru.Departments = sruObj.Departments; // this.getSRUObj(sruObj, currSru);

          for (let currDepartment of currSru.Departments) {
            let departmentObj = new Department();
            departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, null, null, null, null, currBu);
            currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
          }

          currSru.Crews = currSru.Crews.filter(x => x.DepartmentId == null);
        }

        currSite.Departments = siteObj.Departments;//this.getSRUObj(sruObj, currSru);
        for (let currDepartment of currSite.Departments) {
          let departmentObj = new Department();
          departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, null, currBu);
          currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
        }

        currSite.Crews = currSite.Crews.filter(x => x.DepartmentId == null && x.SRUId == null && x.SubSiteId == null);
      }

      currBu.SubSites = buObj.SubSites;
      for (let currSubSite of currBu.SubSites) {
        let subSiteObj = new SubSite();
        subSiteObj = this.getSubSiteChildren(currSubSite.SubSiteId, null, null, null, currBu);

        currSubSite.SRUs = subSiteObj.SRUs;//this.getSubSiteObj(subSiteObj, currSubSite);
        for (let currSru of currSubSite.SRUs) {
          let sruObj = new SRU();
          sruObj = this.getSRUChildren(currSru.SRUId, currSubSite.SubSiteId, null, null, null, currBu);
          currSru.Departments = sruObj.Departments; // this.getSRUObj(sruObj, currSru);

          for (let currDepartment of currSru.Departments) {
            let departmentObj = new Department();
            departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, currSubSite.SubSiteId, null, null, null, currBu);
            currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
          }

          currSru.Crews = currSubSite.Crews.filter(x => x.DepartmentId == null);
        }

        currSubSite.Departments = subSiteObj.Departments;//this.getSRUObj(sruObj, currSru);
        for (let currDepartment of currSubSite.Departments) {
          let departmentObj = new Department();
          departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, null, currBu);
          currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
        }

        currSubSite.Crews = currSubSite.Crews.filter(x => x.DepartmentId == null && x.SRUId == null);
      }

      currBu.SRUs = buObj.SRUs;
      for (let currSru of currBu.SRUs) {
        let sruObj = new SRU();
        sruObj = this.getSRUChildren(currSru.SRUId, null, null, null, null, currBu);

        currSru.Departments = sruObj.Departments;//this.getSRUObj(sruObj, currSru);
        for (let currDepartment of currSru.Departments) {
          let departmentObj = new Department();
          departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, currSru.SRUId, null, null, null, null, currBu);
          currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
        }

        currSru.Crews = currSru.Crews.filter(x => x.DepartmentId == null);
      }

      currBu.Departments = buObj.Departments;
      for (let currDepartment of currBu.Departments) {
        let departmentObj = new Department();
        departmentObj = this.getDepartmentChildren(currDepartment.DepartmentId, null, null, null, null, null, currBu);
        currDepartment = this.getDepartmentObj(departmentObj, currDepartment);
      }

      currBu.Crews = buObj.Crews;

      buList.push(currBu);
    }
    return buList;
  }

  getBUObject(buObj: BU, currBU: BU): BU {
    currBU.Regions = buObj.Regions;
    currBU.SubBUs = buObj.SubBUs;
    currBU.Sites = buObj.Sites;
    currBU.SubSites = buObj.SubSites;
    currBU.SRUs = buObj.SRUs;
    currBU.Departments = buObj.Departments;
    currBU.Crews = buObj.Crews;
    return currBU;
  }

  getRegionObject(regionObj: Region, currRegion: Region): Region {
    currRegion.SubBUs = regionObj.SubBUs;
    currRegion.Sites = regionObj.Sites;
    currRegion.SubSites = regionObj.SubSites;
    currRegion.SRUs = regionObj.SRUs;
    currRegion.Departments = regionObj.Departments;
    currRegion.Crews = regionObj.Crews;
    return currRegion;
  }

  getSubBUObject(subBUObj: SubBU, currSubBu: SubBU): SubBU {
    currSubBu.Sites = subBUObj.Sites;
    currSubBu.SubSites = subBUObj.SubSites;
    currSubBu.SRUs = subBUObj.SRUs;
    currSubBu.Departments = subBUObj.Departments;
    currSubBu.Crews = subBUObj.Crews;
    return currSubBu;
  }

  getSiteObj(siteObj: Site, currSite: Site): Site {
    currSite.SubSites = siteObj.SubSites;
    currSite.SRUs = siteObj.SRUs;
    currSite.Departments = siteObj.Departments;
    currSite.Crews = siteObj.Crews;
    return currSite;
  }

  getSubSiteObj(subSiteObj: SubSite, currSubSite: SubSite): SubSite {
    currSubSite.SRUs = subSiteObj.SRUs;
    currSubSite.Departments = subSiteObj.Departments;
    currSubSite.Crews = subSiteObj.Crews;
    return currSubSite;
  }

  getSRUObj(sruObj: SRU, currSru: SRU): SRU {
    currSru.Departments = sruObj.Departments;
    currSru.Crews = sruObj.Crews;
    return currSru;
  }

  getDepartmentObj(departmentObj: Department, currDepartment: Department): Department {
    currDepartment.Crews = departmentObj.Crews;
    return currDepartment;
  }

  getBUChildren(currBu: BU): BU {
    let bu = new BU();
    bu.Regions = [];
    bu.SubBUs = [];
    bu.Sites = [];
    bu.SubSites = [];
    bu.SRUs = [];
    bu.Departments = [];
    bu.Crews = [];

    bu.Regions = currBu.Regions.filter(x => x.BUId == currBu.BUId);
    bu.SubBUs = currBu.SubBUs.filter(x => x.BUId == currBu.BUId && x.RegionId == null);
    bu.Sites = currBu.Sites.filter(x => x.BUId == currBu.BUId && x.RegionId == null && x.SubBUId == null);
    bu.SubSites = currBu.SubSites.filter(x => x.BUId == currBu.BUId && x.RegionId == null && x.SubBUId == null && x.SiteId == null);
    bu.SRUs = currBu.SRUs.filter(x => x.BUId == currBu.BUId && x.RegionId == null && x.SubBUId == null && x.SiteId == null && x.SubSiteId == null);
    bu.Departments = currBu.Departments.filter(x => x.BUId == currBu.BUId && x.RegionId == null && x.SubBUId == null && x.SiteId == null && x.SubSiteId == null && x.SRUId == null);
    bu.Crews = currBu.Crews.filter(x => x.BUId == currBu.BUId && x.RegionId == null && x.SubBUId == null && x.SiteId == null && x.SubSiteId == null && x.SRUId == null && x.DepartmentId == null);

    return bu;
  }

  getRegionChildren(regionId: number, currentBu: BU): Region {
    let region = new Region();
    region.SubBUs = [];
    region.Sites = [];
    region.SubSites = [];
    region.SRUs = [];
    region.Departments = [];
    region.Crews = [];

    region.SubBUs = currentBu.SubBUs.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId);
    region.Sites = currentBu.Sites.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == null);
    region.SubSites = currentBu.SubSites.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == null && x.SiteId == null);
    region.SRUs = currentBu.SRUs.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == null && x.SiteId == null && x.SubSite == null);
    region.Departments = currentBu.Departments.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == null && x.SiteId == null && x.SubSite == null && x.SRUId == null);
    region.Crews = currentBu.Crews.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == null && x.SiteId == null && x.SubSite == null && x.SRUId == null && x.DepartmentId == null);

    return region;
  }

  getSubBUChildren(subBuId: number, regionId: number, currentBu: BU): SubBU {
    let subBU = new SubBU();
    subBU.Sites = [];
    subBU.SubSites = [];
    subBU.SRUs = [];
    subBU.Departments = [];
    subBU.Crews = [];

    subBU.Sites = currentBu.Sites.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId);
    subBU.SubSites = currentBu.SubSites.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId && x.SiteId == null);
    subBU.SRUs = currentBu.SRUs.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId && x.SiteId == null && x.SubSiteId == null);
    subBU.Departments = currentBu.Departments.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId && x.SiteId == null && x.SubSiteId == null && x.SRUId == null);
    subBU.Crews = currentBu.Crews.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId && x.SiteId == null && x.SubSiteId == null && x.SRUId == null && x.DepartmentId == null);

    return subBU;
  }

  getSiteChildren(siteId: number, subBuId: number, regionId: number, currentBu: BU): Site {
    let site = new Site();
    site.SubSites = [];
    site.SRUs = [];
    site.Departments = [];
    site.Crews = [];

    site.SubSites = currentBu.SubSites.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId && x.SiteId == siteId);
    site.SRUs = currentBu.SRUs.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId && x.SiteId == siteId && x.SubSiteId == null);
    site.Departments = currentBu.Departments.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId && x.SiteId == siteId && x.SubSiteId == null && x.SRUId == null);
    site.Crews = currentBu.Crews.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId && x.SiteId == siteId && x.SubSiteId == null && x.SRUId == null && x.DepartmentId == null);

    return site;
  }

  getSubSiteChildren(subSiteId: number, siteId: number, subBuId: number, regionId: number, currentBu: BU): SubSite {
    let subSite = new SubSite();
    subSite.SRUs = [];
    subSite.Departments = [];
    subSite.Crews = [];

    subSite.SRUs = currentBu.SRUs.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId && x.SiteId == siteId && x.SubSiteId == subSiteId);
    subSite.Departments = currentBu.Departments.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId && x.SiteId == siteId && x.SubSiteId == subSiteId && x.SRUId == null);
    subSite.Crews = currentBu.Crews.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId && x.SiteId == siteId && x.SubSiteId == subSiteId && x.SRUId == null && x.DepartmentId == null);

    return subSite;
  }

  getSRUChildren(sruId: number, subSiteId: number, siteId: number, subBuId: number, regionId: number, currentBu: BU): SRU {
    let sru = new SRU();
    sru.Departments = [];
    sru.Crews = [];

    sru.Departments = currentBu.Departments.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId && x.SiteId == siteId && x.SubSiteId == subSiteId && x.SRUId == sruId);
    sru.Crews = currentBu.Crews.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId && x.SiteId == siteId && x.SubSiteId == subSiteId && x.SRUId == sruId && x.DepartmentId == null);

    return sru;
  }

  getDepartmentChildren(departmentId: number, sruId: number, subSiteId: number, siteId: number, subBuId: number, regionId: number, currentBu: BU): Department {
    let department = new Department();
    department.Crews = [];

    department.Crews = currentBu.Crews.filter(x => x.BUId == currentBu.BUId && x.RegionId == regionId && x.SubBUId == subBuId && x.SiteId == siteId && x.SubSiteId == subSiteId && x.SRUId == sruId && x.DepartmentId == departmentId);

    return department;
  }

  getTreeNode(listBU: BU[]): TreeNode[] {
    let node: TreeNode[] = [];
    for (let currBU of listBU) {
      // let buTree: TreeNode = {
      //   label: currBU.BUName,
      //   data: currBU,
      //   children: this.getTreeChildren(currBU, 2)
      // }
      let list = this.getTreeChildren(currBU, 2);
      list.forEach(item => node.push(item));
    }
    return node;
  }

  getTreeChildren(obj: any, id: number): TreeNode[] {

    let node: TreeNode[] = [];
    switch (id) {
      case 2: for (let currRegion of obj.Regions) {
        let regionTree: TreeNode = {
          label: currRegion.RegionName,
          data: currRegion,
          children: this.getTreeChildren(currRegion, 3)
        }
        node.push(regionTree);
      }
      case 3: for (let currSubBu of obj.SubBUs) {
        let subBUTree: TreeNode = {
          label: currSubBu.SubBUName,
          data: currSubBu,
          children: this.getTreeChildren(currSubBu, 4)
        }
        node.push(subBUTree);
      }
      case 4: for (let currSite of obj.Sites) {
        let siteTree: TreeNode = {
          label: currSite.SiteName,
          data: currSite,
          children: this.getTreeChildren(currSite, 5)
        }
        node.push(siteTree);
      }
      case 5: for (let currSubSite of obj.SubSites) {
        let subSiteTree: TreeNode = {
          label: currSubSite.SubSiteName,
          data: currSubSite,
          children: this.getTreeChildren(currSubSite, 6)
        }
        node.push(subSiteTree);
      }
      case 6: for (let currSru of obj.SRUs) {
        let sruTree: TreeNode = {
          label: currSru.SRUName,
          data: currSru,
          children: this.getTreeChildren(currSru, 7)
        }
        node.push(sruTree);
      }
      case 7: for (let currDept of obj.Departments) {
        let deptTree: TreeNode = {
          label: currDept.DeptName,
          data: currDept,
          children: this.getTreeChildren(currDept, 8)
        }
        node.push(deptTree);
      }
      case 8: for (let currCrew of obj.Crews) {
        let crewTree: TreeNode = {
          label: currCrew.CrewName,
          data: currCrew
        }
        node.push(crewTree);
      }
    }

    return node;
  }
  hierarchyTreeArr = []
  nodeSelect(event) {
    //console.log(event.node);
    var obj = new HierarchyTree();
    obj.BuId = event.node.data.BUId;
    obj.RegionId = event.node.data.RegionId;
    obj.SubBuId = event.node.data.SubBUId;
    obj.SiteId = event.node.data.SiteId;
    obj.SubSiteId = event.node.data.SubSiteId;
    obj.SruId = event.node.data.SRUId;
    obj.DepartmentId = event.node.data.DepartmentId;
    obj.CrewId = event.node.data.CrewId;


    this.selectedNode = null;
    this.selectedNode = [];
    this.selectedNodeArr = [];
    //this.selectedNode.push(event.node);
    let currentNode: TreeNode;
    let currentNodeArr: TreeNode[] = []
    this.UnselectEntireTree();


    if (this.flag) {
      this.counter += 1;
      this.selectedNodeArr = this.GetHierarchyBasedOnInputArr(obj, this.node, currentNodeArr);
      this.prev = [...this.prev, ...this.selectedNodeArr];
      if (this.counter > 0) {
        let copy = [...this.prev];
        for (let i = 0; i < copy.length - 1; i++) {
          for (let j = i + 1; j < copy.length; j++) {
            if (copy[i].label == copy[j].label) {
              copy.splice(j, 1);
            }
          }
        }

        this.selectedNodeArr = [...copy];
      }
      this.copySel = [...this.selectedNodeArr];
      this.prev = this.selectedNodeArr;

      this.selectedNodeArr.forEach(i => {
        i.expanded = true;
        if (i.parent)
          i.parent.partialSelected = true;
      });

      this.selectedNodeArr.forEach(i => {
        this.hierarchyTreeArr.push(this.getSelectedData(i));
      })
      this.sendTreeValueSelect.emit(this.hierarchyTreeArr);
      console.log(this.hierarchyTreeArr)
      this.hierarchyTreeArr = [];
    }


    else {
      this.UnselectEntireTree();
      this.selectedNode.push(this.GetHierarchyBasedOnInput(obj, this.node, currentNode));
      this.hierarchyTreeObj = this.getSelectedData(event.node);
      //this.sendNode.emit(event.node);
      this.sendTreeValue.emit(this.hierarchyTreeObj);
    }

  }

  nodeUnselect(event) {
    console.log(this.flag)
    if (this.flag) {
      let unselNode = this.copySel.find(i => i.label == event.node.label);
      let copy = [...this.copySel];
      if (!unselNode.parent) {
        copy = [...copy.filter(i => i.data.RegionId != unselNode.data.RegionId)];
        this.selectedNodeArr = [...copy];
      }
      else if (unselNode.children.length > 0 && unselNode.parent) {
        for (let i = 0; i < unselNode.children.length; i++) {
          for (let j = 0; j < copy.length; j++) {
            if (copy[j].label == unselNode.children[i].label) {
              copy.splice(j, 1);
            }
          }
        }
        copy.splice(copy.findIndex(i => i.label == event.node.label), 1);
        copy[0].partialSelected = false;
        this.selectedNodeArr = [...copy];
      }
      else {
        this.copySel.splice(this.copySel.findIndex(i => i.label == event.node.label), 1);
        this.selectedNodeArr = this.copySel;
      }
      this.prev = [...this.selectedNodeArr];
      this.copySel = [...this.selectedNodeArr];
      this.selectedNodeArr.forEach(i => {
        this.hierarchyTreeArr.push(this.getSelectedData(i));
      })
      this.sendTreeValueSelect.emit(this.hierarchyTreeArr);
      if (this.selectedNodeArr.length > 1)
        this.selectedNodeArr[0].partialSelected = true;
      console.log(this.hierarchyTreeArr)
      this.hierarchyTreeArr = [];
      this.selectedNodeArr.forEach(i => {
        let f = 0;
        for (let j of i.children) {
          if (!j.partialSelected) {
            f = 1;
          }
          if (f == 1)
            i.partialSelected = true;
        }
      })
      if (this.selectedNodeArr.length == 1)
        this.selectedNodeArr[0].partialSelected = false;

    }

    else {
      var obj = new HierarchyTree();
      if (event.node.parent != undefined) {
        obj.BuId = event.node.parent.data.BUId;
        obj.RegionId = event.node.parent.data.RegionId;
        obj.SubBuId = event.node.parent.data.SubBUId;
        obj.SiteId = event.node.parent.data.SiteId;
        obj.SubSiteId = event.node.parent.data.SubSiteId;
        obj.SruId = event.node.parent.data.SRUId;
        obj.DepartmentId = event.node.parent.data.DepartmentId;
        obj.CrewId = event.node.parent.data.CrewId;
      }
      else {
        obj = null;
      }
      //this.selectedNode.pop();
      this.selectedNode = null;
      this.selectedNode = [];
      if (obj) {
        //this.selectedNode.push(event.node);
        let currentNode: TreeNode;
        this.UnselectEntireTree();
        this.selectedNode.push(this.GetHierarchyBasedOnInput(obj, this.node, currentNode));
      }

      if (event.node.parent != undefined) {
        this.hierarchyTreeObj = this.getSelectedData(event.node.parent);
      }
      else {
        this.hierarchyTreeObj = {
          Name: null,
          BuId: null,
          RegionId: null,
          SubBuId: null,
          SiteId: null,
          SubSiteId: null,
          SruId: null,
          DepartmentId: null,
          CrewId: null,
          BUName: '',
          RegionName: '',
          SubBUName: '',
          SiteName: '',
          SubSiteName: '',
          SRUName: '',
          DeptName: '',
          CrewName: '',
          SiteAbbreviation: ''
        };
      }

      //this.sendNode.emit(event.node);
      this.sendTreeValue.emit(this.hierarchyTreeObj);
    }
  }

  getSelectedData(nodeValue: TreeNode): HierarchyTree {
    let hierarchyTreeObj: HierarchyTree = {
      Name: nodeValue.label,
      BuId: nodeValue.data.BUId,
      RegionId: nodeValue.data.RegionId == undefined ? null : nodeValue.data.RegionId,
      SubBuId: nodeValue.data.SubBUId == undefined ? null : nodeValue.data.SubBUId,
      SiteId: nodeValue.data.SiteId == undefined ? null : nodeValue.data.SiteId,
      SubSiteId: nodeValue.data.SubSiteId == undefined ? null : nodeValue.data.SubSiteId,
      SruId: nodeValue.data.SRUId == undefined ? null : nodeValue.data.SRUId,
      DepartmentId: nodeValue.data.DepartmentId == undefined ? null : nodeValue.data.DepartmentId,
      CrewId: nodeValue.data.CrewId == undefined ? null : nodeValue.data.CrewId,
      BUName: '',
      RegionName: '',
      SubBUName: '',
      SiteName: '',
      SubSiteName: '',
      SRUName: '',
      DeptName: '',
      CrewName: '',
      SiteAbbreviation: ''
    }
    hierarchyTreeObj = this.getHierarchyNames(nodeValue, hierarchyTreeObj);
    return hierarchyTreeObj;
  }

  getHierarchyNames(nodeValue: TreeNode, hierarchyTreeObj: HierarchyTree) {
    if (nodeValue == undefined) {
      return hierarchyTreeObj;
    }
    else {
      if (nodeValue.data.BUId != null) {
        hierarchyTreeObj.BUName = nodeValue.label;
      }
      if (nodeValue.data.RegionId != null) {
        hierarchyTreeObj.RegionName = nodeValue.label;
      }
      if (nodeValue.data.SubBUId != null) {
        hierarchyTreeObj.SubBUName = nodeValue.label;
      }
      if (nodeValue.data.SiteId != null) {
        hierarchyTreeObj.SiteName = nodeValue.label;
      }
      if (nodeValue.data.SubSiteId != null) {
        hierarchyTreeObj.SubSiteName = nodeValue.label;
      }
      if (nodeValue.data.SRUId != null) {
        hierarchyTreeObj.SRUName = nodeValue.label;
      }
      if (nodeValue.data.DepartmentId != null) {
        hierarchyTreeObj.DeptName = nodeValue.label;
      }
      if (nodeValue.data.CrewId != null) {
        hierarchyTreeObj.CrewName = nodeValue.label;
      }
      return this.getHierarchyNames(nodeValue.parent, hierarchyTreeObj);
    }
  }

  UnselectEntireTree() {
    if (this.node.length > 0) {
      // To collapse and unselect entire tree
      this.node.forEach(obj => {
        obj.expanded = false;
        obj.partialSelected = false;
        this.collapseChildren(obj);
      });
    }
  }

  GetHierarchyBasedOnInput(val: HierarchyTree, nodeObj: TreeNode[], currentNode: TreeNode): TreeNode {

    if (val.RegionId != null) {
      for (let obj of nodeObj) {
        if (obj.data.RegionId == val.RegionId) {
          if (currentNode == undefined) {
            currentNode = obj;
            nodeObj = obj.children;
            break;
          }
          currentNode.expanded = true;
          currentNode.partialSelected = true;
          currentNode = obj;
          nodeObj = obj.children;
          break;
        }
      }
    }
    if (val.SubBuId != null) {
      for (let obj of nodeObj) {
        if (obj.data.SubBUId == val.SubBuId) {
          if (currentNode == undefined) {
            currentNode = obj;
            nodeObj = obj.children;
            break;
          }
          currentNode.expanded = true;
          currentNode.partialSelected = true;
          currentNode = obj;
          nodeObj = obj.children;
          break;
        }
      }
    }
    if (val.SiteId != null) {

      //ISSUE
      for (let obj of nodeObj) {
        if (obj.data.SiteId == val.SiteId) {
          if (currentNode == undefined) {
            currentNode = obj;
            nodeObj = obj.children;
            break;
          }
          currentNode.expanded = true;
          currentNode.partialSelected = true;
          currentNode = obj;
          nodeObj = obj.children;
          break;
        }
      }
    }
    if (val.SubSiteId != null) {
      for (let obj of nodeObj) {
        if (obj.data.SubSiteId == val.SubSiteId) {
          if (currentNode == undefined) {
            currentNode = obj;
            nodeObj = obj.children;
            break;
          }
          currentNode.expanded = true;
          currentNode.partialSelected = true;
          currentNode = obj;
          nodeObj = obj.children;
          break;
        }
      }
    }
    if (val.SruId != null) {
      for (let obj of nodeObj) {
        if (obj.data.SRUId == val.SruId) {
          if (currentNode == undefined) {
            currentNode = obj;
            nodeObj = obj.children;
            break;
          }
          currentNode.expanded = true;
          currentNode.partialSelected = true;
          currentNode = obj;
          nodeObj = obj.children;
          break;
        }
      }
    }
    if (val.DepartmentId != null) {
      for (let obj of nodeObj) {
        if (obj.data.DepartmentId == val.DepartmentId) {
          if (currentNode == undefined) {
            currentNode = obj;
            nodeObj = obj.children;
            break;
          }
          currentNode.expanded = true;
          currentNode.partialSelected = true;
          currentNode = obj;
          nodeObj = obj.children;
          break;
        }
      }
    }
    if (val.CrewId != null) {
      for (let obj of nodeObj) {
        if (obj.data.CrewId == val.CrewId) {
          if (currentNode == undefined) {
            currentNode = obj;
            nodeObj = obj.children;
            break;
          }
          currentNode.expanded = true;
          currentNode.partialSelected = true;
          currentNode = obj;
          nodeObj = obj.children;
          break;
        }
      }
    }
    return currentNode;
  }

  GetHierarchyBasedOnInputArr(val: HierarchyTree, nodeObj: TreeNode[], currentNode: TreeNode[]): TreeNode[] {

    if (val.RegionId != null) {
      for (let obj of nodeObj) {
        if (obj.data.RegionId == val.RegionId) {
          if (currentNode[0] == undefined) {
            currentNode.push(obj);
            nodeObj = obj.children;
            break;
          }
          currentNode[currentNode.length - 1].expanded = true;
          currentNode[currentNode.length - 1].partialSelected = true;
          currentNode.push(obj)
          nodeObj = obj.children;
          break;
        }
      }
    }
    if (val.SubBuId != null) {
      for (let obj of nodeObj) {
        if (obj.data.SubBUId == val.SubBuId) {
          if (currentNode[0] == undefined) {
            currentNode.push(obj);
            nodeObj = obj.children;
            break;
          }
          currentNode[currentNode.length - 1].expanded = true;
          currentNode[currentNode.length - 1].partialSelected = false;
          currentNode.push(obj);
          nodeObj = obj.children;
          break;
        }
      }
    }
    if (val.SiteId != null) {

      //ISSUE
      for (let obj of nodeObj) {
        if (obj.data.SiteId == val.SiteId) {
          if (currentNode[0] == undefined) {
            currentNode.push(obj)
            nodeObj = obj.children;
            break;
          }
          currentNode[currentNode.length - 1].expanded = true;
          currentNode[currentNode.length - 1].partialSelected = false;
          currentNode.push(obj);
          nodeObj = obj.children;
          break;
        }
      }
    }
    if (val.SubSiteId != null) {
      for (let obj of nodeObj) {
        if (obj.data.SubSiteId == val.SubSiteId) {
          if (currentNode[0] == undefined) {
            currentNode.push(obj)
            nodeObj = obj.children;
            break;
          }
          currentNode[currentNode.length - 1].expanded = true;
          currentNode[currentNode.length - 1].partialSelected = false;
          currentNode.push(obj)
          nodeObj = obj.children;
          break;
        }
      }
    }
    if (val.SruId != null) {
      for (let obj of nodeObj) {
        if (obj.data.SRUId == val.SruId) {
          if (currentNode[0] == undefined) {
            currentNode.push(obj)
            nodeObj = obj.children;
            break;
          }
          currentNode[currentNode.length - 1].expanded = true;
          currentNode[currentNode.length - 1].partialSelected = false;
          currentNode.push(obj)
          nodeObj = obj.children;
          break;
        }
      }
    }
    if (val.DepartmentId != null) {
      for (let obj of nodeObj) {
        if (obj.data.DepartmentId == val.DepartmentId) {
          if (currentNode[0] == undefined) {
            currentNode.push(obj)
            nodeObj = obj.children;
            break;
          }
          currentNode[currentNode.length - 1].expanded = true;
          currentNode[currentNode.length - 1].partialSelected = false;
          currentNode.push(obj)
          nodeObj = obj.children;
          break;
        }
      }
    }
    if (val.CrewId != null) {
      for (let obj of nodeObj) {
        if (obj.data.CrewId == val.CrewId) {
          if (currentNode[0] == undefined) {
            currentNode.push(obj);
            nodeObj = obj.children;
            break;
          }
          currentNode[currentNode.length - 1].expanded = true;
          currentNode[currentNode.length - 1].partialSelected = false;
          currentNode.push(obj)
          nodeObj = obj.children;
          break;
        }
      }
    }
    currentNode[currentNode.length - 1].expanded = true;
    currentNode[currentNode.length - 1].partialSelected = false;
    return currentNode;

  }


  clearSelectedNode() {
    this.selectedNode = null;
  }



}
