import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Crew } from '../ViewModel/Crew';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';

@Injectable()
export class CrewService {
  
  getAllCrewURL = environment.WEBAPIURL + 'Crew/Crew';
  getCrewDropdownURL = environment.WEBAPIURL + 'Crew/GetCrewDropdown'; 
  editCrewURL = environment.WEBAPIURL + 'Crew/Crew';  
  addCrewURL = environment.WEBAPIURL + 'Crew/Crew';
  AddBulkURL = environment.WEBAPIURL + 'DataUpload/bulk-crew';

  constructor(private _http: HttpClient, private _helperService: HelperService) { }

  //Excel value bulk upload
  addBulkValue(Crew) {  
    localStorage.setItem("_hierarchtTree", null);
    return this._http.post(this.AddBulkURL, Crew,{observe: 'response'});
   }
  //Update Department
  editCrew(Crew: Crew) {
    localStorage.setItem("_hierarchtTree", null);
    Crew.DefaultLanguageId=+localStorage.getItem("_languageID"); 
    return this._http.put(this.editCrewURL, Crew, { observe: 'response' });
  }

  getAllCrew() {
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.Active = true;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Crew[]>(this.getAllCrewURL,{params: params});
  }
  
  getCrewDropdown() {
    return this._http.get<Crew[]>(this.getCrewDropdownURL);
  }

  //Get All the Site Details By Region
  getCrewByDept(DeptId) {
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.DepartmentId = DeptId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Crew[]>(this.getAllCrewURL,{params: params}); 
  }

  getCrewByDeptByRole(DeptId) {
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.DepartmentId = DeptId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Crew[]>(this.getAllCrewURL,{params: params});
  }

  //Getting Crew By all parent hierarchy
  getCrewByParent(BUId, RegionId, SubBUId, SiteId, SubSite, SruId, DeptId) {
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = BUId;
    objVMHierarchySearch.RegionId = RegionId;
    objVMHierarchySearch.SubBuId = SubBUId;
    objVMHierarchySearch.SiteId = SiteId;
    objVMHierarchySearch.SubSiteId = SubSite;
    objVMHierarchySearch.SruId = SruId;
    objVMHierarchySearch.DepartmentId = DeptId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Crew[]>(this.getAllCrewURL,{params: params});
  }

  getCrewByParentForCRP(BUId, RegionId, SubBUId, SiteId, SubSite, SruId, DeptId) {
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = BUId;
    objVMHierarchySearch.RegionId = RegionId;
    objVMHierarchySearch.SubBuId = SubBUId;
    objVMHierarchySearch.SiteId = SiteId;
    objVMHierarchySearch.SubSiteId = SubSite;
    objVMHierarchySearch.SruId = SruId;
    objVMHierarchySearch.DepartmentId = DeptId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Crew[]>(this.getAllCrewURL,{params: params});
  }

  getCrewByParentByRole(BUId, RegionId, SubBUId, SiteId, SubSite, SruId, DeptId) {
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = BUId;
    objVMHierarchySearch.RegionId = RegionId;
    objVMHierarchySearch.SubBuId = SubBUId;
    objVMHierarchySearch.SiteId = SiteId;
    objVMHierarchySearch.SubSiteId = SubSite;
    objVMHierarchySearch.SruId = SruId;
    objVMHierarchySearch.DepartmentId = DeptId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Crew[]>(this.getAllCrewURL,{params: params});
  }

  addCrew(crew: Crew) {
    localStorage.setItem("_hierarchtTree", null);
    crew.DefaultLanguageId=+localStorage.getItem("_languageID"); 
    return this._http.post(this.addCrewURL, crew, { observe: 'response' });
  }

  //Search Crew 
  searchCrew ( _objBuId,_objRegionId,_objSubBuId,_objSiteId,_objSubSiteID,_objSruId,_objDepartmentId,_objCrewName,isActive) { 
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = _objBuId;
    objVMHierarchySearch.RegionId = _objRegionId;
    objVMHierarchySearch.SubBuId = _objSubBuId;
    objVMHierarchySearch.SiteId = _objSiteId;
    objVMHierarchySearch.SubSiteId = _objSubSiteID;
    objVMHierarchySearch.SruId = _objSruId;
    objVMHierarchySearch.DepartmentId = _objDepartmentId;
    objVMHierarchySearch.CrewName = _objCrewName;
    objVMHierarchySearch.Active = isActive;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));   
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));   
    return this._http.get<Crew[]>(this.getAllCrewURL, {params: params});
  }
}