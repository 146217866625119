import { Attributes } from './Attributes';
import { HierarchyTranslation } from './HierarchyTranslation';
import { BU } from './BU';
import { Region } from './Region';
import { SubBU } from './SubBU';
import { SubSite } from './SubSite';
import { SRU } from './sru';
import { Department } from './Department';
import { Crew } from './Crew';


export class Site{
    SiteId: number;
    SiteName: string;
    SiteAbbreviation: string;
    BUId: number;
    BUName: string;    
    RegionId: number;
    RegionName: string;
    SubBUId: number;
    SubBUName: string;
    OrganizationCode: string;
    TimeZone: string;

    Active: boolean;
    CreatedBy: string;
    CreatedDate: Date;
    ModifiedBy: string;
    ModifiedDate: Date;
    DefaultLanguageId:number;

    BU?: BU | null;
    Region?: Region | null;
    SubBU?: SubBU | null;    
    Attributes?: Attributes[]|null;
    HierarchyTranslations?: HierarchyTranslation[] | null;
    Sites?: Site[] | null;
    SubSites?: SubSite[] | null;
    SRUs?: SRU[] | null;
    Departments?:Department[]|null;
    Crews?:Crew[]|null;
}
