import { UpdateStamp } from './UpdateStamp';

export class UserForRoleGroupViewModel extends UpdateStamp {
    RoleId: number;
    UserShortInfo: UserShortInfo[] | null;
}
export class UserShortInfo {
    LoginId: string;
    UserName: string;
    UserId: number;
}


export class RoleGroupApproval extends UpdateStamp {
    RoleGroupApprovalId: number;
    PermitTypeSectionId?: number;
    PermitTypeLevelApprovalId?: number;
    ApproverText: string;
    RoleName: string;
    SelectedUserDetails: string[] | null;
    UserDetails: RoleGroupUserDetails[] | null;
    UserShortInfo: UserShortInfo[] | null;
    ApproverInfo: any[] | null;
    PendingWithEditor?: boolean;
}
export class RoleGroupUserDetails extends UpdateStamp {
    RoleGroupUserDetailsId: number;
    RoleGroupApprovalId: number;
    LoginId: string;
}