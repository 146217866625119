import {Injectable, ErrorHandler} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { throwError  } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Common } from '../ViewModel/Common';
import { SubBU } from '../ViewModel/SubBU';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';

@Injectable()
export class SubBUService  {
 
  getAllSubBuURL = environment.WEBAPIURL + 'SubBu/sub-bus';
  editSubBuURL = environment.WEBAPIURL + 'SubBu/SubBu';
  addSubBuURL = environment.WEBAPIURL + 'SubBu/SubBu';
  getSubBUDropdownURL = environment.WEBAPIURL + 'SubBU/GetSubBUDropdown';
  AddBulkURL = environment.WEBAPIURL + 'DataUpload/bulk-subu';

  constructor(private _http: HttpClient, private _helperService: HelperService) {
  }

  //Excel value bulk upload
  addBulkValue(SubBU) {  
    return this._http.post(this.AddBulkURL, SubBU, { observe: 'response' });
   }
  //Get All the BU Details
  getAllSubBU() {
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.Active = true;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    return this._http.get<SubBU[]>(this.getAllSubBuURL, { params:params });
  }
  //Get SubBU dropdown details
  getSubBUDropdown() {    
    return this._http.get<SubBU[]>(this.getSubBUDropdownURL);
  }

  GetSubBuChildren(buId,regionId,subBuId){
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = buId;
    objVMHierarchySearch.RegionId=regionId;
    objVMHierarchySearch.SubBuId=subBuId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    return this._http.get<SubBU[]>(this.getAllSubBuURL, { params: params });
  }

  //Get All the SubBU Details By Region
  getsubBUByRegion(RegionId){
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.RegionId=RegionId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    return this._http.get<SubBU[]>(this.getAllSubBuURL, {  params: params });
  }

  //Get All the SubBU Details By Region by Role
  getsubBUByRegionByRole(RegionId){   
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.RegionId=RegionId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    return this._http.get<SubBU[]>(this.getAllSubBuURL, { params: params });
  }

  //Get SubBU By BU and Region
  getSubBUByParent(BUId, RegionId) {
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = BUId;
    objVMHierarchySearch.RegionId = RegionId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    return this._http.get<SubBU[]>(this.getAllSubBuURL, { params: params });
  } 

  //Get SubBU By BU and Region
  getSubBUByParentByRole(BUId, RegionId) {
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = BUId;
    objVMHierarchySearch.RegionId = RegionId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    return this._http.get<SubBU[]>(this.getAllSubBuURL, { params: params });
  } 

  //Add new BU
  addSubBU(SubBU: SubBU) {   
    SubBU.DefaultLanguageId=+localStorage.getItem("_languageID"); 
    return this._http.post(this.addSubBuURL, SubBU, { observe: 'response' });
  }  

  //Update Sub BU
  editSubBU(_objsubBU) {  
    _objsubBU.DefaultLanguageId=+localStorage.getItem("_languageID"); 
    return this._http.put(this.editSubBuURL, _objsubBU, {observe: 'response' });
  }

  //Search Subbu
  SearchSubBU (BUId,RegionId, SubBuName, isActive) {
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = BUId;
    objVMHierarchySearch.RegionId=RegionId;
    objVMHierarchySearch.SubBuName = SubBuName;
    objVMHierarchySearch.Active = isActive;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    return this._http.get<SubBU[]>(this.getAllSubBuURL, { params: params });
  }
  
}
