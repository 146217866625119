import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of, Observable, Subscription, interval } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from './ViewModel/User';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from './Services/helper.service';
@Injectable()
export class AuthGuardService implements CanActivate {

  private subscription: Subscription;
  private CheckLoadFlg = false;
  private isSubscribed: boolean = false;
  private userLoginId: string
  private getUserAccessURL = environment.WEBAPIURL + 'User/user/';

  constructor(public router: Router, private _http: HttpClient, private translate: TranslateService, private _helperService: HelperService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    if(this._helperService._loggedInUser && this._helperService._loggedInUser.UserId > 0) {
      return of(this.LoadComponent(this._helperService._loggedInUser));
    }

    this.userLoginId = localStorage.getItem("_loginId");
    return this._http.get<User>(this.getUserAccessURL + this.userLoginId).pipe(
      map(e => {
        return this.LoadComponent(e);        
      }),
      catchError((err) => {
        this.router.navigate(['/home']);
        return of(false);
      })
    );
  }

  LoadComponent(user: User): boolean {
    if (user && user.UserId > 0) {
      return true;
    }
    else {
      this.translate.use('en');
      this.router.navigate(['/user-self-service']);
      return false;
    }
  }

  CheckLoadFlag() {
    try {
      if (localStorage.getItem("_loginId") && localStorage.getItem("_userName")) {
        this.CheckLoadFlg = true;
        if (this.isSubscribed) {
          this.subscription.unsubscribe();
        }
        return true;
      }
      else {
        const source = interval(1000);
        this.subscription = source.subscribe(val => {
          this.CheckLoadFlag();
          this.isSubscribed = true;
        });
      }
    } catch (e) {
      this.router.navigate(['/home']);
      return false;
    }
  }

}