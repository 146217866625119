import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RevalidateService {

  private _revalidateUrl = environment.WEBAPIURL + 'revalidate';
  constructor(private _http: HttpClient) { }

  revalidatePermit(permitTypeId:number, permitId:number, permitStateId:number,returnList:boolean,isRevalidateBeforeLapse:boolean) {
    let languageId = localStorage.getItem("_languageID");
    return this._http.post(this._revalidateUrl + '/' + permitTypeId + '/' + permitId + '/' + permitStateId+ '/' + returnList+ '/'+ isRevalidateBeforeLapse + '/' + languageId, { observe: 'response' });
  }

  getPermitRevisionById(permitId:number){
    return this._http.get(this._revalidateUrl + '/' + permitId);
  }
}
