import { UpdateStamp } from './UpdateStamp';

export class PermitWorkFlow extends UpdateStamp {
    PermitWorkFlowId: number;
    PermitId: number;
    TagId?: number;
    RoleId?: number;
    PermitStateId: number;
    ActionTaken?: number;
    DivisionNumber?: number;
    Sequence?: number;
}