export enum PermitStates {

    // PERMIT STATES
    DRAFT = 1,
    PENDINGAPPROVAL = 2,
    ACTIVE = 3,
    REJECTED = 4,
    CANCELLED = 5,
    SUSPENDED = 6,
    REVALIDATED = 7,
    LAPSED = 8,

    // SECTION LEVEL STATES
    SECTIONSAVED = 9,
    SECTIONEDITED = 10,
    SECTIONAPPROVED = 11,
    SECTIONREJECTED = 12,

    // DIVISION LEVEL STATES
    DIVISIONSAVED = 13,
    DIVISIONEDITED = 14,
    DIVISIONAPPROVED = 15,
    DIVISIONREJECTED = 16,
    
    CLOSED = 17,

    // PERMIT TYPE LEVEL STATES
    PERMITLEVELAPPROVED = 18,
    PERMITLEVELREJECTED = 19,

    // PERMIT STATES (contd. from above)
    AWAITINGEDITORRESPONSE = 20,

    // WORKFLOW STATES
    SECTIONEDIT = 21,
    SECTIONAPPROVE = 22,
    PERMITLEVELAPPROVE = 23,
    PERMITCLOSE = 24
}