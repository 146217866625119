import {Injectable} from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { SubSite } from '../ViewModel/SubSite';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';



@Injectable()
export class SubSiteService {
           
    getAllsubSiteUrl = environment.WEBAPIURL + 'SubSite/sub-sites';  
    getSubSiteDropdownURL = environment.WEBAPIURL + 'SubSite/GetSubSiteDropdown';
    getSubSiteBySiteUrl = environment.WEBAPIURL + 'SubSite/sites/'; 
    saveSubSiteURL= environment.WEBAPIURL + 'SubSite/sub-sites';
    editSubSiteURL = environment.WEBAPIURL + 'SubSite/sub-sites';
    AddBulkURL = environment.WEBAPIURL + 'DataUpload/bulk-sub-site';
    addUpdateTranslationURL = environment.WEBAPIURL + 'SubSite/AddUpdateSubSiteTranslations';

  constructor(private _http: HttpClient, private _helperService: HelperService) {}


  //Excel value bulk upload
  addBulkValue(SubSite) {  
    return this._http.post(this.AddBulkURL, SubSite,{observe: 'response'});
   }

  //Get All the SubSite Details
  getAllsubSite() { 
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.Active = true;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));   
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));      
    return this._http.get<SubSite[]>(this.getAllsubSiteUrl,{params:params});
  }

  //Get SubSite Dropdown Details
  getSubSiteDropdown() {
    return this._http.get<SubSite[]>(this.getSubSiteDropdownURL);
  }

  //Get All the SubSite Details By Site
  getSubSiteBySite(SiteId){    
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.SiteId = SiteId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));  
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    // return this._http.get<SubSite[]>(this.getSubSiteBySiteUrl+SiteId+'/sub-sites',{headers: this._common.Headers}).pipe(
    //    catchError(this._common.handleError)
    // );
    return this._http.get<SubSite[]>(this.getAllsubSiteUrl,{params:params});
  }
  
  //Get All the SubSite Details By Site
  getSubSiteBySiteByRole(SiteId){   
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.SiteId = SiteId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    return this._http.get<SubSite[]>(this.getAllsubSiteUrl,{params:params});
  }

  //Get SubSite By BU ,Region , SubBUId and SiteId
  getSubSiteByParent(BUId, RegionId, SubBUId, SiteId) {  
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = BUId;
    objVMHierarchySearch.RegionId = RegionId;
    objVMHierarchySearch.SubBuId = SubBUId;
    objVMHierarchySearch.SiteId = SiteId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    return this._http.get<SubSite[]>(this.getAllsubSiteUrl,{params:params});
  }

  //Get SubSite By BU ,Region , SubBUId and SiteId
  getSubSiteByParentByRole(BUId, RegionId, SubBUId, SiteId) {  
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = BUId;
    objVMHierarchySearch.RegionId = RegionId;
    objVMHierarchySearch.SubBuId = SubBUId;
    objVMHierarchySearch.SiteId = SiteId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch));
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    return this._http.get<SubSite[]>(this.getAllsubSiteUrl,{params:params});
  }

  //Add new SubSite
  addSubSite(SubSite:SubSite) { 
    SubSite.DefaultLanguageId=+localStorage.getItem("_languageID");  
   return this._http.post(this.saveSubSiteURL, SubSite,{observe: 'response'});
  }

  //Update SubSite
  editSubSite (SubSite:SubSite) {   
    SubSite.DefaultLanguageId=+localStorage.getItem("_languageID"); 
    return this._http.put(this.editSubSiteURL, SubSite,{observe: 'response'});
  }

  //Search SubSite
  searchSubSite ( BUId,RegionId,SubBUId,SiteId,SubSiteName,isActive) { 
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId=BUId;
    objVMHierarchySearch.RegionId=RegionId;
    objVMHierarchySearch.SubBuId=SubBUId;
    objVMHierarchySearch.SiteId=SiteId;
    objVMHierarchySearch.SubSiteName=SubSiteName;
    objVMHierarchySearch.Active = isActive;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch)); 
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));        
    return this._http.get<SubSite[]>(this.getAllsubSiteUrl, { params: params });
  }

  GetSubSiteChildren(buId,regionId,subBuId,siteId,subSiteId){
    let objVMHierarchySearch=new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = buId;
    objVMHierarchySearch.RegionId=regionId;
    objVMHierarchySearch.SubBuId=subBuId;
    objVMHierarchySearch.SiteId=siteId;
    objVMHierarchySearch.SubSiteId=subSiteId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    // let params = new HttpParams().set("searchOptions", JSON.stringify(objVMHierarchySearch))
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));     
    return this._http.get<SubSite[]>(this.getAllsubSiteUrl,{params: params});
  }

  //Add Update Sub site Translations
  AddUpdateSubSiteTranslations(subSite: SubSite) {
    return this._http.post(this.addUpdateTranslationURL, subSite, { observe: 'response' });
  }

}
