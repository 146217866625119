import { UpdateStamp } from '../../UpdateStamp';
import { PermitTypeSection } from './PermitTypeSection';

export class PermitTypeDivision extends UpdateStamp{
    PermitTypeDivisionId: number;
    PermitTypeVersionId: number;
    DivisionName: string;
    Approver?: number;
    Editor?: number;
    EmailNotification:boolean;
    SMSNotification:boolean;
    Sections?:PermitTypeSection[]|null;
}
