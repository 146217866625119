import { UpdateStamp } from '../../UpdateStamp';
import { PermitTypeAnswerOption } from './PermitTypeAnswerOption';
import { PermitTypeTableFieldType } from './PermitTypeTableFieldType';
import { TableTypeResponse } from '../../TableTypeResponse';
import { PermitApproverType } from './PermitApproverType';


export class PermitTypeQuestion extends UpdateStamp {
    PermitTypeQuestionId: number;
    PermitTypeSectionId: number;
    AnswerTypeId: number;
    Mandatory: boolean;
    Order: number;
    QuestionText: String;
    AttachmentRequired: boolean;
    AlphabeticalOrderRequired:boolean;
    CommentRequired: boolean;
    SideOptionPlacement: boolean;
    AnswerOptions?: PermitTypeAnswerOption[] | null;
    PermitApproverType?: PermitApproverType | null;
    AnswerText: string;
    RowCount?: number;
    CopyQuestionValue?: boolean;
    Deleted?: boolean;
    FieldIdentifier?: boolean;
    QuestionWidth?: number;
    SelectedAnswerOptionID: number = 0;
    SelectedAnswerCheckBox?: number[] | null;
    TableFieldType?: PermitTypeTableFieldType[] | null;
    TableData?: [][];
    TableTypeResponses?: TableTypeResponse[] | null;
    CancelApplicable: boolean = false;
    FieldValue: string | null;
    FieldDesc: string | null;
    ProcName: string | null;
    ProcRef: string | null;
    RiskAssesment: string | null;
    Approver: string | null;
    TimeStamp: string | null;
    ContactNumber: string | null;
    ExceededMaxAllowedWidth: boolean = false;
    MandatoryValidationSatisfied: boolean = true;
    PermitTypeQuestionRef: string | null;
   
}
