import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PermitControl } from '../ViewModel/PermitBuilder/PermitControl/PermitControl';
import { environment } from 'src/environments/environment';
import { PermitControlRoleViewModel, SuspendPermitTypeViewModel } from '../ViewModel/PermitControlRoleViewModel';
import { HelperService } from './helper.service';
import { PermitTypeControlTimeOption } from '../ViewModel/PermitTypeControlTimeOPtion';

@Injectable({
  providedIn: 'root'
})
export class PermitControlService {

  private _permitTypeControlUrl = environment.WEBAPIURL + 'PermitControl/permit-control';
  private _canSuspendPermitTypeURL = environment.WEBAPIURL + 'PermitControl/suspension-enabled';
  private _permitTypeControlTimeOptionUrl = environment.WEBAPIURL + 'PermitControl/time-option';
  private _permitTypeControlRole = environment.WEBAPIURL + 'PermitControl/permit-control-roles';

  constructor(private _http: HttpClient, private _helperService: HelperService) { }

  getPermitTypeControl(permitTypeVersionId) {
    return this._http.get<PermitControl>(this._permitTypeControlUrl + '/' + permitTypeVersionId);
  }

  addPermitTypeControl(permitTypeControl: PermitControl) {
    return this._http.post(this._permitTypeControlUrl, permitTypeControl, { observe: 'response' });
  }
  getPermitControlRoles(permitTypeId, loginId, siteId) {
    return this._http.get<boolean>(this._permitTypeControlUrl + '/' + permitTypeId + '/' + loginId + '/' + siteId);
  }
  getPermitTypeControlRoles(permitTypeId: number) {
    return this._http.get<PermitControl>(this._permitTypeControlRole + '/' + permitTypeId);
  }
  canSuspendPermit(permitControlRoleViewModel: PermitControlRoleViewModel) {
    let params = new HttpParams().set("permitControlRoleViewModel", this._helperService.EscapeSpecialCharacters(JSON.stringify(permitControlRoleViewModel)));
    // return this._http.get<SuspendPermitTypeViewModel>(this._canSuspendPermitTypeURL, { params: params });
    return this._http.get<boolean>(this._canSuspendPermitTypeURL, { params: params });
  }

  getPermitControlTimeOptions() {
    return this._http.get<PermitTypeControlTimeOption[]>(this._permitTypeControlTimeOptionUrl);
  }
}
