import { PermitTypeQuestion } from './PermitBuilder/PermitDivision/PermitTypeQuestion';
import { UpdateStamp } from './UpdateStamp';

export class TableTypeResponse extends UpdateStamp{
    TableTypeResponseId:number|null;
    TableFieldTypeId: number|null;
    ResponseId: number;
    ResponseText?: string|null;
    SelectedAnswerOptionId?: number|null=0;
    SelectedAnswerCheckBoxForTable?:number[]|null;
    Question?: PermitTypeQuestion|null;
    RowIndex?: number;
    PermitTypeTableFieldTypeRef: string|null;
    PermitTypeTableAnswerOptionRef:string|null;
}