export class UserSearchViewModel {
    BuId?: number;
    RegionId?: number;
    SubBuId?: number;
    SiteId?: number;
    SubSiteId?: number;
    SruId?: number;
    DepartmentId?: number;
    CrewId?: number;
    Active?: boolean;
    languageId: number;
    LoginId: string;
    EmployeeId: number;
    ContractorId: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    EmailId: string;
    RoleId: number;
}