import { Injectable } from '@angular/core';
// import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
const EXTENSION = '.pdf';
const pdfsize = 'a4';
declare var jsPDF: any;

@Injectable()
export class PDFService {

    constructor() { }
    datetime = new Date();
    exportToPDF(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        doc.autoTable(columns, rows, {
            theme: 'grid',
            styles: {
                overflow: 'linebreak'
            },
            columnStyles: {
                id: { fillColor: 255 }
            },
            margin: { top: 50 },
            addPageContent: function (data) {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }
    exportToPDFLandscape(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        doc.autoTable(columns, rows, {
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 7,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                Hierarchy: { columnWidth: 150 }
            },          
            margin: { top: 50 },
            addPageContent: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }
    exportToPDFDepartment(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        doc.autoTable(columns, rows, {
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 5,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                Hierarchy: { columnWidth: 150 }
            },          
            margin: { top: 50 },
            addPageContent: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }
    exportToPDFPermitType(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        doc.autoTable(columns, rows, {
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 5,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                Hierarchy: { columnWidth: 150 }
            },          
            margin: { top: 50 },
            addPageContent: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }
    exportToPDFCrew(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        doc.autoTable(columns, rows, {
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 4,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                Hierarchy: { columnWidth: 150 }
            },          
            margin: { top: 50 },
            addPageContent: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }

    exportToPDFRole(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        doc.autoTable(columns, rows, {
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 4,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                Hierarchy: { columnWidth: 150 }
            },          
            margin: { top: 50 },
            addPageContent: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }

    exportToPDFContractor(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        doc.autoTable(columns, rows, {
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 4,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                Hierarchy: { columnWidth: 150 }
            },          
            margin: { top: 50 },
            addPageContent: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }
    
    exportToPDFUsers(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        doc.autoTable(columns, rows, {
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 4,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                LoginId: { columnWidth: 30 },
                ClockNo: { columnWidth: 40 },
                Name: { columnWidth: 40 },
                BU : { columnWidth: 30 },
                Region: { columnWidth: 30 },
                SubBU: { columnWidth: 30 },
                Site: { columnWidth: 30 },
                SubSite: { columnWidth: 30 },
                SRU: { columnWidth: 30 },
                Department: { columnWidth: 30 },
                Crew: { columnWidth: 30 },
                Language: { columnWidth: 30 },
                Contractor: { columnWidth: 40 },
                EmailAddress: { columnWidth: 40 },
                Active: { columnWidth: 30 }
            },          
            margin: { top: 50 },
            addPageContent: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }

    exportToPDFAssignment(columns, rows, header, fileName) {
       
        const doc = new jsPDF('l', 'pt', pdfsize);
        doc.autoTable(columns, rows, {
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 7,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                
                    'Org Level': { columnWidth: 70 },
                    Role: { columnWidth: 70 },
                    Type: { columnWidth: 60 },
                    Category : { columnWidth: 80 },
                    Template: { columnWidth: 70 },
                    'Allocation Method': { columnWidth: 130 },
                    Frequency: { columnWidth: 100 },
                    'Target' : { columnWidth: 45 },
                    Hierarchy: { columnWidth: 150 }
                
            
            },          
            margin: { top: 50 },
            addPageContent: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }

    exportToPDFTeamWorklist(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        doc.autoTable(columns, rows, {
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 7,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
               
        Category: { columnWidth: 60 },
        Assigned: { columnWidth: 60 },
        'Completed (On Time)' : { columnWidth: 60 },
        'Completed (Late)': { columnWidth: 70 },
        '% Completed (On Time)': { columnWidth: 55 }
            
            },          
            margin: { top: 50 },
            addPageContent: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }

    exportToPDFSearchAndView(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        doc.autoTable(columns, rows, {
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 5,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                Hierarchy: { columnWidth: 150 }
            },          
            margin: { top: 50 },
            addPageContent: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }

    exportToPDFRiskNotification(columns, rows, header, fileName) {
       
        const doc = new jsPDF('l', 'pt', pdfsize);
        doc.autoTable(columns, rows, {
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 7,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
               
                RNId: { columnWidth: 20 },
                UserWLId: { columnWidth: 20 },
                RNNumber: { columnWidth: 130 },
                AIEntityCode : { columnWidth: 60 },
                IsRNPosted: { columnWidth: 70 },
                RaisedBy: { columnWidth: 55 },
                BriefStatement: { columnWidth: 55 },
                ObservedDate: { columnWidth: 125 },
                CorrectiveAction: { columnWidth: 110 },
                Template: { columnWidth: 70 },
                Category: { columnWidth: 60 },
                Type: { columnWidth: 80 },
            
            },          
            margin: { top: 50 },
            addPageContent: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }

    exportToPDFWorkRequest(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        doc.autoTable(columns, rows, {
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 7,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
               
        Type: { columnWidth: 50 },
        Category: { columnWidth: 60 },
        Template: { columnWidth: 60 },
        Site : { columnWidth: 60 },
        WRNumber: { columnWidth: 70 },
        Priority: { columnWidth: 55 },
        WRPosted: { columnWidth: 55 },
        AssetNumber: { columnWidth: 125 },
        WRDescription: { columnWidth: 125 },
        RaisedBy: { columnWidth: 70 },
        RaisedDate: { columnWidth: 60 }
            
            },          
            margin: { top: 50 },
            addPageContent: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }
}
