import { UserRole } from './UserRole';
import { Contractor } from './Contractor';
import { Languages } from './Languages';
import { Attachment } from './Attachment';

export class User{
    UserId: number;
    LoginId: string;
    PayrollNo?: string;
    
    Attachment: Attachment | null;
    FirstName: string;
    MiddleName: string;
    LastName :string;
    EmailAddress: string;  
    CountryCode: string;
    MobileNumber:string ; 
    EBICardNumber?: number;
    BUId: number;
    RegionId: number;
    SubBUId: number;
    SiteId: number;
    SubSiteId:number;
    SRUId: number;
    DepartmentId: number;
    CrewId: number;
    LanguageId: number;
    ContractorId:number;
    Languages:Languages;
    BUName: string;
    RegionName: string;
    SubBUName: string;
    SiteName: string;
    SubSiteName: string;
    SRUName: string;
    DeptName: string;
    CrewName: string;
    Contractor:Contractor;
    //Language: Languages;
    RoleName: string;
    //Languages:Languages;
    Active: boolean;
    CreatedBy: string;
    CreatedDate: Date;
    ModifiedBy: string;
    ModifiedDate: Date;
    UserRoles?: UserRole[] | null;
    
    
}