import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PermitDashboardConfiguration } from '../ViewModel/PermitBuilder/PermitDivision/DashboardConfiguration/PermitDashboardConfiguration';
import { HttpClient } from '@angular/common/http';
import { DashboardViewModel } from '../ViewModel/Dashboard/DashboardViewModel';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private dashboardConfigUrl = environment.WEBAPIURL + 'dashboard-configuration';
  private dashboardListUrl=environment.WEBAPIURL + 'permit-dashboard';
  constructor(private _http: HttpClient) { }

  //Add Configuration
  addDashboardConfiguration(dashboradConfiguration: PermitDashboardConfiguration[]) {
    return this._http.post(this.dashboardConfigUrl, dashboradConfiguration, { observe: 'response' });
  }

  //Update Configuration
  editDashboardConfiguration(dashboradConfiguration: PermitDashboardConfiguration[]) {
    return this._http.put(this.dashboardConfigUrl, dashboradConfiguration, { observe: 'response' });
  }

  getAllDashboardConfiguration(permitTypeVersionId) {
    return this._http.get<PermitDashboardConfiguration[]>(this.dashboardConfigUrl + '/' + permitTypeVersionId);
  }

  getAllDashboardList(permitTypeVersionId){
    let languageId = localStorage.getItem("_languageID");
    return this._http.get<DashboardViewModel[]>(this.dashboardListUrl + '/' + permitTypeVersionId + '/' + languageId);
  }
}
