import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { CreatePermitComponent } from './create-permit/create-permit.component';
import { UserProfileComponent } from './commonhelpers/user-profile/user-profile.component';
import { SelfServiceComponent } from './self-service/self-service.component';
import { PermitComponent } from './permit/permit.component';
import { NotificationComponent } from './notification/notification.component';
import { AuthGuardService as AuthGuard } from './auth-guard.service';
import { RoleGuardService as RoleGuard } from './role-guard.services';
// import { FireCrewComponent } from './fire-crew/fire-crew.component';
// import { EntryLeaderComponent } from './entry-leader/entry-leader.component';

// import { MyPermitsComponent } from './my-permits/my-permits.component';
// import { CommentBoxComponent } from './comment-box/comment-box.component';
// import { ManageDashboardComponent } from './manage-dashboard/manage-dashboard.component';
// import { ViewRegisterComponent } from './view-register/view-register.component';
import { SecurityComponent } from './security/security.component';
import { FAQComponent } from './faq/faq.component';

const routes: Routes = [
  { path: 'home', component: LayoutComponent, pathMatch: 'full' },
  { path: 'createpermit', component: CreatePermitComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'dashboard', component: CreatePermitComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  // { path: 'admin', loadChildren: './admin/admin.module#AdminModule', canActivate: [AuthGuard] },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [RoleGuard] },
  //{ path: 'user-profile', component: UserProfileComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  // { path: "chat", component: CommentBoxComponent, pathMatch: "full", canActivate: [AuthGuard] },
  // { path: "entryleader", component: EntryLeaderComponent, pathMatch: "full", canActivate: [AuthGuard] },
  //{ path: 'user-self-service', component: SelfServiceComponent, pathMatch: 'full' },
  { path: 'user-self-service', loadChildren:() => import('./self-service/self-service.module').then(m => m.SelfServiceModule) },
  //{ path: 'permitmanagement', component: SecurityComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'permitmanagement', loadChildren:() => import('./security/security.module').then(m => m.SecurityModule), canActivate: [AuthGuard] },
  { path: '#/permitmanagement', pathMatch: 'full', redirectTo: 'permitmanagement' },
  { path: 'createpermit/:id', component: PermitComponent, canActivate: [AuthGuard] },
  // { path: 'manage-dashboard/:id', component: ManageDashboardComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  //{ path: 'notification', component: NotificationComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'notification', loadChildren:() => import('./notification/notification.module').then(m => m.NotificationModule), canActivate: [AuthGuard] },
  { path: 'permit/:permitTypeId/:permitId', component: PermitComponent, pathMatch: "full", canActivate: [AuthGuard] },
  // { path: 'mypermits', component: MyPermitsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  // { path: 'firecrew', component: FireCrewComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  // { path: 'viewregister', component: ViewRegisterComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  //{ path: 'faq', component: FAQComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'faq', loadChildren:() => import('./faq/faq.module').then(m => m.FAQModule), canActivate: [AuthGuard] },
  { path: '**', pathMatch: 'full', redirectTo: 'home' },
  { path: '', pathMatch: 'full', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: true,
    initialNavigation: isInIframe() ? 'disabled' : undefined
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export function isInIframe() {
  return window !== window.parent && !window.opener;
}
